import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

const FixSchemeCardArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    if (fieldArrayRenderProps?.value === undefined) {
      pushElementInarray();
    }
  }, []);

  const handleOpenDeleteDialog = () => {
    dispatch(openDialog("oItemDeleteDialog"));
    // dispatch(setOrderDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index - 1 });
      dispatch(closeDialog());
      //   dispatch(setOrderDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        free_good: null,
        article: null,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <>
      {dialogName === "oItemDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Item"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() =>
                    handleDeleteAction(fieldArrayRenderProps.value.length)
                  }
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      <tr>
        {fieldArrayRenderProps.formRenderProps.valueGetter("card_scheme") && (
          <td></td>
        )}
        <td className="text-center fw-600">Free Good</td>
        <td className="text-center fw-600">Article</td>
        <td>
          <div
            style={{
              display: "flex",
              gap: 5,
              justifyContent: "center",
              padding: 0,
            }}
          >
            <IconButton
              onClick={pushElementInarray}
              themeColor={"error"}
              size={"small"}
              fillMode={"solid"}
              type="button"
              style={{
                height: 32,
                width: 32,
              }}
            >
              <FaPlus
                className="absolute-position"
                style={{ fontSize: "16px" }}
              />
            </IconButton>
            <IconButton
              onClick={() => handleOpenDeleteDialog()}
              disabled={fieldArrayRenderProps?.value?.length === 1}
              themeColor={"error"}
              size={"small"}
              fillMode={"solid"}
              type="button"
              style={{
                height: 32,
                width: 32,
              }}
            >
              <MdDelete
                className="absolute-position"
                style={{ fontSize: "16px" }}
              />
            </IconButton>
          </div>
        </td>
        {fieldArrayRenderProps.formRenderProps.valueGetter("free_scheme") && (
          <td></td>
        )}
      </tr>
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((scheme: any, index: number) => (
          <tr>
            {fieldArrayRenderProps.formRenderProps.valueGetter(
              "card_scheme"
            ) && <td></td>}
            <td>
              <Field
                wrapperStyle={{ margin: 0 }}
                name={`scheme.${index}.free_good`}
                component={FormTextField}
              />
            </td>
            <td>
              <Field
                wrapperStyle={{ margin: 0 }}
                name={`scheme.${index}.article`}
                component={FormTextField}
              />
            </td>
            <td></td>
            {fieldArrayRenderProps.formRenderProps.valueGetter(
              "free_scheme"
            ) && <td></td>}
          </tr>
        ))}
    </>
  );
};

export default FixSchemeCardArray;
