import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRights, IRightsInitialState } from "./rightsModel";
import {
  ActiveInactiveRights,
  createRights,
  deleteRights,
  getAllActiveRights,
  getAllRights,
  getRightsByID,
  updateRights,
} from "./services/rights.services";

const initialState: IRightsInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RightsList: [],
  RightsID: null,
  RightsDetail: {
    name: "",
    isactive: 1,
  },
};

const rightsSlice = createSlice({
  name: "rights",
  initialState,
  reducers: {
    clearRightsDetails: (state) => {
      state.RightsDetail = initialState.RightsDetail;
    },
    setRightsID: (state, action) => {
      state.RightsID = action.payload;
    },
    clearRightsID: (state) => {
      state.RightsID = initialState.RightsID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRights.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRights.fulfilled,
      (state, action: PayloadAction<IRights[]>) => {
        state.loading = false;
        state.RightsList = action.payload || [];
      }
    );
    builder.addCase(getAllRights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RightsList = [];
    });
    builder.addCase(createRights.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRights.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRights.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRights.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRights.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRights.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRights.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRights.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRightsByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRightsByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RightsDetail = action.payload;
    });
    builder.addCase(getRightsByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RightsDetail = initialState.RightsDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveRights.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRights.fulfilled,
      (state, action: PayloadAction<IRights[]>) => {
        state.loading = false;
        state.RightsList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RightsList = [];
    });
    builder.addCase(ActiveInactiveRights.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveRights.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveRights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearRightsDetails, clearRightsID, setRightsID } =
  rightsSlice.actions;
export default rightsSlice.reducer;
