import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllUserTypes = createAsyncThunk(
  "UserType/FindAllUserType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/FindAllUserType`,
        {
          user_type: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching User Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createUserType = createAsyncThunk(
  "UserType/InsertUserType",
  async (UserTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/InsertUserType`,
        UserTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserType = createAsyncThunk(
  "UserType/UpdateUserType",
  async (UserTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/UpdateUserType`,
        UserTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteUserType = createAsyncThunk(
  "UserType/DeleteUserType",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/DeleteUserType`,
        { user_type_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserTypeByID = createAsyncThunk(
  "UserType/FindByIDUserType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/FindByIDUserType`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        id: result.id,
        user_type_guid: result.user_type_guid,
        user_type: result.user_type,
        isactive: result.isactive === true ? 1 : 2,
        ...result?.UserTypeRightsAssign?.reduce(
          (
            acc: any,
            { menu_id, rights_id }: { menu_id: any; rights_id: any }
          ) => {
            if (!rights_id) {
              acc[menu_id] = true;
            } else {
              acc[`${menu_id}Rights`] = rights_id.split(",").map(Number);
              acc[menu_id] = true;
            }
            return acc;
          },
          {}
        ),
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveUserTypes = createAsyncThunk(
  "UserType/FindAllActiveUserType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/UserType/FindAllActiveUserType`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching User Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveUserType = createAsyncThunk(
  "UserType/ActiveInActiveUserType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/ActiveInActiveUserType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
