import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
// import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  clearSalaryDateList,
  clearSalaryDetails,
  decreaseActiveStep,
} from "../employeeSlice";
import FormTextField from "../../../components/formFields/FormTextField";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { ISalaryDate } from "../employeeModel";
import {
  getAllSalaryDateByEmployeeID,
  getSalaryStructureByDateAndEmpID,
  updateEmployeeSalaryStructure,
} from "../services/employee.services";
import moment from "moment";
import { LoadingPanel } from "../../../components/layout/Loading";
import { ErrorToast } from "../../../components/toast/Toasts";
import {
  convertNumToIndianCurrency,
  PercentageValue,
} from "../../../_helper/helper";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  getAllActiveProfessionTaxDate,
  getProfessionTaxSlabByDate,
} from "../../ProfessionTaxSlab/services/professionTaxSlab.services";

interface SalaryChangeWatcherProps {
  formRenderProps: FormRenderProps;
  applyFromDate: string;
}
const SalaryChangeWatcher: React.FC<SalaryChangeWatcherProps> = ({
  formRenderProps,
  applyFromDate,
}) => {
  const ApplyFromDate = new Date(applyFromDate);
  const useRefBasic = useRef(false);
  const dispatch = useAppDispatch();

  const ProfessionTaxSlabDateList = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDateList
  );
  const ProfessionTaxSlabDetails = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDetail
  );

  const PFPercent = +formRenderProps.valueGetter("pf_per") || 0;
  const ESIPercent = +formRenderProps.valueGetter("esi_per") || 0;

  const Basic = +formRenderProps.valueGetter("basic") || 0;
  const CellingAmount = +formRenderProps.valueGetter("celling_amount") || 0;
  const HRA = +formRenderProps.valueGetter("hra") || 0;
  const Wash = +formRenderProps.valueGetter("wash") || 0;
  const ProdInsAll = +formRenderProps.valueGetter("prod_ins_all") || 0;
  const Bonus = +formRenderProps.valueGetter("adv_bonus") || 0;
  const Leave = +formRenderProps.valueGetter("adv_leave") || 0;
  const AdvStdBe = +formRenderProps.valueGetter("adv_std_be") || 0;
  const Food = +formRenderProps.valueGetter("food") || 0;
  const DA = +formRenderProps.valueGetter("da") || 0;
  const Other_Earning = +formRenderProps.valueGetter("other_earning") || 0;
  const TotalIncome = +formRenderProps.valueGetter("total_income") || 0;

  const PF = +formRenderProps.valueGetter("pf") || 0;
  const ESI = +formRenderProps.valueGetter("esi") || 0;
  const IT = +formRenderProps.valueGetter("it") || 0;
  const PT = +formRenderProps.valueGetter("pt") || 0;
  const Loan = +formRenderProps.valueGetter("loan") || 0;
  const Advance = +formRenderProps.valueGetter("advance") || 0;
  const LWF = +formRenderProps.valueGetter("lwf") || 0;
  const TDS = +formRenderProps.valueGetter("tds") || 0;
  const Other = +formRenderProps.valueGetter("other") || 0;
  const TotalDeduction = +formRenderProps.valueGetter("total_deduction") || 0;

  const Net_Payble_Salary = formRenderProps.valueGetter("payable_amount") || 0;

  useEffect(() => {
    if (
      ApplyFromDate?.getMonth() + 1 === 6 ||
      ApplyFromDate?.getMonth() + 1 === 12
    ) {
      formRenderProps.onChange("lwf", {
        value: 6,
      });
    } else {
      formRenderProps.onChange("lwf", {
        value: 0,
      });
    }

    if (ApplyFromDate) {
      ProfessionTaxSlabDateList.forEach((taxSlab) => {
        if (
          moment(taxSlab?.effective_from_date, "DD/MM/YYYY").toDate() <=
            ApplyFromDate &&
          ApplyFromDate <=
            (taxSlab?.effective_to_date
              ? moment(taxSlab?.effective_to_date, "DD/MM/YYYY").toDate()
              : new Date())
        ) {
          const EffectiveFromDate = moment(
            taxSlab?.effective_from_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          dispatch(getProfessionTaxSlabByDate(EffectiveFromDate));
        }
      });
    }
  }, [ApplyFromDate.getDate()]);

  useEffect(() => {
    const TaxList = ProfessionTaxSlabDetails?.proffestional_tax;
    const matchingTax = TaxList.find(
      (tax) =>
        tax.salary_from !== null &&
        tax.salary_till !== null &&
        (tax.salary_from ?? 0) <= TotalIncome &&
        TotalIncome <= (tax.salary_till ?? 0)
    );
    if (matchingTax) {
      // console.log("TAX AMOUNT", matchingTax.tax_amount);
      // Set the form value
      formRenderProps.onChange("pt", {
        value: matchingTax.tax_amount || 0,
      });
    } else {
      formRenderProps.onChange("pt", {
        value: 0,
      });
    }
  }, [TotalIncome]);

  useEffect(() => {
    formRenderProps.onChange("pf", {
      value: Math.round(CellingAmount * (PFPercent / 100)),
    });

    if (TotalIncome > 21000) {
      formRenderProps.onChange("esi", {
        value: 0,
      });
      formRenderProps.onChange("esi_per", {
        value: 0,
      });
    } else {
      formRenderProps.onChange("esi", {
        value: Math.ceil(TotalIncome * (ESIPercent / 100)),
      });
    }
    formRenderProps.onChange("total_income", {
      value: Math.ceil(
        Basic +
          DA +
          HRA +
          Wash +
          Bonus +
          Leave +
          Food +
          Other_Earning +
          AdvStdBe +
          ProdInsAll
      ),
    });
    formRenderProps.onChange("total_deduction", {
      value: Math.ceil(PF + ESI + PT + IT + LWF + Advance + Loan + Other + TDS),
    });
    formRenderProps.onChange("payable_amount", {
      value: Math.ceil(+(TotalIncome - TotalDeduction)),
    });
  }, [
    Basic,
    DA,
    PFPercent,
    ESIPercent,
    HRA,
    Wash,
    Bonus,
    Leave,
    Food,
    Other_Earning,
    AdvStdBe,
    ProdInsAll,
    TotalIncome,
    Net_Payble_Salary,
    CellingAmount,

    PF,
    ESI,
    PT,
    IT,
    LWF,
    Advance,
    Loan,
    Other,
    TDS,

    TotalIncome,
    TotalDeduction,
  ]);

  useEffect(() => {
    if (useRefBasic.current) {
      formRenderProps.onChange("celling_amount", {
        value: Math.ceil(+Basic),
      });
    } else {
      useRefBasic.current = true;
    }
  }, [Basic]);

  useEffect(() => {
    formRenderProps.onChange("pf_per", { value: PercentageValue(PFPercent) });
    formRenderProps.onChange("esi_per", { value: PercentageValue(ESIPercent) });
  }, [PFPercent, ESIPercent]);

  return null;
};

const SalaryStructure = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const EmployeeGUID = location.state?.EmployeeGUID;
  const EmployeeID = location.state?.EmployeeID;
  const formloading = useAppSelector((state) => state.employee.formloading);
  const gridRef = useRef<any>(null);
  const [selectedDate, setSelectedDate] = useState<ISalaryDate>();
  const SalaryDetails = useAppSelector(
    (state) => state?.employee?.SalaryDetails
  );
  const SalaryDateList = useAppSelector(
    (state) => state?.employee?.SalaryDateList
  );
  const [formKey, setFormKey] = useState(1);
  const [editMode, setEditMode] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(getAllActiveProfessionTaxDate());
    return () => {
      dispatch(clearSalaryDateList());
      dispatch(clearSalaryDetails());
    };
  }, []);

  useEffect(() => {
    dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
  }, [EmployeeID]);

  useEffect(() => {
    if (SalaryDateList?.length > 0) {
      const payload = {
        employee_id: EmployeeID,
        apply_from_date: moment(
          SalaryDateList[0]?.apply_from_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };
      dispatch(getSalaryStructureByDateAndEmpID(payload));
      setSelectedDate(SalaryDateList[0]);
    } else {
      setSelectedDate(undefined);
    }
  }, [SalaryDateList]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalaryDetails]);

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
    setFormKey(formKey + 1);
    dispatch(clearSalaryDetails());
    setSelectedDate(undefined);

    if (editMode) {
      const payload = {
        employee_id: EmployeeID,
        apply_from_date: moment(
          SalaryDateList[0]?.apply_from_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };
      dispatch(getSalaryStructureByDateAndEmpID(payload));
      setSelectedDate(SalaryDateList[0]);
    }
  };

  const handleSubmit = async (values: any) => {
    const formdata = {
      employee_id: EmployeeID,
      basic: +values?.basic,
      celling_amount: +values?.celling_amount,
      da_per: +values?.da_per,
      da: +values?.da,
      hra_per: +values?.hra_per,
      hra: +values?.hra,
      wash: +values?.wash,
      adv_bonus: +values?.adv_bonus,
      adv_leave: +values?.adv_leave,
      prod_ins_all: +values?.prod_ins_all,
      adv_std_be: +values?.adv_std_be,
      food: +values?.food,
      other_earning: +values?.other_earning,
      pf_per: +values?.pf_per,
      pf: +values?.pf,
      esi_per: +values?.esi_per,
      esi: +values?.esi,
      pt: +values?.pt,
      it: +values?.it,
      lwf: +values?.lwf,
      tds: +values?.tds,
      advance: +values?.advance,
      loan: +values?.loan,
      other: +values?.other,
      total_income: +values?.total_income,
      total_deduction: +values?.total_deduction,
      payable_amount: +values?.payable_amount,
      apply_from_date: values?.apply_from_date
        ? moment(values?.apply_from_date).format("YYYY-MM-DD")
        : "",
    };
    try {
      if (!values?.salary_guid) {
        if (!formdata?.apply_from_date) {
          ErrorToast("Date Field is required!");
          return;
        }
        const response = await dispatch(
          updateEmployeeSalaryStructure(formdata)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormKey(formKey + 1);
          dispatch(clearSalaryDetails());
          dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
          setEditMode(false);
        }
      } else {
        const response = await dispatch(
          updateEmployeeSalaryStructure({
            ...formdata,
            salary_guid: values?.salary_guid,
            apply_from_date: formdata?.apply_from_date
              ? moment(formdata?.apply_from_date).format("YYYY-MM-DD")
              : null,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormKey(formKey + 1);
          dispatch(clearSalaryDetails());
          dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleDateChange = (event: ComboBoxChangeEvent) => {
    setSelectedDate(event.target.value);
    if (!event.target.value) {
      dispatch(clearSalaryDetails());
    }
    const payload = {
      employee_id: EmployeeID,
      apply_from_date: moment(
        event.target.value?.apply_from_date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD"),
    };
    if (event.target.value?.apply_from_date) {
      dispatch(getSalaryStructureByDateAndEmpID(payload));
    }
  };

  return (
    <>
      {formloading && <LoadingPanel gridRef={gridRef} />}
      <ShadowCard style={{ padding: 10 }}>
        <Form
          onSubmit={handleSubmit}
          key={formKey}
          initialValues={SalaryDetails}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <SalaryChangeWatcher
                formRenderProps={formRenderProps}
                applyFromDate={formRenderProps.valueGetter("apply_from_date")}
              />
              <div
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                {editMode && (
                  <Field
                    name="apply_from_date"
                    label="Date"
                    format="dd/MM/yyyy"
                    wrapperStyle={{ width: 300, margin: "0", float: "left" }}
                    component={FormDatePicker}
                    // minDate={moment().toDate()}
                    validator={requiredValidator}
                    astrike={true}
                  />
                )}
                {!editMode && (
                  <ComboBox
                    data={SalaryDateList}
                    textField="apply_from_date"
                    dataItemKey="apply_from_date"
                    label="Date"
                    style={{ width: 300 }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                )}
                <Button
                  style={{ marginTop: 21, marginLeft: 10 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleEditMode}
                >
                  {editMode ? "Cancel" : "Add"}
                </Button>
              </div>
              <div className="container p-0" style={{ maxWidth: 850 }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  {/* Salary Structure */}
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      border: "1px solid #ccc",
                      // backgroundColor:"#000",
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <GridLayout
                      style={{ marginRight: 10 }}
                      gap={{ rows: 10, cols: 10 }}
                      cols={[{ width: "50%" }, { width: "50%" }]}
                    >
                      {/* Earnings */}
                      <GridLayoutItem>
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Earnings"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="basic"
                              label="Basic"
                              wrapperStyle={{ width: "50%" }}
                              component={FormTextField}
                              type="number"
                              placeholder="Basic"
                              validator={requiredValidator}
                              astrike={true}
                            />
                            <Field
                              name="celling_amount"
                              label="Celling Amount"
                              wrapperStyle={{ width: "50%" }}
                              component={FormTextField}
                              type="number"
                              placeholder="Celling Amount"
                              // validator={requiredValidator}
                              // astrike={true}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="hra"
                              label="HRA"
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              format="n2"
                              placeholder="HRA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="wash"
                              label="Wash"
                              type="number"
                              placeholder="Wash"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="prod_ins_all"
                              label="Production Incentive Allowance"
                              type="number"
                              placeholder="Production Incentive Allowance"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_bonus"
                              label="Advance Bonus"
                              type="number"
                              placeholder="Advance Bonus"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_leave"
                              label="Advance Leave"
                              type="number"
                              placeholder="Advance Leave"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_std_be"
                              label="Advance Standard Benefit"
                              type="number"
                              placeholder="Advance Standard Benefit"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="food"
                              label="Food"
                              type="number"
                              placeholder="Food"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            {/* <Field
                              name="da_per"
                              label="% DA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            /> */}
                            <Field
                              name="da"
                              label="DA"
                              wrapperStyle={{ width: "100%" }}
                              format="n2"
                              type="number"
                              placeholder="DA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="other_earning"
                              label="Other"
                              type="number"
                              placeholder="Other Earning"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>

                      {/* Deduction */}
                      <GridLayoutItem
                        style={{
                          borderLeft: "2px solid #000",
                          paddingLeft: 10,
                        }}
                      >
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Deduction"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="pf_per"
                              label="% PF"
                              type="number"
                              wrapperStyle={{ width: "40%" }}
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="pf"
                              label="PF"
                              wrapperStyle={{ width: "100%" }}
                              disabled
                              type="number"
                              placeholder="PF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="esi_per"
                              label="% ESI"
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="esi"
                              label="ESI"
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              disabled
                              placeholder="ESI"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="it"
                              label="IT"
                              type="number"
                              placeholder="IT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="pt"
                              label="PT"
                              type="number"
                              placeholder="PT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="loan"
                              label="Loan"
                              type="number"
                              placeholder="Loan"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="advance"
                              label="Advance"
                              type="number"
                              placeholder="Advance"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="lwf"
                              label="LWF"
                              type="number"
                              placeholder="LWF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="tds"
                              label="TDS"
                              type="number"
                              placeholder="TDS"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="other"
                              label="Other"
                              type="number"
                              placeholder="Other"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                  {/* <GridLayoutItem></GridLayoutItem>

                  <GridLayoutItem></GridLayoutItem> */}
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      {" "}
                      Gross Earning:
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_income")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginLeft: 10,
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      Gross Deduction :
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_deduction")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      padding: "0 0 11px  9px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        backgroundColor: "rgb(231, 230, 230)",
                        padding: "5px 20px",
                        borderRadius: "5px",
                        marginTop: "13px",
                        // minHeight: "68px",
                      }}
                    >
                      <Typography.h5 style={{ color: "green", margin: "0" }}>
                        Net Salary:
                      </Typography.h5>
                      <Typography.h4 style={{ color: "green", margin: "0" }}>
                        {convertNumToIndianCurrency(
                          formRenderProps.valueGetter("payable_amount")
                        )}
                      </Typography.h4>
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ textAlign: "end", marginTop: 20 }}
                    colSpan={4}
                  >
                    <Button
                      type="button"
                      fillMode="outline"
                      themeColor="primary"
                      style={{ marginRight: 10 }}
                      onClick={() => dispatch(decreaseActiveStep())}
                    >
                      Previous
                    </Button>

                    <ButtonWithLoading
                      label={SalaryDetails.salary_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || formloading}
                      loading={formloading}
                    />
                    <Button
                      fillMode="solid"
                      themeColor="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => navigate("/employee")}
                    >
                      Go to Employee List
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </div>
              {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
            </FormElement>
          )}
        />
      </ShadowCard>
    </>
  );
};

export default SalaryStructure;
