import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  pincodeWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { findIndex } from "lodash";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveStates } from "../state/services/state.services";
import { IState } from "../state/stateModel";
import { ICountry } from "../country/countryModel";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import { clearAreaDetails, clearFilterArea } from "./areaSlice";
import {
  createArea,
  getAllActiveAreas,
  getAllAreas,
  getAreaByID,
  updateArea,
} from "./services/area.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { ICity } from "../city/cityModel";
import { getAllActiveCities } from "../city/services/city.services";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("city_id");

  useEffect(() => {
    const city_id = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { id: city_id });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("state_id", { value: city.state_id });
      formRenderProps.onChange("country_id", { value: city.country_id });
    } else {
      formRenderProps.onChange("city_id", { value: "" });
      formRenderProps.onChange("state_id", { value: "" });
      formRenderProps.onChange("country_id", { value: "" });
    }
  }, [cityID]);
  return null;
};

const CreateArea: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const [formKey, setFormKey] = useState(1);

  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const AreaDetail = useAppSelector((state) => state.area.AreaDetail);
  const area_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.city.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    return () => {
      dispatch(clearAreaDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (area_guid) {
      dispatch(getAreaByID(area_guid));
    }
  }, [area_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [AreaDetail]);

  const handleSubmit = async (values: any) => {
    try {
      const listingPayload = {
        area_name: "",
        city_name: "",
        country_name: "",
        state_name: "",
        sequence_no: 0,
      };
      if (!area_guid) {
        const payload = {
          area_name: values?.area_name ? values?.area_name : "",
          sequence_no: values?.sequence_no ? +values?.sequence_no : null,
          city_id: values?.city_id ? +values?.city_id : null,
          state_id: values?.state_id ? +values?.state_id : null,
          country_id: values?.country_id ? +values?.country_id : null,
          pincode: values?.pincode ? +values?.pincode : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createArea(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearAreaDetails());
          dispatch(clearFilterArea());
          isIndirect
            ? dispatch(getAllActiveAreas())
            : dispatch(getAllAreas(listingPayload));
        }
      } else {
        const payload = {
          id: values?.id ? values?.id : null,
          area_guid: area_guid,
          area_name: values?.area_name ? values?.area_name : "",
          sequence_no: values?.sequence_no ? +values?.sequence_no : null,
          city_id: values?.city_id ? +values?.city_id : null,
          state_id: values?.state_id ? +values?.state_id : null,
          country_id: values?.country_id ? +values?.country_id : null,
          pincode: values?.pincode ? +values?.pincode : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateArea(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearAreaDetails());
          dispatch(clearFilterArea());
          isIndirect
            ? dispatch(getAllActiveAreas())
            : dispatch(getAllAreas(listingPayload));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      key={formKey}
      onSubmit={handleSubmit}
      initialValues={AreaDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {area_guid ? "Update Area" : "Add Area"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="area_name"
                label="Area Name"
                placeholder="i.e. Vatva"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                wrapperClassName="right-alighned-field"
                name="sequence_no"
                label="Sequence No"
                placeholder="0"
                component={FormNumericTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"city_id"}
                name={"city_id"}
                label={"City Name"}
                placeholder="i.e. Ahmedabad"
                component={FormSelectionField}
                options={CityList?.map((city: ICity) => {
                  return {
                    value: city?.id,
                    label: city?.city_name,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"state_id"}
                name={"state_id"}
                label={"State Name"}
                placeholder="i.e. Gujarat"
                component={FormSelectionField}
                disabled={true}
                options={StateList?.map((state: IState) => {
                  return {
                    value: state?.id,
                    label: state?.state_name,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"country_id"}
                name={"country_id"}
                label={"Country Name"}
                placeholder="i.e. India"
                component={FormSelectionField}
                disabled={true}
                options={CountryList.map((country: ICountry) => {
                  return {
                    value: country?.id,
                    label: country?.country_name,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="pincode"
                label="Pincode"
                type="number"
                placeholder="i.e. 380000"
                minNumber="0"
                component={FormTextField}
                validator={pincodeWOValueValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={area_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
            <CityChangeWatcher
              CityList={CityList}
              formRenderProps={formRenderProps}
            />
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateArea;
