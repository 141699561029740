import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { Typography } from "@progress/kendo-react-common";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getDeliveryDetailsByID,
  updateDriverVisit,
} from "./services/driverVisit.services";
import { clearDriverVisitDetails } from "./driverVisitSlice";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import useWindowSize from "../../_hooks/useWindowSize";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { getTimeDuration } from "../../_helper/helper";
import FormTextArea from "../../components/formFields/FormTextArea";

const MAX_WIDTH = 768;

export const APPROVE_STATUS = [
  { label: "Approved", value: "Approved" },
  { label: "Not Approved", value: "Not Approved" },
];

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const ExpChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const diesel = formRenderProps.valueGetter("diesel") || 0;
  const tea_coffee = formRenderProps.valueGetter("tea_coffee") || 0;
  const food_snacks = formRenderProps.valueGetter("food_snacks") || 0;
  const toll_tax_fasttag = formRenderProps.valueGetter("toll_tax_fasttag") || 0;
  const transport_receipt =
    formRenderProps.valueGetter("transport_receipt") || 0;
  const misc_exp = formRenderProps.valueGetter("misc_exp") || 0;
  const other_exp = formRenderProps.valueGetter("other_exp") || 0;

  useEffect(() => {
    formRenderProps.onChange("total_exp", {
      value:
        +diesel +
          +tea_coffee +
          +food_snacks +
          +toll_tax_fasttag +
          +transport_receipt +
          +misc_exp +
          +other_exp || 0,
    });
  }, [
    diesel,
    tea_coffee,
    food_snacks,
    toll_tax_fasttag,
    transport_receipt,
    misc_exp,
    other_exp,
  ]);

  return null;
};

const CreateDriverVisit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = location.state?.id;

  const loading = useAppSelector((state) => state.drivervisit.loading);
  const DriverVisitDetails = useAppSelector(
    (state) => state.drivervisit.DriverVisitDetails
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [DriverVisitDetails]);

  useEffect(() => {
    if (id) {
      dispatch(getDeliveryDetailsByID(+id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearDriverVisitDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (id) {
      try {
        const editPayload = {
          id: id ? +id : null,
          diesel: values?.diesel ? +values?.diesel : null,
          tea_coffee: values?.tea_coffee ? +values?.tea_coffee : null,
          food_snacks: values?.food_snacks ? +values?.food_snacks : null,
          toll_tax_fasttag: values?.toll_tax_fasttag
            ? +values?.toll_tax_fasttag
            : null,
          transport_receipt: values?.transport_receipt
            ? +values?.transport_receipt
            : null,
          misc_exp: values?.misc_exp ? +values?.misc_exp : null,
          other_exp: values?.other_exp ? +values?.other_exp : null,
          claim_status: values?.claim_status ? values?.claim_status : "",
          remarks: values?.remarks ? values?.remarks : "",
          driver_remarks: values?.driver_remarks ? values?.driver_remarks : "",
        };
        const response = await dispatch(updateDriverVisit(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/drivervisit");
          dispatch(clearDriverVisitDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    }
  };

  const handleFormClose = () => {
    navigate("/drivervisit");
  };

  return (
    <div className="container-fluid">
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={DriverVisitDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>{"Approve Driver Visit"}</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4} className="p-2">
                  <div
                    className="row"
                    style={{
                      padding: "10px 0",
                      background: "whitesmoke",
                      borderRadius: "10px",
                      margin: "0 -8px",
                    }}
                  >
                    <div className="col-lg-6 mb-2">
                      <b>Route : </b>&nbsp;
                      <span>{DriverVisitDetails?.route_name}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>Helper : </b>&nbsp;
                      <span>{DriverVisitDetails?.helper_name}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>Visit Start Time : </b>&nbsp;
                      <span>{DriverVisitDetails?.session_start}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>Visit End Time : </b>&nbsp;
                      <span>{DriverVisitDetails?.session_end}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>Start KM : </b>&nbsp;
                      <span>{DriverVisitDetails?.start_km}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>End KM : </b>&nbsp;
                      <span>{DriverVisitDetails?.end_km}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>Total KM : </b>&nbsp;
                      <span>{DriverVisitDetails?.total_km}</span>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <b>Vehicle No. : </b>&nbsp;
                      <span>{DriverVisitDetails?.vehicle_no}</span>
                    </div>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4} className="p-2">
                  <div className="row" style={{ marginLeft: -8 }}>
                    <div className="col-12 p-0">
                      <Typography.h4 className="mb-0">
                        Distributer List
                      </Typography.h4>
                    </div>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <DistributorGridComponent />
                </GridLayoutItem>
                <GridLayoutItem colSpan={4} className="p-2">
                  <div className="row" style={{ marginLeft: -8 }}>
                    <div className="col-12 p-0">
                      <Typography.h4 className="mb-0">
                        Expense Details
                      </Typography.h4>
                    </div>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"diesel"}
                    label="Diesel"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"tea_coffee"}
                    label="Tea / Coffee"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"food_snacks"}
                    label="Food / Snacks"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"toll_tax_fasttag"}
                    label="Toll Tax / Fast Tag"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"transport_receipt"}
                    label="Transport"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"misc_exp"}
                    label="Misc."
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"other_exp"}
                    label="Others"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={"total_exp"}
                    label="Total"
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    disabled={true}
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="claim_status"
                    label="Status"
                    placeholder="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={APPROVE_STATUS?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name={"remarks"}
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={"driver_remarks"}
                    label="Driver Remarks"
                    placeholder="Driver Remarks"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={"Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ExpChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
      {/* </div> */}
    </div>
  );
};

const DistributorGridComponent: React.FC = () => {
  const DriverVisitDetails = useAppSelector(
    (state) => state.drivervisit.DriverVisitDetails
  );
  const { width } = useWindowSize();

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)", padding: 0 }}
        filterable={false}
        data={DriverVisitDetails?.distributerlists}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="account_name"
          title="Name"
          width={width > MAX_WIDTH ? "unset" : "250px"}
        />
        <Column
          field="contact_person_name"
          title="Person Name"
          width={width > MAX_WIDTH ? "unset" : "250px"}
        />
        <Column
          field="checkin"
          title="Check In"
          width={width > MAX_WIDTH ? "unset" : "150px"}
          filterable={false}
        />
        <Column
          field="checkout"
          title="Check Out"
          width={width > MAX_WIDTH ? "unset" : "150px"}
          filterable={false}
        />
        <Column
          field="duration"
          title="Duration"
          width={width > MAX_WIDTH ? "unset" : "150px"}
          cell={(props: any) => (
            <td>
              {props.dataItem?.checkin && props.dataItem?.checkout
                ? getTimeDuration(
                    props.dataItem?.checkin,
                    props.dataItem?.checkout
                  )
                : ""}
            </td>
          )}
        />
      </KendoGrid>
    </>
  );
};

export default CreateDriverVisit;
