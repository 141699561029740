import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import { findAllActiveItem } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import {
  createOutwardPackingMaterial,
  getOutwardPackingMaterialByID,
  updateOutwardPackingMaterial,
} from "./services/outwardPackingMaterial.services";
import { clearOutwardPackingDetails } from "./outwardPackingMaterialSlice";
import OutwardPackingMaterialArray from "./OutwardPackingMaterialArray";
import moment from "moment";
import { FINANCIAL_YEAR, OUTWARDTYPE } from "../../_contstants/common";

const CreateOutwardPackingMaterial: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const outward_packing_material_guid =
    location.state?.outward_packing_material_guid;
  const gridRef = useRef<any>(null);

  const loading = useAppSelector(
    (state) => state.outwardPackingMaterial.loading
  );
  const OutwardPackingDetail = useAppSelector(
    (state) => state.outwardPackingMaterial.OutwardPackingDetail
  );
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [OutwardPackingDetail]);

  useEffect(() => {
    if (outward_packing_material_guid) {
      const payload = {
        outward_packing_material_guid: outward_packing_material_guid,
      };
      dispatch(getOutwardPackingMaterialByID(payload));
    }
  }, [outward_packing_material_guid]);

  useEffect(() => {
    dispatch(findAllActiveItem());
    dispatch(findAllActiveUnit());

    return () => {
      dispatch(clearOutwardPackingDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (outward_packing_material_guid) {
      try {
        const updatePayload = {
          outward_packing_material_guid:
            OutwardPackingDetail?.outward_packing_material_guid,
          outward_type: values?.outward_type ? values?.outward_type : "",
          outward_packing_material_date: values?.outward_packing_material_date
            ? moment(values?.outward_packing_material_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          financial_year: FINANCIAL_YEAR,
          OutwardPackingMaterialDetails: values?.OutwardPackingMaterialDetails
            ? values?.OutwardPackingMaterialDetails?.map((outwarditem: any) => {
                return {
                  id: outwarditem?.id ? +outwarditem?.id : 0,
                  item_id: outwarditem?.item_id ? +outwarditem?.item_id : null,
                  outward_item_qty: outwarditem?.outward_item_qty
                    ? +outwarditem?.outward_item_qty
                    : 0,
                  unit_id: outwarditem?.unit_id ? +outwarditem?.unit_id : null,
                  roll: outwarditem?.roll ? +outwarditem?.roll : null,
                };
              })
            : [],
        };
        const response = await dispatch(
          updateOutwardPackingMaterial(updatePayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/outwardpackingmaterial");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          outward_type: values?.outward_type ? values?.outward_type : "",
          outward_packing_material_date: values?.outward_packing_material_date
            ? moment(values?.outward_packing_material_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          financial_year: FINANCIAL_YEAR,
          OutwardPackingMaterialDetails: values?.OutwardPackingMaterialDetails
            ? values?.OutwardPackingMaterialDetails?.map((outwarditem: any) => {
                return {
                  item_id: outwarditem?.item_id ? +outwarditem?.item_id : null,
                  outward_item_qty: outwarditem?.outward_item_qty
                    ? +outwarditem?.outward_item_qty
                    : 0,
                  unit_id: outwarditem?.unit_id ? +outwarditem?.unit_id : null,
                  roll: outwarditem?.roll ? +outwarditem?.roll : null,
                };
              })
            : [],
        };
        const response = await dispatch(
          createOutwardPackingMaterial(insertPayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/outwardpackingmaterial");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleOutwardPackingNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          outward_packing_no: (ev.target as HTMLInputElement).value,
        };
        dispatch(getOutwardPackingMaterialByID(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={OutwardPackingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {outward_packing_material_guid
                      ? "Update Outward Packing Material"
                      : "Add Outward Packing Material"}
                  </Typography.h4>
                </GridLayoutItem>
                {outward_packing_material_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      name="outward_packing_no"
                      onKeyDown={handleOutwardPackingNoChange}
                      label="Outward Packing No"
                      placeholder="Outward Packing No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {outward_packing_material_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="outward_packing_material_date"
                    label="Outward Packing Material Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="outward_type"
                    label="Outward Type"
                    placeholder="Outward Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={OUTWARDTYPE?.map((outwardtype: any) => {
                      return {
                        value: outwardtype?.value,
                        label: outwardtype?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "100%" }]}
              >
                <GridLayoutItem>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ borderBottom: "1px solid lightgray" }}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={OutwardPackingMaterialArray}
                    name="OutwardPackingMaterialDetails"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={outward_packing_material_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/outwardpackingmaterial")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateOutwardPackingMaterial;
