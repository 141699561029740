import React, { useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getDeliveryDetailsByID } from "./services/driverVisit.services";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import useWindowSize from "../../_hooks/useWindowSize";
import { Button } from "@progress/kendo-react-buttons";
import { clearDriverVisitDetails } from "./driverVisitSlice";
import { getTimeDuration } from "../../_helper/helper";
import { LoadingPanel } from "../../components/layout/Loading";

const MAX_WIDTH = 768;

const DriverRouteVisitView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const UserTypeIDString = useAppSelector(
    (state) => state.login.loginDetails.UserTypeID
  );
  const UserTypeID = parseInt(UserTypeIDString);

  const DriverVisitDetails = useAppSelector(
    (state) => state.drivervisit.DriverVisitDetails
  );
  const loading = useAppSelector((state) => state.drivervisit.loading);

  useEffect(() => {
    if (id) {
      dispatch(getDeliveryDetailsByID(+id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearDriverVisitDetails());
    };
  }, []);

  const gridRef = React.useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div className="container-fluid">
        <div className={`container ${UserTypeID === 4 ? "pt-3" : ""}`}>
          <div className="row">
            <div className="col-12 p-0">
              <Typography.h4>Driver Visit View</Typography.h4>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "10px 0",
              background: "whitesmoke",
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-6 mb-2">
              <b>Route : </b>&nbsp;
              <span>{DriverVisitDetails?.route_name}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Helper : </b>&nbsp;
              <span>{DriverVisitDetails?.helper_name}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Visit Start Time : </b>&nbsp;
              <span>{DriverVisitDetails?.session_start}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Visit End Time : </b>&nbsp;
              <span>{DriverVisitDetails?.session_end}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Start KM : </b>&nbsp;
              <span>{DriverVisitDetails?.start_km}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>End KM : </b>&nbsp;
              <span>{DriverVisitDetails?.end_km}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Total KM : </b>&nbsp;
              <span>{DriverVisitDetails?.total_km}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Vehicle No. : </b>&nbsp;
              <span>{DriverVisitDetails?.vehicle_no}</span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 p-0">
              <Typography.h4>Distributer List</Typography.h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <DistributorGridComponent />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 p-0">
              <Typography.h4>Expense Details</Typography.h4>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "10px 0",
              background: "whitesmoke",
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-6 mb-2">
              <b>Diesel : </b>&nbsp;
              <span>{DriverVisitDetails?.diesel || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Tea/Coffee : </b>&nbsp;
              <span>{DriverVisitDetails?.tea_coffee || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Food/Snacks : </b>&nbsp;
              <span>{DriverVisitDetails?.food_snacks || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Toll Tax : </b>&nbsp;
              <span>{DriverVisitDetails?.toll_tax_fasttag || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Transport : </b>&nbsp;
              <span>{DriverVisitDetails?.transport_receipt || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Miscellaneous : </b>&nbsp;
              <span>{DriverVisitDetails?.misc_exp || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Others : </b>&nbsp;
              <span>{DriverVisitDetails?.other_exp || 0}</span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Total : </b>&nbsp;
              <span>
                {(DriverVisitDetails?.diesel
                  ? +DriverVisitDetails?.diesel
                  : 0) +
                  (DriverVisitDetails?.tea_coffee
                    ? +DriverVisitDetails?.tea_coffee
                    : 0) +
                  (DriverVisitDetails?.food_snacks
                    ? +DriverVisitDetails?.food_snacks
                    : 0) +
                  (DriverVisitDetails?.toll_tax_fasttag
                    ? +DriverVisitDetails?.toll_tax_fasttag
                    : 0) +
                  (DriverVisitDetails?.transport_receipt
                    ? +DriverVisitDetails?.transport_receipt
                    : 0) +
                  (DriverVisitDetails?.misc_exp
                    ? +DriverVisitDetails?.misc_exp
                    : 0) +
                  (DriverVisitDetails?.other_exp
                    ? +DriverVisitDetails?.other_exp
                    : 0)}
              </span>
            </div>
          </div>
          <div
            className="row mt-2"
            style={{
              padding: "10px 0",
              background: "whitesmoke",
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-6 mb-2">
              <b>Status : </b>&nbsp;
              <span
                style={{
                  color:
                    DriverVisitDetails?.claim_status === "Approved"
                      ? "green"
                      : "red",
                }}
              >
                {DriverVisitDetails?.claim_status}
              </span>
            </div>
            <div className="col-lg-6 mb-2">
              <b>Remarks : </b>&nbsp;
              <span>{DriverVisitDetails?.remarks}</span>
            </div>
            <div className="col-12 mb-2">
              <b>Driver Remarks : </b>&nbsp;
              <span>{DriverVisitDetails?.driver_remarks}</span>
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col-12 p-0 text-end">
              <Button
                themeColor={"primary"}
                fillMode={"solid"}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DistributorGridComponent: React.FC = () => {
  const DriverVisitDetails = useAppSelector(
    (state) => state.drivervisit.DriverVisitDetails
  );
  const { width } = useWindowSize();

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)", padding: 0 }}
        filterable={false}
        data={DriverVisitDetails?.distributerlists}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="account_name"
          title="Name"
          width={width > MAX_WIDTH ? "unset" : "250px"}
        />
        <Column
          field="contact_person_name"
          title="Person Name"
          width={width > MAX_WIDTH ? "unset" : "250px"}
        />
        <Column
          field="checkin"
          title="Check In"
          width={width > MAX_WIDTH ? "unset" : "150px"}
          filterable={false}
        />
        <Column
          field="checkout"
          title="Check Out"
          width={width > MAX_WIDTH ? "unset" : "150px"}
          filterable={false}
        />
        <Column
          field="duration"
          title="Duration"
          width={width > MAX_WIDTH ? "unset" : "150px"}
          cell={(props: any) => (
            <td>
              {props.dataItem?.checkin && props.dataItem?.checkout
                ? getTimeDuration(
                    props.dataItem?.checkin,
                    props.dataItem?.checkout
                  )
                : ""}
            </td>
          )}
        />
      </KendoGrid>
    </>
  );
};

export default DriverRouteVisitView;
