import React from "react";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { CgDanger } from "react-icons/cg";
const FormTimePicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    format = "HH:mm",
    wrapperStyle,
    astrike = false,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className="k-form-label"
      >
        {label} {astrike && <span style={{ color: "red" }}>*</span>}
      </Label>
      <div className={"k-form-field-wrap"}>
        <TimePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          format={format}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger /> {validationMessage}
            </span>
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormTimePicker;
