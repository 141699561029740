import React, { useCallback, useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { setProductionDeleteIndex } from "./productionSlice";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { IProcess } from "../process/processModel";
import { useLocation } from "react-router-dom";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import _ from "lodash";
import { formatIndianNumberForQty } from "../../_helper/helper";

const ProductionItemDetailsArray2 = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const Production_guid = location.state?.Production_guid;
  const ProductionDeleteIndex = useAppSelector(
    (state) => state.production.ProductionDeleteIndex
  );
  const ProductionDetail = useAppSelector(
    (state) => state.production.ProductionDetail
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const ProcessList = useAppSelector((state) => state.process.ProcessList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("productionItemDeleteDialog"));
    dispatch(setProductionDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Production/DeleteProductionItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setProductionDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setProductionDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.process_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno.grn_no}`,
              label: `${grnno.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        fieldArrayRenderProps?.formRenderProps.onChange(
          `ProductionItemDetails.${index}.grnNoOptions`,
          {
            value: result,
          }
        );
      } else {
        fieldArrayRenderProps?.formRenderProps.onChange(
          `ProductionItemDetails.${index}.grnNoOptions`,
          {
            value: [],
          }
        );
        fieldArrayRenderProps?.formRenderProps.onChange(
          `ProductionItemDetails.${index}.grn_no`,
          {
            value: null,
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.grn_no && item?.process_id) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: +item.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getCurrentStock(payload));
        fieldArrayRenderProps?.formRenderProps.onChange(
          `ProductionItemDetails.${index}.stock_qty`,
          {
            value: response.payload?.actual_stock || 0,
          }
        );
        if (response.payload?.actual_stock < item?.used_qty) {
          fieldArrayRenderProps?.formRenderProps.onChange(
            `ProductionItemDetails.${index}.used_qty`,
            {
              value: response.payload?.actual_stock,
            }
          );
        }
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const handleFieldChange = (field: string, index: number, item: any) => {
    switch (field) {
      case "item_id":
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
        break;
      case "grn_no":
        fetchCurrentStock(index, item);
        break;
      case "process_id":
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
        break;
      default:
        break;
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((field: any, index: number, item: any) => {
      handleFieldChange(field, index, item);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (field: any, index: number, item: any) => {
    debouncedHandleProductChange(field, index, item);
  };

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        bom_qty: null,
        grn_no: null,
        stock_qty: null,
        used_qty: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps.value?.length < 1 && pushElementInarray()}
      <GridLayoutItem className="bg-dark text-white fw-600">
        <GridLayout
          style={{ marginRight: 30, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={[
            { width: "22%" },
            { width: "27%" },
            { width: "11%" },
            { width: "13%" },
            { width: "11%" },
            { width: "11%" },
            { width: "5%" },
          ]}
        >
          <GridLayoutItem>Department Name</GridLayoutItem>
          <GridLayoutItem>Item Name</GridLayoutItem>
          <GridLayoutItem>BOM Qty</GridLayoutItem>
          <GridLayoutItem>GRN / Lot No</GridLayoutItem>
          <GridLayoutItem>Stock Qty</GridLayoutItem>
          <GridLayoutItem colSpan={2}>Used Qty</GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
      {dialogName === "productionItemDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Item"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ProductionDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (ProductionItemDetails: any, index: number) => (
            <GridLayoutItem key={index}>
              {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
              <GridLayout
                style={{ marginRight: 30, padding: 10 }}
                gap={{ rows: 10, cols: 5 }}
                cols={[
                  { width: "22%" },
                  { width: "27%" },
                  { width: "11%" },
                  { width: "13%" },
                  { width: "11%" },
                  { width: "11%" },
                  { width: "5%" },
                ]}
              >
                <GridLayoutItem ref={InputRef}>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`ProductionItemDetails.${index}.process_id`}
                    placeholder="Process"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={() =>
                      handleFieldChange(
                        `process_id`,
                        index,
                        fieldArrayRenderProps.value[index]
                      )
                    }
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`ProductionItemDetails.${index}.item_id`}
                    placeholder="Item"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    onChange={() =>
                      handleChange(
                        `item_id`,
                        index,
                        fieldArrayRenderProps.value[index]
                      )
                    }
                    fetchIncrementalData={(search: any) =>
                      fieldArrayRenderProps?.handleItemSearchChange(
                        search,
                        `ProductionItemDetails.${index}.item_options`,
                        fieldArrayRenderProps.formRenderProps
                      )
                    }
                    options={
                      fieldArrayRenderProps?.value[index]?.item_options?.map(
                        (item: any) => {
                          return {
                            value: item?.id,
                            label: item?.product_name,
                          };
                        }
                      ) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`ProductionItemDetails.${index}.bom_qty`}
                    placeholder="0"
                    disabled={true}
                    component={FormNumericTextField}
                    format="n3"
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`ProductionItemDetails.${index}.grn_no`}
                    placeholder="GRN / Lot No"
                    component={FormSelectionField}
                    onChange={() =>
                      handleFieldChange(
                        `grn_no`,
                        index,
                        fieldArrayRenderProps.value[index]
                      )
                    }
                    validator={requiredValidator}
                    options={
                      fieldArrayRenderProps.value[index]?.grnNoOptions || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`ProductionItemDetails.${index}.stock_qty`}
                    disabled={true}
                    placeholder="0"
                    format="n3"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`ProductionItemDetails.${index}.used_qty`}
                    placeholder="0"
                    format="n3"
                    min={0}
                    max={
                      Production_guid
                        ? (ProductionDetail?.ProductionItemDetails[index]
                            ?.used_qty !== undefined
                            ? ProductionDetail?.ProductionItemDetails[index]
                                ?.used_qty
                            : 0) + fieldArrayRenderProps.value[index]?.stock_qty
                        : fieldArrayRenderProps.value[index]?.stock_qty
                    }
                    component={FormNumericTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ position: "relative" }}>
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      height: 32,
                      width: 32,
                      top: 1,
                      right: 0,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
              {/* </Card> */}
            </GridLayoutItem>
          )
        )}

      <GridLayoutItem
        style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
      >
        <GridLayout
          style={{ marginRight: 30, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={[
            { width: "22%" },
            { width: "27%" },
            { width: "11%" },
            { width: "13%" },
            { width: "11%" },
            { width: "11%" },
            { width: "5%" },
          ]}
        >
          <GridLayoutItem colSpan={3} className="d-flex align-items-end">
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={pushElementInarray}
              type="button"
            >
              Add Row
            </Button>
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="d-flex justify-content-end align-items-center fw-600 flex-wrap"
          >
            Total Quantity &nbsp;<b className="text-danger">(A)</b>
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="total_quantity"
              format="n3"
              component={FormNumericTextField}
              disabled="true"
            />
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="position-relative fw-600 flex-wrap"
          >
            <span style={{ position: "absolute", top: 5, right: 0 }}>
              Old Material Lot No
            </span>
          </GridLayoutItem>
          <GridLayoutItem>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="old_material_lot_no"
              placeholder="0"
              component={FormTextField}
            />
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="position-relative fw-600 flex-wrap"
          >
            <span style={{ position: "absolute", top: 5, right: 0 }}>
              Quantity &nbsp;<span className="text-danger">(B)</span>
            </span>
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="quantity"
              format="n3"
              validator={
                fieldArrayRenderProps.formRenderProps.valueGetter(
                  "old_material_lot_no"
                ) && requiredValidator
              }
              component={FormNumericTextField}
            />
          </GridLayoutItem>

          <GridLayoutItem rowSpan={4} colSpan={3}>
            <Field
              name="remarks"
              label="Remarks"
              placeholder="Remarks"
              component={FormRichTextField}
            />
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="d-flex justify-content-end align-items-center fw-600 flex-wrap"
          >
            Total Qty &nbsp;<span className="text-danger">(C) = (A + B)</span>
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="total_qty"
              format="n3"
              component={FormNumericTextField}
              disabled="true"
            />
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="position-relative fw-600 flex-wrap"
          >
            <span style={{ position: "absolute", top: 5, right: 0 }}>
              Received Qty &nbsp;<span className="text-danger">(D)</span>
            </span>
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="received_qty"
              format="n3"
              max={fieldArrayRenderProps.formRenderProps.valueGetter(
                "total_qty"
              )}
              component={FormNumericTextField}
              validator={requiredValidator}
            />
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="d-flex justify-content-end align-items-center fw-600 flex-wrap"
          >
            Production Loss Qty &nbsp;
            <span className="text-danger">(E) = (C - D)</span>
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="loss_qty"
              format="n3"
              component={FormNumericTextField}
              disabled="true"
            />
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            className="d-flex justify-content-end align-items-center fw-600 flex-wrap"
          >
            Production Loss Qty (%){" "}
            {/* &nbsp;<span className="text-danger">(E) = (C - D)</span> */}
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <div className="w-100">
              <Field
                wrapperStyle={{ margin: 0 }}
                wrapperClassName="red-highlight-field right-alighned-field d-flex flex-wrap justify-content-center"
                name="production_loss_percentage"
                placeholder="0"
                disabled={true}
                component={FormTextField}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ProductionItemDetailsArray2;
