import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductMilling, IProductMillingInitial } from "./productmillingModel";
import {
  createProductMiliing,
  createProductMiliingNew,
  deleteProductMiliing,
  getAllProductMiliing,
  getProductMiliingByID,
  getProductMiliingByIDNew,
  updateProductMiliing,
  updateProductMiliingNew,
} from "./services/productmilling.services";
import moment from "moment";

const initialState: IProductMillingInitial = {
  loading: false,
  error: "",
  PMDeleteIndex: -1,
  ProductMillingID: null,
  ProductMillingList: [],
  ProductMillingDetail: {
    milling_date: moment().toDate(),
    // item_id: null,
    // item_options: [],
    // grn_no: null,
    milling_no: null,
    no_of_workers: null,
    machine_id: null,
    start_time: "",
    end_time: "",
    total_qty: null,
    received_qty: null,
    production_loss_qty: null,
    milling_loss_percentage: null,
    lot_no: "",
    katta: null,
    checker_by: null,
    financial_year: null,
    // department_id: null,
    // process_id: null,
    ready_product_id: null,
    remarks: "",
    product_milling_items: [],
  },
  FilterProductMilling: {
    product_name: "",
  },
};

const productMillingSlice = createSlice({
  name: "productmilling",
  initialState,
  reducers: {
    clearProductMillingDetails: (state) => {
      state.ProductMillingDetail = initialState.ProductMillingDetail;
    },
    setProductMillingID: (state, action) => {
      state.ProductMillingID = action.payload;
    },
    clearProductCleaningID: (state) => {
      state.ProductMillingID = initialState.ProductMillingID;
    },
    setFilterProductMilling: (state, action) => {
      state.FilterProductMilling = action.payload;
    },
    clearFilterProductMilling: (state) => {
      state.FilterProductMilling = initialState.FilterProductMilling;
    },
    setPMDeleteIndex: (state, action) => {
      state.PMDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProductMiliing.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllProductMiliing.fulfilled,
      (state, action: PayloadAction<IProductMilling[]>) => {
        state.loading = false;
        state.ProductMillingList = action.payload || [];
      }
    );
    builder.addCase(getAllProductMiliing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductMillingList = [];
    });
    builder.addCase(createProductMiliing.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createProductMiliing.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createProductMiliing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateProductMiliing.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateProductMiliing.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProductMiliing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteProductMiliing.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteProductMiliing.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProductMiliing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getProductMiliingByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getProductMiliingByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ProductMillingDetail = action.payload;
    });
    builder.addCase(getProductMiliingByID.rejected, (state, action) => {
      state.loading = false;
      state.ProductMillingDetail = initialState.ProductMillingDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(createProductMiliingNew.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createProductMiliingNew.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createProductMiliingNew.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateProductMiliingNew.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateProductMiliingNew.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProductMiliingNew.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getProductMiliingByIDNew.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductMiliingByIDNew.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.ProductMillingDetail = action.payload;
      }
    );
    builder.addCase(getProductMiliingByIDNew.rejected, (state, action) => {
      state.loading = false;
      state.ProductMillingDetail = initialState.ProductMillingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearProductMillingDetails,
  clearProductCleaningID,
  setProductMillingID,
  setFilterProductMilling,
  clearFilterProductMilling,
  setPMDeleteIndex,
} = productMillingSlice.actions;
export default productMillingSlice.reducer;
