import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRetailerDetail } from "../retailerModel";

export const getAllRetailers = createAsyncThunk(
  "Retailer/FindAllRetailer",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/FindAllRetailer`,
        {
          retailer_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Retailers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRetailer = createAsyncThunk(
  "Retailer/InsertRetailer",
  async (RetailerData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/InsertRetailer`,
        RetailerData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating retailer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRetailer = createAsyncThunk(
  "Retailer/UpdateRetailer",
  async (RetailerData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/UpdateRetailer`,
        RetailerData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating retailer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRetailer = createAsyncThunk(
  "Retailer/DeleteRetailer",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/DeleteRetailer`,
        { retailer_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting retailer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRetailerByID = createAsyncThunk(
  "Retailer/FindByIDRetailer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/FindByIDRetailer`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        retailer_guid: result.retailer_guid,
        retailer_name: result.retailer_name,
        contact_person: result.contact_person,
        email_id: result.email_id,
        mobile_no: result.mobile_no,
        address1: result.address1,
        // address2: result.address2,
        pincode: result.pincode,
        city_id: result.city_id,
        state_id: result.state_id,
        country_id: result.country_id,
        area_id: result.area_id,
        sequence_no: result.sequence_no,
        isactive: result.isactive === true ? 1 : 2,
      } as IRetailerDetail;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching retailer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRetailers = createAsyncThunk(
  "Retailer/FindAllActiveRetailer",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Retailer/FindAllActiveRetailer`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Retailers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRetailer = createAsyncThunk(
  "Retailer/ActiveInActiveRetailer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/ActiveInActiveRetailer`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive retailer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
