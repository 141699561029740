import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeave, ILeaveState } from "./leaveModel";
import {
  leaveActiveInactive,
  createLeave,
  deleteLeave,
  getAllActiveLeaves,
  getAllLeaves,
  getLeaveByID,
  updateLeave,
} from "./services/leave.services";

const initialState: ILeaveState = {
  loading: false,
  formLoading: false,
  error: "",
  LeaveList: [],
  LeaveID: null,
  LeaveGUID: "",
  LeaveDetail: {
    leave_type: "",
    leave_count: null,
    isactive: 1,
  },
};

const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    clearLeaveDetails: (state) => {
      state.LeaveDetail = initialState.LeaveDetail;
    },
    setLeaveID: (state, action) => {
      state.LeaveID = action.payload;
    },
    clearLeaveID: (state) => {
      state.LeaveID = initialState.LeaveID;
    },
    setLeaveGUID: (state, action) => {
      state.LeaveGUID = action.payload;
    },
    clearLeaveGUID: (state) => {
      state.LeaveGUID = initialState.LeaveGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeaves.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeaves.fulfilled,
      (state, action: PayloadAction<ILeave[]>) => {
        state.loading = false;
        state.LeaveList = action.payload || [];
      }
    );
    builder.addCase(getAllLeaves.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeaveList = [];
    });
    builder.addCase(getAllActiveLeaves.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveLeaves.fulfilled,
      (state, action: PayloadAction<ILeave[]>) => {
        state.loading = false;
        state.LeaveList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveLeaves.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeaveList = [];
    });
    builder.addCase(createLeave.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createLeave.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeave.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateLeave.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeave.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeave.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeave.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeave.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeave.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(leaveActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(leaveActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(leaveActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getLeaveByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeaveByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeaveDetail = action.payload;
    });
    builder.addCase(getLeaveByID.rejected, (state, action) => {
      state.formLoading = false;
      state.LeaveDetail = initialState.LeaveDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearLeaveDetails,
  setLeaveID,
  clearLeaveID,
  setLeaveGUID,
  clearLeaveGUID,
} = leaveSlice.actions;
export default leaveSlice.reducer;
