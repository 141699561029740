import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllInwardPackingMaterials = createAsyncThunk(
  "InwardPackingMaterial/FindAllInwardPackingMaterial",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardPackingMaterial/FindAllInwardPackingMaterial`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Inward Packing Materials:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createInwardPackingMaterial = createAsyncThunk(
  "InwardPackingMaterial/InsertInwardPackingMaterial",
  async (InwardPackingMaterialData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardPackingMaterial/InsertInwardPackingMaterial`,
        InwardPackingMaterialData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating inward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateInwardPackingMaterial = createAsyncThunk(
  "InwardPackingMaterial/UpdateInwardPackingMaterial",
  async (InwardPackingMaterialData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardPackingMaterial/UpdateInwardPackingMaterial`,
        InwardPackingMaterialData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating inward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInwardPackingMaterialByID = createAsyncThunk(
  "InwardPackingMaterial/FindByIDInwardPackingMaterial",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardPackingMaterial/FindByIDInwardPackingMaterial`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        itemcount: result?.itemcount,
        totalpages: result?.totalpages,
        inward_packing_material_guid: result?.inward_packing_material_guid,
        inward_packing_no: result?.inward_packing_no,
        vendor_id: result?.vendor_id,
        contact_person_name: result?.contact_person_name,
        inward_packing_material_date: result?.inward_packing_material_date
          ? moment(result?.inward_packing_material_date, "YYYY-MM-DD").toDate()
          : "",
        remarks: result?.remarks,
        financial_year: result?.financial_year,
        createdby: result?.createdby,
        create_name: result?.create_name,
        vendor_options: result?.vendor_options,
        InwardPackingMaterialDetails: result?.InwardPackingMaterialDetails
          ? result?.InwardPackingMaterialDetails.map((item: any) => {
              return {
                id: item?.id,
                inward_packing_item_details_guid:
                  item?.inward_packing_item_details_guid,
                item_id: item?.item_id,
                item_options: item?.item_options || [],
                product_name: item?.product_name,
                inward_packing_material_id: item?.inward_packing_material_id,
                inward_item_qty: item?.inward_item_qty,
                unit_id: item?.unit_id,
                unit_name: item?.unit_name,
                roll: item?.roll,
                isactive: item?.isactive,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching inward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteInwardPackingMaterial = createAsyncThunk(
  "InwardPackingMaterial/DeleteInwardPackingMaterial",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardPackingMaterial/DeleteInwardPackingMaterial`,
        { inward_packing_material_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting inward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// export const getInwardPackingMaterialByInwardPackingNo = createAsyncThunk(
//   "InwardPackingMaterial/FindByinwardno",
//   async (FormData: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/InwardPackingMaterial/FindByinwardno`,
//         FormData
//       );
//       const result = response.data?.Data as any;
//       return {
//         id: result?.id,
//         itemcount: result?.itemcount,
//         totalpages: result?.totalpages,
//         inward_packing_material_guid: result?.inward_packing_material_guid,
//         inward_packing_no: result?.inward_packing_no,
//         vendor_id: result?.vendor_id,
//         contact_person_name: result?.contact_person_name,
//         inward_packing_material_date: result?.inward_packing_material_date
//           ? moment(result?.inward_packing_material_date, "YYYY-MM-DD").toDate()
//           : "",
//         remarks: result?.remarks,
//         financial_year: result?.financial_year,
//         createdby: result?.createdby,
//         create_name: result?.create_name,
//         InwardPackingMaterialDetails: result?.InwardPackingMaterialDetails
//           ? result?.InwardPackingMaterialDetails.map((item: any) => {
//               return {
//                 id: item?.id,
//                 inward_packing_item_details_guid:
//                   item?.inward_packing_item_details_guid,
//                 item_id: item?.item_id,
//                 product_name: item?.product_name,
//                 inward_packing_material_id: item?.inward_packing_material_id,
//                 inward_item_qty: item?.inward_item_qty,
//                 unit_id: item?.unit_id,
//                 unit_name: item?.unit_name,
//                 roll: item?.roll,
//                 isactive: item?.isactive,
//               };
//             })
//           : [],
//       } as any;
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error fetching inward packing material:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );
