import React from "react";
import EmployeeLeave from "./EmployeeLeave";
import AdminLeave from "./AdminLeave";
import {
  ADMINUSERTYPEID,
  SUPERADMINUSERTYPEID,
} from "../../_contstants/common";

const AdminEmployeeLeave = () => {
  const USERTYPE = JSON.parse(localStorage.getItem("UserTypeID") || "");
  const isEmployee =
    USERTYPE !== SUPERADMINUSERTYPEID && USERTYPE !== ADMINUSERTYPEID;

  return <>{isEmployee ? <EmployeeLeave /> : <AdminLeave />}</>;
};

export default AdminEmployeeLeave;
