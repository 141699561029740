import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  IStockTransfer,
  IStockTransferInitialState,
} from "./stockTransferModel";
import {
  createStockTransfer,
  deleteStockTransfer,
  getAllStockTransfers,
  getStockTransferByID,
  updateStockTransfer,
} from "./services/stockTransfer.services";

const initialState: IStockTransferInitialState = {
  loading: false,
  error: "",
  stateForGST: null,
  PODeleteIndex: -1,
  StockTransferID: null,
  StockTransferList: [],
  StockTransferDetail: {
    stock_transfer_date: moment().toDate(),
    from_warehouse_id: null,
    to_warehouse_id: null,
    from_department_id: null,
    to_department_id: null,
    katta: null,
    outward_no: "",
    vehicle_no: "",
    item_id: null,
    grn_no: null,
    stock_qty: null,
    vacal_no: "",
    inward_no: null,
    inward_date: "",
    financial_year: null,
    remarks: "",
  },
  FilterStockTransfer: {
    product_name: "",
  },
};

const stockTransferSlice = createSlice({
  name: "stockTransfer",
  initialState,
  reducers: {
    clearStockTransferDetails: (state) => {
      state.StockTransferDetail = initialState.StockTransferDetail;
    },
    setStockTransferID: (state, action) => {
      state.StockTransferID = action.payload;
    },
    clearStockTransferID: (state) => {
      state.StockTransferID = initialState.StockTransferID;
    },
    setFilterStockTransfer: (state, action) => {
      state.FilterStockTransfer = action.payload;
    },
    clearFilterStockTransfer: (state) => {
      state.FilterStockTransfer = initialState.FilterStockTransfer;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStockTransfers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllStockTransfers.fulfilled,
      (state, action: PayloadAction<IStockTransfer[]>) => {
        state.loading = false;
        state.StockTransferList = action.payload || [];
      }
    );
    builder.addCase(getAllStockTransfers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.StockTransferList = [];
    });
    builder.addCase(createStockTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createStockTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createStockTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateStockTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateStockTransfer.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStockTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteStockTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteStockTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteStockTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getStockTransferByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getStockTransferByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.StockTransferDetail = action.payload;
    });
    builder.addCase(getStockTransferByID.rejected, (state, action) => {
      state.loading = false;
      state.StockTransferDetail = initialState.StockTransferDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearStockTransferDetails,
  clearStockTransferID,
  setStockTransferID,
  setFilterStockTransfer,
  clearFilterStockTransfer,
} = stockTransferSlice.actions;
export default stockTransferSlice.reducer;
