import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute, IRouteInitialState } from "./routeModel";
import { deleteRoute, getAllActiveRoute, getAllRoute, getRouteByID, insertRoute, routeActiveInactive, updateRoute } from "./services/route.services";


const initialState: IRouteInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RouteList: [],
  RouteID: null,
  RouteDetails: {
    route_name: "",
    description:"",
    isactive: 1,
  },
};

const routeSlice = createSlice({
    name: "route",
    initialState,
    reducers: {
      clearRouteDetails: (state) => {
        state.RouteDetails = initialState.RouteDetails;
      },
      setRouteID: (state, action) => {
        state.RouteID = action.payload;
      },
      clearRouteID: (state) => {
        state.RouteID = initialState.RouteID;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(
        getAllRoute.fulfilled,
        (state, action: PayloadAction<IRoute[]>) => {
          state.loading = false;
          state.RouteList = action.payload || [];
        }
      );
      builder.addCase(getAllRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.RouteList = [];
      });
      builder.addCase(insertRoute.pending, (state) => {
        state.formLoading = true;
        state.error = "";
      });
      builder.addCase(insertRoute.fulfilled, (state) => {
        state.formLoading = false;
      });
      builder.addCase(insertRoute.rejected, (state, action) => {
        state.formLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(updateRoute.pending, (state) => {
        state.formLoading = true;
        state.error = "";
      });
      builder.addCase(updateRoute.fulfilled, (state) => {
        state.formLoading = false;
      });
      builder.addCase(updateRoute.rejected, (state, action) => {
        state.formLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(deleteRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(deleteRoute.fulfilled, (state) => {
        state.loading = false;
      });
      builder.addCase(deleteRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(getRouteByID.pending, (state) => {
        state.formLoading = true;
        state.error = "";
      });
      builder.addCase(getRouteByID.fulfilled, (state, action: any) => {
        state.formLoading = false;
        state.RouteDetails = action.payload;
      });
      builder.addCase(getRouteByID.rejected, (state, action) => {
        state.formLoading = false;
        state.RouteDetails = initialState.RouteDetails;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(getAllActiveRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(
        getAllActiveRoute.fulfilled,
        (state, action: PayloadAction<IRoute[]>) => {
          state.loading = false;
          state.RouteList = action.payload || [];
        }
      );
      builder.addCase(getAllActiveRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.RouteList = [];
      });
      builder.addCase(routeActiveInactive.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(routeActiveInactive.fulfilled, (state) => {
        state.loading = false;
      });
      builder.addCase(routeActiveInactive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
    },
  });


  export const { clearRouteDetails, setRouteID, clearRouteID } = routeSlice.actions;
export default routeSlice.reducer;

