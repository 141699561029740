import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllEmployeeByDepartmentID = createAsyncThunk(
  "User/DepartmentWiseEmployee",
  async (department_id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DepartmentWiseEmployee`,
        { department_id }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Employee By Department ID:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const processAllEmployeeSalary = createAsyncThunk(
  "User/InsertMultipleEmployeeSalaryGeneration",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertMultipleEmployeeSalaryGeneration`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Packing:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
