import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IInwardPacking,
  IInwardPackingState,
} from "./inwardPackingMaterialModel";
import {
  createInwardPackingMaterial,
  deleteInwardPackingMaterial,
  getAllInwardPackingMaterials,
  getInwardPackingMaterialByID,
  updateInwardPackingMaterial,
} from "./services/inwardPackingMaterial.services";
import moment from "moment";

const initialState: IInwardPackingState = {
  loading: false,
  error: "",
  InwardPackingDeleteIndex: -1,
  InwardPackingID: null,
  InwardPackingList: [],
  InwardPackingDetail: {
    vendor_id: null,
    inward_packing_material_date: moment().toDate(),
    remarks: "",
    financial_year: null,
    InwardPackingMaterialDetails: [],
  },
};

const inwardPackingMaterialSlice = createSlice({
  name: "inwardPackingMaterial",
  initialState,
  reducers: {
    clearInwardPackingDetails: (state) => {
      state.InwardPackingDetail = initialState.InwardPackingDetail;
    },
    setInwardPackingID: (state, action) => {
      state.InwardPackingID = action.payload;
    },
    clearInwardPackingID: (state) => {
      state.InwardPackingID = initialState.InwardPackingID;
    },
    setInwardPackingDeleteIndex: (state, action) => {
      state.InwardPackingDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInwardPackingMaterials.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInwardPackingMaterials.fulfilled,
      (state, action: PayloadAction<IInwardPacking[]>) => {
        state.loading = false;
        state.InwardPackingList = action.payload || [];
      }
    );
    builder.addCase(getAllInwardPackingMaterials.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InwardPackingList = [];
    });
    builder.addCase(createInwardPackingMaterial.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createInwardPackingMaterial.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createInwardPackingMaterial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateInwardPackingMaterial.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateInwardPackingMaterial.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInwardPackingMaterial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteInwardPackingMaterial.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteInwardPackingMaterial.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteInwardPackingMaterial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getInwardPackingMaterialByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getInwardPackingMaterialByID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.InwardPackingDetail = action.payload;
      }
    );
    builder.addCase(getInwardPackingMaterialByID.rejected, (state, action) => {
      state.loading = false;
      state.InwardPackingDetail = initialState.InwardPackingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearInwardPackingDetails,
  clearInwardPackingID,
  setInwardPackingID,
  setInwardPackingDeleteIndex,
} = inwardPackingMaterialSlice.actions;
export default inwardPackingMaterialSlice.reducer;
