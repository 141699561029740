import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { useNavigate } from "react-router-dom";
import {
  createProfessionTaxSlab,
  getAllActiveProfessionTaxDate,
  getProfessionTaxSlabByDate,
  updateProfessionTaxSlab,
} from "./services/professionTaxSlab.services";
import { clearProfessionTaxSlabDetails } from "./professionTaxSlabSlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import FormDatePicker from "../../components/formFields/FormDateField";
import ProfessionTaxSlabArray from "./ProfessionTaxSlabArray";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { IProfessionTaxSlabDate } from "./professionTaxSlabModel";
import { ErrorToast } from "../../components/toast/Toasts";

const CreateProfessionTaxSlab: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const ProfessionTaxSlabDetail = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDetail
  );
  const ProfessionTaxSlabDateList = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDateList
  );
  const [effectiveDate, setEffectiveDate] = useState<IProfessionTaxSlabDate>();
  const formLoading = useAppSelector(
    (state) => state.professionTaxSlab.formLoading
  );
  const [formKey, setFormKey] = React.useState(1);
  const gridRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getAllActiveProfessionTaxDate());
    return () => {
      dispatch(clearProfessionTaxSlabDetails());
    };
  }, []);

  useEffect(() => {
    if (ProfessionTaxSlabDateList?.length > 0) {
      const SelectedDate = moment(
        ProfessionTaxSlabDateList[0]?.effective_from_date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      dispatch(getProfessionTaxSlabByDate(SelectedDate));
      setEffectiveDate(ProfessionTaxSlabDateList[0]);
    } else {
      setEffectiveDate(undefined);
    }
  }, [ProfessionTaxSlabDateList]);

  const handleSubmit = async (values: any) => {
    if (!values?.effective_from_date) {
      return ErrorToast("Please Select Effective Date");
    }
    const formData = {
      effective_from_date: values?.effective_from_date
        ? moment(values.effective_from_date).format("YYYY-MM-DD")
        : "",
      proffestional_tax: values?.proffestional_tax?.length
        ? values?.proffestional_tax?.map((item: any, index: number) => {
            if (index !== values?.proffestional_tax.length - 1) {
              return item;
            }
            return {
              salary_from: null,
              salary_till: null,
              tax_amount: null,
            };
          })
        : [],
    };

    if (ProfessionTaxSlabDetail?.profession_tax_guid) {
      try {
        const editPayload = {
          profession_tax_guid: ProfessionTaxSlabDetail?.profession_tax_guid,
          ...formData,
        };
        const response = await dispatch(updateProfessionTaxSlab(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (effectiveDate?.effective_from_date) {
            const SelectedDate = moment(
              effectiveDate?.effective_from_date,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            dispatch(getProfessionTaxSlabByDate(SelectedDate));
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const response = await dispatch(createProfessionTaxSlab(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearProfessionTaxSlabDetails());
          setFormKey(formKey + 1);
          dispatch(getAllActiveProfessionTaxDate());
          setEditMode(false);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/");
  };
  const handleDateChange = (event: ComboBoxChangeEvent) => {
    setEffectiveDate(event.target.value);
    if (!event.target.value) {
      dispatch(clearProfessionTaxSlabDetails());
    }
    const SelectedDate = moment(
      event.target.value?.effective_from_date,
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    if (event.target.value?.effective_from_date) {
      dispatch(getProfessionTaxSlabByDate(SelectedDate));
    }
  };
  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  useEffect(() => {
    if (editMode) {
      dispatch(clearProfessionTaxSlabDetails());
      setFormKey(formKey + 1);
    } else {
      dispatch(clearProfessionTaxSlabDetails());
      setFormKey(formKey + 1);
      if (effectiveDate?.effective_from_date) {
        const SelectedDate = moment(
          effectiveDate?.effective_from_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        dispatch(getProfessionTaxSlabByDate(SelectedDate));
      }
    }
  }, [editMode]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProfessionTaxSlabDetail]);

  return (
    <>
      {formLoading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ProfessionTaxSlabDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>Profession Tax Slab</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "20%" }, { width: "40%" }]}
                >
                  <GridLayoutItem
                    colSpan={3}
                    style={{ display: "flex", gap: 10, alignItems: "start" }}
                  >
                    {editMode && (
                      <Field
                        name="effective_from_date"
                        label="Effective From Date"
                        format="dd/MM/yyyy"
                        wrapperStyle={{ margin: "0" }}
                        component={FormDatePicker}
                        validator={requiredValidator}
                        astrike={true}
                      />
                    )}
                    {!editMode && (
                      <ComboBox
                        data={ProfessionTaxSlabDateList}
                        textField="effective_from_date"
                        dataItemKey="effective_from_date"
                        label="Effective From Date"
                        value={effectiveDate}
                        onChange={handleDateChange}
                      />
                    )}
                    <Button
                      style={{ marginTop: 21 }}
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      onClick={handleEditMode}
                    >
                      {editMode ? "Cancel" : "Add"}
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem colSpan={3}>
                <ShadowCard style={{ marginTop: 10 }}>
                  <GridLayout
                    style={{ marginRight: 20 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "33.33%" },
                      { width: "33.33%" },
                      { width: "33.33%" },
                    ]}
                  >
                    <GridLayoutItem colSpan={3}>
                      <Typography.h5>Profession Tax Slab</Typography.h5>
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={3}
                      style={{
                        borderBottom: "1px solid lightgray",
                        paddingBottom: 10,
                      }}
                    >
                      <FieldArray
                        formRenderProps={formRenderProps}
                        component={ProfessionTaxSlabArray}
                        name="proffestional_tax"
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={3}
                      style={{
                        textAlign: "end",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <ButtonWithLoading
                        label={
                          !ProfessionTaxSlabDetail?.profession_tax_guid
                            ? "Save"
                            : "Update"
                        }
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || formLoading}
                        loading={formLoading}
                      />
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginLeft: 4 }}
                        onClick={handleFormClose}
                      >
                        Cancel
                      </Button>
                    </GridLayoutItem>
                  </GridLayout>
                </ShadowCard>
              </GridLayoutItem>
            </GridLayout>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateProfessionTaxSlab;
