import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  convertToWords,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import { getSalesPrint } from "./services/sales.services";

const SalesPrint = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const queryParam = new URLSearchParams(location.search);
  const isPrint = queryParam.get("print");
  const sale_guid = params?.sale_guid;
  const SalesPrintDetail = useAppSelector(
    (state) => state.sales.SalesPrintDetail
  );

  // const [tableHeight, setTableHeight] = React.useState<any>(null);

  useEffect(() => {
    if (sale_guid) {
      const handleOrderPrint = async () => {
        const res = await dispatch(getSalesPrint(sale_guid));
        if (res.meta.requestStatus === "fulfilled") {
          if (isPrint === "1") {
            window.print();
            // const table1: any = document.getElementById("main-table");
            // const table2: any = document.getElementById("secondary-table");
            // console.log("table1.offsetHeight", table1.offsetHeight);

            // if (table1 && table1.offsetHeight > 421) {
            //   table2.classList.add("start-on-new-page");
            // }
          }
        }
      };
      handleOrderPrint();
    }
  }, [sale_guid]);

  // const table1: any = document.getElementById("main-table");
  // const table2: any = document.getElementById("secondary-table");

  // useEffect(() => {
  //   if (table1?.offsetHeight) {
  //     setTableHeight(table1?.offsetHeight);
  //   }
  //   if (table1 && table1.offsetHeight > 421) {
  //     table2.classList.add("start-on-new-page");
  //   }
  // }, [table1 && table1?.offsetHeight]);

  let totalAmount = 0;
  let totalTaxableAmount = 0;

  SalesPrintDetail?.sales_items &&
    SalesPrintDetail?.sales_items.length > 0 &&
    SalesPrintDetail?.sales_items?.map(
      (item: any) => (totalAmount += item?.amount)
    );

  SalesPrintDetail?.gst_details &&
    SalesPrintDetail?.gst_details?.length > 0 &&
    SalesPrintDetail?.gst_details?.map((gstamount: any) => {
      const totaltax =
        gstamount?.total_taxable_amount &&
        gstamount?.gst &&
        (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
      totalTaxableAmount += totaltax;
    });

  return (
    <div className="p-4 sales-print-page">
      <p className="fw-600 text-center mb-1">
        <i>(ORIGINAL FOR RECEPIENT)</i>
      </p>
      <h6 className="text-center fw-600">Tax Invoice</h6>
      {/* <p className="text-center">
        <b>Harihar Foods Pvt. Ltd.</b>
        <br />
        519/1, Nr Ramol Police Chowky
        <br />
        Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
        <br />
        Tel (Off) 079-25841773/25840855 <br />
        FSSAI No: 10012021000359 <br />
        GSTIN/UIN: 24AAACH5100R1ZN <br />
        State Name: Gujarat, Code: 24 <br />
        E-Mail: marketing@shreeganeshmasala.com
      </p> */}
      <table className="table table-bordered w-100 sales-print-table m-0">
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td rowSpan={3} className="p-0" style={{ width: "40%" }}>
            <p className="m-0 p-1">
              <b style={{ fontSize: 13 }}>Harihar Foods Pvt. Ltd.</b>
              <br />
              519/1, Nr Ramol Police Chowky
              <br />
              Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
              <br />
              Tel (Off) 079-25841773/25840855 <br />
              FSSAI No: 10012021000359 <br />
              GSTIN/UIN: 24AAACH5100R1ZN <br />
              State Name: Gujarat, Code: 24 <br />
              E-Mail: marketing@shreeganeshmasala.com
            </p>
          </td>
          <td style={{ height: 40, width: "20%" }}>
            Invoice No.
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.invoice_no_string}
            </span>
          </td>
          <td style={{ height: 40, width: "20%" }}>
            Dated
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.invoice_date}</span>
          </td>
          <td style={{ height: 40, width: "20%" }}>
            Sales Order No.
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.sales_order_no}
            </span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td>
            Destination
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.city_name}</span>
          </td>
          <td style={{ height: 40 }}>
            Challan No.
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.challan_no_string}
            </span>
          </td>
          <td>
            Challan Date
            <br />
            <span className="fw-600 p-0">
              {" "}
              {SalesPrintDetail?.sales_challan_date}
            </span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ height: 40 }}>
            Dispatched through
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.transport_name}
            </span>
          </td>
          <td>
            Vehicle Number
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.vehicle_no}</span>
          </td>
          <td colSpan={2} style={{ height: 40 }}>
            Scheme
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.scheme_name}</span>
          </td>
        </tr>

        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td colSpan={4} className="p-0">
            <div style={{ display: "flex", padding: 0 }}>
              <div
                className="p-1"
                style={{
                  flex: "1",
                  borderRight: "1px solid rgba(0, 0, 0, 0.5)",
                }}
              >
                <p className="fw-600 mb-1">Billing Address:-</p>
                <p className="m-0 fw-bold" style={{ fontSize: 13 }}>
                  {SalesPrintDetail?.account_name}
                </p>
                <div className="pt-0" style={{ minHeight: 80 }}>
                  {SalesPrintDetail?.address1}{" "}
                  {SalesPrintDetail?.address1 && <br />}
                  Ph.: {SalesPrintDetail?.mobile_number}
                  {SalesPrintDetail?.mobile_number &&
                    SalesPrintDetail?.alternet_mobile_no &&
                    ", "}
                  {SalesPrintDetail?.alternet_mobile_no} <br />
                  GSTIN/UIN: {SalesPrintDetail?.gst_no}
                  {", "}&nbsp;&nbsp; PAN No.: {SalesPrintDetail?.pan_no} <br />
                  State: {SalesPrintDetail?.state_name} Code:{" "}
                  {SalesPrintDetail?.gst_state_code}
                </div>
              </div>
              <div className="p-1" style={{ minHeight: 80, flex: 1 }}>
                <p className="fw-600 mb-1">Shipping Address:-</p>
                {SalesPrintDetail?.address2}{" "}
                {SalesPrintDetail?.address2 && <br />}
                {SalesPrintDetail?.city_name2}{" "}
                {SalesPrintDetail?.city_name2 &&
                  SalesPrintDetail?.pincode2 &&
                  " - "}
                {SalesPrintDetail?.pincode2}{" "}
                {SalesPrintDetail?.pincode2 && <br />}
                State: {SalesPrintDetail?.state_name2} Code:{" "}
                {SalesPrintDetail?.gst_state_code}
              </div>
            </div>
          </td>
        </tr>
        {/* <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td className="w-25" style={{ height: 40 }}>
            Sales Order No.
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.sales_order_no}
            </span>
          </td>
          <td className="w-25">
            Destination
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.city_name}</span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td className="w-25" style={{ height: 40 }}>
            Challan No.
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.sales_challan_no}
            </span>
          </td>
          <td className="w-25">
            Delivery Note Date
            <br />
            <span className="fw-600 p-0"></span>
          </td>
        </tr> */}
        {/* <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td className="w-25" style={{ height: 40 }}>
            Dispatched through
            <br />
            <span className="fw-600 p-0">
              {SalesPrintDetail?.transport_name}
            </span>
          </td>
          <td className="w-25">
            Vehicle Number
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.vehicle_no}</span>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td colSpan={2} style={{ height: 40 }}>
            Scheme
            <br />
            <span className="fw-600 p-0">{SalesPrintDetail?.scheme}</span>
          </td>
        </tr> */}
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table w-100  sales-print-table mb-0 b-none"
        id="main-table"
      >
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th rowSpan={2} style={{ width: "5%" }}>
            Sr No.
          </th>
          <th rowSpan={2} style={{ width: "40%" }}>
            Description Of Goods
          </th>
          <th rowSpan={2} style={{ width: "5%" }}>
            Batch No.
          </th>
          <th rowSpan={2} style={{ width: "8%" }}>
            HSN/SAC
          </th>
          <th rowSpan={2} style={{ width: "4%" }}>
            GST Rate
          </th>
          <th rowSpan={2} style={{ width: "6%" }}>
            MRP
          </th>
          <th colSpan={2} style={{ width: "10%" }}>
            Quantity
          </th>
          <th rowSpan={2} style={{ width: "5%" }}>
            Per
          </th>
          <th rowSpan={2} style={{ width: "7%" }}>
            Rate
          </th>
          <th rowSpan={2} style={{ width: "5%" }}>
            Vatav (%)
          </th>
          <th rowSpan={2} style={{ width: "10%" }}>
            Amount
          </th>
        </tr>
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th>Free</th>
          <th>Billed</th>
        </tr>
        {SalesPrintDetail?.sales_items &&
          SalesPrintDetail?.sales_items.length > 0 &&
          SalesPrintDetail?.sales_items?.map((item: any, index: number) => (
            <>
              <tr>
                <td className="text-center">{index + 1}</td>
                <td>
                  {item?.product_name}
                  {item?.item_description && <br />}{" "}
                  <span
                    style={{
                      fontSize: 8,
                      display: "inline-table",
                      marginLeft: 10,
                    }}
                  >
                    {item?.item_description}
                  </span>
                </td>
                <td className="text-center">{item?.lot_no}</td>
                <td className="text-center">{item?.hsn_sac_code}</td>
                <td className="text-end">
                  {item?.billed_quantity && item?.gst ? `${item?.gst} %` : ""}
                </td>
                <td className="text-end">
                  {item?.billed_quantity && item?.mrp ? item?.mrp || 0 : ""}
                </td>
                <td className="text-end">
                  {formatIndianNumberForQty(item?.free_quantity || 0)}
                </td>
                <td className="text-end">
                  {formatIndianNumberForQty(item?.billed_quantity || 0)}
                </td>
                <td>{item?.unit_name}</td>
                <td className=" text-end">
                  {item?.billed_quantity ? formatIndianNumber(item?.rate) : ""}
                </td>
                <td className=" text-end">
                  {item?.billed_quantity && item?.vatav_per
                    ? `${item?.vatav_per} %`
                    : ""}
                </td>
                <td className=" text-end">
                  {formatIndianNumber(item?.amount)}
                </td>
              </tr>
            </>
          ))}
        {/* {tableHeight > 421 && (
          <tr>
            <td style={{ height: "calc(100vh - 820px)" }}></td>
            <td colSpan={10}></td>
            <td></td>
          </tr>
        )} */}
        <tr>
          <td colSpan={11}></td>
          <td className="text-end fw-600" style={{ fontSize: 12 }}>
            {formatIndianNumber(totalAmount)}
          </td>
        </tr>
        {SalesPrintDetail?.gst_details &&
          SalesPrintDetail?.gst_details?.length > 0 &&
          SalesPrintDetail?.gst_details?.map((gstdetails: any) =>
            SalesPrintDetail?.state_name === "GUJARAT" ? (
              <>
                {gstdetails?.gst > 0 && (
                  <tr>
                    <td className=" text-end" colSpan={9}>
                      CGST {gstdetails?.gst / 2}%
                    </td>
                    <td className=" text-end">
                      {(gstdetails?.gst / 2)?.toFixed(2)}
                    </td>
                    <td>%</td>
                    <td className=" text-end">
                      {gstdetails?.total_taxable_amount &&
                        gstdetails?.gst &&
                        formatIndianNumber(
                          (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                            100 /
                            2
                        )}
                    </td>
                  </tr>
                )}
                {gstdetails?.gst > 0 && (
                  <tr>
                    <td className=" text-end" colSpan={9}>
                      SGST {gstdetails?.gst / 2}%
                    </td>
                    <td className=" text-end">
                      {(gstdetails?.gst / 2)?.toFixed(2)}
                    </td>
                    <td>%</td>
                    <td className=" text-end">
                      {gstdetails?.total_taxable_amount &&
                        gstdetails?.gst &&
                        formatIndianNumber(
                          (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                            100 /
                            2
                        )}
                    </td>
                  </tr>
                )}
              </>
            ) : (
              gstdetails?.gst > 0 && (
                <tr>
                  <td className=" text-end" colSpan={9}>
                    IGST {gstdetails?.gst}%
                  </td>
                  <td className=" text-end">{gstdetails?.gst?.toFixed(2)}</td>
                  <td>%</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100
                      )}
                  </td>
                </tr>
              )
            )
          )}
        <tr style={{ fontSize: 12 }}>
          <td colSpan={11} className=" text-end fw-600">
            {" "}
            Round Off:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(
              Math.round(+totalAmount + +totalTaxableAmount) -
                (+totalAmount + +totalTaxableAmount)
            )}
          </td>
        </tr>
        <tr style={{ fontSize: 12 }}>
          <td colSpan={11} className=" text-end fw-600">
            {" "}
            Total Invoice Amount:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(Math.round(+totalAmount + +totalTaxableAmount))}
          </td>
        </tr>
        <tr className="b-none">
          <td colSpan={11} className="b-none">
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Amount Chargeable (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(Math.round(+totalAmount + +totalTaxableAmount))}
            </span>
          </td>
          <td className="text-end b-none">E & O.E</td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table table-bordered w-100  sales-print-table mb-0 b-none"
        id="secondary-table"
        style={{ pageBreakInside: "avoid" }}
      >
        {SalesPrintDetail?.state_name === "GUJARAT" ? (
          <>
            <tr className="text-center fw-600" style={{ fontSize: 13 }}>
              <th style={{ width: "30%" }} rowSpan={2}>
                HSN/SAC Code
              </th>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={2}>Central Tax</th>
              <th colSpan={2}>State Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th>Amount</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </>
        ) : (
          <>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th style={{ width: "30%" }} rowSpan={2}>
                HSN/SAC Code
              </th>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={4}>Integrated Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th colSpan={3}>Amount</th>
            </tr>
          </>
        )}
        {SalesPrintDetail?.gst_details_hsn &&
          SalesPrintDetail?.gst_details_hsn?.length > 0 &&
          SalesPrintDetail?.gst_details_hsn?.map((gstdetails: any) =>
            SalesPrintDetail?.state_name === "GUJARAT" ? (
              <tr className="text-end">
                <td className="text-start">{gstdetails?.hsn_sac_code || ""}</td>
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td className=" ">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            ) : (
              <tr className="text-end">
                <td className="text-start">{gstdetails?.hsn_sac_code || ""}</td>
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst)} %</td>
                <td colSpan={3}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        {SalesPrintDetail?.state_name === "GUJARAT" ? (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td></td>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        ) : (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td></td>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td colSpan={3}>{formatIndianNumber(totalTaxableAmount)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        )}
        <tr style={{ borderBottom: "none" }}>
          <td colSpan={7} style={{ textAlign: "end", border: "none" }}>
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Tax Amount (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(+totalTaxableAmount?.toFixed(2))}
            </span>
          </td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}
        className="table table-bordered w-100 mb-0"
      >
        <tr>
          <td className="border-bottom-0 p-1" style={{ width: "66%" }}>
            Company's PAN : <span className="fw-600 p-0">AAACH5100R</span>{" "}
            <br />
            CIN NO : <span className="fw-600 p-0">U15499GJ1994PTC021520</span>
            {/* <br />
            FSSAI No : <span className="fw-600 p-0">10012021000359</span> */}
          </td>
          <td
            className="p-0 border-bottom-0"
            style={{
              width: "34%",
              verticalAlign: "baseline",
              borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="p-0">
              <table className="table w-100 sales-print-table border-0  m-0">
                <tr className="border-0">
                  <td
                    className=" border-top-0 border-start-0"
                    style={{ width: "33.33%" }}
                  >
                    <span
                      style={{ boxSizing: "border-box" }}
                      className="fw-600 p-0"
                    >
                      Bags :
                    </span>{" "}
                    {SalesPrintDetail?.bag || 0}
                  </td>
                  <td className="border-top-0" style={{ width: "33.33%" }}>
                    <span
                      style={{ boxSizing: "border-box" }}
                      className="fw-600 p-0"
                    >
                      Cartoon :
                    </span>{" "}
                    {SalesPrintDetail?.cartoon || 0}
                  </td>
                  <td
                    className="border-top-0 border-end-0"
                    style={{ width: "33.33%" }}
                  >
                    <span
                      style={{ boxSizing: "border-box" }}
                      className="fw-600 p-0"
                    >
                      Total :
                    </span>{" "}
                    {+SalesPrintDetail?.bag + +SalesPrintDetail?.cartoon}
                  </td>
                </tr>
                {/* <tr className="border-end-0 border-bottom-0">
                </tr>
                <tr
                  className="border-end-0 border-bottom-0"
                  style={{ background: "whitesmoke" }}
                >
                </tr> */}
              </table>
            </div>
          </td>
        </tr>
        <tr className="border-top-0">
          <td className="p-0 border-top-0" rowSpan={2} style={{ width: "66%" }}>
            <u className="p-1 d-block pb-0 fw-600">Remarks:</u>
            {SalesPrintDetail.remarks && (
              <p className="p-0 ps-1 m-0">{SalesPrintDetail.remarks}</p>
            )}
            <u className="p-1 d-block pb-0 fw-600">Declaration:</u>
            <div
              className="sales-description"
              dangerouslySetInnerHTML={{
                __html: SalesPrintDetail.description,
              }}
            />
          </td>
          <td
            className=" p-0"
            style={{
              width: "34%",
              borderTop: 0,
              borderLeft: "1px solid rgba(0, 0, 0, 0.5)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="p-1">
              <b>Company's Bank Details</b> <br />
              <table className="table bank-details-table mb-0">
                <tr>
                  <td>Bank Name</td>
                  <td className="fw-600">: Kotak Mahindra Bank</td>
                </tr>
                <tr>
                  <td>A/c No.</td>
                  <td className="fw-600">: 2411684374</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td className="fw-600">: SATELITE BRANCH</td>
                </tr>
                <tr>
                  <td>IFS Code</td>
                  <td className="fw-600">: KKBK0000810</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td
            style={{
              height: 80,
              border: "none",
              borderLeft: "1px solid rgba(0, 0, 0, 0.5) ",
              textAlign: "end",
            }}
          >
            <p className="p-0 mb-5 fw-600">for Harihar Foods Pvt. Ltd.</p>
            <p className="m-0 p-0">Authorised Signatory</p>
          </td>
        </tr>
      </table>
      <p className="text-center m-0">
        This is Computer Generated Invoice. Signature Is Not Required.
      </p>
    </div>
  );
};

export default SalesPrint;
