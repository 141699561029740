import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOpeningStock,
  IOpeningStockInitialState,
} from "./distributorOpeningStockModel";
import {
  createOpeningStock,
  deleteOpeningStock,
  getAllDistributorRetailerMarginRates,
  getAllOpeningStocks,
  getOpeningStockByID,
  updateOpeningStock,
} from "./services/distributorOpeningStock.services";

const initialState: IOpeningStockInitialState = {
  loading: false,
  error: "",
  OpeningStockID: null,
  OpeningStockList: [],
  MarginRateList: [],
  OpeningStockDetail: {
    accountid: null,
    date: "",
    open_stock_list: [],
  },
};

const distributorOpeningStockSlice = createSlice({
  name: "distributorOpeningStock",
  initialState,
  reducers: {
    clearOpeningStockDetails: (state) => {
      state.OpeningStockDetail = initialState.OpeningStockDetail;
    },
    setOpeningStockID: (state, action) => {
      state.OpeningStockID = action.payload;
    },
    clearOpeningStockID: (state) => {
      state.OpeningStockID = initialState.OpeningStockID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOpeningStocks.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOpeningStocks.fulfilled,
      (state, action: PayloadAction<IOpeningStock[]>) => {
        state.loading = false;
        state.OpeningStockList = action.payload || [];
      }
    );
    builder.addCase(getAllOpeningStocks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OpeningStockList = [];
    });

    builder.addCase(createOpeningStock.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createOpeningStock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createOpeningStock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateOpeningStock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateOpeningStock.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOpeningStock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteOpeningStock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteOpeningStock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOpeningStock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getOpeningStockByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getOpeningStockByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.OpeningStockDetail = action.payload;
    });
    builder.addCase(getOpeningStockByID.rejected, (state, action) => {
      state.loading = false;
      state.OpeningStockDetail = initialState.OpeningStockDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllDistributorRetailerMarginRates.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDistributorRetailerMarginRates.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.MarginRateList = action.payload || [];
      }
    );
    builder.addCase(
      getAllDistributorRetailerMarginRates.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.MarginRateList = [];
      }
    );
  },
});

export const {
  clearOpeningStockDetails,
  clearOpeningStockID,
  setOpeningStockID,
} = distributorOpeningStockSlice.actions;
export default distributorOpeningStockSlice.reducer;
