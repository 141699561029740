import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
// import { findIndex } from "lodash";
import FormSelectionField from "../../components/formFields/FormSelectionField";

import { getAllActiveStates } from "../state/services/state.services";

import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import { clearDistrictDetails } from "./districtSlice";
import {
  getAllActiveDistrict,
  getAllDistrict,
  getDistrictByID,
  InsertDistrict,
  updateDistrict,
} from "./services/district.services";
import { IState } from "../state/stateModel";

const CreateDistrict: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const StateList = useAppSelector((state) => state.state.StateList);
  const DistrictDetail = useAppSelector(
    (state) => state.district.DistrictDetail
  );
  const district_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.district.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    dispatch(getAllActiveStates());
    return () => {
      dispatch(clearDistrictDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (district_guid) {
      dispatch(getDistrictByID(district_guid));
    }
  }, [district_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!district_guid) {
        const payload = {
          district_name: values.district_name,
          state_id: values.state_id,

          isactive: values.isactive === 1,
        };
        const response = await dispatch(InsertDistrict(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearDistrictDetails());
          isIndirect
            ? dispatch(getAllActiveDistrict())
            : dispatch(getAllDistrict());
        }
      } else {
        const payload = {
          district_guid: values.district_guid,
          district_name: values.district_name,
          state_id: values.state_id,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateDistrict(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearDistrictDetails());
          isIndirect
            ? dispatch(getAllActiveDistrict())
            : dispatch(getAllDistrict());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={DistrictDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {district_guid ? "Update District" : "Add District"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="district_name"
                label="District Name"
                placeholder="i.e. Ahmedabad"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"state_id"}
                name={"state_id"}
                label={"State Name"}
                placeholder="i.e. Gujarat"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={StateList?.map((state: IState) => {
                  return {
                    value: state?.id,
                    label: `${state?.state_name}`,
                  };
                })}
              />
            </GridLayoutItem>

            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={district_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateDistrict;
