import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IDocument } from "../documentModel";

export const getAllDocuments = createAsyncThunk(
  "User/FindAllDocument",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindAllDocument`,
        {
          document_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveDocuments = createAsyncThunk(
  "User/FindAllActiveDocument",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveDocument`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDocument = createAsyncThunk(
  "User/InsertDocument",
  async (DocumentData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertDocument`,
        DocumentData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDocument = createAsyncThunk(
  "User/UpdateDocument",
  async (DocumentData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateDocument`,
        DocumentData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDocumentByID = createAsyncThunk(
  "User/FindByIDDocument",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDDocument`,
        { document_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        document_guid: result.document_guid,
        document_name: result.document_name,
        document_code: result.document_code,
        isactive: result.isactive === true ? 1 : 2,
      } as IDocument;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const documentActiveInactive = createAsyncThunk(
  "User/ActiveInActiveDocument",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveDocument`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "User/DeleteDocument",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteDocument`,
        { document_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting document:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
