import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { clearRetailerRateListReportList } from "../reportsSlice";
import { getRetailerRateReport } from "../services/reports.services";
import { checkAcessRights, formatIndianNumber } from "../../../_helper/helper";
import { LoadingPanel } from "../../../components/layout/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { RxCross2 } from "react-icons/rx";
import { Tooltip } from "@progress/kendo-react-tooltip";
import Logo from "../../../assets/Images/logo.png";
import { PRINT_ACCESS } from "../../../_contstants/common";

const columns = [
  { label: "Retailer Rate", value: "Retailer Rate" },
  { label: "Distributor Rate", value: "Distributor Rate" },
];

const RetailerRateReport: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const RetailerRateListReportList = useAppSelector(
    (state) => state.reports.RetailerRateListReportList
  );
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const [showfilterColumns, setShowFilterColumns] = useState(false);
  const initialValues = {
    filter_columns: ["Retailer Rate", "Distributor Rate"],
  };

  useEffect(() => {
    dispatch(getRetailerRateReport());
    return () => {
      dispatch(clearRetailerRateListReportList());
    };
  }, []);

  const printTable = (
    RetailerRateListReportList: any[],
    formRenderProps: any
  ) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    const data: any[] = RetailerRateListReportList;
    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content += '<td colSpan="2"><h3 style="margin:0;">Price List</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Sr. No.</th>';
      content += "<th>Description Of Goods</th>";
      content += "<th>Per</th>";
      content += "<th>MRP</th>";

      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Retailer Rate") &&
          (content += "<th>Retailer Rate Excl. GST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Retailer Rate") && (content += "<th>Retailer CGST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Retailer Rate") && (content += "<th>Retailer SGST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Retailer Rate") &&
          (content += "<th>Retailer Rate Incl. GST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Distributor Rate") &&
          (content += "<th>Distributor Rate Excl. GST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Distributor Rate") &&
          (content += "<th>Distributor CGST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Distributor Rate") &&
          (content += "<th>Distributor SGST</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("Distributor Rate") &&
          (content += "<th>Distributor Rate Incl. GST</th>");
      }

      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      if (data && data?.length > 0) {
        data?.forEach((item: any) => {
          // let total = 0;
          content += `<tr style="background:whitesmoke;">`;
          content += `<td colSpan="12" style="font-weight:600;">${item.item_group}</td>`;
          content += `</tr>`;

          if (item?.item_margin_rate && item?.item_margin_rate?.length > 0) {
            item.item_margin_rate?.forEach((subitem: any, index: number) => {
              // total += +subitem?.amount;

              content += `<tr>`;
              content += `<td style="text-align:center;">${index + 1}</td>`;
              content += `<td>${subitem?.product_name}</td>`;
              content += `<td>${subitem?.unit_name}</td>`;
              content += `<td>Rs. ${subitem?.mrp}</td>`;
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Retailer Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.retailer_rate
                      ? formatIndianNumber(subitem.retailer_rate)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Retailer Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.retail_cgst
                      ? formatIndianNumber(subitem.retail_cgst)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Retailer Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.retail_sgst
                      ? formatIndianNumber(subitem.retail_sgst)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Retailer Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.retail_rate_include_gst
                      ? formatIndianNumber(subitem.retail_rate_include_gst)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Distributor Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.distributor_rate
                      ? formatIndianNumber(subitem.distributor_rate)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Distributor Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.distributor_cgst
                      ? formatIndianNumber(subitem.distributor_cgst)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Distributor Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.distributor_sgst
                      ? formatIndianNumber(subitem.distributor_sgst)
                      : ""
                  }</td>`);
              }
              {
                formRenderProps
                  .valueGetter("filter_columns")
                  ?.includes("Distributor Rate") &&
                  (content += `<td style="text-align:end;">${
                    subitem.distributor_rate_include_gst
                      ? formatIndianNumber(subitem.distributor_rate_include_gst)
                      : ""
                  }</td>`);
              }
              content += "</tr>";
            });
          }

          // content += `<tr style="text-align:end; font-weight:600;">`;
          // content += `<td colSpan="6">Total</td>`;
          // content += `<td>${formatIndianNumber(total)}</td>`;
          // content += `</tr>`;
        });
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        initialValues={initialValues}
        // onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <FormElement style={{ width: "100%" }}>
              <GridLayout
                gap={{ rows: 10, cols: 10 }}
                cols={[{ width: "100%" }]}
              >
                <GridLayoutItem className="d-flex justify-content-between align-items-center">
                  <Typography.h4 className="m-0">
                    Retailer Price List
                  </Typography.h4>
                  <div className="position-relative">
                    <div className="d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(
                              RetailerRateListReportList,
                              formRenderProps
                            )
                          }
                        >
                          Print
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                    </div>
                    {showfilterColumns && (
                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                          background: "white",
                          padding: "12px",
                          border: "1px solid whitesmoke",
                          width: "200px",
                          zIndex: 1,
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-baseline">
                          <p className="m-0 fw-600">Filter</p>
                          <Button
                            themeColor={"primary"}
                            fillMode={"flat"}
                            onClick={() => setShowFilterColumns(false)}
                          >
                            <RxCross2 />
                          </Button>
                        </div>
                        <hr className="m-1" style={{ color: "lightgray" }} />
                        <Field
                          id={"filter_columns"}
                          name={"filter_columns"}
                          marginRight={0}
                          marginBeetween={8}
                          component={FormCheckboxGroup}
                          options={columns}
                        />
                      </div>
                    )}
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    height: "calc(100vh - 112px)",
                    overflow: "scroll",
                    scrollbarWidth: "thin",
                  }}
                >
                  <table
                    cellSpacing={1}
                    cellPadding={2}
                    className="table table-bordered w-100  currentstock-print-table"
                    style={{ marginTop: 1 }}
                  >
                    <tr
                      className="text-center fw-600"
                      style={{
                        fontSize: 12,
                        position: "sticky",
                        top: 0,
                        outline: "1px solid #3b3b3b",
                      }}
                    >
                      <th style={{ minWidth: 80 }}>Sr No.</th>
                      <th style={{ minWidth: 300 }}>Description Of Goods</th>
                      <th style={{ minWidth: 120 }}>Per</th>
                      <th style={{ minWidth: 120 }}>MRP</th>
                      {formRenderProps
                        .valueGetter("filter_columns")
                        ?.includes("Retailer Rate") && (
                        <>
                          <th style={{ minWidth: 120 }}>
                            Retailer Rate Excl. GST
                          </th>
                          <th style={{ minWidth: 120 }}>Retailer CGST</th>
                          <th style={{ minWidth: 120 }}>Retailer SGST</th>
                          <th style={{ minWidth: 120 }}>
                            Retailer Rate Incl. GST
                          </th>
                        </>
                      )}
                      {formRenderProps
                        .valueGetter("filter_columns")
                        ?.includes("Distributor Rate") && (
                        <>
                          <th style={{ minWidth: 120 }}>
                            Distributor Rate Excl. GST
                          </th>
                          <th style={{ minWidth: 120 }}>Distributor CGST</th>
                          <th style={{ minWidth: 120 }}>Distributor SGST</th>
                          <th style={{ minWidth: 120 }}>
                            Distributor Rate Incl. GST
                          </th>
                        </>
                      )}
                    </tr>
                    {RetailerRateListReportList &&
                      RetailerRateListReportList?.length > 0 &&
                      RetailerRateListReportList?.map((item: any) => {
                        return (
                          <>
                            <tr style={{ background: "whitesmoke" }}>
                              <td
                                className="fw-600"
                                colSpan={12}
                                style={{ fontSize: 12 }}
                              >
                                {item?.item_group}
                              </td>
                            </tr>
                            {item?.item_margin_rate &&
                              item?.item_margin_rate?.length > 0 &&
                              item?.item_margin_rate?.map(
                                (subitem: any, index: number) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {index + 1}
                                      </td>
                                      <td>{subitem?.product_name}</td>
                                      <td className="text-center">
                                        {subitem?.unit_name}
                                      </td>
                                      <td>Rs. {subitem?.mrp || 0}</td>
                                      {formRenderProps
                                        .valueGetter("filter_columns")
                                        ?.includes("Retailer Rate") && (
                                        <>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.retailer_rate || 0
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.retail_cgst || 0
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.retail_sgst || 0
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.retail_rate_include_gst ||
                                                0
                                            )}
                                          </td>
                                        </>
                                      )}
                                      {formRenderProps
                                        .valueGetter("filter_columns")
                                        ?.includes("Distributor Rate") && (
                                        <>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.distributor_rate || 0
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.distributor_cgst || 0
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.distributor_sgst || 0
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {formatIndianNumber(
                                              subitem?.distributor_rate_include_gst ||
                                                0
                                            )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                          </>
                        );
                      })}
                    {/* <tr className="text-end fw-600" style={{ background: "#ffe1e1" }}>
              <td colSpan={6}>Grand Total</td>
              <td>{formatIndianNumber(grandTotal)}</td>
            </tr> */}
                  </table>
                </GridLayoutItem>
              </GridLayout>
            </FormElement>
          );
        }}
      />
    </>
  );
};

export default RetailerRateReport;
