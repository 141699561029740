import React, { useRef, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

interface CameraModalProps {
  onCapture: (file: File) => void;
  onClose: () => void;
}

const CameraModal: React.FC<CameraModalProps> = ({ onCapture, onClose }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isCaptured, setIsCaptured] = useState(false);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      onClose();
      alert("Camera Not Found or Something went wrong!");
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject as MediaStream;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current!.srcObject = null;
    }
  };

  const handleCapture = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        setIsCaptured(true);
      }
    }
  };

  const handleSave = () => {
    if (canvasRef.current) {
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "captured_image.jpg", {
            type: "image/jpeg",
          });
          onCapture(file);
        }
      });
      handleClose();
    }
  };

  const handleClose = () => {
    stopCamera();
    setIsCaptured(false);
    onClose();
  };

  React.useEffect(() => {
    startCamera();
    return () => handleClose();
  }, []);

  return (
    <Dialog onClose={handleClose} className="visitor-camera-modal">
      <div style={{ textAlign: "center" }}>
        <video
          ref={videoRef}
          autoPlay
          style={{
            display: isCaptured ? "none" : "block",
            width: "100%",
            height: "auto",
            borderRadius: 5,
          }}
        ></video>
        <canvas
          ref={canvasRef}
          width={640}
          height={480}
          style={{
            display: isCaptured ? "block" : "none",
            margin: "auto",
            borderRadius: 5,
          }}
        ></canvas>
        {!isCaptured ? (
          <div style={{ marginTop: 10 }}>
            <Button
              fillMode={"solid"}
              themeColor={"success"}
              type="button"
              onClick={handleCapture}
            >
              Capture
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              fillMode={"solid"}
              type="button"
              themeColor={"primary"}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div style={{ marginTop: 10 }}>
            <Button
              fillMode={"solid"}
              themeColor={"success"}
              type="button"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              fillMode={"solid"}
              type="button"
              themeColor={"info"}
              onClick={() => setIsCaptured(false)}
            >
              Retake
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CameraModal;
