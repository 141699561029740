import React, { CSSProperties } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Ripple } from "@progress/kendo-react-ripple";

type loadingType = "pulsing" | "infinite-spinner" | "converging-spinner";
type loadingSize = "small" | "medium" | "large";
type fillMode = "solid" | "outline" | "flat" | "link";
type Type = "submit" | "button" | "reset";
type themeColor =
  | "primary"
  | "base"
  | "secondary"
  | "tertiary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "light"
  | "inverse";

interface ILoadingButtonProps {
  label: string;
  loading?: boolean;
  disabled?: boolean;
  loadingType?: loadingType;
  size?: loadingSize;
  color?: string;
  fillMode?: fillMode;
  type?: Type;
  width?: string;
  themeColor?: themeColor;
  className?: string;
  tabIndex?: number;
  buttonStyle?: CSSProperties;
  onClick?: () => void;
}

const ButtonWithLoading: React.FC<ILoadingButtonProps> = ({
  label,
  loading = false,
  disabled = false,
  tabIndex,
  loadingType = "pulsing",
  size = "small",
  color = "white",
  fillMode = "solid",
  themeColor = "primary",
  type = "button",
  width = "fit-content",
  className = "",
  buttonStyle,
  onClick,
}) => {
  return (
    <Ripple>
      <Button
        tabIndex={tabIndex}
        type={type}
        fillMode={fillMode}
        style={{ ...buttonStyle, width: width }}
        themeColor={themeColor}
        disabled={disabled}
        className={className}
        onClick={onClick}
      >
        {!loading ? (
          label
        ) : (
          <Loader style={{ color: color }} size={size} type={loadingType} />
        )}
      </Button>
    </Ripple>
  );
};

export default ButtonWithLoading;
