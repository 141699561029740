import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  deleteProductMiliing,
  getAllProductMiliing,
} from "./services/productmilling.services";
import {
  clearFilterProductMilling,
  setFilterProductMilling,
  setProductMillingID,
} from "./productmillingSlice";
import { clearProductCleaningID } from "../productcleaning/productCleaningSlice";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
} from "../../_contstants/common";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { TextBox } from "@progress/kendo-react-inputs";

const ProductMilling: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/productmilling/create");
  };

  return (
    <>
      <DeleteProductMillingDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Product Milling List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ProductMillingGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const ProductMillingGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const ProductMillingList = useAppSelector(
    (state) => state.productmilling.ProductMillingList
  );
  const loading = useAppSelector((state) => state.productmilling.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProductMilling = useAppSelector(
    (state) => state.productmilling.FilterProductMilling
  );

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterProductMilling());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterProductMilling,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllProductMiliing(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (product_milling_guid: number) => {
      navigate("/productmilling/edit", {
        state: { product_milling_guid: product_milling_guid },
      });
    };
    const handleOpenDeleteDialog = (product_milling_guid: number) => {
      dispatch(openDialog("deleteProductMilling"));
      dispatch(setProductMillingID(product_milling_guid));
    };

    return (
      <td
        className={`${props.className} action-td`}
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.product_milling_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem?.product_milling_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
       style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
        filterable={true}
        filter={filter}
        data={
          filter ? filterBy(ProductMillingList, filter) : ProductMillingList
        }
        onFilterChange={handleFilterChange}
        total={ProductMillingList[0]?.itemcount || ProductMillingList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="milling_no"
          title="Milling No"
          width={150}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/productmilling/edit", {
                    state: {
                      product_milling_guid:
                        props.dataItem?.product_milling_guid,
                    },
                  })
                }
              >
                {props.dataItem?.milling_no}
              </div>
            </td>
          )}
        />
        <Column field="milling_date" title="Milling Date" width={150} />
        <Column
          field="product_name"
          title="Product Name"
          width={300}
          cell={(props: any) => <td>{props.dataItem?.ready_product_name}</td>}
          filterCell={CommonFilterCell}
        />
        <Column field="lot_no" title="Lot No" width={150} />
        {/* <Column field="grn_no" title="GRN No" width={150} /> */}
        <Column
          field="no_of_workers"
          title="No of Workers"
          width={150}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.no_of_workers}</td>
          )}
        />
        <Column field="machine_name" title="Machine No" width={250} />
        <Column
          field="start_time"
          title="Time"
          width={200}
          cell={(props: any) => (
            <td>
              {props.dataItem?.start_time}
              {props.dataItem?.start_time && props.dataItem?.end_time && " - "}
              {props.dataItem?.end_time}
            </td>
          )}
        />
        <Column
          field="total_qty"
          title="Total Quantity"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.total_qty)}
            </td>
          )}
        />
        <Column
          field="received_qty"
          title="Received Quantity"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.received_qty)}
            </td>
          )}
        />
        <Column
          field="production_loss_qty"
          title="Milling Loss Quantity"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.production_loss_qty)}
            </td>
          )}
        />
        <Column
          field="katta"
          title="Katta"
          width={200}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.katta}</td>
          )}
        />
        {/* <Column field="process_name" title="Next Process Name" width={200} /> */}
        <Column field="checker_name" title="Checker By" width={300} />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            locked={true}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteProductMillingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ProductMillingID = useAppSelector(
    (state) => state.productmilling.ProductMillingID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProductMilling = useAppSelector(
    (state) => state.productmilling.FilterProductMilling
  );

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteProductMiliing(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterProductMilling,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(closeDialog());
        dispatch(clearProductCleaningID());
        dispatch(getAllProductMiliing(payload));
      } else {
        dispatch(closeDialog());
        dispatch(clearProductCleaningID());
        dispatch(getAllProductMiliing(payload));
      }
    }
  };

  return (
    <>
      {dialogName === "deleteProductMilling" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Product Milling"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Product Milling?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ProductMillingID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProductMilling: any = useAppSelector(
    (state) => state.productmilling.FilterProductMilling
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllProductMiliing(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterProductMilling,
        [inputField]: inputValue,
      };
      dispatch(setFilterProductMilling(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterProductMilling, [props.field]: "" };
    dispatch(setFilterProductMilling(updatedFilters));

    const payload = {
      ...updatedFilters,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllProductMiliing(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterProductMilling[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterProductMilling[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default ProductMilling;
