import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveTermsAndConditions,
  createTermsAndConditions,
  deleteTermsAndConditions,
  getAllActiveTermsAndConditions,
  getAllTermsAndConditions,
  getTermsAndConditionsByID,
  updateTermsAndConditions,
} from "./services/termsAndCondition.services";
import {
  ITermsAndCondition,
  ITermsAndConditionInitialState,
} from "./termsAndConditionModel";

const initialState: ITermsAndConditionInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  TermsAndConditionList: [],
  TermsAndConditionID: "",
  TermsAndConditionDetail: {
    terms_and_condition: "",
    description: "",
    isactive: 1,
  },
};

const termAndConditionSlice = createSlice({
  name: "termsandcondition",
  initialState,
  reducers: {
    clearTermsAndConditionDetails: (state) => {
      state.TermsAndConditionDetail = initialState.TermsAndConditionDetail;
    },
    setTermsAndConditionID: (state, action) => {
      state.TermsAndConditionID = action.payload;
    },
    clearTermsAndConditionID: (state) => {
      state.TermsAndConditionID = initialState.TermsAndConditionID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTermsAndConditions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTermsAndConditions.fulfilled,
      (state, action: PayloadAction<ITermsAndCondition[]>) => {
        state.loading = false;
        state.TermsAndConditionList = action.payload || [];
      }
    );
    builder.addCase(getAllTermsAndConditions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TermsAndConditionList = [];
    });
    builder.addCase(createTermsAndConditions.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createTermsAndConditions.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createTermsAndConditions.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTermsAndConditions.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateTermsAndConditions.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateTermsAndConditions.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTermsAndConditions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTermsAndConditions.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTermsAndConditions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTermsAndConditionsByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getTermsAndConditionsByID.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.TermsAndConditionDetail = action.payload;
      }
    );
    builder.addCase(getTermsAndConditionsByID.rejected, (state, action) => {
      state.formLoading = false;
      state.TermsAndConditionDetail = initialState.TermsAndConditionDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveTermsAndConditions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTermsAndConditions.fulfilled,
      (state, action: PayloadAction<ITermsAndCondition[]>) => {
        state.loading = false;
        state.TermsAndConditionList = action.payload || [];
      }
    );
    builder.addCase(
      getAllActiveTermsAndConditions.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.TermsAndConditionList = [];
      }
    );
    builder.addCase(ActiveInactiveTermsAndConditions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveTermsAndConditions.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      ActiveInactiveTermsAndConditions.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  clearTermsAndConditionDetails,
  setTermsAndConditionID,
  clearTermsAndConditionID,
} = termAndConditionSlice.actions;
export default termAndConditionSlice.reducer;
