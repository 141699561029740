import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../components/common/AlertBox";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights } from "../../_helper/helper";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  deleteRetailerSalesOrder,
  getAllRetailerSalesOrders,
} from "./services/retailerSalesOrder.services";
import {
  clearRetailerSalesOrderID,
  setRetailerSalesOrderID,
} from "./retailerSalesOrderSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

const RetailerSalesOrder: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const AccountID = parseInt(AccountIDString);

  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(setCurrentPage(0));
    };
  }, []);

  const handleRetailerSalesOrderCreate = () => {
    navigate("/retailersalesorder/create");
  };

  return (
    <div className={AccountID ? "container pt-2" : ""}>
      <DeleteRetailerSalesOrderDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: AccountID ? "4px 0px 15px 0" : "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Retailer Sales Order List
          </Typography.h4>
          {(AccountID || isAddAccess) && (
            <Button
              onClick={handleRetailerSalesOrderCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add New
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <RetailerSalesOrderGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

const RetailerSalesOrderGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const AccountID = parseInt(AccountIDString);

  const loading = useAppSelector((state) => state.retailerSalesOrder.loading);
  const RetailerSalesOrderList = useAppSelector(
    (state) => state.retailerSalesOrder.RetailerSalesOrderList
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  useEffect(() => {
    const payload = {
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllRetailerSalesOrders(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditRetailerSalesOrder = (id: any) => {
      navigate("/retailersalesorder/edit", { state: { id: id } });
    };
    const handleOpenDeleteDialog = (id: any) => {
      dispatch(openDialog("deleteRetailerSalesOrder"));
      dispatch(setRetailerSalesOrderID(id));
    };
    return (
      <td className="action-td">
        {(AccountID || isEditAccess) && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditRetailerSalesOrder(props.dataItem.id)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.id)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    // setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {RetailerSalesOrderList && RetailerSalesOrderList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            (filter
              ? filterBy(RetailerSalesOrderList, filter)
              : RetailerSalesOrderList) || []
          }
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          total={
            RetailerSalesOrderList[0]?.itemcount ||
            RetailerSalesOrderList?.length
          }
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="uniqueKey"
            title="Sr. No."
            width={80}
            filterable={false}
          />
          <Column
            field="retailer_name"
            title="Name"
            cell={(props: any) => (
              <td>
                {AccountID || isEditAccess ? (
                  <div
                    className="hoverableName"
                    onClick={() =>
                      navigate("/retailersalesorder/edit", {
                        state: { id: props.dataItem?.id },
                      })
                    }
                  >
                    {props.dataItem.retailer_name}
                  </div>
                ) : (
                  <div className="normalName">
                    {props.dataItem.retailer_name}
                  </div>
                )}
              </td>
            )}
          />
          {/* <Column field="user_type" title="User Type" /> */}
          <Column field="date" title="Date" />
          {(AccountID || isDeleteAccess || isEditAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteRetailerSalesOrderDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const RetailerSalesOrderID = useAppSelector(
    (state) => state.retailerSalesOrder.RetailerSalesOrderID
  );
  const handleDeleteAction = async (id: any) => {
    if (id) {
      const response = await dispatch(deleteRetailerSalesOrder(id));
      dispatch(setCurrentPage(0));
      const payload = {
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllRetailerSalesOrders(payload));
        dispatch(closeDialog());
        dispatch(clearRetailerSalesOrderID());
      } else {
        dispatch(getAllRetailerSalesOrders(payload));
        dispatch(closeDialog());
        dispatch(clearRetailerSalesOrderID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteRetailerSalesOrder" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Retailer Sales Order?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this retailer sales order?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RetailerSalesOrderID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default RetailerSalesOrder;
