import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  ITermsAndCondition,
  ITermsAndConditionDetails,
} from "../termsAndConditionModel";

export const getAllTermsAndConditions = createAsyncThunk(
  "TermsAndCondition/FindAllTermsAndCondition",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndCondition/FindAllTermsAndCondition`,
        {
          TermsAndConditions: "",
        }
      );
      return response.data?.Data as ITermsAndCondition[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Terms & Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTermsAndConditions = createAsyncThunk(
  "TermsAndCondition/InsertTermsAndCondition",
  async (TermsAndConditionsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndCondition/InsertTermsAndCondition`,
        TermsAndConditionsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTermsAndConditions = createAsyncThunk(
  "TermsAndCondition/UpdateTermsAndCondition",
  async (TermsAndConditionsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndCondition/UpdateTermsAndCondition`,
        TermsAndConditionsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTermsAndConditions = createAsyncThunk(
  "TermsAndCondition/DeleteTermsAndCondition",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndCondition/DeleteTermsAndCondition`,
        { terms_and_condition_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTermsAndConditionsByID = createAsyncThunk(
  "TermsAndCondition/FindByIDTermsAndCondition",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndCondition/FindByIDTermsAndCondition`,
        { terms_and_condition_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        terms_and_condition: result.terms_and_condition,
        description: result.description,
        isactive: result.isactive === true ? 1 : 2,
      } as ITermsAndConditionDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveTermsAndConditions = createAsyncThunk(
  "TermsAndCondition/ActiveInActiveTermsAndCondition",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndCondition/ActiveInActiveTermsAndCondition`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTermsAndConditions = createAsyncThunk(
  "TermsAndCondition/FindAllActiveTermsAndCondition",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/TermsAndCondition/FindAllActiveTermsAndCondition`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
