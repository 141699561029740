import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearInwardRegisterDetails,
  clearInwardRegisterList,
  setInwardRegisterReport,
} from "../reportsSlice";
import { IItem } from "../../Item/itemModel";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { getInwardRegisterReport } from "../services/reports.services";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const startdate = moment()?.startOf("month")?.toDate() || "";
  const enddate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("start_date", {
      value: startdate,
    });
    formRenderProps.onChange("end_Date", {
      value: enddate,
    });
  }, []);

  return null;
};

const InwardRegisterReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const InwardRegisterList = useAppSelector(
    (state) => state.reports.InwardRegisterList
  );
  const InwardRegisterDetails = useAppSelector(
    (state) => state.reports.InwardRegisterDetails
  );

  useEffect(() => {
    return () => {
      dispatch(clearInwardRegisterList());
      dispatch(clearInwardRegisterDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      start_date: values?.start_date
        ? moment(values?.start_date).format("YYYY-MM-DD")
        : null,
      end_Date: values?.end_Date
        ? moment(values?.end_Date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setInwardRegisterReport(payload));
    dispatch(getInwardRegisterReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={InwardRegisterDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "60%" }]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Inward Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="start_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="end_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("start_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      // component={FormSelectionField}
                      // options={ItemList?.map((item: IItem) => {
                      //   return {
                      //     value: item?.id,
                      //     label: item?.product_name,
                      //   };
                      // })}
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                    <div
                      style={{
                        marginBottom: 1,
                      }}
                    >
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          InwardRegisterList &&
          InwardRegisterList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <InwardRegisterGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const InwardRegisterGridCommponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  //   const loading = useAppSelector((state) => state.reports.loading);
  const InwardRegisterList = useAppSelector(
    (state) => state.reports.InwardRegisterList
  );
  const InwardRegisterDetails = useAppSelector(
    (state) => state.reports.InwardRegisterDetails
  );
  const isInwardRegisterRef = React.useRef(true);

  useEffect(() => {
    if (!isInwardRegisterRef.current) {
      const payload = {
        item_id: InwardRegisterDetails?.item_id
          ? InwardRegisterDetails?.item_id
          : null,
        start_date: InwardRegisterDetails?.start_date
          ? InwardRegisterDetails?.start_date
          : "",
        end_Date: InwardRegisterDetails?.end_Date
          ? InwardRegisterDetails?.end_Date
          : "",
      };
      dispatch(getInwardRegisterReport(payload));
    } else {
      isInwardRegisterRef.current = false;
    }
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? InwardRegisterList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="4" style="width:16%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="4" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content += "<td>Document Name:</td>";
      content +=
        '<td colSpan="3" style="font-weight:bold;">Inward Register Report</td>';
      content += "</tr>";
      content += "<tr>";
      content += "<td>Document No.:</td>";
      content += "<td></td>";
      content += "<td>Page</td>";
      content += `<td>${i + 1}</td>`;
      content += "</tr>";
      content += "<tr>";
      content += "<td>Date Of Issue:</td>";
      content += "<td></td>";
      content += "<td></td>";
      content += "<td></td>";
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Sr. No.</th>';
      content += "<th>Date</th>";
      content += "<th>Product Name</th>";
      content += "<th>GRN No</th>";
      content += "<th>Dagina</th>";
      content += "<th>Weight</th>";
      content += "<th>Unit</th>";
      content += "<th>Rate</th>";
      content += "<th>Total</th>";
      content += "<th>Account Name</th>";
      content += "<th>Chalan No</th>";
      content += "<th>Bill No</th>";
      content += "<th>Bill Date</th>";
      content += "<th>Vehicle No</th>";
      content += "<th>Vehicle In Time</th>";
      content += "<th>Vehicle Out Time</th>";
      content += "<th>Warehouse Name</th>";
      content += "<th>Receipt No</th>";
      content += "<th>Remarks</th>";
      // content += "<th>Lot No</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td>${j + 1}</td>`;
        content += `<td>${
          data[j]?.inward_date
            ? moment(data[j]?.inward_date, "DD/MM/YYYY").format("DD MMM YYYY")
            : ""
        }</td>`;
        content += `<td>${data[j].item_name}</td>`;
        content += `<td style="text-align:end;">${data[j].grn_no}</td>`;
        content += `<td style="text-align:end;">${data[j].dagina}</td>`;
        content += `<td style="text-align:end;">${
          data[j].weight ? formatIndianNumberForQty(data[j].weight) : null
        }</td>`;
        content += `<td>${data[j].unit_name}</td>`;
        content += `<td style="text-align:end;">${
          data[j].rate ? formatIndianNumber(data[j].rate) : null
        }</td>`;
        content += `<td style="text-align:end;">${
          data[j].total ? formatIndianNumber(data[j].total) : null
        }</td>`;
        content += `<td>${data[j].account_name}</td>`;
        content += `<td>${data[j].chalan_no}</td>`;
        content += `<td>${data[j].bill_no}</td>`;
        content += `<td>${data[j].bill_date}</td>`;
        content += `<td>${data[j].vehicle_no}</td>`;
        content += `<td>${data[j].vehicle_in_time}</td>`;
        content += `<td>${data[j].vehicle_out_time}</td>`;
        content += `<td>${data[j].warehouse_name}</td>`;
        content += `<td>${data[j].receipt_no}</td>`;
        content += `<td>${data[j].remarks}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      // content +=
      //   '<table class="table table-bordered" style="font-size:11px; width:100%; margin:30px 0; text-align:center">';
      // content += "<tr>";
      // content += `<td style="width:33%">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      // content += `<td style="width:34%">Document Status: </br>CONTROL COPY</td>`;
      // content += `<td style="width:33%">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      // content += "</tr>";
      // content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {InwardRegisterList && InwardRegisterList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Inward Register List{" "}
              {(InwardRegisterDetails?.start_date ||
                InwardRegisterDetails?.end_Date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {InwardRegisterDetails?.start_date
                    ? moment(
                        InwardRegisterDetails?.start_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {InwardRegisterDetails?.start_date &&
                    InwardRegisterDetails?.end_Date &&
                    " - "}
                  {InwardRegisterDetails?.end_Date
                    ? moment(
                        InwardRegisterDetails?.end_Date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            {isPrintAccess && (
              <Button
                style={{ marginRight: 5 }}
                type="button"
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={() => printTable(InwardRegisterList)}
              >
                Print
              </Button>
            )}
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered responsive-table"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(InwardRegisterList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : InwardRegisterList.slice(page.skip, page.take + page.skip)
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={InwardRegisterList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn
                field="inward_date"
                title="Inward Date"
                width={150}
                cell={(props: any) => (
                  <td>
                    {props.dataItem?.inward_date
                      ? moment(
                          props.dataItem?.inward_date,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : ""}
                  </td>
                )}
              />
              <GridColumn field="item_name" title="Item Name" width={250} />
              <GridColumn
                field="grn_no"
                title="GRN No"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>{props.dataItem?.grn_no}</td>
                )}
              />
              <GridColumn
                field="dagina"
                title="Dagina"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>{props.dataItem?.dagina}</td>
                )}
              />
              <GridColumn
                field="weight"
                title="Weight"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {formatIndianNumberForQty(+props.dataItem?.weight)}
                  </td>
                )}
              />
              <GridColumn field="unit_name" title="Unit" width={150} />
              <GridColumn
                field="rate"
                title="Rate"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem.rate
                      ? formatIndianNumber(props.dataItem.rate)
                      : null}
                  </td>
                )}
              />
              <GridColumn
                field="total"
                title="Total"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem.total
                      ? formatIndianNumber(props.dataItem.total)
                      : null}
                  </td>
                )}
              />

              <GridColumn
                field="account_name"
                title="Account Name"
                width={200}
              />
              <GridColumn field="chalan_no" title="Chalan No" width={150} />
              <GridColumn field="bill_no" title="Bill No" width={150} />
              <GridColumn
                field="bill_date"
                title="Bill Date"
                width={150}
                cell={(props: any) => (
                  <td>
                    {props.dataItem?.bill_date
                      ? moment(props.dataItem?.bill_date, "DD/MM/YYYY").format(
                          "DD MMM YYYY"
                        )
                      : ""}
                  </td>
                )}
              />
              <GridColumn field="vehicle_no" title="Vehicle No" width={150} />
              <GridColumn
                field="vehicle_in_time"
                title="Vehicle In Time"
                width={150}
              />
              <GridColumn
                field="vehicle_out_time"
                title="Vehicle Out Time"
                width={150}
              />
              <GridColumn
                field="warehouse_name"
                title="Warehouse Name"
                width={200}
              />
              <GridColumn field="receipt_no" title="Receipt No" width={150} />
              <GridColumn
                field="remarks"
                title="Remarks"
                width={250}
                cell={(props: any) => (
                  <td>
                    <div
                      className="remarks-content"
                      dangerouslySetInnerHTML={{
                        __html: props.dataItem?.remarks,
                      }}
                    />
                  </td>
                )}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default InwardRegisterReportForm;
