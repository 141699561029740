import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getVisitorByID } from "./services/visitor.services";
import LOGO from "../../assets/Images/logo.png";
import userAvatar from "../../assets/Images/useravatar.jpg";
import { Avatar } from "@progress/kendo-react-layout";
import moment from "moment";

const VisitorPrint: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const visitor_guid = params?.visitor_guid;
  const VisitorDetail = useAppSelector((state) => state.visitor.VisitorDetail);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (visitor_guid) {
      const handlePrint = async () => {
        const response = await dispatch(getVisitorByID(visitor_guid));

        if (response.meta.requestStatus === "fulfilled") {
          setIsDataLoaded(true);
        }
      };
      handlePrint();
    }
  }, [visitor_guid, dispatch]);

  useEffect(() => {
    if (isImageLoaded && isDataLoaded) {
      window.print();
    }
  }, [isImageLoaded, isDataLoaded]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className="container pt-5 print-container">
      <div className="row">
        <div className="col-12">
          <div
            className="print-area"
            style={{
              maxWidth: 300,
              margin: "0 auto",
              textAlign: "center",
              border: "1px solid lightpink",
              outline: "1px solid pink",
              outlineOffset: 3,
              background: "lightpink",
            }}
          >
            <div
              style={{
                color: "brown",
                background: "#fff",
                padding: "10px",
                borderRadius: "0 0 15px 15px",
              }}
            >
              <h2 className="m-0">Visitor</h2>
            </div>
            <div style={{ background: "lightpink", padding: 10 }}>
              <img src={LOGO} alt="" height={80} />
              <h5 className="mb-4">SHREE GANESH MASALA</h5>
              <Avatar
                style={{
                  width: 90,
                  height: 90,
                  flexBasis: 90,
                  outline: "1px solid red",
                  outlineOffset: 2,
                }}
                rounded={"full"}
                type={"image"}
              >
                {VisitorDetail?.visitor_image ? (
                  <img
                    style={{ width: 90, height: 90, cursor: "pointer" }}
                    src={VisitorDetail?.visitor_image}
                    alt={"User Avatar"}
                    onLoad={handleImageLoad}
                  />
                ) : (
                  <img
                    style={{ width: 90, height: 90 }}
                    src={userAvatar}
                    alt="user-avatar"
                    // onLoad={handleImageLoad}
                  />
                )}
              </Avatar>
              <h4 className="mt-1 fw-600">{VisitorDetail?.name}</h4>
              <h6 style={{ color: "brown" }}>{VisitorDetail?.from_company}</h6>
            </div>
            <p
              className="text-start"
              style={{
                textAlign: "left",
                padding: "20px 10px",
                margin: 0,
                borderRadius: "15px 15px 0 0",
                background: "white",
              }}
            >
              {VisitorDetail?.visitor_date
                ? moment(VisitorDetail?.visitor_date).format("DD/MM/YYYY")
                : ""}
              ,{" "}
              {VisitorDetail?.in_time
                ? moment(VisitorDetail?.in_time).format("hh:mm a")
                : ""}{" "}
              <br />
              Person to See ({VisitorDetail?.user_name})
              <div style={{ marginTop: 20 }}>
                <b>Host Sign : ______________________</b>
              </div>
            </p>
          </div>
        </div>
      </div>
      {/* <button className="no-print" onClick={handlePrint}>Print</button> */}
    </div>
  );
};

export default VisitorPrint;
