import React from 'react'

const EnvelopePrint = () => {
    return (
        <div className='container envelope-print'>
            <div className="row">
                <div className="col-12">
                    Envelope
                </div>
            </div>
        </div>
    )
}

export default EnvelopePrint