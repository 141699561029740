import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRoute } from "../routeModel";

export const getAllRoute = createAsyncThunk("Route/FindAllRoute", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Route/FindAllRoute`,
      {
        route_name: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Route:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const insertRoute = createAsyncThunk(
  "Route/InsertRoute",
  async (RouteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/InsertRoute`,
        RouteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoute = createAsyncThunk(
  "Route/UpdateRoute",
  async (RouteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/UpdateRoute`,
        RouteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRoute = createAsyncThunk(
  "Route/DeleteRoute",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/DeleteRoute`,
        { route_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRouteByID = createAsyncThunk(
  "Route/FindByIDRoute",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Route/FindByIDRoute`,
        { route_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        route_guid: result.route_guid,
        route_name: result.route_name,
        description: result.description,
        isactive: result.isactive === true ? 1 : 2,
      } as IRoute;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);




export const getAllActiveRoute = createAsyncThunk(
    "Route/FindAllActiveRoute",
    async () => {
      try {
        const response = await axiosApiInstance.get(
          `${API_URL}/Route/FindAllActiveRoute`
        );
        return response.data?.Data;
      } catch (error: any) {
        // ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error Fetching route:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    }
  );


  export const routeActiveInactive = createAsyncThunk(
    "Route/ActiveInActiveRoute",
    async (formData: any) => {
      try {
        const response = await axiosApiInstance.post(
          `${API_URL}/Route/ActiveInActiveRoute`,
          formData
        );
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } catch (error: any) {
        ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error active-inactive route:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    }
  );
