import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IArea, IAreaInitialState } from "./areaModel";
import {
  activeInactiveArea,
  createArea,
  deleteArea,
  getAllActiveAreas,
  getAllAreas,
  getAreaByID,
  updateArea,
} from "./services/area.services";

const initialState: IAreaInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  AreaList: [],
  AreaID: null,
  AreaDetail: {
    area_name: "",
    sequence_no: null,
    city_id: null,
    state_id: null,
    country_id: null,
    pincode: null,
    isactive: 1,
  },
  FilterArea: {
    area_name: "",
    city_name: "",
    country_name: "",
    state_name: "",
  },
};

const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    clearAreaDetails: (state) => {
      state.AreaDetail = initialState.AreaDetail;
    },
    setAreaID: (state, action) => {
      state.AreaID = action.payload;
    },
    clearAreaID: (state) => {
      state.AreaID = initialState.AreaID;
    },
    setFilterArea: (state, action) => {
      state.FilterArea = action.payload;
    },
    clearFilterArea: (state) => {
      state.FilterArea = initialState.FilterArea;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAreas.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAreas.fulfilled,
      (state, action: PayloadAction<IArea[]>) => {
        state.loading = false;
        state.AreaList = action.payload || [];
      }
    );
    builder.addCase(getAllAreas.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AreaList = [];
    });
    builder.addCase(createArea.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createArea.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createArea.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateArea.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateArea.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateArea.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteArea.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteArea.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteArea.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAreaByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getAreaByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.AreaDetail = action.payload;
    });
    builder.addCase(getAreaByID.rejected, (state, action) => {
      state.formLoading = false;
      state.AreaDetail = initialState.AreaDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveAreas.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAreas.fulfilled,
      (state, action: PayloadAction<IArea[]>) => {
        state.loading = false;
        state.AreaList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveAreas.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AreaList = [];
    });
    builder.addCase(activeInactiveArea.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(activeInactiveArea.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(activeInactiveArea.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearAreaDetails,
  setAreaID,
  clearAreaID,
  setFilterArea,
  clearFilterArea,
} = areaSlice.actions;
export default areaSlice.reducer;
