import * as React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  InputSuffix,
  // NumericTextBox,
  TextBox,
  TextBoxProps,
} from "@progress/kendo-react-inputs";
import {
  // FloatingLabel,
  Hint,
  Error,
  Label,
} from "@progress/kendo-react-labels";
import { warningTriangleIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { CgDanger } from "react-icons/cg";

const FormTextField = (props: TextBoxProps & FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type = "text",
    optional,
    placeholder,
    labelStyle,
    wrapperClassName,
    wrapperStyle,
    textBoxStyle,
    max,
    minNumber,
    maxNumber,
    onBlur,
    astrike = false,
    ...other
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper className={wrapperClassName} style={wrapperStyle}>
      <Label
        // label={label}
        editorId={id}
        style={labelStyle}
        // editorValue={other.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label} {astrike && <span style={{ color: "red" }}>*</span>}
      </Label>
      {/* {type === "number" ? (
          <NumericTextBox
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            disabled={disabled}
            
            // onChange={handleInputChange}
            defaultValue={isNaN(defaultValue) ? null : defaultValue}
            step={typeof step === "number" ? step : undefined}
            min={typeof min === "number" ? min : undefined}
            max={typeof max === "number" ? max : undefined}
            {...other}
          />
        ) : ( */}
      <TextBox
        valid={valid}
        rounded={"large"}
        type={type}
        size={"large"}
        style={textBoxStyle}
        placeholder={placeholder}
        suffix={() =>
          touched ? (
            <InputSuffix>
              {!valid && (
                <Button
                  tabIndex={-1}
                  style={{ height: "100%" }}
                  themeColor={"error"}
                  fillMode={"flat"}
                  svgIcon={warningTriangleIcon}
                />
              )}
              {/* {valid ? (
                <Button
                  style={{ height: "100%" }}
                  themeColor={"success"}
                  fillMode={"flat"}
                  svgIcon={checkIcon}
                />
              ) : (
                <Button
                  style={{ height: "100%" }}
                  themeColor={"error"}
                  fillMode={"flat"}
                  svgIcon={warningTriangleIcon}
                />
              )} */}
            </InputSuffix>
          ) : null
        }
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (max) {
            return (e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, +max));
          } else if (minNumber && maxNumber) {
            return (e.target.value =
              +e.target.value < +minNumber
                ? String(minNumber)
                : +e.target.value > +maxNumber
                ? String(maxNumber)
                : String(e.target.value));
          } else if (minNumber) {
            return (e.target.value =
              +e.target.value < +minNumber
                ? String(minNumber)
                : String(e.target.value));
          } else if (maxNumber) {
            return (e.target.value =
              +e.target.value > +maxNumber
                ? String(maxNumber)
                : String(e.target.value));
          } else {
            return e;
          }
        }}
        id={id}
        disabled={disabled}
        aria-describedby={`${hintId} ${errorId}`}
        onBlur={onBlur}
        {...other}
      />
      {/* )} */}
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && (
        <Error className="d-flex justify-content-end" id={errorId}>
          <span
            className="d-flex align-items-center"
            style={{
              background: "#DF4957",
              color: "white",
              padding: "1px 8px 1px 5px",
              borderRadius: 10,
              gap: 5,
            }}
          >
            <CgDanger /> {validationMessage}
          </span>
        </Error>
      )}
    </FieldWrapper>
  );
};

export default FormTextField;
