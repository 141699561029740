import React, { useCallback, useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearPORegisterDetails,
  clearPORegisterList,
  setPORegisterReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EDIT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import { getAllAccountIncremental } from "../../account/services/account.services";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getPORegisterReport } from "../services/reports.services";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_Date", {
      value: todate,
    });
  }, []);

  return null;
};

const PORegisterReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const PORegisterList = useAppSelector(
    (state) => state.reports.PORegisterList
  );
  const PORegisterDetails = useAppSelector(
    (state) => state.reports.PORegisterDetails
  );

  useEffect(() => {
    return () => {
      dispatch(clearPORegisterList());
      dispatch(clearPORegisterDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      vendor_id: values?.vendor_id ? values?.vendor_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_Date: values?.to_Date
        ? moment(values?.to_Date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setPORegisterReport(payload));
    dispatch(getPORegisterReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={PORegisterDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Purchase Order Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="vendor_id"
                      label="Vendor"
                      placeholder="Type here to search Vendor..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleVendorSearchChange(
                          search,
                          `vendor_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("vendor_options")
                          ?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.account_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleItemSearchChange(
                          search,
                          `item_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("item_options")
                          ?.map((item: any) => {
                            return {
                              value: item?.id,
                              label: item?.product_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: 10,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          PORegisterList &&
          PORegisterList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <PORegisterGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  // { label: "PO No.", value: "po_no" },
  { label: "PO Date", value: "po_date" },
  { label: "Vendor", value: "account_name" },
  { label: "Mobile No", value: "mobile_no" },
  { label: "Email", value: "email_id" },
  { label: "Item Name", value: "product_name" },
  { label: "Item Category", value: "item_category" },
  { label: "Qty", value: "qty" },
  { label: "Rate", value: "rate" },
  { label: "Unit", value: "unit_name" },
  { label: "Discount", value: "discount" },
  { label: "Taxable Amount ", value: "taxable_amount" },
  { label: "CGST(%)", value: "cgst" },
  { label: "CGST Amount", value: "cgst_amount" },
  { label: "SGST(%)", value: "sgst" },
  { label: "SGST Amount", value: "sgst_amount" },
  { label: "IGST(%)", value: "igst" },
  { label: "IGST Amount", value: "igst_amount" },
  { label: "Total Amount", value: "total_amount" },
  { label: "Taxable Amount ", value: "taxable_amount" },
  { label: "Total Taxable Amount", value: "total_taxable_amount" },
  { label: "Total CGST Amount", value: "total_cgst" },
  { label: "Total SGST Amount", value: "total_sgst" },
  { label: "Total IGST Amount", value: "total_igst" },
  { label: "Grand Total Amount", value: "ground_total" },
  { label: "Quotation No", value: "quotation_no" },
  { label: "Quotation Reference", value: "quotation_ref" },
  { label: "Delivery Date", value: "delivery_date" },
  { label: "Freight", value: "freight" },
  { label: "Payment Terms", value: "payment_terms" },
  { label: "Mode Of Transport", value: "mode_of_transport" },
  { label: "P&F Charge", value: "p_and_f_charge" },
];
const PORegisterGridCommponent: React.FC = () => {
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights("/purchaseorder", EDIT_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const PORegisterList = useAppSelector(
    (state) => state.reports.PORegisterList
  );
  const PORegisterDetails = useAppSelector(
    (state) => state.reports.PORegisterDetails
  );

  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  const handleRowClick = (po_no: string, vendor_id: string) => {
    const groupKey = `${po_no}_${vendor_id}`;
    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  };

  const initialValues = {
    filter_columns: [
      // "po_no",
      "po_date",
      "account_name",
      "mobile_no",
      "email_id",
      "product_name",
      "item_category",
      "qty",
      "rate",
      "unit_name",
      "discount",
      "taxable_amount",
      "cgst",
      "cgst_amount",
      "sgst",
      "sgst_amount",
      "igst",
      "igst_amount",
      "total_amount",
      "total_taxable_amount",
      "total_cgst",
      "total_sgst",
      "total_igst",
      "ground_total",
      "quotation_no",
      "quotation_ref",
      "delivery_date",
      "freight",
      "payment_terms",
      "mode_of_transport",
      "p_and_f_charge",
    ],
  };

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Purchase Order Register Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      // {
      //   formRenderProps.valueGetter("filter_columns")?.includes("po_no") &&
      content += '<th style="width:30px;">PO. No.</th>';
      // }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("po_date") &&
          (content += "<th>PO Date</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("account_name") && (content += "<th>Vendor Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("mobile_no") &&
          (content += "<th>Mobile No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("email_id") &&
          (content += "<th>Email ID</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("product_name") && (content += "<th>Item Name</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("item_category") && (content += "<th>Item Category</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("qty") &&
          (content += "<th>Qty</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
          (content += "<th>Rate</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("unit_name") &&
          (content += "<th>Unit</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
          (content += "<th>Discount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("taxable_amount") &&
          (content += "<th>Taxable Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
          (content += "<th>CGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("cgst_amount") && (content += "<th>CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
          (content += "<th>SGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("sgst_amount") && (content += "<th>SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
          (content += "<th>IGST(%)</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("igst_amount") && (content += "<th>IGST Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_amount") && (content += "<th>Total Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("total_taxable_amount") &&
          (content += "<th>Total Taxable Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_cgst") &&
          (content += "<th>Total CGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_sgst") &&
          (content += "<th>Total SGST Amount</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total_igst") &&
          (content += "<th>Total IGST Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("ground_total") &&
          (content += "<th>Grand Total Amount</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("quotation_no") && (content += "<th>Quotation No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("quotation_ref") && (content += "<th>Quotation Ref.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("delivery_date") && (content += "<th>Delivery Date</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("freight") &&
          (content += "<th>Freight</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("p_and_f_charge") && (content += "<th>P&F Charge</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("payment_terms") && (content += "<th>Payment Terms</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("mode_of_transport") &&
          (content += "<th>Mode Of Transport</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";

        // content += `<td style="text-align:center;">${data[j].po_no}</td>`;
        rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
          (content += `<td rowspan="${
            rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
          }" class="text-start">${data[j].po_no}</td>`)
        ) : (
          <></>
        );

        {
          formRenderProps.valueGetter("filter_columns")?.includes("po_date") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:center;">${
              data[j]?.po_date
                ? moment(data[j]?.po_date, "DD/MM/YYYY").format("DD MMM YYYY")
                : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("account_name") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].account_name ? data[j].account_name : ""}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("mobile_no") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].mobile_no}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("email_id") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].email_id}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("product_name") &&
            (content += `<td>${data[j].product_name}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("item_category") &&
            (content += `<td>${data[j].item_category}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("qty") &&
            (content += `<td style="text-align:end;">${
              data[j].qty ? formatIndianNumber(data[j].qty) : null
            }</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
            (content += `<td style="text-align:end;">${
              data[j].rate ? formatIndianNumber(data[j].rate) : null
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("unit_name") &&
            (content += `<td>${data[j].unit_code}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
            (content += `<td style="text-align:end;">${data[j].discount} ${
              data[j].discount &&
              data[j].discount_type &&
              data[j].discount_type === 1
                ? "₹"
                : data[j].discount_type === 2
                ? "%"
                : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("taxable_amount") &&
            (content += `<td style="text-align:end;">${
              data[j].taxable_amount
                ? formatIndianNumber(data[j].taxable_amount)
                : 0
            }</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
            (content += `<td style="text-align:end;">${
              data[j].cgst ? `${data[j].cgst} %` : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("cgst_amount") &&
            (content += `<td style="text-align:end;">${
              data[j].cgst_amount ? formatIndianNumber(data[j].cgst_amount) : ""
            }</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
            (content += `<td style="text-align:end;">${
              data[j].sgst ? `${data[j].sgst} %` : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("sgst_amount") &&
            (content += `<td style="text-align:end;">${
              data[j].sgst_amount ? formatIndianNumber(data[j].sgst_amount) : ""
            }</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
            (content += `<td style="text-align:end;">${
              data[j].igst ? `${data[j].igst} %` : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("igst_amount") &&
            (content += `<td style="text-align:end;">${
              data[j].igst_amount ? formatIndianNumber(data[j].igst_amount) : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_amount") &&
            (content += `<td style="text-align:end;">${
              data[j].total_amount
                ? formatIndianNumber(data[j].total_amount)
                : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_taxable_amount") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:end;">${
              data[j].total_taxable_amount
                ? formatIndianNumber(data[j].total_taxable_amount)
                : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_cgst") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:end;">${
              data[j].total_cgst ? formatIndianNumber(data[j].total_cgst) : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_sgst") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:end;">${
              data[j].total_sgst ? formatIndianNumber(data[j].total_sgst) : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("total_igst") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:end;">${
              data[j].total_igst ? formatIndianNumber(data[j].total_igst) : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("ground_total") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:end;">${
              data[j].ground_total
                ? formatIndianNumber(data[j].ground_total)
                : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("quotation_no") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].quotation_no}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("quotation_ref") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }">${data[j].quotation_ref}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("delivery_date") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" style="text-align:center;">${
              data[j]?.delivery_date
                ? moment(data[j]?.delivery_date, "DD/MM/YYYY").format(
                    "DD MMM YYYY"
                  )
                : ""
            }</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("freight") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].freight}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("p_and_f_charge") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].p_and_f_charge}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("payment_terms") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }">${data[j].payment_terms}</td>`)
          ) : (
            <></>
          );
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("mode_of_transport") &&
          rowSpans[data[j]?.po_no][data[j]?.vendor_id].index === j ? (
            (content += `<td rowspan="${
              rowSpans[data[j]?.po_no][data[j]?.vendor_id].count
            }" >${data[j].mode_of_transport}</td>`)
          ) : (
            <></>
          );
        }
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  const calculateRowSpans = (data: any[]) => {
    const rowSpans: any = {};

    data.forEach((item: any, index: number) => {
      const { po_no, vendor_id } = item;
      if (!rowSpans[po_no]) {
        rowSpans[po_no] = {};
      }

      if (!rowSpans[po_no][vendor_id]) {
        rowSpans[po_no][vendor_id] = { count: 1, index };
      } else {
        rowSpans[po_no][vendor_id].count++;
      }
    });

    return rowSpans;
  };

  const rowSpans = calculateRowSpans(PORegisterList);

  return (
    <>
      {PORegisterList && PORegisterList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  //   style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Purchase Order Register List{" "}
                      {(PORegisterDetails?.from_date ||
                        PORegisterDetails?.to_Date) && (
                        <span style={{ fontSize: 13 }}>
                          {"("}
                          {PORegisterDetails?.from_date
                            ? moment(
                                PORegisterDetails?.from_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {PORegisterDetails?.from_date &&
                            PORegisterDetails?.to_Date &&
                            " - "}
                          {PORegisterDetails?.to_Date
                            ? moment(
                                PORegisterDetails?.to_Date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {")"}
                        </span>
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(PORegisterList, formRenderProps)
                          }
                        >
                          Print
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 2,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 340px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          outline: "1px solid #3b3b3b",
                        }}
                      >
                        {/* {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("po_no") && ( */}
                        <th style={{ minWidth: 80 }}>PO No.</th>
                        {/* )} */}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("po_date") && (
                          <th style={{ minWidth: 120 }}>PO Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("account_name") && (
                          <th style={{ minWidth: 200 }}>Vendor Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("mobile_no") && (
                          <th style={{ minWidth: 150 }}>Mobile No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("email_id") && (
                          <th style={{ minWidth: 250 }}>Email ID</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("product_name") && (
                          <th style={{ minWidth: 250 }}>Item Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("item_category") && (
                          <th style={{ minWidth: 200 }}>Item Category</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("qty") && (
                          <th style={{ minWidth: 150 }}>Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("rate") && (
                          <th style={{ minWidth: 150 }}>Rate</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("unit_name") && (
                          <th style={{ minWidth: 100 }}>Unit</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("discount") && (
                          <th style={{ minWidth: 80 }}>Discount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("taxable_amount") && (
                          <th style={{ minWidth: 150 }}>Taxable Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst") && (
                          <th style={{ minWidth: 100 }}>CGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("cgst_amount") && (
                          <th style={{ minWidth: 150 }}>CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst") && (
                          <th style={{ minWidth: 100 }}>SGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("sgst_amount") && (
                          <th style={{ minWidth: 150 }}>SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst") && (
                          <th style={{ minWidth: 100 }}>IGST(%)</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("igst_amount") && (
                          <th style={{ minWidth: 150 }}>IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_amount") && (
                          <th style={{ minWidth: 150 }}>Total Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_taxable_amount") && (
                          <th style={{ minWidth: 150 }}>
                            Total Taxable Amount
                          </th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_cgst") && (
                          <th style={{ minWidth: 150 }}>Total CGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_sgst") && (
                          <th style={{ minWidth: 150 }}>Total SGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total_igst") && (
                          <th style={{ minWidth: 150 }}>Total IGST Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("ground_total") && (
                          <th style={{ minWidth: 150 }}>Grand Total Amount</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("quotation_no") && (
                          <th style={{ minWidth: 120 }}>Quotation No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("quotation_ref") && (
                          <th style={{ minWidth: 200 }}>Quotation Ref.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("delivery_date") && (
                          <th style={{ minWidth: 120 }}>Delivery Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("freight") && (
                          <th style={{ minWidth: 120 }}>Freight</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("p_and_f_charge") && (
                          <th style={{ minWidth: 120 }}>P&F Charge</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("payment_terms") && (
                          <th style={{ minWidth: 120 }}>Payment Terms</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("mode_of_transport") && (
                          <th style={{ minWidth: 150 }}>Mode Of Transport</th>
                        )}
                      </tr>
                      {PORegisterList &&
                        PORegisterList?.length > 0 &&
                        PORegisterList?.map((item: any, index: number) => {
                          const groupKey = `${item.po_no}_${item.vendor_id}`;
                          const isSelected = selectedGroup === groupKey;

                          return (
                            <tr
                              className="text-center"
                              style={{
                                background: isSelected
                                  ? "rgb(255 198 198 / 53%)"
                                  : "transparent",
                              }}
                              onClick={() =>
                                handleRowClick(item.po_no, item.vendor_id)
                              }
                            >
                              {rowSpans[item.po_no][item?.vendor_id].index ===
                              index ? (
                                <td
                                  rowSpan={
                                    rowSpans[item.po_no][item?.vendor_id].count
                                  }
                                  className="text-start"
                                >
                                  {item.po_no}
                                  {isEditAccess && (
                                    <Button
                                      type="button"
                                      fillMode="flat"
                                      themeColor={"primary"}
                                      size="small"
                                      style={{
                                        padding: "15px",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => {
                                        navigate("/purchaseorder/edit", {
                                          state: {
                                            purchase_order_guid:
                                              item?.purchase_order_guid,
                                          },
                                        });
                                      }}
                                    >
                                      <FiEdit
                                        className="absolute-position"
                                        style={{
                                          fontSize: "20px",
                                          zIndex: 0,
                                          color: "red",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </td>
                              ) : (
                                <></>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("po_date") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                  >
                                    {item.po_date}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("account_name") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.account_name}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("mobile_no") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                  >
                                    {item.mobile_no}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("email_id") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.email_id}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("product_name") && (
                                <td className="text-start">
                                  {item?.product_name}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("item_category") && (
                                <td className="text-start">
                                  {item?.item_category}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("qty") && (
                                <td className="text-end">
                                  {formatIndianNumberForQty(item?.qty || 0)}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("rate") && (
                                <td className="text-end">
                                  {formatIndianNumber(item?.rate || 0)}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("unit_name") && (
                                <td>{item?.unit_code}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("discount") && (
                                <td className="text-end">
                                  {item?.discount ? item?.discount : ""}
                                  {item?.discount &&
                                  item?.discount_type &&
                                  item?.discount_type === 1
                                    ? " ₹"
                                    : item?.discount_type === 2
                                    ? " %"
                                    : ""}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("taxable_amount") && (
                                <td className="text-end">
                                  {formatIndianNumber(
                                    item?.taxable_amount || 0
                                  )}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("cgst") && (
                                <td className="text-end">
                                  {item?.cgst ? `${item?.cgst} %` : ""}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("cgst_amount") && (
                                <td className="text-end">
                                  {formatIndianNumber(item?.cgst_amount)}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("sgst") && (
                                <td className="text-end">
                                  {item?.sgst ? `${item?.sgst} %` : ""}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("sgst_amount") && (
                                <td className="text-end">
                                  {formatIndianNumber(item?.sgst_amount)}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("igst") && (
                                <td className="text-end">
                                  {item?.igst ? `${item?.igst} %` : ""}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("igst_amount") && (
                                <td className="text-end">
                                  {formatIndianNumber(item?.igst_amount)}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("total_amount") && (
                                <td className="text-end">
                                  {formatIndianNumber(item?.total_amount || 0)}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("total_taxable_amount") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-end"
                                  >
                                    {formatIndianNumber(
                                      item?.total_taxable_amount || 0
                                    )}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("total_cgst") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-end"
                                  >
                                    {formatIndianNumber(item?.total_cgst)}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("total_sgst") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-end"
                                  >
                                    {formatIndianNumber(item?.total_sgst)}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("total_igst") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-end"
                                  >
                                    {formatIndianNumber(item?.total_igst)}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("ground_total") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-end"
                                  >
                                    {formatIndianNumber(
                                      item?.ground_total || 0
                                    )}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("quotation_no") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.quotation_no}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("quotation_ref") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.quotation_ref}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("delivery_date") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                  >
                                    {item.delivery_date}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("freight") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.freight}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("p_and_f_charge") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.p_and_f_charge}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("payment_terms") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.payment_terms}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("mode_of_transport") &&
                                (rowSpans[item.po_no][item?.vendor_id].index ===
                                index ? (
                                  <td
                                    rowSpan={
                                      rowSpans[item.po_no][item?.vendor_id]
                                        .count
                                    }
                                    className="text-start"
                                  >
                                    {item.mode_of_transport}
                                  </td>
                                ) : (
                                  <></>
                                ))}
                            </tr>
                          );
                        })}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default PORegisterReportForm;
