import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/logo.png";
import DriverUserProfile from "./DriverUserProfile";

const DriverLayout: React.FC = () => {
  const navigate = useNavigate();
  //   const location = useLocation();

  return (
    <div id="wrapper">
      <div
        className="bg-dark"
        style={{ overflowX: "scroll", scrollbarWidth: "none" }}
      >
        <nav className="container" role="navigation">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <img
                src={Logo}
                alt=""
                height={45}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
              <DriverUserProfile />
            </div>
          </div>
        </nav>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 p-0"
            style={{ overflow: "scroll", scrollbarWidth: "none" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverLayout;
