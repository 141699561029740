import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IItemCategory, IItemCategoryInitialState } from "./ItemCategoryModel";
import {
  createItemCategory,
  deleteItemCategory,
  findAllActiveItemCategory,
  getAllItemCategory,
  getItemCategoryById,
  ItemCategoryActiveInactive,
  updateItemCategory,
} from "./services/Itemcategory.services";

const initialState: IItemCategoryInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ItemCategoryList: [],
  ItemCategoryID: null,
  ItemCategoryDetail: {
    item_category: "",
    remarks: "",
    isactive: 1,
  },
};

export const iemcategorySlice = createSlice({
  name: "itemCategory",
  initialState,
  reducers: {
    clearItemCategoryDetails: (state) => {
      state.ItemCategoryDetail = initialState.ItemCategoryDetail;
    },
    setItemCategoryID: (state, action) => {
      state.ItemCategoryID = action.payload;
    },
    clearItemCategoryID: (state) => {
      state.ItemCategoryID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll ItemCategory */

    builder.addCase(getAllItemCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItemCategory.fulfilled,
      (state, action: PayloadAction<IItemCategory[]>) => {
        state.loading = false;
        state.ItemCategoryList = action.payload;
      }
    );
    builder.addCase(getAllItemCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemCategoryList = [];
    });

    /* For FindAllActiveItemCategory */

    builder.addCase(findAllActiveItemCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveItemCategory.fulfilled,
      (state, action: PayloadAction<IItemCategory[]>) => {
        state.loading = false;
        state.ItemCategoryList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveItemCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemCategoryList = [];
    });

    /* For createItemCategory */

    builder.addCase(createItemCategory.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createItemCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createItemCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateItemCategory */

    builder.addCase(updateItemCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateItemCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateItemCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteItemCategory */

    builder.addCase(deleteItemCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteItemCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItemCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ActiveInactiveItemCategory */

    builder.addCase(ItemCategoryActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ItemCategoryActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ItemCategoryActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ItemCategoryById */

    builder.addCase(getItemCategoryById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getItemCategoryById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ItemCategoryDetail = action.payload;
    });
    builder.addCase(getItemCategoryById.rejected, (state, action) => {
      state.formLoading = false;
      state.ItemCategoryDetail = initialState.ItemCategoryDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearItemCategoryDetails,
  setItemCategoryID,
  clearItemCategoryID,
} = iemcategorySlice.actions;
export default iemcategorySlice.reducer;
