import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";
// import { IEmployeeLeaveDetail } from "../employeeLeaveModel";
export const getAllEmployeeLeave = createAsyncThunk(
  "Leave/FindAllEmployeeLeave",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/FindAllEmployeeLeave`,
        filter
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Employee Leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createEmployeeLeave = createAsyncThunk(
  "Leave/EmployeeInsertLeave",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/EmployeeInsertLeave`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Employee Leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeLeave = createAsyncThunk(
  "Leave/UpdateEmployeeLeave",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/UpdateEmployeeLeave`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating employee Leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteEmployeeLeave = createAsyncThunk(
  "Leave/DeleteEmployeeLeave",
  async (employee_leave_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/DeleteEmployeeLeave`,
        { employee_leave_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting employee Leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEmployeeLeaveByID = createAsyncThunk(
  "Leave/FindByIdEmployeeLeave",
  async (employee_leave_guid: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/FindByIdEmployeeLeave`,
        { employee_leave_guid }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        employee_leave_guid: result?.employee_leave_guid,
        ccto: result?.ccto
          ? result?.ccto.split(",").map((email: string) => email.trim())
          : [],
        itemcount: result?.itemcount,
        totalpages: result?.totalpages,
        employee_id: result?.employee_id,
        employee_name: result?.employee_name,
        leave_id: result?.leave_id,
        leave_type: result?.leave_type,
        from_date: result?.from_date
          ? moment(result?.from_date, "YYYY-MM-DD").toDate()
          : "",
        from_session: result?.from_session,
        to_date: result?.to_date
          ? moment(result?.to_date, "YYYY-MM-DD").toDate()
          : "",
        to_session: result?.to_session,
        days: result?.days,
        apply_to_reporting_id: result?.apply_to_reporting_id,
        apply_to_reporting: result?.apply_to_reporting,
        attachment: result?.attachment ? result?.attachment : "",
        reason: result?.reason,
        leavestatus: result?.leavestatus,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching employee Leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeLeaveStatus = createAsyncThunk(
  "Leave/EmployeeleaveApprovedRejected",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/EmployeeleaveApprovedRejected`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating employee Leave Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadEmployeeLeaveAttachment = createAsyncThunk(
  "Leave/EmployeeImageUpdate",
  async (formData: any) => {
    const data = Object.fromEntries(formData.entries());
    const file = new FormData();
    file.append("attachment", data?.attachment);
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/EmployeeImageUpdate?employee_leave_guid=${data?.employee_leave_guid}`,
        file
      );
      SuccessToast(response?.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Upload Employee Leave Attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
