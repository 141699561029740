import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";

import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
// import { RxCross1 } from "react-icons/rx";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import { clearAccountGroupDetails } from "./accountgroupSlice";
import {
  createAccountGroup,
  getAccountGroupByID,
  getAllAccountGroup,
  getAllActiveAccountGroup,
  updateAccountGroup,
} from "./services/accountGroup.services";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

// import { closed } from "../../components/drawer/drawerSlice";

const CreateAccountGroup: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();

  const AccountGroupDetail = useAppSelector(
    (state) => state.accountGroup.AccountGroupDetail
  );
  const AccountGroupList = useAppSelector(
    (state) => state.accountGroup.AccountGroupList
  );
  const account_group_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.accountGroup.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);
  // const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    if (account_group_guid) {
      const payload = {
        account_group_guid: account_group_guid,
      };
      dispatch(getAccountGroupByID(payload));
    }
  }, [account_group_guid]);

  useEffect(() => {
    return () => {
      dispatch(clearAccountGroupDetails());
      dispatch(closed());
    };
  }, []);
  // useEffect(() => {
  //   setFormKey(formKey + 1);
  // }, [AccountGroupDetail]);

  const NatureofGroup = [
    { value: 1, label: "Assets" },
    { value: 2, label: "Expenses" },
    { value: 3, label: "Income" },
    { value: 4, label: "Liabilities" },
  ];

  const handleSubmit = async (values: any) => {
    try {
      if (!account_group_guid) {
        const insertPayload = {
          group_name: values?.group_name ? values?.group_name : "",
          under_group_id: values?.under_group_id
            ? +values?.under_group_id
            : null,
          nature_of_group_id: values?.nature_of_group_id
            ? +values?.nature_of_group_id
            : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createAccountGroup(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearAccountGroupDetails());
          isIndirect
            ? dispatch(getAllActiveAccountGroup())
            : dispatch(getAllAccountGroup());
        }
      } else {
        const editPayload = {
          account_group_guid: values.account_group_guid,
          group_name: values?.group_name ? values?.group_name : "",
          under_group_id: values?.under_group_id
            ? +values?.under_group_id
            : null,
          nature_of_group_id: values?.nature_of_group_id
            ? +values?.nature_of_group_id
            : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateAccountGroup(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearAccountGroupDetails());
          isIndirect
            ? dispatch(getAllActiveAccountGroup())
            : dispatch(getAllAccountGroup());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/accountgroup");
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  // if (formLoading || !AccountGroupDetail) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      // key={formKey}
      onSubmit={handleSubmit}
      initialValues={AccountGroupDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {account_group_guid
                  ? "Update Account Group"
                  : "Add Account Group"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="group_name"
                label="Account Group"
                placeholder="Account Group Name"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name={"under_group_id"}
                label={"Under Group"}
                component={FormSelectionField}
                // validator={requiredValidator}
                astrike={true}
                options={[
                  {
                    value: "0",
                    label: "Primary",
                  },
                  ...(AccountGroupList.map((undergroup: any) => {
                    return {
                      value: `${undergroup?.id}`,
                      label: undergroup?.group_name,
                    };
                  }) || []),
                ]}
              />
              {/* <Field
                    name="under_group_id"
                    label="User Type"
                    placeholder="User Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={[
                      { label: "Primary", value: 0},
                      { label: "Default Groups", value: 1 },
                      { label: "Added New Groups", value: 2 },
                    ]}
                  /> */}

              {formRenderProps.valueGetter("under_group_id") === "0" && (
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"nature_of_group_id"}
                    label={"Nature of Group"}
                    component={FormSelectionField}
                    validator={
                      formRenderProps.valueGetter("under_group_id") === "0"
                        ? requiredValidator
                        : skipValidator
                    }
                    astrike={
                      formRenderProps.valueGetter("under_group_id") === "0"
                        ? true
                        : false
                    }
                    options={NatureofGroup.map((status: any) => ({
                      value: status.value,
                      label: status.label,
                    }))}
                  />
                </GridLayoutItem>
              )}
            </GridLayoutItem>

            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!account_group_guid ? "Save" : "Update"}
                type="submit"
                disabled={
                  !formRenderProps.allowSubmit ||
                  formLoading ||
                  AccountGroupDetail?.isdefault
                }
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateAccountGroup;
