import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IMagnetCleaning } from "../magnetcleaningModel";
import moment from "moment";

export const getAllMagnetCleaning = createAsyncThunk(
  "MagnetCleaning/FindAllMagnetCleaning",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MagnetCleaning/FindAllMagnetCleaning`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Magnet Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMagnetCleaning = createAsyncThunk(
  "MagnetCleaning/InsertMagnetCleaning",
  async (magnetCleaning: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MagnetCleaning/InsertMagnetCleaning`,
        magnetCleaning
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Magnet Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMagnetCleaning = createAsyncThunk(
  "MagnetCleaning/UpdateMagnetCleaning",
  async (magnetCleaning: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MagnetCleaning/UpdateMagnetCleaning`,
        magnetCleaning
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Magnet Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMagnetCleaningById = createAsyncThunk(
  "MagnetCleaning/FindByIDMagnetCleaning",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MagnetCleaning/FindByIDMagnetCleaning`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        cleaning_no: result.cleaning_no,
        magnet_cleaning_guid: result.magnet_cleaning_guid,
        machine_no: result.machine_no,
        item_id: result.item_id,
        metal_qty: result.metal_qty,
        m_c_cleaning: result.m_c_cleaning,
        sieve_condition: result.sieve_condition,
        magnet_cleaning_date: result.magnet_cleaning_date
          ? moment(result.magnet_cleaning_date, "YYYY-MM-DD").toDate()
          : "",
        supervisor_by: result.supervisor_by,
        verify_by: result.verify_by,
        remarks: result.remarks,
        item_options: result.item_options,
        item_qty: result.item_qty,
        lot_no: result.lot_no,
        metal_qty_percentage: result.metal_qty_percentage,
      } as IMagnetCleaning;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Magnet Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMagnetCleaning = createAsyncThunk(
  "MagnetCleaning/DeleteMagnetCleaning",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MagnetCleaning/DeleteMagnetCleaning`,
        {
          magnet_cleaning_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Magnet Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveMagnetCleaning = createAsyncThunk(
  "MagnetCleaning/FindAllActiveMagnetCleaning",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MagnetCleaning/FindAllActiveMagnetCleaning`
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Magnet Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
