import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Logo from "../../assets/Images/logo.png";
import AccountUserProfile from "./AccountUserProfile";
import useWindowSize from "../../_hooks/useWindowSize";
const MAX_WIDTH = 768;

const AccountLayout: React.FC = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();

  return (
    <div id="wrapper">
      <div
        className="container-fluid bg-dark p-0 account-layout"
        style={{ overflowX: "scroll", scrollbarWidth: "none" }}
      >
        {/* <nav className="container account-home" role="navigation">
          <div className="row">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <img
                src={Logo}
                alt=""
                height={45}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="col-5">
              <ul className="account-home-menu">
                <li
                  className={
                    location.pathname?.split("/")[1] === "order" ? "active" : ""
                  }
                  onClick={() => {
                    navigate("/order");
                  }}
                >
                  Place Order
                </li>
                <li
                  className={
                    location.pathname?.split("/")[1] === "orderhistory"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/orderhistory");
                  }}
                >
                  Order History
                </li>
              </ul>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <AccountUserProfile />
            </div>
          </div>
        </nav> */}
        <nav className="container navbar navbar-expand-md navbar-dark bg-dark p-0">
          <div className="container-fluid p-1 p-md-0">
            <Link className="navbar-brand" to="/">
              <img
                src={Logo}
                alt=""
                height={45}
                style={{ cursor: "pointer" }}
                // onClick={() => navigate("/")}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
              style={{ lineHeight: width > MAX_WIDTH ? "39px" : "" }}
            >
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <Link
                    className={`nav-link text-center  ${
                      location.pathname?.split("/")[1] === "order"
                        ? "active"
                        : ""
                    }`}
                    aria-current="page"
                    to="/order"
                  >
                    Order
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link text-center  ${
                      location.pathname?.split("/")[1] === "orderhistory"
                        ? "active"
                        : ""
                    }`}
                    to="/orderhistory"
                  >
                    Order History
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link text-center  ${
                      location.pathname?.split("/")[1] === "retailersalesorder"
                        ? "active"
                        : ""
                    }`}
                    to="/retailersalesorder"
                  >
                    Retailer Sales Order
                  </Link>
                </li>
              </ul>
              <form className="d-md-flex">
                <AccountUserProfile />
              </form>
            </div>
          </div>
        </nav>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 p-0"
            style={{ overflow: "scroll", scrollbarWidth: "none" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;
