import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getEmployeeSalaryDaysByIDMonthYear = createAsyncThunk(
  "User/FindByIdEmployeeSalaryMonthDay",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIdEmployeeSalaryMonthDay`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        // ...formData,
        // payout_month:+formData.payout_month,
        total_days: result?.total_days,
        working_days: result?.working_days,
        present_days: result?.present_days,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Working Days:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEmployeeSalaryByIDDaysMonthYear = createAsyncThunk(
  "User/FindByIdEmployeeSalaryMonthDaySalary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIdEmployeeSalaryMonthDaySalary`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        basic: result?.basic,
        celling_amount:result?.celling_amount,
        da_per: result?.da_per,
        da: result?.da,
        hra_per: result?.hra_per,
        hra: result?.hra,
        wash: result?.wash,
        adv_bonus: result?.adv_bonus,
        adv_leave: result?.adv_leave,
        prod_ins_all: result?.prod_ins_all,
        adv_std_be: result?.adv_std_be,
        food: result?.food,
        other_earning: result?.other_earning,
        pf_per: result?.pf_per,
        pf: result?.pf,
        esi_per: result?.esi_per,
        esi: result?.esi,
        pt: result?.pt,
        it: result?.it,
        lwf: result?.lwf,
        tds:result?.tds,
        advance: result?.advance,
        loan: result?.loan,
        other: result?.other,
        total_income: result?.total_income,
        total_deduction: result?.total_deduction,
        payable_amount: result?.payable_amount,
        employee_code_string:result?.employee_code_string
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Salary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeSalaryGeneration = createAsyncThunk(
  "User/UpdateEmployeeSalaryGeneration",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeSalaryGeneration`,
        formdata
      );

      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Employee Salary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEmployeeSalaryByIDYear = createAsyncThunk(
  "User/FindByIdEmployeeSalaryView",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIdEmployeeSalaryView`,
        formData
      );
      const result = response.data?.Data as any;
      return result;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Sales details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
