import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWarehouse, IWarehouseInitial } from "./warehouseModel";
import { createWarehouse, deleteWarehouse, findAllActiveWarehouse, getAllWarehouse, getWarehouseById, updateWarehouse, WarehouseActiveInactive } from "./services/warehouse.services";

const initialState: IWarehouseInitial = {
  loading: false,
  formLoading: false,
  error: "",
  warehouseList: [],
  warehouseID: null,
  warehouseDetail: {
    warehouse_name: "",
    address: "",
    remarks: "",
    isactive: 1,
  },
};

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    clearWarehouseDetails: (state) => {
      state.warehouseDetail = initialState.warehouseDetail;
    },
    setWarehouseID: (state, action) => {
      state.warehouseID = action.payload;
    },
    clearWarehouseID: (state) => {
      state.warehouseID = null;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getAllWarehouse.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllWarehouse.fulfilled,
      (state, action: PayloadAction<IWarehouse[]>) => {
        state.loading = false;
        state.warehouseList = action.payload;
      }
    );
    builder.addCase(getAllWarehouse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.warehouseList = [];
    });


    builder.addCase(findAllActiveWarehouse.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveWarehouse.fulfilled,
      (state, action: PayloadAction<IWarehouse[]>) => {
        state.loading = false;
        state.warehouseList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveWarehouse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.warehouseList = [];
    });


    builder.addCase(createWarehouse.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createWarehouse.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createWarehouse.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(updateWarehouse.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateWarehouse.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateWarehouse.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(deleteWarehouse.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteWarehouse.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteWarehouse.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(WarehouseActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(WarehouseActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(WarehouseActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(getWarehouseById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getWarehouseById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.warehouseDetail = action.payload;
    });
    builder.addCase(getWarehouseById.rejected, (state, action) => {
      state.formLoading = false;
      state.warehouseDetail = initialState.warehouseDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearWarehouseDetails,
  setWarehouseID,
  clearWarehouseID,
} = warehouseSlice.actions;
export default warehouseSlice.reducer;
