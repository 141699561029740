import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllOutwardPackingMaterials = createAsyncThunk(
  "OutwardPackingMaterial/FindAllOutwardPackingMaterial",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OutwardPackingMaterial/FindAllOutwardPackingMaterial`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Outward Packing Materials:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createOutwardPackingMaterial = createAsyncThunk(
  "OutwardPackingMaterial/InsertOutwardPackingMaterial",
  async (OutwardPackingMaterialData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OutwardPackingMaterial/InsertOutwardPackingMaterial`,
        OutwardPackingMaterialData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating outward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOutwardPackingMaterial = createAsyncThunk(
  "OutwardPackingMaterial/UpdateOutwardPackingMaterial",
  async (OutwardPackingMaterialData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OutwardPackingMaterial/UpdateOutwardPackingMaterial`,
        OutwardPackingMaterialData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating outward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOutwardPackingMaterialByID = createAsyncThunk(
  "OutwardPackingMaterial/FindByIDOutwardPackingMaterial",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OutwardPackingMaterial/FindByIDOutwardPackingMaterial`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        itemcount: result?.itemcount,
        totalpages: result?.totalpages,
        outward_packing_material_guid: result?.outward_packing_material_guid,
        outward_packing_no: result?.outward_packing_no,
        outward_type: result?.outward_type,
        outward_packing_material_date: result?.outward_packing_material_date
          ? moment(result?.outward_packing_material_date, "YYYY-MM-DD").toDate()
          : "",
        remarks: result?.remarks,
        financial_year: result?.financial_year,
        createdby: result?.createdby,
        create_name: result?.create_name,
        OutwardPackingMaterialDetails: result?.OutwardPackingMaterialDetails
          ? result?.OutwardPackingMaterialDetails.map((item: any) => {
              return {
                id: item?.id,
                outward_packing_item_details_guid:
                  item?.outward_packing_item_details_guid,
                item_id: item?.item_id,
                product_name: item?.product_name,
                outward_item_qty: item?.outward_item_qty,
                unit_id: item?.unit_id,
                unit_name: item?.unit_name,
                roll: item?.roll,
                isactive: item?.isactive,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching outward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteOutwardPackingMaterial = createAsyncThunk(
  "OutwardPackingMaterial/DeleteOutwardPackingMaterial",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OutwardPackingMaterial/DeleteOutwardPackingMaterial`,
        { outward_packing_material_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting outward packing material:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
