import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { getter, Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridHeaderSelectionChangeEvent,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import {
  getSelectedState,
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { ErrorToast } from "../../components/toast/Toasts";
import {
  getAllEmployeeByDepartmentID,
  processAllEmployeeSalary,
} from "./services/processpayroll.services";
import {
  clearProcessPayrollEmployeeDetail,
  clearProcessPayrollEmployeeList,
} from "./processpayrollSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormTextArea from "../../components/formFields/FormTextArea";
import { MONTHS } from "../../_contstants/common";
import { getYearsArray } from "../../_helper/helper";
import { findAllActiveDepartment } from "../department/services/department.services";

interface DepartmentChangeWatcherProps {
  formRenderProps: FormRenderProps;
  setRowSelectionModel: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedState: React.Dispatch<
    React.SetStateAction<{
      [id: string]: boolean | number[];
    }>
  >;
}

const DepartmentChangeWatcher: React.FC<DepartmentChangeWatcherProps> = ({
  formRenderProps,
  setRowSelectionModel,
  setSelectedState,
}) => {
  const DepartmentID = formRenderProps.valueGetter("department_id");
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (DepartmentID) {
      setRowSelectionModel([]);
      setSelectedState({});
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        dispatch(getAllEmployeeByDepartmentID(`${DepartmentID}`));
      }, 300);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [DepartmentID]);
  return null;
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const Processpayroll = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = useState(1);
  const loading = useAppSelector((state) => state.processpayroll.loading);
  const ProcessPayrollEmployeeDetail = useAppSelector(
    (state) => state.processpayroll.ProcessPayrollEmployeeDetail
  );
  const ProcessPayrollEmployeeList = useAppSelector(
    (state) => state.processpayroll.ProcessPayrollEmployeeList
  );
  const DepartmentList = useAppSelector(
    (state) => state.department.DepartmentList
  );
  useEffect(() => {
    dispatch(findAllActiveDepartment());
    return () => {
      dispatch(clearProcessPayrollEmployeeDetail());
      dispatch(clearProcessPayrollEmployeeList());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProcessPayrollEmployeeDetail]);

  const [rowSelectionModel, setRowSelectionModel] = React.useState<number[]>(
    []
  );
  const dataState = ProcessPayrollEmployeeList?.map((dataItem: any) =>
    Object.assign({ selected: false }, dataItem)
  );
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  const DATA_ITEM_KEY: string = "id";
  const SELECTED_FIELD: string = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? ProcessPayrollEmployeeList?.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: { [key: string]: boolean } = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key: string) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    []
  );

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleSubmit = async (values: any) => {
    if (rowSelectionModel?.length < 1) {
      return ErrorToast("Please select atleast 1 Employee!");
    }
    try {
      const payload = {
        employee_id: rowSelectionModel.join(","),
        payout_month: values?.payout_month ? `${values?.payout_month}` : "",
        payout_year: values?.payout_year,
        remarks: values?.remarks,
      };
      const response = await dispatch(processAllEmployeeSalary(payload));
      if (response.meta.requestStatus === "fulfilled") {
        setFormKey(formKey + 1);
        dispatch(clearProcessPayrollEmployeeDetail());
        dispatch(clearProcessPayrollEmployeeList());
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ProcessPayrollEmployeeDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 5, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Employee Process Payroll</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem className="d-flex  gap-2">
                  <Field
                    name="payout_month"
                    label="Payout Month"
                    placeholder="Payout Month"
                    component={FormSelectionField}
                    options={MONTHS.map((month: any) => ({
                      value: month.value,
                      label: month.label,
                    }))}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  <Field
                    name="payout_year"
                    label="Payout Year"
                    placeholder="Payout Year"
                    component={FormSelectionField}
                    options={getYearsArray(2000, new Date().getFullYear() + 1)
                      ?.reverse()
                      ?.map((year: any) => {
                        return {
                          value: year?.value,
                          label: year?.label,
                        };
                      })}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="department_id"
                    label="Department"
                    placeholder="Select Department"
                    component={FormSelectionField}
                    options={[
                      {
                        id: "all",
                        department_name: "Select All",
                      },
                      ...DepartmentList,
                    ].map((dep: any) => ({
                      value: dep.id,
                      label: dep.department_name,
                    }))}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks..."
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <DepartmentChangeWatcher
                  formRenderProps={formRenderProps}
                  setRowSelectionModel={setRowSelectionModel}
                  setSelectedState={setSelectedState}
                />
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                  colSpan={3}
                >
                  <div>
                    <ButtonWithLoading
                      label={"Process"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
      {loading && <LoadingPanel gridRef={gridRef} />}
      {ProcessPayrollEmployeeList?.length > 0 && (
        <ShadowCard style={{ padding: 12, marginTop: 10 }}>
          <GridLayout
            style={{ marginRight: 20 }}
            gap={{ rows: 5, cols: 10 }}
            cols={[
              { width: "33.33%" },
              { width: "33.33%" },
              { width: "33.33%" },
            ]}
          >
            <GridLayoutItem
              style={{
                maxHeight: "calc(100vh - 152px)",
                width: "100%",
              }}
              colSpan={3}
            >
              <Grid
                style={{
                  maxHeight: "calc(100vh - 152px)",
                }}
                data={ProcessPayrollEmployeeList?.map((item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                skip={page.skip}
                take={page.take}
                total={ProcessPayrollEmployeeList?.length}
                pageable={{
                  buttonCount: 5,
                  pageSizes: [10, 25, 50, "All"],
                  pageSizeValue: pageSizeValue,
                  type: "input",
                }}
                onPageChange={pageChange}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                  enabled: true,
                  drag: false,
                  cell: false,
                  mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                cells={{
                  headerCell: HeaderCustomCell,
                  data: MyDataCustomCell,
                }}
              >
                <GridColumn
                  filterable={false}
                  field={SELECTED_FIELD}
                  width={50}
                  headerSelectionValue={
                    dataState?.findIndex(
                      (item: any) => !selectedState[idGetter(item)]
                    ) === -1
                  }
                />
                <GridColumn field="name" title="Employee Name" width={300} />
                <GridColumn field="department_name" title="Department" />
                <GridColumn field="designation_name" title="Designation" />
                <GridColumn field="date_of_joining" title="Joining Date" />
              </Grid>
            </GridLayoutItem>
          </GridLayout>
        </ShadowCard>
      )}
    </>
  );
};

export default Processpayroll;
