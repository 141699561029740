import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllSchemes = createAsyncThunk(
  "Scheme/FindAllScheme",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Scheme/FindAllScheme`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Schemes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createScheme = createAsyncThunk(
  "Scheme/InsertScheme",
  async (SchemeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Scheme/InsertScheme`,
        SchemeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating scheme:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateScheme = createAsyncThunk(
  "Scheme/UpdateScheme",
  async (SchemeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Scheme/UpdateScheme`,
        SchemeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating scheme:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteScheme = createAsyncThunk(
  "Scheme/DeleteScheme",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Scheme/DeleteScheme`,
        { scheme_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting scheme:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSchemeByID = createAsyncThunk(
  "Scheme/FindByIDScheme",
  async (scheme_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Scheme/FindByIDScheme`,
        { scheme_guid: scheme_guid }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        scheme_guid: result.scheme_guid,
        scheme_name: result?.scheme_name,
        from_date: result?.from_date
          ? moment(result?.from_date, "YYYY-MM-DD")?.toDate()
          : "",
        to_date: result?.to_date
          ? moment(result?.to_date, "YYYY-MM-DD")?.toDate()
          : "",
        remarks: result?.remarks,
        // scheme_items: result?.scheme_items || [],
        scheme_items: result?.scheme_items
          ? result?.scheme_items?.map((scheme: any) => {
              return {
                id: scheme.id,
                scheme_id: scheme.scheme_id,
                item_id: scheme.item_id,
                product_name: scheme.product_name,
                item_type: scheme.item_type,
                qty: scheme.qty,
                free_qty: scheme.free_qty,
                mrp: scheme.mrp,
                rate: scheme.rate,
                unit_id: scheme.unit_id,
                unit_name: scheme.unit_name,
                unit_code: scheme.unit_code,
              };
            })
          : [],
        // isactive: result.isactive === true ? 1 : 2,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching scheme:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
