import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGst, IGstInitialState } from "./gstModel";
import {getAllGST,getGstByID,createGST,UpdateGST,DeleteGST,getAllActiveGST,ActiveInactiveGST}from './services/gst.services';

const initialState:IGstInitialState ={
    loading: false,
    formLoading: false,
    error: "",
    GstList: [],
    GstID: null,
    GstDetail: {
        gst_title:'' ,
        gst:null ,
        cgst: null ,
        sgst:null ,
        igst:null ,
        isactive: 1
    }
}

const gstSlice = createSlice({
    initialState,
    name: "gst",
    reducers: {
        clearGSTDetails: (state) => {
            state.GstDetail = initialState.GstDetail;
        },
        setGSTID: (state, action) => {
            state.GstID = action.payload;
        },
        clearGSTID: (state) => {
            state.GstID = initialState.GstID;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllGST.pending, (state) => {
          state.loading = true;
          state.error = "";
        });
        builder.addCase(
          getAllGST.fulfilled,
          (state, action: PayloadAction<IGst[]>) => {
            state.loading = false;
            state.GstList = action.payload || [];
          }
        );
        builder.addCase(getAllGST.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Something went wrong";
          state.GstList = [];
        });
        builder.addCase(createGST.pending, (state) => {
          state.loading = false;
          state.error = "";
        });
        builder.addCase(createGST.fulfilled, (state) => {
          state.formLoading = false;
        });
        builder.addCase(createGST.rejected, (state, action) => {
          state.formLoading = false;
          state.error = action.error.message || "Something went wrong";
        });
        builder.addCase(UpdateGST.pending, (state) => {
          state.formLoading = true;
          state.error = "";
        });
        builder.addCase(UpdateGST.fulfilled, (state) => {
          state.formLoading = false;
        });
        builder.addCase(UpdateGST.rejected, (state, action) => {
          state.formLoading = false;
          state.error = action.error.message || "Something went wrong";
        });
        builder.addCase(DeleteGST.pending, (state) => {
          state.loading = true;
          state.error = "";
        });
        builder.addCase(DeleteGST.fulfilled, (state) => {
          state.loading = false;
        });
        builder.addCase(DeleteGST.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Something went wrong";
        });
        builder.addCase(getGstByID.pending, (state) => {
          state.formLoading = true;
          state.error = "";
        });
        builder.addCase(getGstByID.fulfilled, (state, action: any) => {
          state.formLoading = false;
          state.GstDetail = action.payload;
        });
        builder.addCase(getGstByID.rejected, (state, action) => {
          state.formLoading = false;
          state.GstDetail = initialState.GstDetail;
          state.error = action.error.message || "Something went wrong";
        });
        builder.addCase(getAllActiveGST.pending, (state) => {
          state.loading = true;
          state.error = "";
        });
        builder.addCase(
          getAllActiveGST.fulfilled,
          (state, action: PayloadAction<IGst[]>) => {
            state.loading = false;
            state.GstList = action.payload || [];
          }
        );
        builder.addCase(getAllActiveGST.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Something went wrong";
          state.GstList = [];
        });
        builder.addCase(ActiveInactiveGST.pending, (state) => {
          state.loading = true;
          state.error = "";
        });
        builder.addCase(ActiveInactiveGST.fulfilled, (state) => {
          state.loading = false;
        });
        builder.addCase(ActiveInactiveGST.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Something went wrong";
        });
      },
})

export const { clearGSTDetails, setGSTID, clearGSTID } = gstSlice.actions;
export default gstSlice.reducer;