import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IDesignation } from "../designationModel";

export const getAllDesignations = createAsyncThunk(
  "User/FindAllDesignation",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindAllDesignation`,
        {
          designation_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveDesignations = createAsyncThunk(
  "User/FindAllActiveDesignation",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveDesignation`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDesignation = createAsyncThunk(
  "User/InsertDesignation",
  async (DesignationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertDesignation`,
        DesignationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating designation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDesignation = createAsyncThunk(
  "User/UpdateDesignation",
  async (DesignationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateDesignation`,
        DesignationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating designation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDesignationByID = createAsyncThunk(
  "User/FindByIDDesignation",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDDesignation`,
        { designation_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        designation_guid: result.designation_guid,
        designation_name: result.designation_name,
        designation_code: result.designation_code,
        isactive: result.isactive === true ? 1 : 2,
      } as IDesignation;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching designation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const designationActiveInactive = createAsyncThunk(
  "User/ActiveInActiveDesignation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveDesignation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive designation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDesignation = createAsyncThunk(
  "User/DeleteDesignation",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteDesignation`,
        { designation_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting designation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
