import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserType, IUserTypeInitialState } from "./userTypeModel";
import {
  ActiveInactiveUserType,
  createUserType,
  deleteUserType,
  getAllActiveUserTypes,
  getAllUserTypes,
  getUserTypeByID,
  updateUserType,
} from "./services/userType.services";

const initialState: IUserTypeInitialState = {
  loading: false,
  error: "",
  UserTypeList: [],
  UserTypeID: null,
  UserTypeDetail: {
    user_type: "",
    isactive: 1,
  },
};

const userTypeSlice = createSlice({
  name: "userType",
  initialState,
  reducers: {
    clearUserTypeDetails: (state) => {
      state.UserTypeDetail = initialState.UserTypeDetail;
    },
    setUserTypeID: (state, action) => {
      state.UserTypeID = action.payload;
    },
    clearUserTypeID: (state) => {
      state.UserTypeID = initialState.UserTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUserTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllUserTypes.fulfilled,
      (state, action: PayloadAction<IUserType[]>) => {
        state.loading = false;
        state.UserTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllUserTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserTypeList = [];
    });
    builder.addCase(createUserType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createUserType.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUserType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUserType.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteUserType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteUserType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getUserTypeByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserTypeByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.UserTypeDetail = action.payload;
    });
    builder.addCase(getUserTypeByID.rejected, (state, action) => {
      state.loading = false;
      state.UserTypeDetail = initialState.UserTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveUserTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveUserTypes.fulfilled,
      (state, action: PayloadAction<IUserType[]>) => {
        state.loading = false;
        state.UserTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveUserTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserTypeList = [];
    });
    builder.addCase(ActiveInactiveUserType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveUserType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveUserType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearUserTypeDetails, setUserTypeID, clearUserTypeID } =
  userTypeSlice.actions;
export default userTypeSlice.reducer;
