// import { getter } from "@progress/kendo-react-common";

import {
  INVALID,
  INVALID_EMAIL,
  INVALID_PHONE,
  INVALID_PINCODE,
  MIN_LENGTH_MOBILE,
  MIN_LENGTH_PWD,
  REQUIRED_FIELD,
} from "../../_contstants/Messages";

const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
// const phoneRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);
const phoneRegex: RegExp = new RegExp(/^\d{1,16}$/);
const ccardRegex: RegExp = new RegExp(/^[0-9-]+$/);
const cvcRegex: RegExp = new RegExp(/^[0-9]+$/);
const pincodeRegex = RegExp(/^[1-9][0-9]{3}$|^[1-9][0-9]{5}$/);
const GSTNoRegex = RegExp(
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/
);
const PANNoRegex = RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

export const requiredValidator = (value: any) => (value ? "" : REQUIRED_FIELD);

export const MobileNoValidator = (value: string) =>
  !value ? REQUIRED_FIELD : value.length > 9 ? "" : MIN_LENGTH_MOBILE;

export const MobileNoWOValueValidator = (value: any) =>
  !value ? "" : value && value.length > 9 ? "" : MIN_LENGTH_MOBILE;

export const requiredWOValueValidator = (value: any) =>
  !value ? "" : value ? "" : REQUIRED_FIELD;

export const minValidator = (value: any) =>
  parseInt(value) >= 0 ? "" : "Minimum 0 Required";

export const skipValidator = () => "";

export const requiredArrayValidator = (value: any) =>
  !value || value.length === 0 ? REQUIRED_FIELD : "";

export const emailValidator = (value: string) =>
  !value ? REQUIRED_FIELD : emailRegex.test(value) ? "" : INVALID_EMAIL;

export const emailWOValueValidator = (value: string) =>
  !value ? "" : emailRegex.test(value) ? "" : INVALID_EMAIL;

export const passwordValidator = (value: string) =>
  value && value.length > 5 ? "" : MIN_LENGTH_PWD;

export const confirmPasswordValidator = (
  newPassword: string,
  confirmPassword: string
) => {
  return newPassword === confirmPassword ? "" : "Passwords do not match";
};

export const pincodeValidator = (value: string) =>
  !value ? REQUIRED_FIELD : pincodeRegex.test(value) ? "" : INVALID_PINCODE;

export const pincodeWOValueValidator = (value: string) =>
  !value ? "" : pincodeRegex.test(value) ? "" : INVALID_PINCODE;

export const gstValidator = (value: string) =>
  !value ? REQUIRED_FIELD : GSTNoRegex.test(value) ? "" : INVALID;

export const gstWOValueValidator = (value: string) =>
  !value ? "" : GSTNoRegex.test(value) ? "" : INVALID;

export const panValidator = (value: string) =>
  !value ? REQUIRED_FIELD : PANNoRegex.test(value) ? "" : INVALID;

export const panWOValueValidator = (value: string) =>
  !value ? "" : PANNoRegex.test(value) ? "" : INVALID;

export const phoneValidator = (value: string) =>
  !value ? REQUIRED_FIELD : phoneRegex.test(value) ? "" : INVALID_PHONE;

export const phoneWOValueValidator = (value: string) =>
  !value ? "" : phoneRegex.test(value) ? "" : INVALID_PHONE;

export const userNameValidator = (value: string) =>
  !value
    ? "User Name is required"
    : value.length < 5
    ? "User name should be at least 5 characters long."
    : "";

export const cardValidator = (value: string) =>
  !value
    ? "Credit card number is required. "
    : ccardRegex.test(value)
    ? ""
    : "Not a valid credit card number format.";

export const cvcValidator = (value: string) =>
  !value
    ? "CVC code is required,"
    : cvcRegex.test(value) || value.length !== 3
    ? ""
    : "Not a valid CVC code format.";

export const requiredValidatorforBOOK = (value: any) =>
  value !== undefined && value !== null ? "" : REQUIRED_FIELD;
