import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IMachineCleaning,
  IMachineCleaningInitial,
} from "./machinecleaningModel";
import {
  createMachineCleaning,
  deleteMachineCleaning,
  findAllActiveMachineCleaning,
  getAllMachineCleaning,
  getMachineCleaningById,
  updateMachineCleaning,
} from "./services/machinecleaning.services";
import moment from "moment";

const initialState: IMachineCleaningInitial = {
  loading: false,
  error: "",
  machineCleaningList: [],
  machineCleaningID: null,
  machinecleaningDetail: {
    machine_no: null,
    item_id: null,
    grinding_lot_no: "",
    production_qty: "",
    maintenance_time: "",
    machine_cleaning_date: moment().toDate(),
    qc_by: null,
    production_by: null,
    remarks: "",
  },
};

export const machinecleaningSlice = createSlice({
  name: "machinecleaning",
  initialState,
  reducers: {
    clearMachineCleaningDetails: (state) => {
      state.machinecleaningDetail = initialState.machinecleaningDetail;
    },
    setMachineCleaningID: (state, action) => {
      state.machineCleaningID = action.payload;
    },
    clearMachineCleaningID: (state) => {
      state.machineCleaningID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMachineCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMachineCleaning.fulfilled,
      (state, action: PayloadAction<IMachineCleaning[]>) => {
        state.loading = false;
        state.machineCleaningList = action.payload;
      }
    );
    builder.addCase(getAllMachineCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.machineCleaningList = [];
    });

    builder.addCase(findAllActiveMachineCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveMachineCleaning.fulfilled,
      (state, action: PayloadAction<IMachineCleaning[]>) => {
        state.loading = false;
        state.machineCleaningList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveMachineCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.machineCleaningList = [];
    });

    builder.addCase(createMachineCleaning.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createMachineCleaning.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createMachineCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateMachineCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateMachineCleaning.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMachineCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteMachineCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMachineCleaning.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMachineCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getMachineCleaningById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getMachineCleaningById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.machinecleaningDetail = action.payload;
    });
    builder.addCase(getMachineCleaningById.rejected, (state, action) => {
      state.loading = false;
      state.machinecleaningDetail = initialState.machinecleaningDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearMachineCleaningDetails,
  setMachineCleaningID,
  clearMachineCleaningID,
} = machinecleaningSlice.actions;
export default machinecleaningSlice.reducer;
