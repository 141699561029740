import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { setPurchaseDeleteIndex } from "./purchaseSlice";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IItem } from "../Item/itemModel";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { IGst } from "../gst/gstModel";
import { getItemById } from "../Item/services/item.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import _ from "lodash";

const PurchaseItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const purchase_guid = location.state?.purchase_guid;
  const PurchaseDetail = useAppSelector(
    (state) => state.purchase.PurchaseDetail
  );
  const PurchaseDeleteIndex = useAppSelector(
    (state) => state.purchase.PurchaseDeleteIndex
  );

  const InputRef = React.useRef<any>(null);

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const GstList = useAppSelector((state) => state.gst.GstList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("purchaseItemDeleteDialog"));
    dispatch(setPurchaseDeleteIndex(index));
  };

  useEffect(() => {
    if (!purchase_guid) {
      pushElementInarray();
    }
  }, []);

  // const handleDeleteAction = React.useCallback(
  //   (index: number) => {
  //     fieldArrayRenderProps.onRemove({ index: index });
  //     dispatch(closeDialog());
  //     dispatch(setPurchaseDeleteIndex(-1));
  //   },
  //   [fieldArrayRenderProps]
  // );

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Purchase/DeletePurchaseItem`,
            { id: fieldArrayRenderProps?.value[index]?.id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setPurchaseDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setPurchaseDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        // mrp: null,
        quantity: null,
        free_quantity: null,
        unit_id: null,
        rate: null,
        discount: null,
        discount_type: "₹",
        gst_id: null,
        amount: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const purchase_items =
      fieldArrayRenderProps.formRenderProps.valueGetter("purchase_items");
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const response: any = await dispatch(getItemById(payload));
      purchase_items &&
        purchase_items.length > 0 &&
        purchase_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (response.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.gst_id`,
                {
                  value: `${response.payload?.gst_id}--${
                    response.payload?.gst || "0"
                  }`,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.unit_id`,
                {
                  value: response.payload?.unit_id,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.mrp`,
                {
                  value: response.payload?.mrp,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.rate`,
                {
                  value: response.payload?.purchase_price,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.gst_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.unit_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.mrp`,
                {
                  value: "",
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_items.${index}.rate`,
                {
                  value: "",
                }
              );
            }
          }
        });
    } else {
      purchase_items &&
        purchase_items.length > 0 &&
        purchase_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_items.${index}.gst_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_items.${index}.unit_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_items.${index}.mrp`,
              {
                value: "",
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_items.${index}.rate`,
              {
                value: "",
              }
            );
          }
        });
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      handleProductChange(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (
    item_id: any,
    index: number,
    fieldArrayRenderProps: any
  ) => {
    debouncedHandleProductChange(item_id, index, fieldArrayRenderProps);
  };

  const handleTotalChange = async (
    e: any,
    total: number,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    e?.syntheticEvent?.preventDefault();
    const qty = fieldArrayRenderProps.formRenderProps.valueGetter(
      `purchase_items.${index}.quantity`
    );
    const discount = fieldArrayRenderProps.formRenderProps.valueGetter(
      `purchase_items.${index}.discount`
    );
    const discount_type = fieldArrayRenderProps.formRenderProps.valueGetter(
      `purchase_items.${index}.discount_type`
    );

    if (discount_type && discount_type === "₹") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `purchase_items.${index}.rate`,
        {
          value: (total + discount) / qty,
        }
      );
    } else if (discount_type && discount_type === "%") {
      let perc = 100 - (discount < 100 ? discount : 100);

      fieldArrayRenderProps.formRenderProps.onChange(
        `purchase_items.${index}.rate`,
        {
          value: (total * 100) / perc / qty,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `purchase_items.${index}.rate`,
        {
          value: total / qty,
        }
      );
    }
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 2000 }}
        >
          <GridLayout
            style={{
              marginRight: 35,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem>Item</GridLayoutItem>
            {/* <GridLayoutItem>MRP</GridLayoutItem> */}
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem>Free Quantity</GridLayoutItem>
            <GridLayoutItem>Rate</GridLayoutItem>
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Discount</GridLayoutItem>
            <GridLayoutItem>GST</GridLayoutItem>
            <GridLayoutItem>Amount</GridLayoutItem>
            <GridLayoutItem
              style={{
                position: "sticky",
                top: 0,
                right: -25,
                zIndex: 9,
                background: "#212529",
              }}
            ></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "purchaseItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(PurchaseDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (purchase_items: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: 2000 }}>
                <GridLayout
                  style={{ marginRight: 35, padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "25%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "5%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={
                        (e: any) =>
                          handleChange(e, index, fieldArrayRenderProps)
                        // handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `purchase_items.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: IItem) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.mrp`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem> */}
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.quantity`}
                      placeholder="0"
                      min="0"
                      // validator={requiredValidator}
                      component={FormNumericTextField}
                      validator={
                        !fieldArrayRenderProps.formRenderProps.valueGetter(
                          `purchase_items.${index}.free_quantity`
                        )
                          ? requiredValidator
                          : skipValidator
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.free_quantity`}
                      placeholder="0"
                      min="0"
                      component={FormNumericTextField}
                      validator={
                        !fieldArrayRenderProps.formRenderProps.valueGetter(
                          `purchase_items.${index}.quantity`
                        )
                          ? requiredValidator
                          : skipValidator
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.rate`}
                      placeholder="0"
                      min="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.unit_id`}
                      placeholder="Unit"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={UnitList?.map((unit: IUnit) => {
                        return {
                          value: unit?.id,
                          label: unit?.unit_code,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ position: "relative" }}>
                    <Field
                      // wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.discount`}
                      placeholder="0 %"
                      min="0"
                      component={FormNumericTextField}
                    />
                    <DropDownList
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: "30%",
                        zIndex: 1,
                      }}
                      onChange={(e) => {
                        fieldArrayRenderProps?.formRenderProps?.onChange(
                          `purchase_items.${index}.discount_type`,
                          {
                            value: e.value,
                          }
                        );
                      }}
                      name={`purchase_items.${index}.discount_type`}
                      data={["₹", "%"]}
                      defaultValue={
                        purchase_guid
                          ? PurchaseDetail &&
                            PurchaseDetail?.purchase_items.length > 0 &&
                            PurchaseDetail?.purchase_items[index]
                              ?.discount_type === "₹"
                            ? "₹"
                            : PurchaseDetail?.purchase_items[index]
                                ?.discount_type === "%"
                            ? "%"
                            : "₹"
                          : "₹"
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.gst_id`}
                      placeholder="GST (%)"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={GstList?.map((gst: IGst) => {
                        return {
                          value: `${gst?.id}--${gst?.gst}`,
                          label: gst?.gst_title,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_items.${index}.amount`}
                      placeholder="0"
                      format="n2"
                      // disabled={true}
                      component={FormNumericTextField}
                      onChange={(e) =>
                        handleTotalChange(
                          e,
                          e?.value,
                          index,
                          fieldArrayRenderProps
                        )
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      zIndex: 9,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "10%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              colSpan={4}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Total
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="taxable_amount"
                format="n2"
                component={FormNumericTextField}
                disabled="true"
              />
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default PurchaseItemDetailsArray;
