import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IDistrict } from "../districtModel";

export const getAllDistrict = createAsyncThunk(
  "District/FindAllDistrict",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/District/FindAllDistrict`,
        {
          district_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching District:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InsertDistrict = createAsyncThunk(
  "District/InsertDistrict",
  async (DistrictData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/District/InsertDistrict`,
        DistrictData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating district:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDistrict = createAsyncThunk(
  "District/UpdateDistrict",
  async (DistrictData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/District/UpdateDistrict`,
        DistrictData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating district:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDistrict = createAsyncThunk(
  "District/DeleteDistrict",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/District/DeleteDistrict`,
        { district_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting district:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDistrictByID = createAsyncThunk(
  "District/FindByIDDistrict",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/District/FindByIDDistrict`,
        { district_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        district_guid: result.district_guid,
        district_name: result.district_name,
        state_id: result.state_id,
        isactive: result.isactive === true ? 1 : 2,
      } as IDistrict;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching district:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);



export const getAllActiveDistrict = createAsyncThunk(
    "District/FindAllActiveDistrict",
    async () => {
      try {
        const response = await axiosApiInstance.get(
          `${API_URL}/District/FindAllActiveDistrict`
        );
        return response.data?.Data;
      } catch (error: any) {
        // ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error Fetching district:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    }
  );


  export const districtActiveInactive = createAsyncThunk(
    "District/ActiveInActiveDistrict",
    async (formData: any) => {
      try {
        const response = await axiosApiInstance.post(
          `${API_URL}/District/ActiveInActiveDistrict`,
          formData
        );
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } catch (error: any) {
        ErrorToast(error?.response?.data?.Details || "Something went wrong");
        console.error("Error active-inactive district:", error);
        return Promise.reject({
          message: error?.response?.data?.Details || "Something went wrong",
        });
      }
    }
  );