import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IProcessPayrollEmployee,
  IprocessPayrollInitialState,
} from "./processpayrollModel";
import {
  getAllEmployeeByDepartmentID,
  processAllEmployeeSalary,
} from "./services/processpayroll.services";

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

const initialState: IprocessPayrollInitialState = {
  loading: false,
  error: "",
  ProcessPayrollEmployeeList: [],
  ProcessPayrollEmployeeDetail: {
    employee_id: "",
    payout_month: previousMonth,
    department_id: null,
    payout_year: `${previousYear}`,
    remarks: "",
  },
};

const processPayrollSlice = createSlice({
  name: "processpayroll",
  initialState,
  reducers: {
    clearProcessPayrollEmployeeDetail: (state) => {
      state.ProcessPayrollEmployeeDetail =
        initialState.ProcessPayrollEmployeeDetail;
    },
    clearProcessPayrollEmployeeList: (state) => {
      state.ProcessPayrollEmployeeList =
        initialState.ProcessPayrollEmployeeList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEmployeeByDepartmentID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllEmployeeByDepartmentID.fulfilled,
      (state, action: PayloadAction<IProcessPayrollEmployee[]>) => {
        state.loading = false;
        state.ProcessPayrollEmployeeList = action.payload || [];
      }
    );
    builder.addCase(getAllEmployeeByDepartmentID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProcessPayrollEmployeeList = [];
    });

    builder.addCase(processAllEmployeeSalary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(processAllEmployeeSalary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(processAllEmployeeSalary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearProcessPayrollEmployeeDetail,
  clearProcessPayrollEmployeeList,
} = processPayrollSlice.actions;
export default processPayrollSlice.reducer;
