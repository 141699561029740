import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IHoliday } from "../holidayModel";
import moment from "moment";

export const getAllHolidays = createAsyncThunk(
  "Holiday/FindAllHoliday",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Holiday/FindAllHoliday`,
        {
          title: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveHolidays = createAsyncThunk(
  "Holiday/FindAllActiveHoliday",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Holiday/FindAllActiveHoliday`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createHoliday = createAsyncThunk(
  "Holiday/InsertHoliday",
  async (HolidayData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Holiday/InsertHoliday`,
        HolidayData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating holiday:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateHoliday = createAsyncThunk(
  "Holiday/UpdateHoliday",
  async (HolidayData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Holiday/UpdateHoliday`,
        HolidayData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating holiday:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHolidayByID = createAsyncThunk(
  "Holiday/FindByIDHoliday",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Holiday/FindByIDHoliday`,
        { holiday_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        title: result.title,
        holiday_date: result.holiday_date
          ? moment(result.holiday_date, "YYYY-MM-DD").toDate()
          : "",
        holiday_day: result.holiday_day,
        isactive: result.isactive === true ? 1 : 2,
      } as IHoliday;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching holiday:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const holidayActiveInactive = createAsyncThunk(
  "Holiday/ActiveInActiveHoliday",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Holiday/ActiveInActiveHoliday`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive holiday:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteHoliday = createAsyncThunk(
  "Holiday/DeleteHoliday",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Holiday/DeleteHoliday`,
        { holiday_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting holiday:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
