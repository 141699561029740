import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import {
  activeInactiveArea,
  deleteArea,
  getAllAreas,
} from "./services/area.services";
import {
  clearAreaID,
  clearFilterArea,
  setAreaID,
  setFilterArea,
} from "./areaSlice";
import CreateArea from "./CreateArea";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import AppDrawer from "../../components/muidrawer/Drawer";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";

const Area: React.FC = () => {
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  return (
    <>
      <DeleteAreaDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Area List
          </Typography.h4>
          {isAddAccess && (
            <ButtonCreate label="Add New" drawerName="areaForm" />
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <AreaGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "areaForm" && (
          <AppDrawer>
            <CreateArea />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const AreaGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const AreaList = useAppSelector((state) => state.area.AreaList);
  const loading = useAppSelector((state) => state.area.loading);
  const FilterArea = useAppSelector((state) => state.area.FilterArea);

  useEffect(() => {
    const payload = {
      ...FilterArea,
    };
    dispatch(getAllAreas(payload));

    return () => {
      dispatch(closeDialog());
      dispatch(closed());
      dispatch(clearFilterArea());
    };
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? AreaList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (area_guid: number) => {
      dispatch(openDialog("deleteArea"));
      dispatch(setAreaID(area_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.area_guid}
            name="areaForm"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.area_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        area_guid: props.dataItem.area_guid,
        isactive: !IsActive,
      };
      const payload = {
        ...FilterArea,
      };
      dispatch(activeInactiveArea(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllAreas(payload));
        } else {
          dispatch(getAllAreas(payload));
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={
          filter
            ? filterBy(AreaList, filter).slice(page.skip, page.take + page.skip)
            : AreaList.slice(page.skip, page.take + page.skip)
        }
        onFilterChange={handleFilterChange}
        skip={page.skip}
        take={page.take}
        total={AreaList?.length}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 25, 50, "All"],
          pageSizeValue: pageSizeValue,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="area_name"
          title="Area Name"
          filterCell={CommonFilterCell}
          cell={(props: any) => (
            <td className={isEditAccess ? "hoverableName" : "normalName"}>
              {isEditAccess ? (
                <ButtonEdit
                  ID={props.dataItem?.area_guid}
                  name="areaForm"
                  label={props.dataItem?.area_name}
                />
              ) : (
                props.dataItem?.area_name
              )}
            </td>
          )}
        />
        <Column
          field="city_name"
          title="City Name"
          filterCell={CommonFilterCell}
        />
        <Column
          field="state_name"
          title="State Name"
          filterCell={CommonFilterCell}
        />
        <Column
          field="country_name"
          title="Country Name"
          filterCell={CommonFilterCell}
        />
        <Column
          field="id"
          title="Status"
          cell={MyActiveInActiveCommandCell}
          width={"90px"}
          filterable={false}
          locked={true}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteAreaDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const AreaID = useAppSelector((state) => state.area.AreaID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const FilterArea = useAppSelector((state) => state.area.FilterArea);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteArea(ID));
      const payload = {
        ...FilterArea,
      };

      if (response.payload.Data.Status === 200) {
        dispatch(getAllAreas(payload));
        dispatch(closeDialog());
        dispatch(clearAreaID());
      } else {
        dispatch(getAllAreas(payload));
        dispatch(closeDialog());
        dispatch(clearAreaID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteArea" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Area?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Area?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(AreaID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterArea: any = useAppSelector((state) => state.area.FilterArea);
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      const payload = {
        ...updatedFilters,
      };

      dispatch(getAllAreas(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterArea,
        [inputField]: inputValue,
      };
      dispatch(setFilterArea(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterArea, [props.field]: "" };
    dispatch(setFilterArea(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllAreas(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterArea[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterArea[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Area;
