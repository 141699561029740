import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ActiveInactiveGST,
  DeleteGST,
  getAllGST,
} from "./services/gst.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import CreateGST from "./CreateGst";
import { clearGSTDetails, clearGSTID, setGSTID } from "./gstSlice";
import AlertBox from "../../components/common/AlertBox";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { Switch } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import AppDrawer from "../../components/muidrawer/Drawer";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";

const Gst: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllGST());
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
      dispatch(clearGSTDetails());
    };
  }, []);
  return (
    <>
      <DeleteCityDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            GST List
          </Typography.h4>
          {isAddAccess && (
            <ButtonCreate label="Add New" drawerName="gstform" />
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <GstGridComponents />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "gstform" && (
          <AppDrawer>
            <CreateGST />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const GstGridComponents: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const GstList = useAppSelector((state) => state.gst.GstList);
  const loading = useAppSelector((state) => state.gst.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = targetEvent.value === "All" ? GstList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteGst"));
      dispatch(setGSTID(ID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.gst_guid}
            name="gstform"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.gst_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        gst_guid: props.dataItem.gst_guid,
        isactive: !IsActive,
      };
      dispatch(ActiveInactiveGST(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllGST());
        } else {
          dispatch(getAllGST());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {GstList && GstList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(GstList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : GstList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={GstList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="gst_title"
            title="GST Title"
            cell={(props: any) => (
              <td className={isEditAccess ? "hoverableName" : "normalName"}>
                {isEditAccess ? (
                  <ButtonEdit
                    ID={props.dataItem.gst_guid}
                    name="gstform"
                    label={props.dataItem?.gst_title}
                  />
                ) : (
                  props.dataItem?.gst_title
                )}
              </td>
            )}
          />
          <Column
            field="gst"
            title="GST %"
            cell={(props: any) => (
              <td className="text-end">{props.dataItem?.gst}</td>
            )}
          />
          <Column
            field="cgst"
            title="CGST %"
            cell={(props: any) => (
              <td className="text-end">{props.dataItem?.cgst}</td>
            )}
          />
          <Column
            field="igst"
            title="IGST %"
            cell={(props: any) => (
              <td className="text-end">{props.dataItem?.igst}</td>
            )}
          />
          <Column
            field="sgst"
            title="SGST %"
            cell={(props: any) => (
              <td className="text-end">{props.dataItem?.sgst}</td>
            )}
          />
          <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteCityDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const GstID = useAppSelector((state) => state.gst.GstID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(DeleteGST(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllGST());
        dispatch(closeDialog());
        dispatch(clearGSTID());
      } else {
        dispatch(getAllGST());
        dispatch(closeDialog());
        dispatch(clearGSTID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteGst" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete GST?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this GST?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(GstID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Gst;
