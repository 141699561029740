import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import {
  deleteMagnetCleaning,
  getAllMagnetCleaning,
} from "./services/magnetcleaning.services";
import {
  clearMagnetCleaningDetails,
  clearMagnetCleaningID,
  setMagnetCleaningID,
} from "./magnetcleaningSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
} from "../../_contstants/common";

const MagnetCleaning: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(clearMagnetCleaningDetails());
    };
  }, []);

  const handleMagnetCleaningCreate = () => {
    navigate("/magnetcleaning/create");
  };

  return (
    <>
      <DeleteMagnetCleaningDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Magnet Cleaning List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleMagnetCleaningCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add New
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <MagnetCleaningGridCpmponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const MagnetCleaningGridCpmponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const magnetCleaningList = useAppSelector(
    (state) => state.magnetcleaning.magnetCleaningList
  );
  const loading = useAppSelector((state) => state.magnetcleaning.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    const payload = {
      product_name: "",
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllMagnetCleaning(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  // const [page, setPage] = React.useState<PageState>(initialDataState);
  // const [pageSizeValue, setPageSizeValue] = React.useState<
  //   number | string | undefined
  // >();
  // const pageChange = (event: GridPageChangeEvent) => {
  //   const targetEvent = event.targetEvent as PagerTargetEvent;
  //   const take =
  //     targetEvent.value === "All" ? magnetCleaningList.length : event.page.take;

  //   if (targetEvent.value) {
  //     setPageSizeValue(targetEvent.value);
  //   }
  //   setPage({
  //     ...event.page,
  //     take,
  //   });
  // };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditMagnetCleaning = (ID: number) => {
      navigate("/magnetcleaning/edit", {
        state: { magnet_cleaning_guid: ID, id: props.dataItem?.id },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteMagnetcleaning"));
      dispatch(setMagnetCleaningID(ID));
    };

    return (
      <td className="action-td">
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditMagnetCleaning(props.dataItem.magnet_cleaning_guid)
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.magnet_cleaning_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {magnetCleaningList && magnetCleaningList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter ? filterBy(magnetCleaningList, filter) : magnetCleaningList
          }
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          total={magnetCleaningList[0]?.itemcount || magnetCleaningList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="cleaning_no"
            title="Cleaning No"
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/magnetcleaning/edit", {
                      state: {
                        magnet_cleaning_guid:
                          props.dataItem?.magnet_cleaning_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.cleaning_no}
                </div>
              </td>
            )}
          />
          <Column field="magnet_cleaning_date" title="Date" />
          <Column field="machine_name" title="Machine No" />
          <Column field="product_name" title="Item Name" />
          <Column field="m_c_cleaning" title="M/C Cleaning" />
          <Column
            field="metal_qty"
            title="Metal Qty (GM)"
            cell={(props: any) => (
              <td style={{ textAlign: "end" }}>
                {formatIndianNumberForQty(+props.dataItem.metal_qty, 4)}
              </td>
            )}
          />
          <Column field="sieve_condition" title="Sieve Condition " />
          <Column field="supervisor_name" title="Supervisor By" />
          <Column field="verify_name" title="Verify By" />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="magnet_cleaning_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteMagnetCleaningDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const magnetCleaningID = useAppSelector(
    (state) => state.magnetcleaning.magnetCleaningID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteMagnetCleaning(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        product_name: "",
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllMagnetCleaning(payload));
        dispatch(closeDialog());
        dispatch(clearMagnetCleaningID());
      } else {
        dispatch(getAllMagnetCleaning(payload));
        dispatch(closeDialog());
        dispatch(clearMagnetCleaningID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteMagnetcleaning" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Magnet Cleaning ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Magnet Cleaning ?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(magnetCleaningID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default MagnetCleaning;
