import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IItem, IItemInitial } from "./itemModel";
import {
  ItemActiveInactive,
  createItem,
  createItemMarginRate,
  createItemMrp,
  deleteItem,
  deleteItemMarginRate,
  findAllActiveItem,
  getAllItem,
  getAllItemIncremental,
  getItemById,
  getPackingMaterialItemIncremental,
  updateItem,
  updateItemMarginRate,
  updateItemMrp,
} from "./services/item.services";

const initialState: IItemInitial = {
  loading: false,
  incrementalLoading: false,
  error: "",
  ItemDeleteIndex: -1,
  ItemMarginRateID: null,
  Item_tab: 0,
  ItemList: [],
  ItemID: null,
  ItemDetail: {
    id: null,
    item_type: "Raw Material",
    product_name: "",
    description: "",
    item_group_id: null,
    item_category_id: null,
    unit_id: null,
    gst_id: null,
    manage_stock: 1,
    product_code: "",
    negative_qty_allowed: false,
    low_stock_warning: true,
    show_item_in_purchase: false,
    show_item_in_sales: false,
    mrp: null,
    purchase_price: null,
    hsn_sac_code: "",
    // sales_price: null,
    // markup: null,
    // min_markup: null,
    expiry_days: null,
    weight_number: null,
    sequence_no: "",
    packing_item_id: null,
    isactive: 1,
    ItemDeatails: [],
  },
  FilterItem: {
    product_name: "",
  },
};
const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    clearItemDetail: (state) => {
      state.ItemDetail = initialState.ItemDetail;
    },
    setItemID: (state, action) => {
      state.ItemID = action.payload;
    },
    clearItemID: (state) => {
      state.ItemID = null;
    },
    setItemDeleteIndex: (state, action) => {
      state.ItemDeleteIndex = action.payload;
    },
    clearItemList: (state) => {
      state.ItemList = [];
    },
    setItemMarginRateID: (state, action) => {
      state.ItemMarginRateID = action.payload;
    },
    clearItemMarginRateID: (state) => {
      state.ItemMarginRateID = null;
    },
    setItemTab: (state, action) => {
      state.Item_tab = action.payload;
    },
    setFilterItem: (state, action) => {
      state.FilterItem = action.payload;
    },
    clearFilterItem: (state) => {
      state.FilterItem = initialState.FilterItem;
    },
  },
  extraReducers: (builder) => {
    /* For getAll getAllItemCategory */

    builder.addCase(getAllItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItem.fulfilled,
      (state, action: PayloadAction<IItem[]>) => {
        state.loading = false;
        state.ItemList = action.payload;
      }
    );
    builder.addCase(getAllItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemList = [];
    });

    /* For findAllActiveItem */

    builder.addCase(findAllActiveItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveItem.fulfilled,
      (state, action: PayloadAction<IItem[]>) => {
        state.loading = false;
        state.ItemList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemList = [];
    });

    /* For createItemCategory */

    builder.addCase(createItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createItem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateItem */

    builder.addCase(updateItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateItem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteItemMaster */

    builder.addCase(deleteItem.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteItem.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ItemActiveInactive */

    builder.addCase(ItemActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ItemActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ItemActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For getItemById */

    builder.addCase(getItemById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getItemById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ItemDetail = action.payload;
    });
    builder.addCase(getItemById.rejected, (state, action) => {
      state.loading = false;
      state.ItemDetail = initialState.ItemDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllItemIncremental.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItemIncremental.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.ItemList = action.payload || [];
      }
    );
    builder.addCase(getAllItemIncremental.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemList = [];
    });

    builder.addCase(getPackingMaterialItemIncremental.pending, (state) => {
      state.incrementalLoading = true;
      state.error = "";
    });
    builder.addCase(
      getPackingMaterialItemIncremental.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.incrementalLoading = false;
        state.ItemList = action.payload || [];
      }
    );
    builder.addCase(
      getPackingMaterialItemIncremental.rejected,
      (state, action) => {
        state.incrementalLoading = false;
        state.error = action.error.message || "Something went wrong";
        state.ItemList = [];
      }
    );

    builder.addCase(createItemMarginRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createItemMarginRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createItemMarginRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateItemMarginRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateItemMarginRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateItemMarginRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteItemMarginRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteItemMarginRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItemMarginRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(createItemMrp.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createItemMrp.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createItemMrp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateItemMrp.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateItemMrp.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateItemMrp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearItemDetail,
  clearItemID,
  setItemID,
  setItemDeleteIndex,
  clearItemList,
  setItemMarginRateID,
  clearItemMarginRateID,
  setItemTab,
  setFilterItem,
  clearFilterItem,
} = itemSlice.actions;
export default itemSlice.reducer;
