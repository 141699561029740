import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";

export const tokenCheck = createAsyncThunk("UserLogin/TokenCheck", async () => {
  try {
    const response = await axiosApiInstance.get(
      `${API_URL}/UserLogin/TokenCheck`
    );
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Token Check:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const insertSession = createAsyncThunk(
  "DeliveryDetails/InsertDeliveryDetails",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/InsertDeliveryDetails`,
        data
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Token Check:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateExpense = createAsyncThunk(
  "DeliveryDetails/UpdateExpenses",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/UpdateExpenses`,
        data
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Token Check:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSessionDetails = createAsyncThunk(
  "DeliveryDetails/ActiveSessionByUser",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/DeliveryDetails/ActiveSessionByUser`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Token Check:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const checkinCheckoutUpdate = createAsyncThunk(
  "DeliveryDetails/checkincheckoutUpdate",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/checkincheckoutUpdate`,
        data
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Token Check:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const sessionEnd = createAsyncThunk(
  "DeliveryDetails/UpdateSession",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/UpdateSession`,
        data
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Token Check:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
