import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMagnetCleaning, IMagnetCleaningInitial } from "./magnetcleaningModel";
import {
  createMagnetCleaning,
  deleteMagnetCleaning,
  findAllActiveMagnetCleaning,
  getAllMagnetCleaning,
  getMagnetCleaningById,
  updateMagnetCleaning,
} from "./services/magnetcleaning.services";
import moment from "moment";

const initialState: IMagnetCleaningInitial = {
  loading: false,
  error: "",
  magnetCleaningList: [],
  magnetCleaningID: null,
  magnetcleaningDetail: {
    machine_no: null,
    item_id: null,
    metal_qty: null,
    m_c_cleaning: "",
    cleaning_no: null,
    sieve_condition: "",
    magnet_cleaning_date: moment().toDate(),
    supervisor_by: null,
    verify_by: null,
    remarks: "",
    item_qty: null,
    lot_no: null,
    metal_qty_percentage: null,
  },
};

export const magnetcleaningSlice = createSlice({
  name: "magnetcleaning",
  initialState,
  reducers: {
    clearMagnetCleaningDetails: (state) => {
      state.magnetcleaningDetail = initialState.magnetcleaningDetail;
    },
    setMagnetCleaningID: (state, action) => {
      state.magnetCleaningID = action.payload;
    },
    clearMagnetCleaningID: (state) => {
      state.magnetCleaningID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMagnetCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMagnetCleaning.fulfilled,
      (state, action: PayloadAction<IMagnetCleaning[]>) => {
        state.loading = false;
        state.magnetCleaningList = action.payload;
      }
    );
    builder.addCase(getAllMagnetCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.magnetCleaningList = [];
    });

    builder.addCase(findAllActiveMagnetCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveMagnetCleaning.fulfilled,
      (state, action: PayloadAction<IMagnetCleaning[]>) => {
        state.loading = false;
        state.magnetCleaningList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveMagnetCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.magnetCleaningList = [];
    });

    builder.addCase(createMagnetCleaning.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createMagnetCleaning.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createMagnetCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateMagnetCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateMagnetCleaning.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMagnetCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteMagnetCleaning.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMagnetCleaning.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMagnetCleaning.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getMagnetCleaningById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getMagnetCleaningById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.magnetcleaningDetail = action.payload;
    });
    builder.addCase(getMagnetCleaningById.rejected, (state, action) => {
      state.loading = false;
      state.magnetcleaningDetail = initialState.magnetcleaningDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearMagnetCleaningDetails,
  setMagnetCleaningID,
  clearMagnetCleaningID,
} = magnetcleaningSlice.actions;
export default magnetcleaningSlice.reducer;
