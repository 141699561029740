import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDriverInitialState } from "./driverVisitModel";
import {
  deleteDriverVisit,
  getAllDriverVisits,
  getDeliveryDetailsByID,
  updateDriverVisit,
} from "./services/driverVisit.services";

const initialState: IDriverInitialState = {
  formLoading: false,
  loading: false,
  error: "",
  DriverVisitList: [],
  DriverVisitID: null,
  DriverVisitDetails: {},
};

const driverVisitSlice = createSlice({
  name: "drivervisit",
  initialState,
  reducers: {
    clearDriverVisitDetails: (state) => {
      state.DriverVisitDetails = initialState.DriverVisitDetails;
    },
    setDriverVisitID: (state, action) => {
      state.DriverVisitID = action.payload;
    },
    clearDriverVisitID: (state) => {
      state.DriverVisitID = initialState.DriverVisitID;
    },
    clearDriverList: (state) => {
      state.DriverVisitList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDriverVisits.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDriverVisits.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.DriverVisitList = action.payload || [];
      }
    );
    builder.addCase(getAllDriverVisits.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DriverVisitList = [];
    });

    // builder.addCase(createDriverVisit.pending, (state) => {
    //   state.loading = false;
    //   state.error = "";
    // });
    // builder.addCase(createDriverVisit.fulfilled, (state) => {
    //   state.loading = false;
    // });
    // builder.addCase(createDriverVisit.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message || "Something went wrong";
    // });

    builder.addCase(updateDriverVisit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateDriverVisit.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateDriverVisit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteDriverVisit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDriverVisit.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDriverVisit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getDeliveryDetailsByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getDeliveryDetailsByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.DriverVisitDetails = action.payload;
    });
    builder.addCase(getDeliveryDetailsByID.rejected, (state, action) => {
      state.loading = false;
      state.DriverVisitDetails = initialState.DriverVisitDetails;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearDriverVisitDetails,
  setDriverVisitID,
  clearDriverVisitID,
  clearDriverList,
} = driverVisitSlice.actions;
export default driverVisitSlice.reducer;
