import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllDriverVisits = createAsyncThunk(
  "DeliveryDetails/RouteVisitList",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/DeliveryDetails/RouteVisitList`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Driver Routes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// export const createDriverVisit = createAsyncThunk(
//   "Driver/InsertDriver",
//   async (DriverData: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/Driver/InsertDriver`,
//         DriverData
//       );
//       SuccessToast(response.data?.Details || "Success");
//       return response.data;
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error creating driver visit:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

export const updateDriverVisit = createAsyncThunk(
  "DeliveryDetails/AdminStatusUpdate",
  async (DriverData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/AdminStatusUpdate`,
        DriverData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating driver visit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDriverVisit = createAsyncThunk(
  "DeliveryDetails/DeleteSession",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/DeleteSession`,
        { id: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting driver visit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDeliveryDetailsByID = createAsyncThunk(
  "DeliveryDetails/DeliveryDetailsFindByID",
  async (id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/DeliveryDetailsFindByID`,
        { id }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        voucher_no: result?.voucher_no,
        voucher_no_string: result?.voucher_no_string,
        route_id: result?.route_id,
        route_name: result?.route_name,
        session_start: result?.session_start,
        session_end: result?.session_end,
        helper_name: result?.helper_name,
        vehicle_no: result?.vehicle_no,
        diesel: result?.diesel,
        tea_coffee: result?.tea_coffee,
        food_snacks: result?.food_snacks,
        toll_tax_fasttag: result?.toll_tax_fasttag,
        transport_receipt: result?.transport_receipt,
        misc_exp: result?.misc_exp,
        other_exp: result?.other_exp,
        total_amount: result?.total_amount,
        approved_amount: result?.approved_amount,
        start_km: result?.start_km,
        end_km: result?.end_km,
        total_km: result?.total_km,
        claim_status: result?.claim_status,
        remarks: result?.remarks,
        driver_remarks: result?.driver_remarks,
        distributerlists: result?.distributerlists
          ? result?.distributerlists
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching driver visit:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
