import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const createRetailerSalesOrder = createAsyncThunk(
  "SaleOrder/InsertRetailerSalesOrder",
  async (RetailerSalesOrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/InsertRetailerSalesOrder`,
        RetailerSalesOrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating retailer sales order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRetailerSalesOrder = createAsyncThunk(
  "SaleOrder/UpdateRetailerSalesOrder",
  async (RetailerSalesOrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/UpdateRetailerSalesOrder`,
        RetailerSalesOrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating retailer sales order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRetailerSalesOrder = createAsyncThunk(
  "SaleOrder/DeleteRetailerSalesOrder",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DeleteRetailerSalesOrder`,
        { id: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting retailer sales order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRetailerSalesOrderByID = createAsyncThunk(
  "SaleOrder/FindByIDRetailerSalesOrder",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/FindByIDRetailerSalesOrder`,
        { id: ID }
      );
      // return response.data?.Data as any;
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        retailerid: result?.retailerid,
        retailer_name: result?.retailer_name,
        date: result?.date ? moment(result?.date, "YYYY-MM-DD").toDate() : "",
        retailer_sales: result?.retailer_sales
          ? result?.retailer_sales?.map((retailersales: any) => {
              return {
                item_group_id: retailersales?.item_group_id
                  ? retailersales?.item_group_id
                  : null,
                item_group: retailersales?.item_group
                  ? retailersales?.item_group
                  : "",
                retailer_sales_order_list:
                  retailersales?.retailer_sales_order_list
                    ? retailersales?.retailer_sales_order_list?.map(
                        (soitem: any) => {
                          return {
                            id: soitem?.id ? +soitem?.id : null,
                            item_group_id: soitem?.item_group_id
                              ? +soitem?.item_group_id
                              : null,
                            sale_order_id: soitem?.sale_order_id
                              ? +soitem?.sale_order_id
                              : null,
                            item_id: soitem?.item_id ? +soitem?.item_id : null,
                            product_name: soitem?.product_name
                              ? soitem?.product_name
                              : "",
                            mrp: soitem?.mrp ? +soitem?.mrp : null,
                            quantity: soitem?.quantity
                              ? +soitem?.quantity
                              : null,
                            // [`qty_${mainIndex}_${subIndex}`]: soitem?.quantity
                            //   ? +soitem?.quantity
                            //   : null,
                            unit_id: soitem?.unit_id ? +soitem?.unit_id : null,
                            rate: soitem?.rate ? +soitem?.rate : null,
                            gst_id: soitem?.gst_id ? +soitem?.gst_id : null,
                            amount: soitem?.total_amount ? +soitem?.total_amount : null,
                            unit_name: soitem?.unit_name
                              ? soitem?.unit_name
                              : "",
                            gst: soitem?.gst ? +soitem?.gst : null,
                          };
                        }
                      )
                    : [],
              };
            })
          : [],
        scheme: result?.free_scheme_offers
          ? result?.free_scheme_offers?.map((item: any) => {
              return {
                free_good: item?.free_good ? item?.free_good : "",
                article: item?.article ? item?.article : "",
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching retailer order details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllRetailerSalesOrders = createAsyncThunk(
  "SaleOrder/FindAllRetailerSalesOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/FindAllRetailerSalesOrder`,
        formData
      );
      const result = response.data?.Data;
      return result
        ? result?.map((retailerso: any, index: number) => {
            return {
              uniqueKey: index + 1,
              id: retailerso?.id ? +retailerso?.id : null,
              retailerid: retailerso?.retailerid
                ? +retailerso?.retailerid
                : null,
              date: retailerso?.date ? retailerso?.date : "",
              retailer_name: retailerso?.retailer_name
                ? retailerso?.retailer_name
                : "",
            };
          })
        : [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Retailer Sales Orders:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
