import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../components/common/AlertBox";
import {
  deleteOpeningStock,
  getAllOpeningStocks,
} from "./services/distributorOpeningStock.services";
import {
  clearOpeningStockID,
  setOpeningStockID,
} from "./distributorOpeningStockSlice";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights } from "../../_helper/helper";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";

const DistributorOpeningStock: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector(
    (state) => state.distributorOpeningStock.loading
  );

  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllOpeningStocks());
    dispatch(closeDialog());
  }, []);

  const handleOpeningStockCreate = () => {
    navigate("/distributoropeningstock/create");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <DeleteOpeningStockDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Opening Stock List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleOpeningStockCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add New
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <OpeningStockGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 50 };

const OpeningStockGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const OpeningStockList = useAppSelector(
    (state) => state.distributorOpeningStock.OpeningStockList
  );
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? OpeningStockList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditOpeningStock = (id: any) => {
      navigate("/distributoropeningstock/edit", { state: { id: id } });
    };
    const handleOpenDeleteDialog = (id: any) => {
      dispatch(openDialog("deleteOpeningStock"));
      dispatch(setOpeningStockID(id));
    };
    return (
      <td className="action-td">
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditOpeningStock(props.dataItem.id)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.id)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {OpeningStockList && OpeningStockList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(OpeningStockList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : OpeningStockList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={OpeningStockList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="uniqueKey"
            title="Sr. No."
            width={80}
            filterable={false}
          />
          <Column
            field="account_name"
            title="Name"
            cell={(props: any) => (
              <td>
                {isEditAccess ? (
                  <div
                    className="hoverableName"
                    onClick={() =>
                      navigate("/distributoropeningstock/edit", {
                        state: { id: props.dataItem?.id },
                      })
                    }
                  >
                    {props.dataItem.account_name}
                  </div>
                ) : (
                  <div className="normalName">
                    {props.dataItem.account_name}
                  </div>
                )}
              </td>
            )}
          />
          <Column field="user_type" title="User Type" />
          <Column field="date" title="Date" />
          {(isDeleteAccess || isEditAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteOpeningStockDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const OpeningStockID = useAppSelector(
    (state) => state.distributorOpeningStock.OpeningStockID
  );
  const handleDeleteAction = async (id: any) => {
    if (id) {
      const response = await dispatch(deleteOpeningStock(id));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllOpeningStocks());
        dispatch(closeDialog());
        dispatch(clearOpeningStockID());
      } else {
        dispatch(getAllOpeningStocks());
        dispatch(closeDialog());
        dispatch(clearOpeningStockID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteOpeningStock" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Opening Stock?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this opening stock?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(OpeningStockID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default DistributorOpeningStock;
