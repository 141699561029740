import { configureStore } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import drawerSlice from "../components/drawer/drawerSlice";
import muiDrawerSlice from "../components/muidrawer/muiDrawerSlice";
import userSlice from "../features/user/userSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import citySlice from "../features/city/citySlice";
import stateSlice from "../features/state/stateSlice";
import purchaseOrderSlice from "../features/purchaseorder/purchaseOrderSlice";
import accountSlice from "../features/account/accountSlice";
import itemCategorySlice from "../features/Item Category/itemCategorySlice";
import gstSlice from "../features/gst/gstSlice";
import itemgroupSlice from "../features/ItemGroup/itemgroupSlice";
import unitSlice from "../features/unit/unitSlice";
import machineSlice from "../features/machine/machineSlice";
import itemSlice from "../features/Item/itemSlice";
import warehouseSlice from "../features/warehouse/warehouseSlice";
import inwardSlice from "../features/inward/inwardSlice";
import paginationSlice from "../features/pagination/paginationSlice";
import inwardReturnSlice from "../features/inwardreturn/inwardReturnSlice";
import magnetcleaningSlice from "../features/magnetcleaning/magnetcleaningSlice";
import machinecleaningSlice from "../features/machinecleaning/machinecleaningSlice";
import processSlice from "../features/process/processSlice";
import productCleaningSlice from "../features/productcleaning/productCleaningSlice";
import departmentSlice from "../features/department/departmentSlice";
import bomSlice from "../features/bom/bomSlice";
import productionSlice from "../features/production/productionSlice";
import productmillingSlice from "../features/productmilling/productmillingSlice";
import roastingSlice from "../features/roasting/roastingSlice";
import reportsSlice from "../features/reports/reportsSlice";
import inwardPackingMaterialSlice from "../features/inwardpacking/inwardPackingMaterialSlice";
import outwardPackingMaterialSlice from "../features/outwardpacking/outwardPackingMaterialSlice";
import stockTransferSlice from "../features/stocktransfer/stockTransferSlice";
import packingSlice from "../features/packing/packingSlice";
import layoutSlice from "../components/layout/layoutSlice";
import salesOrderSlice from "../features/salesorder/salesOrderSlice";
import visitorSlice from "../features/visitor/visitorSlice";
import officeStaffOutSlice from "../features/officestaffout/officeStaffOutSlice";
import orderSlice from "../features/placeorder/orderSlice";
import salesSlice from "../features/sales/salesSlice";
import salesChallanSlice from "../features/saleschallan/salesChallanSlice";
import dashboardSlice from "../features/dashboard/dashboardSlice";
import employeeSlice from "../features/employee/employeeSlice";
import designationSlice from "../features/designation/designationSlice";
import documentSlice from "../features/document/documentSlice";
import relationSlice from "../features/relation/relationSlice";
import holidaySlice from "../features/holiday/holidaySlice";
import leaveSlice from "../features/leave/leaveSlice";
import professionTaxSlabSlice from "../features/ProfessionTaxSlab/professionTaxSlabSlice";
import distributorOpeningStockSlice from "../features/distributoropeningstock/distributorOpeningStockSlice";
import retailerSlice from "../features/retailer/retailerSlice";
import retailerSalesOrderSlice from "../features/retailersalesorder/retailerSalesOrderSlice";
import employeeLeaveSlice from "../features/employeeLeave/employeeLeaveSlice";
import purchaseSlice from "../features/purchase/purchaseSlice";
import processPayrollSlice from "../features/processpayroll/processpayrollSlice";
import salaryGenerationSlice from "../features/salarygeneration/salryGenerationSlice";
import districtSlice from "../features/district/districtSlice";
import termsAndConditionSlice from "../features/termsandcondition/termsAndConditionSlice";
import routeSlice from "../features/route/routeSlice";
import driverVisitSlice from "../features/drivervisit/driverVisitSlice";
import oldDistributorDataSlice from "../features/olddistributordata/oldDistributorDataSlice";
import accountgroupSlice from "../features/accountgroup/accountgroupSlice";
import schemeSlice from "../features/scheme/schemeSlice";
import areaSlice from "../features/area/areaSlice";
import settingSlice from "../features/setting/settingSlice";

// import bomSlice from "../features/bom/bomSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    login: loginReducer,
    dialog: dialogSlice,
    drawer: drawerSlice,
    muidrawer: muiDrawerSlice,
    menu: menuSlice,
    rights: rightsSlice,
    user: userSlice,
    employee: employeeSlice,
    userType: userTypeSlice,
    country: countrySlice,
    city: citySlice,
    district: districtSlice,
    state: stateSlice,
    route: routeSlice,
    purchaseOrder: purchaseOrderSlice,
    inward: inwardSlice,
    inwardReturn: inwardReturnSlice,
    productCleaning: productCleaningSlice,
    pagination: paginationSlice,
    account: accountSlice,
    itemCategory: itemCategorySlice,
    gst: gstSlice,
    itemgroup: itemgroupSlice,
    unit: unitSlice,
    machine: machineSlice,
    item: itemSlice,
    warehouse: warehouseSlice,
    magnetcleaning: magnetcleaningSlice,
    machinecleaning: machinecleaningSlice,
    process: processSlice,
    department: departmentSlice,
    bom: bomSlice,
    productmilling: productmillingSlice,
    roasting: roastingSlice,
    production: productionSlice,
    reports: reportsSlice,
    inwardPackingMaterial: inwardPackingMaterialSlice,
    outwardPackingMaterial: outwardPackingMaterialSlice,
    stockTransfer: stockTransferSlice,
    packing: packingSlice,
    layout: layoutSlice,
    salesOrder: salesOrderSlice,
    visitor: visitorSlice,
    officeStaffOut: officeStaffOutSlice,
    order: orderSlice,
    sales: salesSlice,
    salesChallan: salesChallanSlice,
    designation: designationSlice,
    document: documentSlice,
    relation: relationSlice,
    holiday: holidaySlice,
    leave: leaveSlice,
    professionTaxSlab: professionTaxSlabSlice,
    distributorOpeningStock: distributorOpeningStockSlice,
    retailer: retailerSlice,
    retailerSalesOrder: retailerSalesOrderSlice,
    employeeLeave: employeeLeaveSlice,
    purchase: purchaseSlice,
    processpayroll: processPayrollSlice,
    salarygeneration: salaryGenerationSlice,
    termsandcondition: termsAndConditionSlice,
    drivervisit: driverVisitSlice,
    oldDistributorData: oldDistributorDataSlice,
    accountGroup: accountgroupSlice,
    scheme: schemeSlice,
    area: areaSlice,
    setting: settingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
