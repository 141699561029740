import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllAccountGroup = createAsyncThunk(
  "AccountGroup/FindAllAccountGroup",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AccountGroup/FindAllAccountGroup`,
        {
          group_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Account Group:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAccountGroup = createAsyncThunk(
  "AccountGroup/FindAllActiveAccountGroup",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/AccountGroup/FindAllActiveAccountGroup`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching AccountGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAccountGroup = createAsyncThunk(
  "AccountGroup/InsertAccountGroup",
  async (AccountGroupData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AccountGroup/InsertAccountGroup`,
        AccountGroupData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Account Group:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAccountGroup = createAsyncThunk(
  "AccountGroup/UpdateAccountGroup",
  async (AccountGroupData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AccountGroup/UpdateAccountGroup`,
        AccountGroupData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Account Group Data:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAccountGroupByID = createAsyncThunk(
  "AccountGroup/FindByIDAccountGroup",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AccountGroup/FindByIDAccountGroup`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        account_group_guid: result.account_group_guid,
        group_name: result.group_name,
        under_group_id: `${result.under_group_id}`,
        nature_of_group_id: result.nature_of_group_id,
        isdefault: result?.isdefault,
        isactive: result.isactive === true ? 1 : 2,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching account:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const accountGroupActiveInactive = createAsyncThunk(
  "AccountGroup/ActiveInActiveAccountGroup",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AccountGroup/ActiveInActiveAccountGroup`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive AccountGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAccountGroup = createAsyncThunk(
  "AccountGroup/DeleteAccountGroup",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AccountGroup/DeleteAccountGroup`,
        { account_group_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
