import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRelation, IRelationState } from "./relationModel";
import {
  relationActiveInactive,
  createRelation,
  deleteRelation,
  getAllActiveRelations,
  getAllRelations,
  getRelationByID,
  updateRelation,
} from "./services/relation.services";

const initialState: IRelationState = {
  loading: false,
  formLoading: false,
  error: "",
  RelationList: [],
  RelationID: null,
  RelationGUID: "",
  RelationDetail: {
    relation: "",
    isactive: 1,
  },
};

const relationSlice = createSlice({
  name: "relation",
  initialState,
  reducers: {
    clearRelationDetails: (state) => {
      state.RelationDetail = initialState.RelationDetail;
    },
    setRelationID: (state, action) => {
      state.RelationID = action.payload;
    },
    clearRelationID: (state) => {
      state.RelationID = initialState.RelationID;
    },
    setRelationGUID: (state, action) => {
      state.RelationGUID = action.payload;
    },
    clearRelationGUID: (state) => {
      state.RelationGUID = initialState.RelationGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRelations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRelations.fulfilled,
      (state, action: PayloadAction<IRelation[]>) => {
        state.loading = false;
        state.RelationList = action.payload || [];
      }
    );
    builder.addCase(getAllRelations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RelationList = [];
    });
    builder.addCase(getAllActiveRelations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRelations.fulfilled,
      (state, action: PayloadAction<IRelation[]>) => {
        state.loading = false;
        state.RelationList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRelations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RelationList = [];
    });
    builder.addCase(createRelation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRelation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRelation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRelation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRelation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRelation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRelation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRelation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRelation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(relationActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(relationActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(relationActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRelationByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRelationByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RelationDetail = action.payload;
    });
    builder.addCase(getRelationByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RelationDetail = initialState.RelationDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearRelationDetails,
  setRelationID,
  clearRelationID,
  setRelationGUID,
  clearRelationGUID,
} = relationSlice.actions;
export default relationSlice.reducer;
