import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllOfficeStaffOut = createAsyncThunk(
  "StaffOut/FindAllStaffOut",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StaffOut/FindAllStaffOut`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Office Staff Out:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createOfficeStaffOut = createAsyncThunk(
  "StaffOut/InsertStaffOut",
  async (OfficeStaffOutData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StaffOut/InsertStaffOut`,
        OfficeStaffOutData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating office staff out:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOfficeStaffOut = createAsyncThunk(
  "StaffOut/UpdateStaffOut",
  async (OfficeStaffOutData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StaffOut/UpdateStaffOut`,
        OfficeStaffOutData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating office staff out:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteOfficeStaffOut = createAsyncThunk(
  "StaffOut/DeleteStaffOut",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StaffOut/DeleteStaffOut`,
        { staff_out_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting office staff out:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOfficeStaffOutByID = createAsyncThunk(
  "StaffOut/FindByIDStaffOut",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StaffOut/FindByIDStaffOut`,
        { staff_out_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        staff_out_guid: result?.staff_out_guid,
        staff_out_date: result?.staff_out_date
          ? moment(result?.staff_out_date, "YYYY-MM-DD").toDate()
          : "",
        user_id: result?.user_id,
        user_name: result?.user_name,
        staff_out_no: result?.staff_out_no,
        reason_for_out: result?.reason_for_out,
        approved_by: result?.approved_by,
        out_time: result?.out_time
          ? moment(result?.out_time, "HH:mm:ss").toDate()
          : "",
        in_time: result?.in_time
          ? moment(result?.in_time, "HH:mm:ss").toDate()
          : "",
        financial_year: result?.financial_year,
        createdby: result?.createdby,
        createdby_name: result?.createdby_name,
        isactive: result?.isactive,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching office staff out details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateStaffInTime = createAsyncThunk(
  "StaffOut/UpdateStaffIn",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StaffOut/UpdateStaffIn`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating office staff in time:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);