import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IMachine } from "../machineModel";

export const getAllMachine = createAsyncThunk(
  "Machine/FindAllMachine",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Machine/FindAllMachine`,
        {
          Machine: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMachine = createAsyncThunk(
  "Machine/InsertMachine",
  async (Machine: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Machine/InsertMachine`,
        Machine
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMachine = createAsyncThunk(
  "Machine/UpdateMachine",
  async (Machine: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Machine/UpdateMachine`,
        Machine
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemMachineById = createAsyncThunk(
  "Machine/FindByIDMachine",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Machine/FindByIDMachine`,
        {
          machine_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        machine_guid: result.machine_guid,
        machine_name: result.machine_name,
        description: result.description,
        isactive: result.isactive === true ? 1 : 2,
      } as IMachine;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMachine = createAsyncThunk(
  "Machine/DeleteMachine",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Machine/DeleteMachine`,
        {
          machine_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveMachine = createAsyncThunk(
  "Machine/FindAllActiveMachine",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Machine/FindAllActiveMachine`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const MachineActiveInactive = createAsyncThunk(
  "Machine/ActiveInActiveMachine",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Machine/ActiveInActiveMachine`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Machine:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
