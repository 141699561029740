import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  convertToWords,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import { getPurchaseOrderPrint } from "./services/purchaseOrder.services";

const PurchaseOrderPrint: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const purchase_order_guid = params?.purchase_order_guid;
  const PurchaseOrderPrintDetail = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderPrintDetail
  );

  useEffect(() => {
    if (purchase_order_guid) {
      const handleOrderPrint = async () => {
        const res = await dispatch(getPurchaseOrderPrint(purchase_order_guid));
        if (res.meta.requestStatus === "fulfilled") {
          window.print();
        }
      };
      handleOrderPrint();
    }
  }, [purchase_order_guid]);

  let totalAmount = 0;
  let totalTaxableAmount = 0;

  PurchaseOrderPrintDetail?.purchase_order_item &&
    PurchaseOrderPrintDetail?.purchase_order_item.length > 0 &&
    PurchaseOrderPrintDetail?.purchase_order_item?.map(
      (item: any) => (totalAmount += item?.amount)
    );

  PurchaseOrderPrintDetail?.gst_details &&
    PurchaseOrderPrintDetail?.gst_details?.length > 0 &&
    PurchaseOrderPrintDetail?.gst_details?.map((gstamount: any) => {
      const totaltax =
        gstamount?.total_taxable_amount &&
        gstamount?.gst &&
        (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
      totalTaxableAmount += totaltax;
    });

  return (
    <div className="p-4 po-print-page">
      <h6 className="text-center">Purchase Order</h6>
      <table className="table table-bordered w-100 po-print-table m-0">
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td colSpan={2} className="w-50 p-0">
            <p
              className="m-0 fw-bold"
              style={{ padding: "2px 8px", fontSize: 13 }}
            >
              Harihar Foods Pvt. Ltd.
            </p>
            <div className="pt-0">
              <b>Office : </b>A/16, Mohan Estate, Opp. Anupan Theatre, Khokhra,
              Ahmedabad -380 008 <br />
              <b>Factory : </b>519/1, Phase-IV, G.I.D.C. Vatva, Nr. Ramol Char
              Rasta, Vatva, Ahmedabad-382 445
              <br />
              Ph.: (0)22934293, (F)25840855 Fax: 079-22177022 <br />
              Email: exports@shreeganeshmasala.com <br />
              accounts@shreeganeshmasala.com
              <br />
            </div>
          </td>
          <td className="w-50" style={{ height: 40 }} colSpan={2}>
            <b className="p-0 mb-3">P.O. No.:</b>{" "}
            {PurchaseOrderPrintDetail?.po_no}
            <br />
            <b className="p-0">Date:</b> {PurchaseOrderPrintDetail?.po_date}{" "}
            <br />
          </td>
        </tr>
        {/* <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td
            className="w-50"
            style={{
              height: 40,
              textAlign: "center",
            }}
            colSpan={2}
          >
            TIN GST No.: 24072000165 <br />
            TIN GST No.: 24572000165
          </td>
        </tr> */}
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td colSpan={2} rowSpan={3} className="w-50 p-0">
            <p
              className="m-0 fw-bold"
              style={{ padding: "2px 8px", fontSize: 13 }}
            >
              To, <br />
              M/s. {PurchaseOrderPrintDetail?.account_name}
            </p>
            <div className="pt-0">
              {PurchaseOrderPrintDetail?.address1}
              {PurchaseOrderPrintDetail?.address1 && <br />}
              {(PurchaseOrderPrintDetail?.mobile_no ||
                PurchaseOrderPrintDetail?.alternet_mobile_no) &&
                "Ph.: "}{" "}
              {PurchaseOrderPrintDetail?.mobile_no}
              {PurchaseOrderPrintDetail?.mobile_no &&
                PurchaseOrderPrintDetail?.alternet_mobile_no &&
                ", "}
              {PurchaseOrderPrintDetail?.alternet_mobile_no}{" "}
              {(PurchaseOrderPrintDetail?.mobile_no ||
                PurchaseOrderPrintDetail?.alternet_mobile_no) && <br />}
              {PurchaseOrderPrintDetail?.email_id && "Email : "}{" "}
              {PurchaseOrderPrintDetail?.email_id} <br />
              <br />
            </div>
          </td>
          <td style={{ height: 40, width: "15%" }}>
            <b className="p-0">Delivery Schedule</b>
          </td>
          <td style={{ height: 40, width: "35%" }}>
            {PurchaseOrderPrintDetail?.delivery_date}
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ height: 40, width: "15%" }}>
            <b className="p-0">Delivery Place</b>
          </td>
          <td style={{ height: 40, width: "35%" }}>
            {PurchaseOrderPrintDetail?.city_name}
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ height: 40, width: "15%" }}>
            <b className="p-0">Mode Of Transport</b>
          </td>
          <td style={{ height: 40, width: "35%" }}>
            {PurchaseOrderPrintDetail?.mode_of_transport}
          </td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table w-100  po-print-table mb-0 b-none"
      >
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th rowSpan={2} style={{ width: "5%" }}>
            Sr No.
          </th>
          <th rowSpan={2} style={{ width: "45%" }}>
            Description Of Goods
          </th>
          <th rowSpan={2} style={{ width: "8%" }}>
            HSN/SAC
          </th>
          <th rowSpan={2} style={{ width: "7%" }}>
            GST (%)
          </th>
          {/* <th rowSpan={2} style={{ width: "10%" }}>
            MRP
          </th> */}
          <th colSpan={2} style={{ width: "10%" }}>
            Quantity
          </th>
          <th rowSpan={2} style={{ width: "7%" }}>
            Per
          </th>
          <th rowSpan={2} style={{ width: "10%" }}>
            Rate
          </th>
          <th rowSpan={2} style={{ width: "15%" }}>
            Amount
          </th>
        </tr>
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th>Shipped</th>
          <th>Billed</th>
        </tr>
        {PurchaseOrderPrintDetail?.purchase_order_item &&
          PurchaseOrderPrintDetail?.purchase_order_item.length > 0 &&
          PurchaseOrderPrintDetail?.purchase_order_item?.map(
            (item: any, index: number) => (
              <>
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td>{item?.product_name}</td>
                  <td>{item?.hsn_sac_code}</td>
                  <td className="text-end">
                    {item?.gst ? `${item?.gst} %` : ""}
                  </td>
                  {/* <td className="text-end">{item?.mrp || 0}</td> */}
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.qty || 0)}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.qty || 0)}
                  </td>
                  <td>{item?.unit_name}</td>
                  <td className=" text-end">
                    {formatIndianNumber(item?.rate)}
                  </td>
                  <td className=" text-end">
                    {formatIndianNumber(item?.amount)}
                  </td>
                </tr>
              </>
            )
          )}
        <tr>
          <td colSpan={8}></td>
          <td className="text-end fw-600" style={{ fontSize: 12 }}>
            {formatIndianNumber(totalAmount)}
          </td>
        </tr>
        {PurchaseOrderPrintDetail?.gst_details &&
          PurchaseOrderPrintDetail?.gst_details?.length > 0 &&
          PurchaseOrderPrintDetail?.gst_details?.map((gstdetails: any) =>
            PurchaseOrderPrintDetail?.state_name === "GUJARAT" ? (
              <>
                <tr>
                  <td className=" text-end" colSpan={6}>
                    CGST {gstdetails?.gst ? `${gstdetails?.gst / 2} %` : ""}
                  </td>
                  <td className=" text-end">
                    {gstdetails?.gst ? (gstdetails?.gst / 2)?.toFixed(2) : ""}
                  </td>
                  <td>{gstdetails?.gst ? "%" : ""}</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                </tr>
                <tr>
                  <td className=" text-end" colSpan={6}>
                    SGST {gstdetails?.gst ? `${gstdetails?.gst / 2} %` : ""}
                  </td>
                  <td className=" text-end">
                    {gstdetails?.gst ? (gstdetails?.gst / 2)?.toFixed(2) : ""}
                  </td>
                  <td>{gstdetails?.gst ? "%" : ""}</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td className=" text-end" colSpan={6}>
                  IGST {gstdetails?.gst ? `${gstdetails?.gst} %` : ""}
                </td>
                <td className=" text-end">
                  {gstdetails?.gst ? gstdetails?.gst?.toFixed(2) : ""}
                </td>
                <td>{gstdetails?.gst ? "%" : ""}</td>
                <td className=" text-end">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        <tr style={{ fontSize: 12 }}>
          <td colSpan={8} className=" text-end fw-600">
            {" "}
            Total Invoice Amount:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(Math.round(+totalAmount + +totalTaxableAmount))}
          </td>
        </tr>
        <tr className="b-none">
          <td colSpan={8} className="b-none">
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Amount Chargeable (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(Math.round(+totalAmount + +totalTaxableAmount))}
            </span>
          </td>
          <td className="text-end b-none">E & O.E</td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table table-bordered w-100  po-print-table mb-0 b-none"
        style={{ pageBreakInside: "avoid" }}
      >
        {PurchaseOrderPrintDetail?.state_name === "GUJARAT" ? (
          <>
            <tr className="text-center fw-600" style={{ fontSize: 13 }}>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={2}>Central Tax</th>
              <th colSpan={2}>State Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th>Amount</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </>
        ) : (
          <>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={4}>Integrated Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th colSpan={3}>Amount</th>
            </tr>
          </>
        )}
        {PurchaseOrderPrintDetail?.gst_details &&
          PurchaseOrderPrintDetail?.gst_details?.length > 0 &&
          PurchaseOrderPrintDetail?.gst_details?.map((gstdetails: any) =>
            PurchaseOrderPrintDetail?.state_name === "GUJARAT" ? (
              <tr className="text-end">
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>
                  {gstdetails?.gst
                    ? `${formatIndianNumber(gstdetails?.gst / 2)} %`
                    : ""}
                </td>
                <td className=" ">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td>
                  {gstdetails?.gst
                    ? `${formatIndianNumber(gstdetails?.gst / 2)} %`
                    : ""}
                </td>
                <td>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            ) : (
              <tr className="text-end">
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>
                  {" "}
                  {gstdetails?.gst
                    ? `${formatIndianNumber(gstdetails?.gst)} %`
                    : ""}
                </td>
                <td colSpan={3}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        {PurchaseOrderPrintDetail?.state_name === "GUJARAT" ? (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        ) : (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td colSpan={3}>{formatIndianNumber(totalTaxableAmount)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        )}
        <tr style={{ borderBottom: "none" }}>
          <td colSpan={7} style={{ textAlign: "end", border: "none" }}>
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Tax Amount (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {totalTaxableAmount
                ? convertToWords(+totalTaxableAmount?.toFixed(2))
                : "ZERO RUPEES ONLY"}
            </span>
          </td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}
        className="table table-bordered w-100 mb-0"
      >
        <tr>
          <td className="w-50 p-1" rowSpan={2}>
            Company's PAN : <span className="fw-600 p-0">AAACH5100R</span>{" "}
            <br />
            GSTIN : <span className="fw-600 p-0">24AAACH5100R1ZN</span> <br />
            CIN NO : <span className="fw-600 p-0">
              U15499GJ1994PTC021520
            </span>{" "}
            <br />
            <br />
            <br />
            <br />
            <u>Declaration:</u>
            <ol className="ms-1 mb-0">
              <li>Please Quote this P.O. on all correspondence.</li>
              <li>The order is confirmed on above rates only.</li>
              <li>
                If you fail to supply the material within the delivery period,
                the order shall be treated as cancelled.
              </li>
            </ol>
          </td>
          <td className="w-50 p-0">
            {/* <div>
              <b>Company's Bank Details</b> <br />
              <table className="table bank-details-table">
                <tr>
                  <td>Bank Name</td>
                  <td className="fw-600">: Kotak Mahindra Bank</td>
                </tr>
                <tr>
                  <td>A/c No.</td>
                  <td className="fw-600">: 2411684374</td>
                </tr>
                <tr>
                  <td>Branch & IFS Code</td>
                  <td className="fw-600">: SATELITE BRANCH & KKBK0000810</td>
                </tr>
              </table>
            </div> */}
          </td>
        </tr>
        <tr>
          <td
            className="p-1"
            style={{
              height: 100,
              borderTop: "1px double rgba(0, 0, 0, 0.5) ",
              borderLeft: "1px solid rgba(0, 0, 0, 0.5) ",
              textAlign: "end",
            }}
          >
            <p className="p-0 mb-5 fw-600">for Harihar Foods Pvt. Ltd.</p>
            <p className="m-0 p-0">Authorised Signatory</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PurchaseOrderPrint;
