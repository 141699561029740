import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveRoute } from "../route/services/route.services";
import { IRoute } from "../route/routeModel";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import AlertBox from "../../components/common/AlertBox";
import IconButton from "../../components/common/IconButton";
import { getAllCountries } from "../country/services/country.services";
import moment from "moment";
import {
  checkinCheckoutUpdate,
  getSessionDetails,
  insertSession,
  sessionEnd,
} from "./services/dashboard.services";
import { BiLogIn } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import useWindowSize from "../../_hooks/useWindowSize";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import { getTimeDuration } from "../../_helper/helper";
import { ErrorToast } from "../../components/toast/Toasts";

const MAX_WIDTH = 768;

interface ITotalCahngeWatcher {
  formRenderProps: FormRenderProps;
  start_km: number;
}

const TotalChangeWatcher: React.FC<ITotalCahngeWatcher> = ({
  formRenderProps,
  start_km,
}) => {
  const end_km = formRenderProps.valueGetter("end_km");
  const total_km = +end_km - +start_km;

  useEffect(() => {
    formRenderProps.onChange("total_km", {
      value: total_km,
    });
  }, [end_km]);
  return null;
};
const DriverDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const { width } = useWindowSize();

  const loading = useAppSelector((state) => state.dashboard.loading);

  const RouteList = useAppSelector((state) => state.route.RouteList);
  const sessionDetails: any = useAppSelector(
    (state) => state.dashboard.sessionDetails
  );

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getSessionDetails());
    dispatch(getAllActiveRoute());
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        ...values,
        session_start: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      await dispatch(insertSession(payload));
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleSessionEnd = async (values: any) => {
    const payload = {
      id: sessionDetails?.id,
      end_km: values?.end_km,
      session_end: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    if (values?.end_km < sessionDetails?.start_km) {
      ErrorToast("End KM must be greater than Start KM.");
    } else {
      await dispatch(sessionEnd(payload));
    }
  };

  const handleViewHistory = async () => {
    navigate("/driverhistory");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <div className="container-fluid">
      <div className="container">
        {!sessionDetails?.id ? (
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <div className="row pt-3">
                  <div className="col-12 d-flex justify-content-between align-items-center flex-nowrap">
                    <Typography.h4 className="m-0">Session Start</Typography.h4>
                    <Button
                      type="button"
                      fillMode={"solid"}
                      themeColor={"primary"}
                      style={{ width: "fit-content" }}
                      onClick={handleViewHistory}
                    >
                      View History
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <Field
                      name="route_id"
                      label="Route"
                      placeholder="Route"
                      component={FormSelectionField}
                      astrike={true}
                      validator={requiredValidator}
                      options={RouteList?.map((route: IRoute) => {
                        return {
                          value: route?.id,
                          label: route?.route_name,
                        };
                      })}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <Field
                      name="vehicle_no"
                      label="Vehicle No"
                      placeholder="Vehicle No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <Field
                      name="helper_name"
                      label="Helper"
                      placeholder="Helper"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <Field
                      name="start_km"
                      label="Start KM"
                      placeholder="Start KM"
                      type="number"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </div>
                  <div
                    className="col-md-4 text-end text-md-start"
                    style={{ marginTop: 36 }}
                  >
                    <ButtonWithLoading
                      label={"Start"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </div>
              </FormElement>
            )}
          />
        ) : (
          <>
            <div className="row pt-3 pb-3">
              <div
                className={`col-12 d-flex justify-content-between align-items-center flex-nowrap`}
              >
                <Typography.h4 className="m-0">Session Details</Typography.h4>
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  style={{ width: "fit-content" }}
                  onClick={handleViewHistory}
                >
                  View History
                </Button>
              </div>
            </div>
            <div
              style={{
                padding: "10px",
                background: "whitesmoke",
                borderRadius: "10px",
              }}
            >
              <div className="row mb-2">
                <div
                  className={`col-12 d-flex mb-2 ${
                    width > MAX_WIDTH
                      ? "align-items-center gap-4"
                      : "gap-2 flex-column"
                  } `}
                >
                  <Typography.h6 className="m-0">
                    Start Date :{" "}
                    <span
                      style={{ fontWeight: 500 }}
                    >{`${sessionDetails?.session_start}`}</span>
                  </Typography.h6>
                  <Typography.h6 className="m-0">
                    Route Name :{" "}
                    <span
                      style={{ fontWeight: 500 }}
                    >{`${sessionDetails?.route_name}`}</span>
                  </Typography.h6>
                </div>
                <div className="col-12">
                  <Typography.h6 className="m-0">
                    Start KM :{" "}
                    <span
                      style={{ fontWeight: 500 }}
                    >{`${sessionDetails?.start_km}`}</span>
                  </Typography.h6>
                </div>
              </div>
              <div className="row">
                <Form
                  onSubmit={handleSessionEnd}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ width: "100%" }}>
                      <div
                        className={`col-12 d-flex mb-2 ${
                          width > MAX_WIDTH ? "gap-4" : "gap-2 flex-column"
                        } `}
                      >
                        <Field
                          name="end_km"
                          label="End KM"
                          placeholder="End KM"
                          type="number"
                          component={FormTextField}
                          validator={requiredValidator}
                          wrapperClassName="m-0"
                          astrike={true}
                        />
                        <Field
                          name="total_km"
                          label="Total KM"
                          placeholder="Total KM"
                          type="number"
                          wrapperClassName="m-0"
                          component={FormTextField}
                          disabled
                        />
                        <ButtonWithLoading
                          label={"Session End"}
                          type="submit"
                          buttonStyle={{
                            marginTop: width > MAX_WIDTH ? 21 : 10,
                          }}
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                      </div>
                      <TotalChangeWatcher
                        formRenderProps={formRenderProps}
                        start_km={sessionDetails?.start_km}
                      />
                    </FormElement>
                  )}
                />
              </div>
            </div>
            <div className="row pt-4 pb-2">
              <Typography.h4 className="m-0">Distributor List</Typography.h4>
            </div>
            <div className="row pt-2 pb-3">
              <div className="col-12">
                <DistributorGridComponent />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const DistributorGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const sessionDetails: any = useAppSelector(
    (state) => state.dashboard.sessionDetails
  );

  const loading = useAppSelector((state) => state.country.loading);
  const { width } = useWindowSize();

  const handleCheckinChekout = async (sales_id: number, isCheckin: boolean) => {
    if (isCheckin) {
      if (sales_id) {
        const payload = {
          sales_id: sales_id,
          delivery_id: sessionDetails?.id,
          checkin: moment().format("YYYY-MM-DD HH:mm:ss"),
          ischeck: isCheckin,
        };

        const res = await dispatch(checkinCheckoutUpdate(payload));
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(getSessionDetails());
        }
      }
    } else {
      if (sales_id) {
        const payload = {
          sales_id: sales_id,
          delivery_id: sessionDetails?.id,
          checkout: moment().format("YYYY-MM-DD HH:mm:ss"),
          ischeck: isCheckin,
        };

        const res = await dispatch(checkinCheckoutUpdate(payload));
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(getSessionDetails());
        }
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {sessionDetails?.distributerlists &&
      sessionDetails?.distributerlists.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)", padding: 0 }}
          filterable={false}
          data={sessionDetails?.distributerlists}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="account_name"
            title="Name"
            width={width > MAX_WIDTH ? "unset" : "250px"}
          />
          <Column
            field="contact_person_name"
            title="Person Name"
            width={width > MAX_WIDTH ? "unset" : "250px"}
          />
          <Column
            field="checkin"
            title="Check In"
            cell={(props: any) =>
              props.dataItem?.checkin ? (
                <td className="text-center">{props.dataItem?.checkin}</td>
              ) : (
                <td className="text-center">
                  <IconButton
                    type="button"
                    fillMode="flat"
                    size="small"
                    title="Check In"
                    onClick={() =>
                      handleCheckinChekout(props.dataItem.sales_id, true)
                    }
                  >
                    <BiLogIn
                      className="absolute-position"
                      style={{ fontSize: "24px" }}
                    />
                  </IconButton>
                </td>
              )
            }
            width={width > MAX_WIDTH ? "unset" : "150px"}
            filterable={false}
          />
          <Column
            field="checkout"
            title="Check Out"
            cell={(props: any) =>
              props.dataItem?.checkin ? (
                props.dataItem?.checkout ? (
                  <td className="text-center">{props.dataItem?.checkout}</td>
                ) : (
                  <td className="text-center">
                    <IconButton
                      type="button"
                      fillMode="flat"
                      size="small"
                      title="Check Out"
                      onClick={() =>
                        handleCheckinChekout(props.dataItem.sales_id, false)
                      }
                    >
                      <BiLogOut
                        className="absolute-position"
                        style={{ fontSize: "24px" }}
                      />
                    </IconButton>
                  </td>
                )
              ) : (
                <td className="text-center"></td>
              )
            }
            width={width > MAX_WIDTH ? "unset" : "150px"}
            filterable={false}
          />
          <Column
            field="duration"
            title="Duration"
            cell={(props: any) => (
              <td>
                {props.dataItem?.checkin && props.dataItem?.checkout
                  ? getTimeDuration(
                      props.dataItem?.checkin,
                      props.dataItem?.checkout
                    )
                  : ""}
              </td>
            )}
            width={width > MAX_WIDTH ? "unset" : "150px"}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default DriverDashboard;
