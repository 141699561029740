import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveEmployee,
  createEmployee,
  createEmployeeEducation,
  createEmployeeExperience,
  deleteEmployee,
  deleteEmployeeEducation,
  deleteEmployeeExperience,
  deleteEmployeeSalaryStructure,
  getAccountStatustoryByUserID,
  getAllActiveEmployee,
  getAllEmployee,
  getAllEmployeeLocation,
  getAllSalaryDateByEmployeeID,
  getDocumentAttachmentByUserID,
  getEducationByUserID,
  getEmployeeByID,
  getExperienceByUserID,
  getFamilyMemberByUserID,
  getSalaryStructureByDateAndEmpID,
  updateAccountStatustory,
  updateEmployee,
  updateEmployeeEducation,
  updateEmployeeExperience,
  updateEmployeeSalaryStructure,
  updateFamilyDetails,
} from "./services/employee.services";
import { IEmployeeInitialState, IEmployeeLocationList } from "./employeeModel";

const initialState: IEmployeeInitialState = {
  loading: false,
  formloading: false,
  error: "",
  EmployeeGUID: "",
  EmployeeList: [],
  EmployeeDetails: {
    user_image: "",
    employee_code: null,
    employee_code_string: "",
    sequence_no:null,
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: null,
    mobile_no: "",
    email: "",
    department_id: null,
    designation_id: null,
    official_email: "",
    date_of_joining: "",
    work_shift_time: "",
    weekly_off: "",
    left_date: "",
    location: "",
    reporting_person: null,
    reason_of_exit: "",
    dob: "",
    blood_group: "",
    personal_email: "",
    marital_status: null,
    date_of_marrige: "",
    spouse_name: "",
    nationality: null,
    address: "",
    street: "",
    area: "",
    city: "",
    pincode: "",
    state_id: null,
    is_permanent_address_same: false,
    permenent_address: "",
    permenent_street: "",
    permenent_area: "",
    permenent_city: "",
    permenent_pincode: "",
    permenent_state_id: null,
    emergency_name: "",
    emergency_mobile: "",
    emergency_relationship_id: null,
    isactive: true,
  },
  AccountAndStatutoryDetails: {
    id: null,
    user_guid: "",
    bank_name: "",
    account_no: "",
    ifsc_code: "",
    bank_branch: "",
    pf_deduction: false,
    pf_no: "",
    pf_join_date: "",
    uan_no: "",
    est_no: "",
    esi_join_date: "",
    aadharcard: "",
    pancard: "",
    passport_no: "",
    isactive: 1,
  },
  FamilyDetails: {
    FamilyMemberDetails: [],
  },
  FamilyDeleteIndex: -1,
  EmployeeAttachments: [],
  AttachmentDeleteIndex: -1,
  EducationDetailsGUID: "",
  EducationDetails: {
    qualification: "",
    university_board: "",
    document_attchement: "",
    employee_id: null,
    year: "",
  },
  EducationDetailsList: [],
  ExperienceDetailsGUID: "",
  ExperienceDetails: {
    company: "",
    designation: "",
    duration_from: "",
    duration_to: "",
  },
  ExperienceDetailsList: [],
  SalaryDateList: [],
  SalaryDetails: {
    basic: null,
    celling_amount: null,
    da_per: null,
    da: null,
    hra: null,
    pf: null,
    esi: null,
    hra_per: null,
    wash: null,
    adv_bonus: null,
    adv_leave: null,
    prod_ins_all: null,
    adv_std_be: null,
    food: null,
    other_earning: null,
    pf_per: 12,
    esi_per: 0.75,
    pt: null,
    it: null,
    lwf: null,
    tds: null,
    advance: null,
    loan: null,
    other: null,
    apply_from_date: "",
    employee_id: null,
    total_income: 0,
    total_deduction: 0,
    payable_amount: 0,
    //  apply_from_date_selector: "",
  },
  ActiveStep: 0,
  FilterCustomEmplooye: {
    name: "",
    email: "",
    mobile_no: "",
  },
  DateFilter: {
    new_date: "",
    apply_from_date_selector: "",
  },

  EmployeeLocationList: [],
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setEmployeeGUID: (state, action) => {
      state.EmployeeGUID = action.payload;
    },
    clearEmployeeGUID: (state) => {
      state.EmployeeGUID = initialState.EmployeeGUID;
    },
    clearEmployeeList: (state) => {
      state.EmployeeList = initialState.EmployeeList;
    },
    clearAccountAndStatutoryDetails: (state) => {
      state.AccountAndStatutoryDetails =
        initialState.AccountAndStatutoryDetails;
    },
    setEducationDetailsGUID: (state, action) => {
      state.EducationDetailsGUID = action.payload;
    },
    clearEducationDetailsGUID: (state) => {
      state.EducationDetailsGUID = initialState.EducationDetailsGUID;
    },
    setExperienceDetailsGUID: (state, action) => {
      state.ExperienceDetailsGUID = action.payload;
    },
    clearExperienceDetailsGUID: (state) => {
      state.ExperienceDetailsGUID = initialState.ExperienceDetailsGUID;
    },
    clearEmployeeDetails: (state) => {
      state.EmployeeDetails = initialState.EmployeeDetails;
    },
    clearFamilyDetails: (state) => {
      state.FamilyDetails = initialState.FamilyDetails;
    },
    clearEmployeeAttachments: (state) => {
      state.EmployeeAttachments = initialState.EmployeeAttachments;
    },
    setActiveStep: (state, action) => {
      state.ActiveStep = action.payload;
    },
    clearActiveStep: (state) => {
      state.ActiveStep = initialState.ActiveStep;
    },
    increaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep + 1;
    },
    decreaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep - 1;
    },
    setFamilyDeleteIndex: (state, action) => {
      state.FamilyDeleteIndex = action.payload;
    },
    setAttachmentDeleteIndex: (state, action) => {
      state.AttachmentDeleteIndex = action.payload;
    },
    setEducationDetails: (state, action) => {
      state.EducationDetails = action.payload;
    },
    clearEducationDetails: (state) => {
      state.EducationDetails = initialState.EducationDetails;
    },
    clearEducationDetailsList: (state) => {
      state.EducationDetailsList = initialState.EducationDetailsList;
    },
    setExperienceDetails: (state, action) => {
      state.ExperienceDetails = action.payload;
    },
    clearExperienceDetails: (state) => {
      state.ExperienceDetails = initialState.ExperienceDetails;
    },
    clearExperienceDetailsList: (state) => {
      state.ExperienceDetailsList = initialState.ExperienceDetailsList;
    },
    clearSalaryDetails: (state) => {
      state.SalaryDetails = initialState.SalaryDetails;
    },
    setApplyFromDate: (state, action) => {
      state.SalaryDetails.apply_from_date = action.payload;
    },
    setApplyFromDateSelector: (state, action) => {
      state.DateFilter.apply_from_date_selector = action.payload;
    },
    clearNewDate: (state) => {
      state.DateFilter = initialState.DateFilter;
    },
    setFilterCustomEmplooye: (state, action) => {
      state.FilterCustomEmplooye = action.payload;
    },
    clearFilterCustomEmplooye: (state) => {
      state.FilterCustomEmplooye = initialState.FilterCustomEmplooye;
    },
    clearSalaryDateList: (state) => {
      state.SalaryDateList = initialState.SalaryDateList;
    },
  },
  extraReducers: (builder) => {
    // EMPLOYEE BASI INFORMATION API
    builder.addCase(getAllEmployee.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllEmployee.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.EmployeeList = action.payload || [];
      }
    );
    builder.addCase(getAllEmployee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error : GET_ALL_EMPLOYEE";
      state.EmployeeList = initialState.EmployeeList;
    });

    builder.addCase(getAllActiveEmployee.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveEmployee.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.EmployeeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveEmployee.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error : GET_ALL_ACTIVE_EMPLOYEE";
      state.EmployeeList = initialState.EmployeeList;
    });

    builder.addCase(createEmployee.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(createEmployee.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(createEmployee.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : CREATE_EMPLOYEE";
    });
    builder.addCase(updateEmployee.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateEmployee.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : UPDATE_EMPLOYEE";
    });
    builder.addCase(deleteEmployee.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(deleteEmployee.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(deleteEmployee.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : DELETE_EMPLOYEE";
    });
    builder.addCase(getEmployeeByID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(getEmployeeByID.fulfilled, (state, action: any) => {
      state.formloading = false;
      state.EmployeeDetails = action.payload;
    });
    builder.addCase(getEmployeeByID.rejected, (state, action) => {
      state.formloading = false;
      state.EmployeeDetails = initialState.EmployeeDetails;
      state.error = action.error.message || "Error : GET_EMPLOYEE";
    });
    builder.addCase(ActiveInactiveEmployee.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveEmployee.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(ActiveInactiveEmployee.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : ACTIVE_INACTIVE_EMPLOYEE";
    });

    // EMPLOYEE ACCOUNT AND STATUSTORY API

    builder.addCase(getAccountStatustoryByUserID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(
      getAccountStatustoryByUserID.fulfilled,
      (state, action: any) => {
        state.formloading = false;
        state.AccountAndStatutoryDetails = action.payload;
      }
    );
    builder.addCase(getAccountStatustoryByUserID.rejected, (state, action) => {
      state.formloading = false;
      state.AccountAndStatutoryDetails =
        initialState.AccountAndStatutoryDetails;
      state.error = action.error.message || "Error : GET_ACCOUNT_STATUSTORY";
    });

    builder.addCase(updateAccountStatustory.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateAccountStatustory.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateAccountStatustory.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : UPDATE_ACCOUNT_STATUSTORY";
    });

    // EMPLOYEE FAMILY MEMBER API

    builder.addCase(getFamilyMemberByUserID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(getFamilyMemberByUserID.fulfilled, (state, action: any) => {
      state.formloading = false;
      state.FamilyDetails = action.payload;
    });
    builder.addCase(getFamilyMemberByUserID.rejected, (state, action) => {
      state.formloading = false;
      state.FamilyDetails = initialState.FamilyDetails;
      state.error = action.error.message || "Error : GET_FAMILY_MEMBER";
    });
    builder.addCase(updateFamilyDetails.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateFamilyDetails.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateFamilyDetails.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : UPDATE_FAMILY_MEMBER";
    });

    // EMPLOYEE DOCUMNET ATTACHMENT API

    builder.addCase(getDocumentAttachmentByUserID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(
      getDocumentAttachmentByUserID.fulfilled,
      (state, action: any) => {
        state.formloading = false;
        state.EmployeeAttachments = action.payload;
      }
    );
    builder.addCase(getDocumentAttachmentByUserID.rejected, (state, action) => {
      state.formloading = false;
      state.EmployeeAttachments = initialState.EmployeeAttachments;
      state.error = action.error.message || "Error : GET_EMPLOYEE_DOCUMENT";
    });

    // EMPLOYEE EDUCATION API

    builder.addCase(createEmployeeEducation.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(createEmployeeEducation.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(createEmployeeEducation.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : CREATE_EMPLOYEE_EDUCATION";
    });
    builder.addCase(updateEmployeeEducation.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateEmployeeEducation.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateEmployeeEducation.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : UPDATE_EMPLOYEE_EDUCATION";
    });
    builder.addCase(deleteEmployeeEducation.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(deleteEmployeeEducation.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(deleteEmployeeEducation.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Error : DELETE_EMPLOYEE_EDUCATION";
    });

    builder.addCase(getEducationByUserID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEducationByUserID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.EducationDetailsList = action.payload;
    });
    builder.addCase(getEducationByUserID.rejected, (state, action) => {
      state.loading = false;
      state.EducationDetailsList = initialState.EducationDetailsList;
      state.error = action.error.message || "Error : GET_EMPLOYEE_EDUCATION";
    });

    // EMPLOYEE EXPERIENCE API

    builder.addCase(createEmployeeExperience.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(createEmployeeExperience.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(createEmployeeExperience.rejected, (state, action) => {
      state.formloading = false;
      state.error =
        action.error.message || "Error : CREATE_EMPLOYEE_EXPERIENCE";
    });
    builder.addCase(updateEmployeeExperience.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateEmployeeExperience.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateEmployeeExperience.rejected, (state, action) => {
      state.formloading = false;
      state.error =
        action.error.message || "Error : UPDATE_EMPLOYEE_EXPERIENCE";
    });
    builder.addCase(deleteEmployeeExperience.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(deleteEmployeeExperience.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(deleteEmployeeExperience.rejected, (state, action) => {
      state.formloading = false;
      state.error =
        action.error.message || "Error : DELETE_EMPLOYEE_EXPERIENCE";
    });

    builder.addCase(getExperienceByUserID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getExperienceByUserID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ExperienceDetailsList = action.payload;
    });
    builder.addCase(getExperienceByUserID.rejected, (state, action) => {
      state.loading = false;
      state.ExperienceDetailsList = initialState.ExperienceDetailsList;
      state.error = action.error.message || "Error : GET_EMPLOYEE_EXPERIENCE";
    });

    // EMPLOYEE SALARY STRUCTURE API

    builder.addCase(getAllSalaryDateByEmployeeID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSalaryDateByEmployeeID.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.SalaryDateList = action.payload || [];
      }
    );
    builder.addCase(getAllSalaryDateByEmployeeID.rejected, (state, action) => {
      state.loading = false;
      state.error =
        action.error.message || "Error : GET_ALL_SALARY_STRUCTURE_DATE";
      state.SalaryDateList = initialState.SalaryDateList;
    });

    builder.addCase(updateEmployeeSalaryStructure.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateEmployeeSalaryStructure.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateEmployeeSalaryStructure.rejected, (state, action) => {
      state.formloading = false;
      state.error =
        action.error.message || "Error : UPDATE_EMPLOYEE_SALARY_STRUCTURE";
    });
    builder.addCase(deleteEmployeeSalaryStructure.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(deleteEmployeeSalaryStructure.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(deleteEmployeeSalaryStructure.rejected, (state, action) => {
      state.formloading = false;
      state.error =
        action.error.message || "Error : DELETE_EMPLOYEE_SALARY_STRUCTURE";
    });

    builder.addCase(getSalaryStructureByDateAndEmpID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(
      getSalaryStructureByDateAndEmpID.fulfilled,
      (state, action: any) => {
        state.formloading = false;
        state.SalaryDetails = action.payload;
      }
    );
    builder.addCase(
      getSalaryStructureByDateAndEmpID.rejected,
      (state, action) => {
        state.formloading = false;
        state.SalaryDetails = initialState.SalaryDetails;
        state.error = action.error.message || "Error : GET_SALARY_STRUCTURE";
      }
    );

    builder.addCase(getAllEmployeeLocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllEmployeeLocation.fulfilled,
      (state, action: PayloadAction<IEmployeeLocationList[]>) => {
        state.loading = false;
        state.EmployeeLocationList = action.payload || [];
      }
    );
    builder.addCase(getAllEmployeeLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error : GET_EMPLOYEE_LOCATION";
      state.EmployeeLocationList = [];
    });
  },
});

export const {
  clearEmployeeDetails,
  clearEmployeeGUID,
  clearEmployeeList,
  setEmployeeGUID,
  setEducationDetailsGUID,
  clearEducationDetailsGUID,
  clearActiveStep,
  setActiveStep,
  increaseActiveStep,
  decreaseActiveStep,
  setAttachmentDeleteIndex,
  setFamilyDeleteIndex,
  clearFamilyDetails,
  clearEmployeeAttachments,
  clearEducationDetails,
  clearEducationDetailsList,
  setEducationDetails,
  clearExperienceDetails,
  clearExperienceDetailsGUID,
  clearExperienceDetailsList,
  setExperienceDetails,
  setExperienceDetailsGUID,
  clearFilterCustomEmplooye,
  clearSalaryDetails,
  setFilterCustomEmplooye,
  clearAccountAndStatutoryDetails,
  setApplyFromDate,
  clearNewDate,
  setApplyFromDateSelector,
  clearSalaryDateList,
} = employeeSlice.actions;
export default employeeSlice.reducer;
