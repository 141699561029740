import React, { useEffect, useRef } from "react";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  Link,
  Unlink,
} = EditorTools;

const FormRichTextField = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    defaultContent,
    style = { height: "100px" },
    wrapperStyle,
    wrapperClassName,
    value,
    onChange,
    astrike = false,
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const editorRef = useRef<any>();

  useEffect(() => {
    if (editorRef.current) {
      const view = editorRef.current.view;
      if (view && value) {
        EditorUtils.setHtml(view, value);
        onChange({ value: value });
      }
    }
  }, []);

  const handleChange = () => {
    if (editorRef.current) {
      const view = editorRef.current.view;
      if (view) {
        const htmlString = EditorUtils.getHtml(view.state);
        onChange({ value: htmlString });
      }
    }
  };

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className="k-form-label"
      >
        {label} {astrike && <span style={{ color: "red" }}>*</span>}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Editor
          className={wrapperClassName}
          ref={editorRef}
          onChange={handleChange}
          onBlur={handleChange}
          tools={[
            [Bold, Italic, Underline],
            [Undo, Redo],
            [Link, Unlink],
            [AlignLeft, AlignCenter, AlignRight],
            [OrderedList, UnorderedList, Indent, Outdent],
          ]}
          contentStyle={style}
          defaultContent={defaultContent}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormRichTextField;
