import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSalesChallanPrint } from "./services/salesChallan.services";
import Logo from "../../assets/Images/logo.png";
import FSSAI from "../../assets/Images/fssai.png";
import { formatIndianNumberForQty } from "../../_helper/helper";

const SalesChallanPrint = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const queryParam = new URLSearchParams(location.search);
  const isPrint = queryParam.get("print");

  const sales_challan_guid = params?.sales_challan_guid;
  const SalesChallanPrintDetail = useAppSelector(
    (state) => state.salesChallan.SalesChallanPrintDetail
  );

  useEffect(() => {
    if (sales_challan_guid) {
      const handleOrderPrint = async () => {
        const res = await dispatch(getSalesChallanPrint(sales_challan_guid));
        if (res.meta.requestStatus === "fulfilled") {
          if (isPrint === "1") {
            window.print();
          }
        }
      };
      handleOrderPrint();
    }
  }, [sales_challan_guid]);

  // let totalAmount = 0;
  let totalBilledQty = 0;
  let totalFreeQty = 0;
  let totalQty = 0;
  // let totalTaxableAmount = 0;

  SalesChallanPrintDetail?.sales_challan_items &&
    SalesChallanPrintDetail?.sales_challan_items.length > 0 &&
    SalesChallanPrintDetail?.sales_challan_items?.map((item: any) => {
      // totalAmount += item?.amount;
      totalBilledQty += item?.billed_quantity;
      totalFreeQty += item?.free_quantity;
      totalQty += item?.shipped_quantity;
    });

  // SalesChallanPrintDetail?.gst_details &&
  //   SalesChallanPrintDetail?.gst_details?.length > 0 &&
  //   SalesChallanPrintDetail?.gst_details?.map((gstamount: any) => {
  //     const totaltax =
  //       gstamount?.total_taxable_amount &&
  //       gstamount?.gst &&
  //       (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
  //     totalTaxableAmount += totaltax;
  //   });

  return (
    <div className="p-4 sales-print-page">
      <div className="sc-head">
        <div className="sc-head-sec-1">
          <h5>Delivery Slip</h5>
          <img src={Logo} width={"100%"} />
        </div>
        <div className="sc-head-sec-2">
          <h3>
            Harihar Foods Pvt. Ltd.{" "}
            <span>(A Govt. Recognized Export House)</span>
          </h3>
          <p className="mb-0">
            <b>Reg. Office</b> : 519/1, Phase-IV, G.I.D.C. vatva, Nr. Ramol Char
            Rasta, Vatva, Ahmedbad, Ph.:079-25841773, 25840855
          </p>
          <p className="mb-0">
            <b>Factory</b>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 519/1,
            Phase-IV, G.I.D.C. vatva, Nr. Ramol Char Rasta, Vatva, Ahmedbad,
            Ph.:079-25841773, 25840855
          </p>
          <p className="mb-0">
            <b>E-mail</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            : marketing@shreeganeshmasala.com visit us :
            wwww.shreeganeshmasala.com
          </p>
        </div>
      </div>
      <div className="sc-head">
        <div className="sc-head-sec-1">
          <span>An ISO 22000:2018 Certified Company</span>
        </div>
        <div className="sc-head-sec-2">
          <span>
            <img src={FSSAI} style={{ height: 8, marginRight: 5 }} />
            Licenece No. 10012021000359
          </span>
        </div>
      </div>
      <table className="table table-bordered w-100 sc-print-table m-0">
        <tr style={{ border: "1px solid white" }}>
          <td colSpan={2} style={{ border: "none" }}></td>
          <td style={{ width: "25%" }}>
            <b className="p-0">Order No.:</b>{" "}
            {SalesChallanPrintDetail?.sales_order_no}
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ width: "25%" }}>
            <b className="p-0">Party Name :</b>{" "}
            {SalesChallanPrintDetail?.account_name}
          </td>
          <td style={{ width: "25%" }}>
            <b className="p-0">Place :</b> {SalesChallanPrintDetail?.city_name}
          </td>
          <td style={{ width: "25%" }}>
            <b className="p-0">Delivery Slip No.: </b>{" "}
            {SalesChallanPrintDetail?.challan_no_string}
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td style={{ width: "37.5%" }}>
            <b className="p-0">GST No.:</b> {SalesChallanPrintDetail?.gst_no}
          </td>
          <td style={{ width: "37.5%" }}>
            <b className="p-0">Transport:</b>{" "}
            {SalesChallanPrintDetail?.transport_name}
          </td>
          <td style={{ width: "25%" }}>
            <b className="p-0">Date:</b> {SalesChallanPrintDetail?.challan_date}
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td>
            <b className="p-0">Scheme :</b> {SalesChallanPrintDetail?.scheme_name}
          </td>
          <td colSpan={2}>
            <b className="p-0">Vehicle No. :</b>{" "}
            {SalesChallanPrintDetail?.vehicle_no}
          </td>
        </tr>
      </table>

      <table
        cellSpacing={1}
        cellPadding={2}
        className="table w-100  sales-print-table mb-0 b-none"
      >
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th rowSpan={2} style={{ width: "5%" }}>
            Sr No.
          </th>
          <th rowSpan={2} style={{ width: "35%" }}>
            Description Of Goods
          </th>
          <th rowSpan={2} style={{ width: "7%" }}>
            Batch No.
          </th>
          <th rowSpan={2} style={{ width: "9%" }}>
            HSN/SAC
          </th>
          <th rowSpan={2} style={{ width: "9%" }}>
            MRP
          </th>
          <th colSpan={3} style={{ width: "27%" }}>
            Quantity
          </th>
          <th rowSpan={2} style={{ width: "8%" }}>
            Per
          </th>
          {/* <th rowSpan={2} style={{ width: "7%" }}>
            Rate
          </th>
          <th rowSpan={2} style={{ width: "10%" }}>
            Amount
          </th> */}
        </tr>
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th>Billed</th>
          <th>Free</th>
          <th>Total</th>
        </tr>
        {SalesChallanPrintDetail?.sales_challan_items &&
          SalesChallanPrintDetail?.sales_challan_items.length > 0 &&
          SalesChallanPrintDetail?.sales_challan_items?.map(
            (item: any, index: number) => (
              <>
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td>{item?.product_name}</td>
                  <td className="text-center">{item?.lot_no}</td>
                  <td>{item?.hsn_sac_code}</td>
                  <td className="text-end">{item?.mrp || 0}</td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.billed_quantity || 0)}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.free_quantity || 0)}
                  </td>
                  <td className="text-end">
                    {formatIndianNumberForQty(item?.shipped_quantity || 0)}
                  </td>
                  <td>{item?.unit_name}</td>
                  {/* <td className=" text-end">
                    {formatIndianNumber(item?.rate)}
                  </td>
                  <td className=" text-end">
                    {formatIndianNumber(item?.amount)}
                  </td> */}
                </tr>
              </>
            )
          )}
        <tr className="text-end fw-600" style={{ background: "whitesmoke" }}>
          <td colSpan={5}>Total</td>
          <td className="text-end">
            {formatIndianNumberForQty(totalBilledQty)}
          </td>
          <td className="text-end">{formatIndianNumberForQty(totalFreeQty)}</td>
          <td className="text-end">{formatIndianNumberForQty(totalQty)}</td>
          <td></td>
        </tr>
        {/* <tr>
          <td colSpan={9}></td>
          <td className="text-end fw-600" style={{ fontSize: 12 }}>
            {formatIndianNumber(totalAmount)}
          </td>
        </tr>
        {SalesChallanPrintDetail?.gst_details &&
          SalesChallanPrintDetail?.gst_details?.length > 0 &&
          SalesChallanPrintDetail?.gst_details?.map((gstdetails: any) =>
            SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
              <>
                <tr>
                  <td></td>
                  <td className=" text-end">CGST {gstdetails?.gst / 2}%</td>
                  <td colSpan={5}></td>
                  <td className=" text-end">
                    {(gstdetails?.gst / 2)?.toFixed(2)}
                  </td>
                  <td>%</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className=" text-end">SGST {gstdetails?.gst / 2}%</td>
                  <td colSpan={5}></td>
                  <td className=" text-end">
                    {(gstdetails?.gst / 2)?.toFixed(2)}
                  </td>
                  <td>%</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td></td>
                <td className=" text-end">IGST {gstdetails?.gst}%</td>
                <td colSpan={5}></td>
                <td className=" text-end">{gstdetails?.gst?.toFixed(2)}</td>
                <td>%</td>
                <td className=" text-end">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        <tr style={{ fontSize: 12 }}>
          <td colSpan={9} className=" text-end fw-600">
            {" "}
            Total Invoice Amount:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(Math.round(+totalAmount + +totalTaxableAmount))}
          </td>
        </tr>
        <tr className="b-none">
          <td colSpan={9} className="b-none">
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Amount Chargeable (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(Math.round(+totalAmount + +totalTaxableAmount))}
            </span>
          </td>
          <td className="text-end b-none">E & O.E</td>
        </tr> */}
      </table>
      {/* <table
        cellSpacing={1}
        cellPadding={2}
        className="table table-bordered w-100  sales-print-table mb-0 b-none"
        style={{ pageBreakInside: "avoid" }}
      >
        {SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
          <>
            <tr className="text-center fw-600" style={{ fontSize: 13 }}>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={2}>Central Tax</th>
              <th colSpan={2}>State Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th>Amount</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </>
        ) : (
          <>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={4}>Integrated Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th colSpan={3}>Amount</th>
            </tr>
          </>
        )}
        {SalesChallanPrintDetail?.gst_details &&
          SalesChallanPrintDetail?.gst_details?.length > 0 &&
          SalesChallanPrintDetail?.gst_details?.map((gstdetails: any) =>
            SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
              <tr className="text-end">
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td className=" ">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            ) : (
              <tr className="text-end">
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst)} %</td>
                <td colSpan={3}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        {SalesChallanPrintDetail?.state_name === "GUJARAT" ? (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        ) : (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td colSpan={3}>{formatIndianNumber(totalTaxableAmount)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        )}
        <tr style={{ borderBottom: "none" }}>
          <td colSpan={7} style={{ textAlign: "end", border: "none" }}>
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Tax Amount (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(+totalTaxableAmount?.toFixed(2))}
            </span>
          </td>
        </tr>
      </table> */}
      {/* <table
        cellSpacing={1}
        cellPadding={2}
        style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}
        className="table table-bordered w-100 mb-0"
      >
        <tr>
          <td className="w-50" rowSpan={2}>
            Company's PAN : <span className="fw-600 p-0">AAACH5100R</span>{" "}
            <br />
            CIN NO : <span className="fw-600 p-0">
              U15499GJ1994PTC021520
            </span>{" "}
            <br />
            <br />
            <u>Declaration:</u>
            <ol className="ms-1 mb-0">
              <li>
                We declare that this invoice shows the actual price of the goods
                described and that all particulars are true and correct.
              </li>
              <li>Goods once sold will not be taken back.</li>
              <li>
                Interest @18% will be levied on outstanding after 30 days of
                invoice date.
              </li>
              <li>Cheque return charges will be Rs 250/-.</li>
              <li>FSSAI Licence No. 10012021000359</li>
              <li>Subject to Ahmedabad Jurisdiction</li>
            </ol>
          </td>
          <td className="w-50 p-0">
            <div>
              <b>Company's Bank Details</b> <br />
              <table className="table bank-details-table">
                <tr>
                  <td>Bank Name</td>
                  <td className="fw-600">: Kotak Mahindra Bank</td>
                </tr>
                <tr>
                  <td>A/c No.</td>
                  <td className="fw-600">: 2411684374</td>
                </tr>
                <tr>
                  <td>Branch & IFS Code</td>
                  <td className="fw-600">: SATELITE BRANCH & KKBK0000810</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td
            style={{
              height: 100,
              borderTop: "1px double rgba(0, 0, 0, 0.5) ",
              borderLeft: "1px solid rgba(0, 0, 0, 0.5) ",
              textAlign: "end",
            }}
          >
            <p className="p-0 mb-5 fw-600">for Harihar Foods Pvt. Ltd.</p>
            <p className="m-0 p-0">Authorised Signatory</p>
          </td>
        </tr>
      </table> */}
      <div className="d-flex gap-2">
        <table
          cellSpacing={1}
          cellPadding={2}
          className="table w-50 mt-2 sales-print-table mb-0 b-none"
        >
          <tr className="text-center" style={{ fontSize: 12 }}>
            <td className="w-50 fw-600">Bags</td>
            <td className="w-50">{SalesChallanPrintDetail?.bag || 0}</td>
          </tr>
          <tr className="text-center" style={{ fontSize: 12 }}>
            <td className="fw-600">Cartoon</td>
            <td>{SalesChallanPrintDetail?.cartoon || 0}</td>
          </tr>
          <tr
            className="text-center"
            style={{ fontSize: 12, background: "whitesmoke" }}
          >
            <td className="fw-600">Total</td>
            <td className="fw-600">
              {+SalesChallanPrintDetail?.bag +
                +SalesChallanPrintDetail?.cartoon || 0}
            </td>
          </tr>
        </table>
        {/* {SalesChallanPrintDetail?.remarks && ( */}
        <div
          className="w-50 mt-2"
          style={{ border: "1px solid gray", padding: "2px 5px" }}
        >
          <span style={{ fontWeight: 600, textDecoration: "underline" }}>
            {" "}
            Remarks:-
          </span>{" "}
          {SalesChallanPrintDetail?.remarks}
        </div>
        {/* )} */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 5px 60px",
          border: "1px solid gray",
          marginTop: "10px",
          fontWeight: 600,
        }}
      >
        <p>Checked By</p>
        <p>Approved By</p>
      </div>
    </div>
  );
};

export default SalesChallanPrint;
