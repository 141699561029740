import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHoliday, IHolidayState } from "./holidayModel";
import {
  holidayActiveInactive,
  createHoliday,
  deleteHoliday,
  getAllActiveHolidays,
  getAllHolidays,
  getHolidayByID,
  updateHoliday,
} from "./services/holiday.services";

const initialState: IHolidayState = {
  loading: false,
  formLoading: false,
  error: "",
  HolidayList: [],
  HolidayID: null,
  HolidayGUID: "",
  HolidayDetail: {
    title: "",
    holiday_date: "",
    holiday_day: "",
    isactive: 1,
  },
};

const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    clearHolidayDetails: (state) => {
      state.HolidayDetail = initialState.HolidayDetail;
    },
    setHolidayID: (state, action) => {
      state.HolidayID = action.payload;
    },
    clearHolidayID: (state) => {
      state.HolidayID = initialState.HolidayID;
    },
    setHolidayGUID: (state, action) => {
      state.HolidayGUID = action.payload;
    },
    clearHolidayGUID: (state) => {
      state.HolidayGUID = initialState.HolidayGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllHolidays.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllHolidays.fulfilled,
      (state, action: PayloadAction<IHoliday[]>) => {
        state.loading = false;
        state.HolidayList = action.payload || [];
      }
    );
    builder.addCase(getAllHolidays.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HolidayList = [];
    });
    builder.addCase(getAllActiveHolidays.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveHolidays.fulfilled,
      (state, action: PayloadAction<IHoliday[]>) => {
        state.loading = false;
        state.HolidayList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveHolidays.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HolidayList = [];
    });
    builder.addCase(createHoliday.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createHoliday.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createHoliday.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateHoliday.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateHoliday.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateHoliday.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteHoliday.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHoliday.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteHoliday.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(holidayActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(holidayActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(holidayActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getHolidayByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getHolidayByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.HolidayDetail = action.payload;
    });
    builder.addCase(getHolidayByID.rejected, (state, action) => {
      state.formLoading = false;
      state.HolidayDetail = initialState.HolidayDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearHolidayDetails,
  setHolidayID,
  clearHolidayID,
  setHolidayGUID,
  clearHolidayGUID,
} = holidaySlice.actions;
export default holidaySlice.reducer;
