import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  Avatar,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
// import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import {
  clearEmployeeGUID,
  clearFilterCustomEmplooye,
  setEmployeeGUID,
  setFilterCustomEmplooye,
  // setFilterEmplooyeName,
} from "./employeeSlice";
import { useNavigate } from "react-router-dom";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import {
  ActiveInactiveEmployee,
  deleteEmployee,
  getAllEmployee,
} from "./services/employee.services";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import _debounce from "lodash/debounce";
import { Tooltip } from "@progress/kendo-react-tooltip";

const Employee: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  const handleCreate = () => {
    navigate("/employee/create");
  };

  useEffect(() => {
    return () => {
      dispatch(clearFilterCustomEmplooye());
    };
  }, []);

  return (
    <>
      <DeleteEmployeeDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Employee List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <EmployeeGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const EmployeeGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const loading = useAppSelector((state) => state.employee.loading);

  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomEmplooye = useAppSelector(
    (state) => state.employee.FilterCustomEmplooye
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        ...FilterCustomEmplooye,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllEmployee(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      navigate("/employee/edit", {
        state: {
          EmployeeID: item?.id,
          EmployeeGUID: item?.user_guid,
        },
      });
    };
    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteEmployee"));
      dispatch(setEmployeeGUID(ID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.user_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        user_guid: props.dataItem?.user_guid,
        isactive: !IsActive,
      };
      dispatch(ActiveInactiveEmployee(data)).then((response: any) => {
        // const payload = {
        //   name: "",
        //   mobile_no: "",
        //   email: "",
        //   limit: +pageLimit,
        //   skip: +currentPage * +pageLimit,
        // };
        if (response.payload?.Status !== 200) {
          setIsActive((prev) => !prev);
        }
      });
    };
    return (
      <td style={{ textAlign: "center" }}>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              disabled={isEditAccess ? false : true}
              name="isactive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
       style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
        filterable={true}
        filter={filter}
        data={
          (filter
            ? filterBy(
                EmployeeList?.map((employee: any) => {
                  return {
                    ...employee,
                    name: `${employee?.first_name} ${employee?.middle_name} ${employee?.last_name}`,
                  };
                }),
                filter
              )
            : EmployeeList?.map((employee: any) => {
                return {
                  ...employee,
                  name: `${employee?.first_name} ${employee?.middle_name} ${employee?.last_name}`,
                };
              })) || []
        }
        onFilterChange={handleFilterChange}
        total={EmployeeList[0]?.itemcount || EmployeeList?.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="user_image"
          title="Image"
          width="80px"
          filterable={false}
          cell={(props: any) => {
            const firstName = props.dataItem.first_name
              ? props.dataItem.first_name?.charAt(0)?.toUpperCase()
              : "";
            const lastName = props.dataItem.last_name
              ? props.dataItem.last_name?.charAt(0)?.toUpperCase()
              : "";
            return (
              <td>
                <Avatar
                  className="employee-image"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: props.dataItem.user_image
                      ? "white"
                      : "#303F9F",
                  }}
                >
                  {props.dataItem.user_image ? (
                    <img
                      src={props.dataItem.user_image}
                      alt={`${firstName + lastName}`}
                      style={{
                        height: "100%",
                      }}
                    />
                  ) : (
                    `${firstName + lastName}`
                  )}
                </Avatar>
              </td>
            );
          }}
        />
        <Column
          field="name"
          title="Employee Name"
          // width={160}
          filterable
          filterCell={MyCustomFilterCell}
          cell={(props: any) => (
            <td>
              {isEditAccess ? (
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/employee/edit", {
                      state: {
                        EmployeeID: props.dataItem?.id,
                        EmployeeGUID: props.dataItem?.user_guid,
                      },
                    })
                  }
                >
                  {`${props.dataItem?.first_name} ${props.dataItem?.middle_name} ${props.dataItem?.last_name}`}
                </div>
              ) : (
                <div className="normalName">{`${props.dataItem?.first_name} ${props.dataItem?.middle_name} ${props.dataItem?.last_name}`}</div>
              )}
            </td>
          )}
        />
        <Column field="employee_code_string" width={150} title="Code" />
        <Column field="sequence_no" width={150} title="Sequence No" />
        <Column
          field="mobile_no"
          width={120}
          filterCell={MyCustomFilterCell}
          title="Mobile No"
        />
        <Column field="email" title="Email" filterCell={MyCustomFilterCell} />
        <Column field="department_name" title="Department" />
        <Column field="designation_name" title="Designation" />
        <Column
          field="id"
          title="Status"
          cell={MyActiveInActtiveCommandCell}
          width={"90px"}
          filterable={false}
          locked={true}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};
const DeleteEmployeeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const EmployeeGUID = useAppSelector((state) => state.employee.EmployeeGUID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomEmplooye = useAppSelector(
    (state) => state.employee.FilterCustomEmplooye
  );
  const handleDeleteAction = async (EmployeeGUID: string) => {
    if (EmployeeGUID) {
      const response = await dispatch(deleteEmployee(EmployeeGUID));
      const payload = {
        ...FilterCustomEmplooye,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllEmployee(payload));
        dispatch(closeDialog());
        dispatch(clearEmployeeGUID());
      } else {
        dispatch(getAllEmployee(payload));
        dispatch(closeDialog());
        dispatch(clearEmployeeGUID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteEmployee" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Employee"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Employee?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(EmployeeGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const MyCustomFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomEmplooye: any = useAppSelector(
    (state) => state.employee.FilterCustomEmplooye
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllEmployee(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCustomEmplooye,
        [inputField]: inputValue,
      };
      dispatch(setFilterCustomEmplooye(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterCustomEmplooye, [props.field]: "" };
    dispatch(setFilterCustomEmplooye(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllEmployee(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCustomEmplooye[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCustomEmplooye[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Employee;
