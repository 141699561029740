import React, { useEffect, useRef, useState } from "react";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import IconButton from "../../components/common/IconButton";
import {
  MachineActiveInactive,
  deleteMachine,
  getAllMachine,
} from "./services/machine.services";
import { clearMachineID, setMachineID } from "./machineSlice";
import CreateMachine from "./CreateMachine";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import AppDrawer from "../../components/muidrawer/Drawer";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";

const Machine: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllMachine());
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
    };
  }, []);

  return (
    <>
      <DeleteStateDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Machine List
          </Typography.h4>
          {isAddAccess && (
            <ButtonCreate label="Add New" drawerName="machineForm" />
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ItemGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "machineForm" && (
          <AppDrawer>
            <CreateMachine />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const ItemGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const MachineList = useAppSelector((state) => state.machine.MachineList);
  const loading = useAppSelector((state) => state.machine.loading);
  const [expandedRow, setExpandedRow] = useState(null);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? MachineList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const truncateRemarks = (remarks: string) => {
    if (!remarks) return "";
    const maxLength = 100;
    if (remarks.length <= maxLength) return remarks;
    return remarks.substring(0, maxLength) + "...";
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteMachine"));
      dispatch(setMachineID(ID));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.machine_guid}
            name="machineForm"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.machine_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        machine_guid: props.dataItem.machine_guid,
        isactive: !IsActive,
      };
      dispatch(MachineActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllMachine());
        } else {
          dispatch(getAllMachine());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };
  return (
    <>
      {MachineList && MachineList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(MachineList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : MachineList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={MachineList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="machine_name"
            title="Machine Name"
            cell={(props: any) => (
              <td className={isEditAccess ? "hoverableName" : "normalName"}>
                {isEditAccess ? (
                  <ButtonEdit
                    ID={props.dataItem.machine_guid}
                    name="machineForm"
                    label={props.dataItem?.machine_name}
                  />
                ) : (
                  props.dataItem?.machine_name
                )}
              </td>
            )}
          />
          <Column
            field="description"
            title="Description"
            cell={(props: any) => (
              <td>
                <div
                  className="remarks-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      expandedRow === props.dataItem?.id
                        ? props.dataItem?.description
                        : truncateRemarks(props.dataItem?.description),
                  }}
                />
                {props.dataItem?.description.length > 100 && (
                  <span
                    className="readmore-btn"
                    onClick={() =>
                      setExpandedRow(
                        expandedRow === props.dataItem?.id
                          ? null
                          : props.dataItem?.id
                      )
                    }
                  >
                    {expandedRow === props.dataItem?.id
                      ? "Read Less"
                      : "Read More"}
                  </span>
                )}
              </td>
            )}
          />
          <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="machine_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteStateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const MachineID = useAppSelector((state) => state.machine.MachineID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteMachine(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllMachine());
        dispatch(closeDialog());
        dispatch(clearMachineID());
      } else {
        dispatch(getAllMachine());
        dispatch(closeDialog());
        dispatch(clearMachineID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteMachine" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Machine?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Machine?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(MachineID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Machine;
