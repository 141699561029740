import React, { useEffect, useRef } from "react";
import { getSalaryRegisterReport } from "../services/reports.services";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  COMPANY_ADDRESS,
  COMPANY_NAME,
  MONTHS_MAP,
} from "../../../_contstants/common";
import { convertToWords } from "../../../_helper/helper";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";

const SalarySlips = () => {
  const state = JSON.parse(sessionStorage.getItem("salaryslipsState") || "{}");
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);
  const SalarySlipsList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const fetchReport = async () => {
    const payload = {
      location: state.location,
      payout_month_year: `${String(state.payout_month).padStart(2, "0")}-${
        state.payout_year
      }`,
    };
    await dispatch(getSalaryRegisterReport(payload));
    window.print();
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("salaryslipsState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const style: Record<string, React.CSSProperties> = {
    headingText: {
      fontSize: "14px",
      fontWeight: "bold",
      margin: 0,
    },
    headingTextSub: {
      fontSize: "12px",
      margin: 0,
    },
    hr: { margin: "1rem 0", borderTop: "1px dashed #000" },
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <div>
      <ButtonWithLoading
        buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
        label={"Print"}
        className="no-visible-print-btn"
        type="button"
        onClick={() => window.print()}
      />
      {SalarySlipsList && SalarySlipsList.length > 0 && (
        <>
          {SalarySlipsList.map((slip: any, index: number) => (
            <>
              <div key={index}>
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    <p style={style.headingText}> {COMPANY_NAME}</p>
                    <p style={style.headingTextSub}>{COMPANY_ADDRESS}</p>
                  </div>
                  <div className="text-end">
                    <p style={style.headingText}>
                      For The Month Of {MONTHS_MAP[state.payout_month]} -{" "}
                      {state.payout_year}
                    </p>
                    <p style={style.headingText}>Salary Slip</p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginBottom: 10,
                      }}
                    >
                      Form IV B [Rule 26(2) (b)]
                    </p>
                  </div>
                </div>

                <table className="salaryslipprinttable ">
                  <tr>
                    <td colSpan={3}>
                      <table
                        className="empprintabledetails border-0"
                        style={{ lineHeight: "22px" }}
                      >
                        <tr>
                          <td>Emp.Id</td>
                          <td>:- </td>
                          <td
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {slip?.employee_code_string}
                          </td>
                        </tr>
                        <tr>
                          <td>Emp. Name</td>
                          <td>:- </td>
                          <td>
                            <b
                              style={{
                                textTransform: "uppercase",
                              }}
                            >
                              {slip?.employee_name}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Designation</td>
                          <td>:- </td>
                          <td
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {slip?.designation_name}
                          </td>
                        </tr>
                        <tr>
                          <td>Department</td>
                          <td>:- </td>
                          <td
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {slip?.department_name}
                          </td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td>:- </td>
                          <td
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {slip?.location}
                          </td>
                        </tr>
                      </table>
                    </td>
                    {/* FINANCIAL DETAILS */}
                    <td colSpan={3}>
                      <table
                        className="empprintabledetails border-0"
                        style={{ lineHeight: "16px" }}
                      >
                        <tr>
                          <td>PF No</td>
                          <td>:- </td>
                          <td>{slip?.pf_no}</td>
                        </tr>
                        <tr>
                          <td>UAN No</td>
                          <td>:- </td>
                          <td>{slip?.uan_no}</td>
                        </tr>
                        <tr>
                          <td>ESI No</td>
                          <td>:- </td>
                          <td>{slip?.est_no}</td>
                        </tr>
                        <tr>
                          <td>Bank</td>
                          <td>:- </td>
                          <td>{slip?.bank_name}</td>
                        </tr>
                        <tr>
                          <td>A/c No</td>
                          <td>:- </td>
                          <td>{slip?.account_no}</td>
                        </tr>
                        <tr>
                          <td>IFSC</td>
                          <td>:- </td>
                          <td>{slip?.ifsc_code}</td>
                        </tr>
                        <tr>
                          <td>DOJ</td>
                          <td>:- </td>
                          <td>{slip?.date_of_joining}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p> WORKING DETAILS</p>
                    </th>
                    <th colSpan={3}>
                      <p> EARNNING DETAILS</p>
                    </th>
                    <th colSpan={2}>
                      <p> DEDUCTION DETAILS</p>
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Earnings</th>
                    <th>Actual</th>
                    <th>Payable</th>
                    <th>Deduction</th>
                    <th>Amount</th>
                  </tr>
                  <tr>
                    <td>
                      <div className="attendance-summary">
                        <div className="attendance-item">
                          <span>WD</span>
                          <span className="value">
                            {Number(slip?.working_days || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>WO</span>
                          <span className="value">
                            {Number(slip?.weekly_off || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>PH</span>
                          <span className="value">
                            {Number(slip?.ph || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>PD</span>
                          <span className="value">
                            {Number(slip?.present_days || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>CL</span>
                          <span className="value">
                            {Number(slip?.casual_leave || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>PL</span>
                          <span className="value">
                            {Number(slip?.pl || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>SL</span>
                          <span className="value">
                            {Number(slip?.sl || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>Coff</span>
                          <span className="value">
                            {Number(slip?.coff || 0)?.toFixed(2)}
                          </span>
                        </div>
                        <div className="attendance-item">
                          <span>LWP</span>
                          <span className="value">
                            {Number(slip?.lwp || 0)?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        className="attendance-summary"
                        style={{ lineHeight: "16px" }}
                      >
                        <p>Basic</p>
                        <p>DA</p>
                        <p>HRA</p>
                        <p>WASH</p>
                        <p>PRO.INS.ALL</p>
                        <p>ADV. BONUS</p>
                        <p>ADV. LEAVE</p>
                        <p>ADV. STD. BE</p>
                        <p>FOOD</p>
                        <p>OTHER EARNING</p>
                        <br />
                        <br />
                      </div>
                    </td>

                    {/* Render Actual Values */}
                    <td>
                      <div
                        className="attendance-summary printtableamout"
                        style={{ lineHeight: "16px" }}
                      >
                        <p>{Number(slip?.actual_basic || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.actual_da || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.actual_hra || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.actual_wash || 0)?.toFixed(2)}</p>
                        <p>
                          {Number(slip?.actual_prod_ins_all || 0)?.toFixed(2)}
                        </p>
                        <p>{Number(slip?.actual_adv_bonus || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.actual_adv_leave || 0)?.toFixed(2)}</p>
                        <p>
                          {Number(slip?.actual_adv_std_be || 0)?.toFixed(2)}
                        </p>
                        <p>{Number(slip?.actual_food || 0)?.toFixed(2)}</p>
                        <p>
                          {Number(slip?.actual_other_earning || 0)?.toFixed(2)}
                        </p>
                      </div>
                    </td>

                    {/* Render Payable Values */}
                    <td>
                      <div
                        className="attendance-summary printtableamout"
                        style={{ lineHeight: "16px" }}
                      >
                        <p>{Number(slip?.basic || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.da || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.hra || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.wash || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.prod_ins_all || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.adv_bonus || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.adv_leave || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.adv_std_be || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.food || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.other_earning || 0)?.toFixed(2)}</p>
                      </div>
                    </td>
                    <td>
                      <div
                        className="attendance-summary"
                        style={{ lineHeight: "16px" }}
                      >
                        <p>P.F</p>
                        <p>ESI</p>
                        <p>P.T</p>
                        <p>I.T</p>
                        <p>L.W.F</p>
                        <p>Advance</p>
                        <p>Loan</p>
                        <p>TDS</p>
                        <p>Other Deduction</p>
                      </div>
                    </td>
                    <td>
                      <div
                        className="attendance-summary printtableamout"
                        style={{ lineHeight: "16px" }}
                      >
                        <p>{Number(slip?.pf || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.esi || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.pt || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.it || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.lwf || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.advance || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.loan || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.tds || 0)?.toFixed(2)}</p>
                        <p>{Number(slip?.other || 0)?.toFixed(2)}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="attendance-summary">
                        <div className="attendance-item">
                          <span className="label">TOTAL</span>
                          <span className="value">
                            {Number(
                              slip?.present_days +
                                slip?.weekly_off +
                                slip?.casual_leave || 0
                            )?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="label">Gross Income</span>
                    </td>
                    <td>
                      <p className="label printtableamout">
                        {Number(
                          slip?.actual_basic +
                            slip?.actual_da +
                            slip?.actual_hra +
                            slip?.actual_wash +
                            slip?.actual_prod_ins_all +
                            slip?.actual_adv_bonus +
                            slip?.actual_adv_leave +
                            slip?.actual_adv_std_be +
                            slip?.actual_food || 0
                        )?.toFixed(2)}
                      </p>
                    </td>
                    <td>
                      <p className="label printtableamout">
                        {Number(slip?.total_income || 0)?.toFixed(2)}
                      </p>
                    </td>
                    <td>
                      <span className="label">Gross Deduction</span>
                    </td>
                    <td>
                      <p className="label printtableamout">
                        {Number(slip?.total_deduction || 0)?.toFixed(2)}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={4}
                      style={{ padding: "5px", fontSize: "12px" }}
                    >
                      Rupees:
                      <em>
                        {convertToWords(Number(slip?.payable_amount || 0))}
                      </em>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <span className="label">Net Amount Rs.</span>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <p className="label printtableamout">
                        {Number(slip?.payable_amount || 0).toFixed(2)}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <div className="attendance-summary">
                        <div
                          className="attendance-item"
                          style={{ padding: "5px 0", fontSize: "12px" }}
                        >
                          <p style={{ whiteSpace: "nowrap" }}>
                            P.F as PF Department(12%+3.67%) :-{" "}
                            {slip?.pf > 0
                              ? Number(
                                  Math.round((slip?.basic * 15.67) / 100) || 0
                                )
                              : 0}
                          </p>
                          <p style={{ whiteSpace: "nowrap" }}>
                            This is computer generated statement hence does not
                            require a signature.
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              {index % 2 === 0 ? <hr style={style.hr} /> : ""}
              {/* <hr style={style.hr} /> */}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default SalarySlips;
