import React, { useCallback, useEffect } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Stepper,
  StepperChangeEvent,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import {
//   clearActiveStep,
//   clearemployeeInformation,
//   setActiveStep,
// } from "./employeeSlice";
import { useLocation } from "react-router-dom";
import {
  setActiveStep,
  clearActiveStep,
  clearEmployeeDetails,
} from "./employeeSlice";
import EmployeeInformation from "./employeeSteps/EmployeeInformation";
import AccountAndStatutory from "./employeeSteps/AccountAndStatutory";
import FamilyDetails from "./employeeSteps/FamilyDetails";
import DocumentAttachments from "./employeeSteps/DocumentAttachments";
import EducationDetails from "./employeeSteps/EducationDetails";
import ExperienceDetails from "./employeeSteps/ExperienceDetails";
import SalaryStructure from "./employeeSteps/SalaryStructure";

type ActiveStepProps = {
  step: number;
};

const steps = [
  { label: "Employee Information", isValid: undefined },
  { label: "Account & Statutory", isValid: undefined },
  { label: "Family Details", isValid: undefined },
  { label: "Document Attachment", isValid: undefined },
  { label: "Education Details", isValid: undefined },
  { label: "Experience Details", isValid: undefined },
  { label: "Salary Structure", isValid: undefined },
];

const CreateEmployee: React.FC = () => {
  const dispatch = useAppDispatch();
  const ActiveStep = useAppSelector((state) => state.employee.ActiveStep);
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(clearActiveStep());
      dispatch(clearEmployeeDetails());
    };
  }, []);

  const renderActiveStep: React.FC<ActiveStepProps> = useCallback(
    ({ step }) => {
      switch (step) {
        case 0:
          return <EmployeeInformation />;
        case 1:
          return <AccountAndStatutory />;
        case 2:
          return <FamilyDetails />;
        case 3:
          return <DocumentAttachments />;
        case 4:
          return <EducationDetails />;
        case 5:
          return <ExperienceDetails />;
        case 6:
          return <SalaryStructure />;
        default:
          return <EmployeeInformation />;
      }
    },
    [ActiveStep]
  );

  const handleChange = (e: StepperChangeEvent) => {
    if (location.pathname !== "/employee/create" || e?.value === 0) {
      dispatch(setActiveStep(e.value));
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      <GridLayoutItem
        style={{
          padding: "15px 0",
        }}
      >
        <Stepper
          className="emplooye-steper"
          value={ActiveStep}
          onChange={handleChange}
          items={steps}
        />
      </GridLayoutItem>
      <GridLayoutItem>{renderActiveStep({ step: ActiveStep })}</GridLayoutItem>
    </GridLayout>
  );
};

export default CreateEmployee;
