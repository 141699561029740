import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createEmployeeLeave,
  getEmployeeLeaveByID,
  updateEmployeeLeave,
  uploadEmployeeLeaveAttachment,
} from "./services/employeeLeave.services";
import {
  clearEmployeeLeaveDetails,
  setCCEmployeeList,
} from "./employeeLeaveSlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { ILeave } from "../leave/leaveModel";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormTextField from "../../components/formFields/FormTextField";
import { IEmployee } from "../employee/employeeModel";
import FormTextArea from "../../components/formFields/FormTextArea";
import moment from "moment";
import { getAllActiveLeaves } from "../leave/services/leave.services";
import { getAllActiveEmployee } from "../employee/services/employee.services";
import FormMultiSelectionSuggestionFiled from "../../components/formFields/FormMultiSelectionSuggestionFiled";
import FormAttachmentUpload from "../../components/formFields/FormAttachmentUpload";
import { daysCountInRange } from "../../_helper/helper";
interface CCToChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const CCToChangeWatcher: React.FC<CCToChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const FromDate = formRenderProps.valueGetter("from_date");
  const ToDate = formRenderProps.valueGetter("to_date");

  useEffect(() => {
    formRenderProps.onChange("days", {
      value:
        daysCountInRange(
          moment(FromDate).format("YYYY-MM-DD"),
          moment(ToDate).format("YYYY-MM-DD")
        ) || 0,
    });
  }, [FromDate, ToDate]);

  const selectedValue = formRenderProps.valueGetter("ccto");
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const CCEmployeeList = useAppSelector(
    (state) => state.employeeLeave.CCEmployeeList
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeCCEmployeeList = async () => {
      if (EmployeeList.length > 0) {
        const employeeList = EmployeeList?.map((employee) => ({
          value: employee.email,
          label: employee.email,
        }));
        dispatch(setCCEmployeeList(employeeList));
        return;
      }

      try {
        const res = await dispatch(getAllActiveEmployee());
        if (res.meta.requestStatus === "fulfilled") {
          const employeeList = res.payload?.map((employee: any) => ({
            value: employee.email,
            label: employee.email,
          }));
          dispatch(setCCEmployeeList(employeeList));
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    initializeCCEmployeeList();
  }, []);

  useEffect(() => {
    if (selectedValue?.length > 0) {
      const newItems = selectedValue
        .filter((email: string) => {
          return !CCEmployeeList?.some(
            (item) => item.value?.toLowerCase() === email?.toLowerCase()?.trim()
          );
        })
        .map((email: string) => {
          return { value: email?.trim(), label: email?.trim() };
        });

      if (newItems.length > 0) {
        dispatch(setCCEmployeeList([...CCEmployeeList, ...newItems]));
      }
    }
  }, [selectedValue, CCEmployeeList]);

  return null;
};

const CreateEmployeeLeave: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const EmployeeLeaveDetail = useAppSelector(
    (state) => state.employeeLeave.EmployeeLeaveDetail
  );
  const LeaveList = useAppSelector((state) => state.leave.LeaveList);
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const [formKey, setFormKey] = React.useState(1);

  const employee_leave_guid = location.state?.employee_leave_guid;
  const gridRef = useRef<any>(null);

  const CCEmployeeList = useAppSelector(
    (state) => state.employeeLeave.CCEmployeeList
  );
  useEffect(() => {
    dispatch(getAllActiveLeaves());

    return () => {
      dispatch(clearEmployeeLeaveDetails());
    };
  }, []);

  const formLoading = useAppSelector(
    (state) => state.employeeLeave.formLoading
  );

  useEffect(() => {
    if (employee_leave_guid) {
      dispatch(getEmployeeLeaveByID(employee_leave_guid));
    }
  }, [employee_leave_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [EmployeeLeaveDetail]);

  const handleSubmit = async (values: any) => {
    const formdata = {
      employee_id: values?.employee_id ?? localStorage.getItem("UserID"),
      leave_id: values?.leave_id,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
      from_session: values?.from_session,
      to_session: values?.to_session,
      days:  `${values?.days}`,
      ccto: values?.ccto ? values?.ccto.join(",") : "",
      apply_to_reporting_id: values?.apply_to_reporting_id,
      reason: values?.reason,
    };
    const attachment = values?.attachment;
    if (employee_leave_guid) {
      try {
        const editPayload = {
          employee_leave_guid: employee_leave_guid,
          ...formdata,
        };
        const response = await dispatch(updateEmployeeLeave(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            attachment &&
            typeof attachment !== "string" &&
            attachment.length > 0 &&
            employee_leave_guid
          ) {
            const files = new FormData();
            let binaryDoc;
            binaryDoc = attachment[0]?.getRawFile();
            files.append("employee_leave_guid", employee_leave_guid);
            files.append("attachment", binaryDoc);
            await dispatch(uploadEmployeeLeaveAttachment(files));
          }
          navigate("/employeeleave");
          dispatch(closed());
          dispatch(clearEmployeeLeaveDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const response = await dispatch(createEmployeeLeave(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            attachment &&
            typeof attachment !== "string" &&
            attachment.length > 0 &&
            response?.payload?.employee_leave_guid
          ) {
            const files = new FormData();
            let binaryDoc;
            binaryDoc = attachment[0]?.getRawFile();
            files.append(
              "employee_leave_guid",
              response?.payload?.employee_leave_guid
            );
            files.append("attachment", binaryDoc);
            await dispatch(uploadEmployeeLeaveAttachment(files));
          }

          navigate("/employeeleave");
          dispatch(closed());
          dispatch(clearEmployeeLeaveDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/employeeleave");
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={EmployeeLeaveDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {employee_leave_guid ? "Update Leave" : "Apply Leave"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="leave_id"
                    label="Leave Type"
                    placeholder="Select Leave Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={LeaveList.map((leaveType: ILeave) => {
                      return {
                        value: leaveType?.id,
                        label: leaveType?.leave_type,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem className="d-flex justify-content-between gap-3">
                  <Field
                    name="from_date"
                    label="From Date"
                    format={"dd/MM/yyyy"}
                    wrapperStyle={{ width: "100%" }}
                    minDate={moment().toDate()}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  <Field
                    name="to_date"
                    label="To Date"
                    format={"dd/MM/yyyy"}
                    wrapperStyle={{ width: "100%" }}
                    disabled={!formRenderProps.valueGetter("from_date")}
                    minDate={formRenderProps.valueGetter("from_date")}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem className="d-flex justify-content-between gap-3">
                  <Field
                    name="from_session"
                    label="From Session"
                    placeholder="From"
                    wrapperStyle={{ width: "100%" }}
                    component={FormTextField}
                    // validator={emailValidator}
                  />
                  <Field
                    name="to_session"
                    label="To Session"
                    wrapperStyle={{ width: "100%" }}
                    placeholder="To"
                    component={FormTextField}
                    // validator={emailValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="days"
                    label="Days"
                    placeholder="Days"
                    component={FormTextField}
                    // validator={emailValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="apply_to_reporting_id"
                    label="Reporting To"
                    placeholder="Select Reporting Person"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={EmployeeList.map((emp: IEmployee) => {
                      return {
                        value: emp?.id,
                        label: emp?.name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="ccto"
                    label="CC To"
                    placeholder="Add Employee Email"
                    component={FormMultiSelectionSuggestionFiled} // Pass the MultiSelectField component here
                    validator={requiredValidator}
                    size={"medium"}
                    options={CCEmployeeList.map((emp: any) => {
                      return {
                        value: emp?.value,
                        label: emp?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="reason"
                    label="Reason"
                    placeholder="Write reason.."
                    component={FormTextArea}
                    // validator={emailValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex" }}>
                  <Field
                    imageWidth={60}
                    imageHeight={60}
                    name={`attachment`}
                    label="Attachment"
                    type="file"
                    accept="image/*,application/pdf"
                    component={FormAttachmentUpload}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <CCToChangeWatcher formRenderProps={formRenderProps} />
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!employee_leave_guid ? "Save" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                    loading={formLoading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateEmployeeLeave;
