import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import {
  requiredValidator,
  requiredWOValueValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import { IWarehouse } from "../warehouse/warehouseModel";
import { setItemDeleteIndex } from "./itemSlice";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";

const ItemWarehouseArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const item_guid = location.state?.item_guid;

  const ItemDeleteIndex = useAppSelector((state) => state.item.ItemDeleteIndex);

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("itemDeleteDialog"));
    dispatch(setItemDeleteIndex(index));
  };

  useEffect(() => {
    if (!item_guid || fieldArrayRenderProps?.value?.length < 1) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setItemDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_qty: null,
        grn_no: null,
        warehouse_id: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              padding: 10,
            }}
            gap={{ rows: 10, cols: "1%" }}
            cols={[
              { width: "30%" },
              { width: "30%" },
              { width: "30%" },
              { width: "7%" },
            ]}
          >
            <GridLayoutItem>Warehouse</GridLayoutItem>
            <GridLayoutItem>GRN No</GridLayoutItem>
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "itemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(ItemDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (ItemDeatails: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: "100%" }}>
                <GridLayout
                  style={{ padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: "1%" }}
                  cols={[
                    { width: "30%" },
                    { width: "30%" },
                    { width: "30%" },
                    { width: "7%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`ItemDeatails.${index}.warehouse_id`}
                      placeholder="warehouse Name"
                      component={FormSelectionField}
                      // validator={requiredValidator}
                      validator={
                        fieldArrayRenderProps.formRenderProps.valueGetter(
                          `ItemDeatails.${index}.item_qty`
                        ) && requiredValidator
                      }
                      options={warehouseList?.map((warehouse: IWarehouse) => {
                        return {
                          value: warehouse?.id,
                          label: warehouse?.warehouse_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`ItemDeatails.${index}.grn_no`}
                      placeholder="GRN No"
                      component={FormTextField}
                      // validator={requiredValidator}
                      validator={
                        fieldArrayRenderProps.formRenderProps.valueGetter(
                          `ItemDeatails.${index}.item_qty`
                        ) && requiredValidator
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`ItemDeatails.${index}.item_qty`}
                      placeholder="0"
                      validator={requiredWOValueValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem colSpan={5} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
                disabled={
                  fieldArrayRenderProps.value[
                    fieldArrayRenderProps.value?.length - 1
                  ]?.item_qty
                    ? false
                    : true
                }
              >
                Add Row
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ItemWarehouseArray;
