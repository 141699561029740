import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearDistributorCurrentStockDetails,
  setDistributorCurrentStockFormDetails,
} from "../reports/reportsSlice";
import { clearAccountList } from "../account/accountSlice";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import moment from "moment";
import ShadowCard from "../../components/common/ShadowCard";
import { getDistributorCurrentStockReportByID } from "../reports/services/reports.services";
import { Typography } from "@progress/kendo-react-common";
import { getAllAccountIncremental } from "../account/services/account.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { Button } from "@progress/kendo-react-buttons";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../_contstants/common";
import Logo from "../../assets/Images/logo.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FormDatePicker from "../../components/formFields/FormDateField";

const DistributorCurrentStock: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const DistributorCurrentStockDetails = useAppSelector(
    (state) => state.reports.DistributorCurrentStockDetails
  );

  useEffect(() => {
    return () => {
      dispatch(clearDistributorCurrentStockDetails());
      dispatch(clearAccountList());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      accountid: values?.accountid ? values?.accountid : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : "",
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : "",
    };
    dispatch(getDistributorCurrentStockReportByID(payload));
    dispatch(setDistributorCurrentStockFormDetails(payload));
  };

  return (
    <div>
      <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <ShadowCard>
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "100%" }}>
                  <GridLayout
                    style={{ marginRight: 10 }}
                    gap={{ rows: 10, cols: 10 }}
                    cols={[{ width: "40%" }, { width: "60%" }]}
                  >
                    <GridLayoutItem colSpan={2}>
                      <Typography.h4
                        style={{ marginBottom: 0, marginLeft: "3px" }}
                      >
                        Distributor Current Stock Report
                      </Typography.h4>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="accountid"
                        label="Distributor"
                        placeholder="Type here to search distributor..."
                        component={FormIncrementalSearch}
                        validator={requiredValidator}
                        astrike={true}
                        fetchIncrementalData={(search: any) =>
                          handleVendorSearchChange(
                            search,
                            `vendor_options`,
                            formRenderProps
                          )
                        }
                        options={
                          formRenderProps
                            .valueGetter("vendor_options")
                            ?.map((item: any) => {
                              return {
                                value: item.id,
                                label: item.account_name,
                              };
                            }) || []
                        }
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                      <Field
                        wrapperStyle={{ width: "100%" }}
                        name="from_date"
                        label="From Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                      />
                      <Field
                        wrapperStyle={{ width: "100%" }}
                        name="to_date"
                        label="To Date"
                        format="dd/MM/yyyy"
                        minDate={moment(
                          formRenderProps.valueGetter("from_date")
                        ).toDate()}
                        component={FormDatePicker}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <ButtonWithLoading
                        buttonStyle={{ marginTop: 35 }}
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
          </ShadowCard>
          {loading ? (
            <LoadingPanel gridRef={gridRef} />
          ) : (
            DistributorCurrentStockDetails &&
            DistributorCurrentStockDetails.accountid && (
              <ShadowCard style={{ marginTop: 10 }}>
                <DistributorCurrentStockGridCommponent />
              </ShadowCard>
            )
          )}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

const DistributorCurrentStockGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  //   const loading = useAppSelector((state) => state.reports.loading);
  const DistributorCurrentStockDetails = useAppSelector(
    (state) => state.reports.DistributorCurrentStockDetails
  );
  const DistributorCurrentStockFormDetails = useAppSelector(
    (state) => state.reports.DistributorCurrentStockFormDetails
  );
  let grandTotal = 0;
  DistributorCurrentStockDetails?.distributer_stock &&
    DistributorCurrentStockDetails?.distributer_stock.length > 0 &&
    DistributorCurrentStockDetails?.distributer_stock?.map((item: any) => {
      item?.distributer_stock_list &&
        item?.distributer_stock_list?.length > 0 &&
        item?.distributer_stock_list?.map((subItem: any) => {
          grandTotal += +subItem?.amount;
        });
    });

  const printTable = (DistributorCurrentStockDetails: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    const data: any[] = DistributorCurrentStockDetails?.distributer_stock;
    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Distributor Current Stock Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="border-right:1px solid white;">${DistributorCurrentStockDetails?.account_name}</td>`;
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:40px;">Sr. No.</th>';
      content += "<th>Item Name</th>";
      content += "<th>MRP</th>";
      content += "<th>Rate</th>";
      content += "<th>Qty</th>";
      content += "<th>Per</th>";
      content += "<th>Amount</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      if (data && data?.length > 0) {
        data?.forEach((item: any) => {
          let total = 0;
          content += `<tr style="background:whitesmoke;">`;
          content += `<td colSpan="7" style="font-weight:600;">${item.item_group}</td>`;
          content += `</tr>`;

          if (
            item?.distributer_stock_list &&
            item?.distributer_stock_list?.length > 0
          ) {
            item.distributer_stock_list?.forEach(
              (subitem: any, index: number) => {
                total += +subitem?.amount;
                content += `<tr>`;
                content += `<td style="text-align:center;">${index + 1}</td>`;
                content += `<td>${subitem?.product_name}</td>`;
                content += `<td>Rs. ${subitem?.mrp}</td>`;
                content += `<td style="text-align:end;">${formatIndianNumber(
                  subitem?.rate || 0
                )}</td>`;
                content += `<td style="text-align:end;">${formatIndianNumberForQty(
                  subitem?.net_quantity || 0
                )}</td>`;
                content += `<td style="text-align:center;">${subitem?.unit_name}</td>`;
                content += `<td style="text-align:end;">${formatIndianNumber(
                  subitem?.amount || 0
                )}</td>`;
                content += "</tr>";
              }
            );
          }

          content += `<tr style="text-align:end; font-weight:600;">`;
          content += `<td colSpan="6">Total</td>`;
          content += `<td>${formatIndianNumber(total)}</td>`;
          content += `</tr>`;
        });
      }
      content += `<tr style="text-align:end; font-weight:600; background:whitesmoke;">`;
      content += `<td colSpan="6">Grand Total</td>`;
      content += `<td>${formatIndianNumber(grandTotal)}</td>`;
      content += `</tr>`;

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; max-width:1140px; margin: 0 auto;}
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".currentstock-print-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "DistributorCurrentStockReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      data,
      `${DistributorCurrentStockDetails?.account_name} Current Stock Report`
    );
  };

  return (
    <>
      {DistributorCurrentStockDetails &&
      DistributorCurrentStockDetails?.accountid ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Distributor Current Stock List{" "}
              {(DistributorCurrentStockFormDetails?.from_date ||
                DistributorCurrentStockFormDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {DistributorCurrentStockFormDetails?.from_date
                    ? moment(
                        DistributorCurrentStockFormDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {DistributorCurrentStockFormDetails?.from_date &&
                    DistributorCurrentStockFormDetails?.to_date &&
                    " - "}
                  {DistributorCurrentStockFormDetails?.to_date
                    ? moment(
                        DistributorCurrentStockFormDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
              {/* {DistributorCurrentStockDetails?.date && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {moment(
                    DistributorCurrentStockDetails?.date,
                    "YYYY-MM-DD"
                  ).format("DD/MM/YYYY")}
                  {")"}
                </span>
              )} */}
            </Typography.h4>
            <div>
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() => printTable(DistributorCurrentStockDetails)}
                >
                  Print
                </Button>
              )}
              {isExcelAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                  style={{ marginLeft: 5 }}
                >
                  Excel Export
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <table
              cellSpacing={1}
              cellPadding={2}
              className="table table-bordered w-100  currentstock-print-table"
              style={{ marginTop: 1 }}
            >
              <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                <th style={{ width: "10%" }}>Sr No.</th>
                <th style={{ width: "40%" }}>Description Of Goods</th>
                <th style={{ width: "10%" }}>MRP</th>
                <th style={{ width: "10%" }}>Rate</th>
                <th style={{ width: "10%" }}>Quantity</th>
                <th style={{ width: "10%" }}>Per</th>
                <th style={{ width: "10%" }}>Amount</th>
              </tr>
              {DistributorCurrentStockDetails?.distributer_stock &&
                DistributorCurrentStockDetails?.distributer_stock.length > 0 &&
                DistributorCurrentStockDetails?.distributer_stock?.map(
                  (item: any) => {
                    let totalAmount = 0;
                    return (
                      <>
                        <tr style={{ background: "whitesmoke" }}>
                          <td
                            className="fw-600"
                            colSpan={7}
                            style={{ fontSize: 12 }}
                          >
                            {item?.item_group}
                          </td>
                        </tr>
                        {item?.distributer_stock_list &&
                          item?.distributer_stock_list?.length > 0 &&
                          item?.distributer_stock_list?.map(
                            (subitem: any, index: number) => {
                              totalAmount += +subitem?.amount;
                              return (
                                <tr>
                                  <td className="text-center">{index + 1}</td>
                                  <td>{subitem?.product_name}</td>
                                  <td>Rs. {subitem?.mrp || 0}</td>
                                  <td className="text-end">
                                    {formatIndianNumber(subitem?.rate || 0)}
                                  </td>
                                  <td className="text-end">
                                    {formatIndianNumberForQty(
                                      subitem?.net_quantity || 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {subitem?.unit_name}
                                  </td>
                                  <td className="text-end">
                                    {formatIndianNumber(subitem?.amount || 0)}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        <tr className="text-end fw-600">
                          <td colSpan={6}>Total</td>
                          <td>{formatIndianNumber(totalAmount)}</td>
                        </tr>
                      </>
                    );
                  }
                )}
              <tr className="text-end fw-600" style={{ background: "#ffe1e1" }}>
                <td colSpan={6}>Grand Total</td>
                <td>{formatIndianNumber(grandTotal)}</td>
              </tr>
            </table>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default DistributorCurrentStock;
