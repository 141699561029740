import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getDeliveryDetailsByID } from "./services/driverVisit.services";
import { clearDriverVisitDetails } from "./driverVisitSlice";
import {
  convertToWords,
  formatIndianNumber,
  getAccountingYear,
} from "../../_helper/helper";
import moment from "moment";

const DriverVisitVoucherPrint: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = params?.id;
  const DriverVisitDetails = useAppSelector(
    (state) => state.drivervisit.DriverVisitDetails
  );

  useEffect(() => {
    if (id) {
      const handleDriverVisitPrint = async () => {
        const res = await dispatch(getDeliveryDetailsByID(+id));
        if (res.meta.requestStatus === "fulfilled") {
          window.print();
        }
      };
      handleDriverVisitPrint();
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearDriverVisitDetails());
    };
  }, []);

  return (
    <div className="container p-4 drivervisit-print-page">
      <p className="text-center">
        <b>Harihar Foods Pvt. Ltd. {getAccountingYear()}</b>
        <br />
        519/1, Nr Ramol Police Chowky
        <br />
        Phase IV, G.I.D.C. Vatva, Ahmedabad 382445
        <br />
        Tel (Off) 079-25841773/25840855 <br />
        FSSAI No: 10012021000359 <br />
        State Name: Gujarat, Code: 24 <br />
        CIN: U15499GJ1994PTC021520 <br />
        E-Mail: marketing@shreeganeshmasala.com
      </p>
      <br />
      <p className="text-center">
        <b>Petty Cash Pymt Voucher</b>
      </p>
      <div className="d-flex justify-content-between">
        <p>
          No.:{" "}
          <span className="fw-bold">
            {DriverVisitDetails?.voucher_no_string}
          </span>
        </p>
        <p>
          Dated :{" "}
          <span className="fw-bold">
            {DriverVisitDetails?.session_end
              ? moment(
                  DriverVisitDetails?.session_end,
                  "DD MMM YYYY HH:mm"
                ).format("DD-MMM-YYYY")
              : ""}
          </span>
        </p>
      </div>
      <table className="drivervisit-print-table">
        <tr
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <td style={{ width: "80%" }}>Particulars</td>
          <td
            style={{
              textAlign: "end",
              width: "20%",
              borderLeft: "1px solid black",
            }}
          >
            Amount
          </td>
        </tr>
        <tr>
          <td className="fw-bold">Account :</td>
          <td style={{ borderLeft: "1px solid black" }}></td>
        </tr>
        {DriverVisitDetails?.diesel && DriverVisitDetails?.diesel > 0 ? (
          <tr>
            <td className="ps-3">Diesel Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.diesel)}
            </td>
          </tr>
        ) : null}
        {DriverVisitDetails?.tea_coffee &&
        DriverVisitDetails?.tea_coffee > 0 ? (
          <tr>
            <td className="ps-3">Tea / Coffee Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.tea_coffee)}
            </td>
          </tr>
        ) : null}
        {DriverVisitDetails?.food_snacks &&
        DriverVisitDetails?.food_snacks > 0 ? (
          <tr>
            <td className="ps-3">Food / Snacks Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.food_snacks)}
            </td>
          </tr>
        ) : null}
        {DriverVisitDetails?.toll_tax_fasttag &&
        DriverVisitDetails?.toll_tax_fasttag > 0 ? (
          <tr>
            <td className="ps-3">Toll Tax / Fast Tag Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.toll_tax_fasttag)}
            </td>
          </tr>
        ) : null}
        {DriverVisitDetails?.transport_receipt &&
        DriverVisitDetails?.transport_receipt > 0 ? (
          <tr>
            <td className="ps-3">Transport Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.transport_receipt)}
            </td>
          </tr>
        ) : null}
        {DriverVisitDetails?.misc_exp && DriverVisitDetails?.misc_exp > 0 ? (
          <tr>
            <td className="ps-3">Misc. Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.misc_exp)}
            </td>
          </tr>
        ) : null}
        {DriverVisitDetails?.other_exp && DriverVisitDetails?.other_exp > 0 ? (
          <tr>
            <td className="ps-3">Others Expense</td>
            <td
              className="fw-bold text-end"
              style={{ borderLeft: "1px solid black" }}
            >
              {formatIndianNumber(DriverVisitDetails?.other_exp)}
            </td>
          </tr>
        ) : null}
        <tr>
          <td className="fw-bold pt-3">Through :</td>
          <td style={{ borderLeft: "1px solid black" }}></td>
        </tr>
        <tr>
          <td className="ps-3">Petty Cash</td>
          <td style={{ borderLeft: "1px solid black" }}></td>
        </tr>
        <tr>
          <td className="fw-bold pt-3">On Account of :</td>
          <td style={{ borderLeft: "1px solid black" }}></td>
        </tr>
        {DriverVisitDetails?.remarks ? (
          <tr>
            <td className="ps-3">{DriverVisitDetails?.remarks}</td>
            <td style={{ borderLeft: "1px solid black" }}></td>
          </tr>
        ) : null}
        <tr>
          <td className="fw-bold pt-3">Amount (in words) :</td>
          <td style={{ borderLeft: "1px solid black" }}></td>
        </tr>
        <tr>
          <td className="ps-3">
            {convertToWords(DriverVisitDetails?.approved_amount || 0)}
          </td>
          <td style={{ borderLeft: "1px solid black" }}></td>
        </tr>
        <tr>
          <td></td>
          <td
            className="fw-bold text-end"
            style={{ border: "1px solid black", borderRight: "none" }}
          >
            Rs. {formatIndianNumber(DriverVisitDetails?.approved_amount || 0)}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default DriverVisitVoucherPrint;
