import React, { useEffect, useRef } from "react";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { tokenCheck } from "./services/dashboard.services";
import { LoadingPanel } from "../../components/layout/Loading";

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const loading = useAppSelector((state) => state.dashboard.loading);

  useEffect(() => {
    dispatch(tokenCheck());
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Typography.h4 style={{ margin: "50px", textAlign: "center" }}>
      Welcome to the Shree Ganesh Masala
    </Typography.h4>
  );
};

export default Dashboard;
