import React, { useEffect, useRef } from "react";
// import { getEmployeeRegisterReport } from "../services/reports.services";
import {
  // useAppDispatch,
  useAppSelector,
} from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
// import { MONTHS_MAP } from "../../../_contstants/common";
// import { convertToWords } from "../../../_helper/helper";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../../_contstants/common";

const LeaveWithWagesCardReport = () => {
  const state = JSON.parse(sessionStorage.getItem("wageRegisterState") || "{}");

  // const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);

  const WageRegisterReportList = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportList
  );
  console.log(WageRegisterReportList);

  const fetchReport = async () => {
    const payload = {
      department_id: state?.department_id,
    };
    console.log(payload);

    // await dispatch(getWageRegisterReport(payload));
    // window.print();
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("wageRegisterState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const style: Record<string, React.CSSProperties> = {
    headingText: {
      fontSize: "14px",
      fontWeight: "bold",
      margin: 0,
    },
    headingTextSub: {
      fontSize: "12px",
      margin: 0,
    },
    hr: { margin: "1rem 0", borderTop: "1px dashed #000" },
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {/* {WageRegisterReportList && WageRegisterReportList.length > 0 && ( */}
      <div>
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <div>
          <div style={{ textAlign: "center" }}>
            <p style={style.headingText}>FORM 19</p>
            <p style={style.headingTextSub}>(Prescribed Under rule no 95)</p>
            <p style={style.headingText}>Leave with Wages Card</p>
          </div>

          <div
            className="d-flex justify-content-between align-items-end py-2"
            style={{ borderBottom: "1px solid #ccc" }}
          >
            <div className="text-start" style={{ width: "75%" }}>
              <p style={style.headingText}>{COMPANY_NAME}</p>
              <p style={style.headingTextSub}>{COMPANY_ADDRESS}</p>
            </div>
            <div style={{ width: "25%" }}>
              <p style={style.headingText}>YEAR :- </p>
            </div>
          </div>
          <div
            className="d-flex justify-content-start py-1"
            style={{ fontSize: "12px" }}
          >
            <div
              className="leave-with-wages-card-emp-info"
              style={{ width: "50%" }}
            >
              <table className="empprintabledetails border-0">
                <tr>
                  <td>1. Employee Name</td>
                  <td>:-</td>
                  <td style={{ textTransform: "uppercase" }}>
                    <b>Airtusan ramilaben rajendra</b>
                  </td>
                </tr>
                <tr>
                  <td>2. Sex</td>
                  <td>:-</td>
                  <td>Female</td>
                </tr>
                <tr>
                  <td>3. Father&apos;s/Husband Name</td>
                  <td>:-</td>
                  <td style={{ textTransform: "uppercase" }}>Rajendra</td>
                </tr>
                <tr>
                  <td>4. Sr.No. in Employee&apos;s Name</td>
                  <td>:-</td>
                  <td style={{ textTransform: "uppercase" }}>107</td>
                </tr>
                <tr>
                  <td>5. Department</td>
                  <td>:-</td>
                  <td style={{ textTransform: "uppercase" }}>Production</td>
                </tr>
                <tr>
                  <td>6. Designation</td>
                  <td>:-</td>
                  <td style={{ textTransform: "uppercase" }}>
                    unskilled Worker
                  </td>
                </tr>
              </table>
            </div>
            <div style={{ textAlign: "start", width: "40%" }}>
              <table className="empprintabledetails border-0">
                <tr>
                  <td>7. Employee Join Date</td>
                  <td></td>
                  <td>
                    <b>01/Jan/15</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    8. Date of discharge/dismissal/Quitting
                    employment/superinnuation/death while in service
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    9. Date of payment in lieu of leave with wages due in such
                    case
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    10. Whether leave in accordance with scheme under section
                    79(8) was refused
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <table
            className="wage-register-print-table"
            style={{ border: "1px solid #ccc" }}
          >
            <thead>
              <tr>
                <th rowSpan={3} className="border-bottom-0">
                  Calender year of service (i.e. Previous year) <br />
                </th>
                <th rowSpan={2} colSpan={2}>
                  Leave Due as on 1st January of the year in col. 1
                </th>
                <th colSpan={4} style={{ whiteSpace: "nowrap" }}>
                  Leave availed during the year
                </th>
                <th rowSpan={3} className="border-bottom-0">
                  Leave Refused out of Regular leave mentioned in col
                </th>
                <th colSpan={5}>
                  Number of Working days for computation of leave during the
                  year mention in col 1
                </th>
                <th rowSpan={3} className="border-bottom-0">
                  Regular leave earned for the mentioned in col 1
                </th>
                <th rowSpan={2} colSpan={2}>
                  Balnce of Leave admissible on 1st January of year following
                  the year Mentioned in col - 1
                </th>
                <th rowSpan={3} className="border-bottom-0">
                  Leave period 4+5 <br /> (in days)
                </th>
                <th colSpan={5}>Details of wages paid</th>
              </tr>
              <tr>
                <th rowSpan={2} className="border-bottom-0">
                  Refused
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Regular
                </th>
                <th colSpan={2}>Date</th>
                <th rowSpan={2} className="border-bottom-0">
                  Days Worked
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  LDay off
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Maternity Leave Upto 12 Weeks
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Leave with wages employed
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Total (9 to 12)
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Normal rates of wages excluding any overtime as well as bonus
                  including D.A.
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Cash equlvaing of advantages accuring through consessional
                  sales of food grains and other articles
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Rate of wages for leave with wages paid
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Total Wages paid for the period of leave
                </th>
                <th rowSpan={2} className="border-bottom-0">
                  Signature
                </th>
              </tr>
              <tr>
                <th className="border-bottom-0">Refused</th>
                <th className="border-bottom-0">Regular</th>
                <th className="border-bottom-0">From</th>
                <th className="border-bottom-0">To</th>
                <th className="border-bottom-0">Refused 2+8-4</th>
                <th className="border-bottom-0">
                  Refused <br />
                  3+14-5
                </th>
              </tr>
              <tr className="border-top-0">
                <th className="border-top-0">1</th>
                <th className="border-top-0">2</th>
                <th className="border-top-0">3</th>
                <th className="border-top-0">4</th>
                <th className="border-top-0">5</th>
                <th className="border-top-0">6</th>
                <th className="border-top-0">7</th>
                <th className="border-top-0">8</th>
                <th className="border-top-0">9</th>
                <th className="border-top-0">10</th>
                <th className="border-top-0">11</th>
                <th className="border-top-0">12</th>
                <th className="border-top-0">13</th>
                <th className="border-top-0">14</th>
                <th className="border-top-0">15</th>
                <th className="border-top-0">16</th>
                <th className="border-top-0">17</th>
                <th className="border-top-0">18</th>
                <th className="border-top-0">19</th>
                <th className="border-top-0">20</th>
                <th className="border-top-0">21</th>
                <th className="border-top-0">22</th>
              </tr>
            </thead>
            <tbody>
              {/* {WageRegisterReportList.map((item: any, index: number) => ( */}
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                (item: any, index: number) => (
                  <tr key={index}>
                    <td>JAN&nbsp;&nbsp;2023</td>
                    <td className={item}></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>25.00</td>
                    <td>1.00</td>
                    <td></td>
                    <td></td>
                    <td>26.00</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>365.40</td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              )}
              <tr>
                <td style={{ fontSize: "14px", textAlign: "start" }}>
                  <b>Total</b>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>255.50</td>
                <td>56.50</td>
                <td></td>
                <td></td>
                <td>312.00</td>
                <td>13.00</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default LeaveWithWagesCardReport;
