import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createOrder,
  getAllItemMarginRates,
  getAllOrdersByUserID,
  getOrderByID,
  updateOrder,
} from "./services/order.services";
import { IOrderInitialState } from "./orderModel";

const initialState: IOrderInitialState = {
  loading: false,
  error: "",
  OrderID: null,
  OrderList: [],
  MarginRateList: [],
  OrderDetail: {},
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrderDetails: (state) => {
      state.OrderDetail = initialState.OrderDetail;
    },
    setOrderID: (state, action) => {
      state.OrderID = action.payload;
    },
    clearOrderID: (state) => {
      state.OrderID = initialState.OrderID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllItemMarginRates.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItemMarginRates.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.MarginRateList = action.payload || [];
      }
    );
    builder.addCase(getAllItemMarginRates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MarginRateList = [];
    });
    builder.addCase(getAllOrdersByUserID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOrdersByUserID.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.OrderList = action.payload || [];
      }
    );
    builder.addCase(getAllOrdersByUserID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OrderList = [];
    });

    builder.addCase(createOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createOrder.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateOrder.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getOrderByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getOrderByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.OrderDetail = action.payload;
    });
    builder.addCase(getOrderByID.rejected, (state, action) => {
      state.loading = false;
      state.OrderDetail = initialState.OrderDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearOrderDetails,
  clearOrderID,
  setOrderID,
} = orderSlice.actions;
export default orderSlice.reducer;
