import { createSlice } from "@reduxjs/toolkit";

interface ILayoutInitial {
  loading: boolean;
  error: string;
  isOpen: boolean;
}

const initialState: ILayoutInitial = {
  loading: false,
  error: "",
  isOpen: true,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setIsOpenID: (state, action) => {
      state.isOpen = action.payload;
    },
  },
  //   extraReducers: (builder) => {

  //   },
});

export const { setIsOpenID } = layoutSlice.actions;
export default layoutSlice.reducer;
