import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllOpeningStocks = createAsyncThunk(
  "SaleOrder/FindAllOpenStock",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/SaleOrder/FindAllOpenStock`
      );
      const result = response.data?.Data;
      return result
        ? result?.map((stock: any, index: number) => {
            return {
              uniqueKey: index + 1,
              id: stock?.id ? +stock?.id : null,
              accountid: stock?.accountid ? +stock?.accountid : null,
              date: stock?.date ? stock?.date : "",
              account_name: stock?.account_name ? stock?.account_name : "",
              user_type: stock?.user_type ? stock?.user_type : "",
            };
          })
        : [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Opening Stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createOpeningStock = createAsyncThunk(
  "SaleOrder/InsertOpenStock",
  async (OpenStockData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/InsertOpenStock`,
        OpenStockData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting open stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOpeningStock = createAsyncThunk(
  "SaleOrder/UpdateOpenStock",
  async (OpenStockData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/UpdateOpenStock`,
        OpenStockData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating open stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteOpeningStock = createAsyncThunk(
  "SaleOrder/DeleteOpenStock",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DeleteOpenStock`,
        { id: id }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting opening stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOpeningStockByID = createAsyncThunk(
  "SaleOrder/FindByIDOpenStock",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/FindByIDOpenStock`,
        { id: id }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        accountid: result?.accountid,
        vendor_options: result?.vendor_options,
        date: result?.date ? moment(result?.date, "YYYY-MM-DD").toDate() : "",
        open_stock: result?.open_stock
          ? result?.open_stock?.map((orderitem: any) => {
              return {
                item_group_id: orderitem?.item_group_id
                  ? orderitem?.item_group_id
                  : null,
                item_group: orderitem?.item_group ? orderitem?.item_group : "",
                item_margin_rate: orderitem?.open_stock_list
                  ? orderitem?.open_stock_list?.map((subitem: any) => {
                      return {
                        id: subitem?.id ? +subitem?.id : null,
                        item_id: subitem?.item_id ? +subitem?.item_id : null,
                        item_group_id: subitem?.item_group_id
                          ? +subitem?.item_group_id
                          : null,
                        product_name: subitem?.product_name
                          ? subitem?.product_name
                          : "",
                        mrp: subitem?.mrp ? +subitem?.mrp : null,
                        quantity: subitem?.quantity ? +subitem?.quantity : null,
                        unit_id: subitem?.unit_id ? +subitem?.unit_id : null,
                        unit_name: subitem?.unit_name ? subitem?.unit_name : "",
                        rate: subitem?.rate ? +subitem?.rate : null,
                        amount: subitem?.total_amount
                          ? +subitem?.total_amount
                          : null,
                        // gst_id: subitem?.gst_id ? +subitem?.gst_id : null,
                        // gst: subitem?.gst ? +subitem?.gst : null,
                      };
                    })
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching opening stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllDistributorRetailerMarginRates = createAsyncThunk(
  "ItemGroup/DistributerRetailerMarginRates",
  async (isdistributer: boolean) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/DistributerRetailerMarginRates`,
        {
          isdistributer: isdistributer,
        }
      );
      const result = response.data?.Data;
      return result.map((item: any) => {
        return {
          item_group_id: item?.item_group_id,
          item_group: item?.item_group,
          item_margin_rate: item?.item_margin_rate
            ? item?.item_margin_rate?.map((subitem: any) => {
                return {
                  item_id: subitem?.item_id ? +subitem?.item_id : null,
                  unit_id: subitem?.unit_id ? +subitem?.unit_id : null,
                  unit_name: subitem?.unit_name ? subitem?.unit_name : "",
                  item_group_id: subitem?.item_group_id
                    ? +subitem?.item_group_id
                    : null,
                  product_name: subitem?.product_name
                    ? subitem?.product_name
                    : "",
                  mrp: subitem?.mrp ? +subitem?.mrp : null,
                  rate: subitem?.rate ? +subitem?.rate : null,
                  retail_margin: subitem?.retail_margin
                    ? +subitem?.retail_margin
                    : null,
                  retail_rate_include_gst: subitem?.retail_rate_include_gst
                    ? +subitem?.retail_rate_include_gst
                    : null,
                  retail_sgst: subitem?.retail_sgst
                    ? +subitem?.retail_sgst
                    : null,
                  retail_cgst: subitem?.retail_cgst
                    ? +subitem?.retail_cgst
                    : null,
                  retail_rate_exclude_gst: subitem?.retail_rate_exclude_gst
                    ? +subitem?.retail_rate_exclude_gst
                    : null,
                  distributor_rate_include_gst:
                    subitem?.distributor_rate_include_gst
                      ? +subitem?.distributor_rate_include_gst
                      : null,
                  distributor_sgst: subitem?.distributor_sgst
                    ? +subitem?.distributor_sgst
                    : null,
                  distributor_cgst: subitem?.distributor_cgst
                    ? +subitem?.distributor_cgst
                    : null,
                  distributor_rate: subitem?.distributor_rate
                    ? +subitem?.distributor_rate
                    : null,
                  gst_id: subitem?.gst_id ? +subitem?.gst_id : null,
                  gst: subitem?.gst ? +subitem?.gst : null,
                };
              })
            : [],
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item Margin Rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
