import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { STATUSARRAY } from "../../_contstants/common";
import { clearUnitDetails } from "./unitSlice";
import {
  createUnit,
  findAllActiveUnit,
  getAllUnit,
  getUnitById,
  updateUnit,
} from "./services/unit.services";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";

const CreateUnit: React.FC = () => {
  const formLoading = useAppSelector((state) => state.unit.formLoading);
  const UnitDetail = useAppSelector((state) => state.unit.UnitDetail);
  const UnitGUID = useAppSelector((state) => state.muidrawer.data);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      dispatch(clearUnitDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (UnitGUID) {
      dispatch(getUnitById(UnitGUID));
    }
  }, [UnitGUID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!UnitGUID) {
        const payload = {
          unit_name: values.unit_name,
          unit_code: values.unit_code,
          unit_decimal: values.unit_decimal ? +values.unit_decimal : 0,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createUnit(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearUnitDetails());
          isIndirect ? dispatch(findAllActiveUnit()) : dispatch(getAllUnit());
        }
      } else {
        const payload = {
          unit_guid: UnitGUID,
          unit_name: values.unit_name,
          unit_code: values.unit_code,
          unit_decimal: values.unit_decimal ? +values.unit_decimal : 0,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateUnit(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearUnitDetails());
          isIndirect ? dispatch(findAllActiveUnit()) : dispatch(getAllUnit());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={UnitDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {UnitGUID ? "Update Unit" : "Add Unit"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="unit_name"
                label="Unit Name"
                placeholder="Unit Name"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="unit_code"
                label="Unit Code"
                placeholder="Unit Code"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="unit_decimal"
                label="Unit Decimal"
                placeholder="Unit Decimal"
                component={FormTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={UnitGUID ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateUnit;
