import React, { useCallback, useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearSchemeReportDetails,
  clearSchemeReportList,
  setSchemeReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { Button } from "@progress/kendo-react-buttons";
// import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";
import { getAllAccountIncremental } from "../../account/services/account.services";
import { getSchemeReport } from "../services/reports.services";
import { getAllSchemes } from "../../scheme/services/scheme.services";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IScheme } from "../../scheme/schemeModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_Date", {
      value: todate,
    });
  }, []);

  return null;
};

const SchemeReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const SchemeReportList = useAppSelector(
    (state) => state.reports.SchemeReportList
  );
  const SchemeReportDetails = useAppSelector(
    (state) => state.reports.SchemeReportDetails
  );
  const SchemeList = useAppSelector((state) => state.scheme.SchemeList);

  useEffect(() => {
    dispatch(getAllSchemes());
    return () => {
      dispatch(clearSchemeReportList());
      dispatch(clearSchemeReportDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      scheme_id: values?.scheme_id ? values?.scheme_id : null,
      vendor_id: values?.vendor_id ? values?.vendor_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_Date: values?.to_Date
        ? moment(values?.to_Date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setSchemeReport(payload));
    dispatch(getSchemeReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={SchemeReportDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Scheme Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="scheme_id"
                      label="Scheme"
                      placeholder="Scheme"
                      astrike={true}
                      validator={requiredValidator}
                      component={FormSelectionField}
                      options={SchemeList?.map((scheme: IScheme) => {
                        return {
                          value: scheme?.id,
                          label: scheme?.scheme_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="vendor_id"
                      label="Distributor"
                      placeholder="Type here to search distributor..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleVendorSearchChange(
                          search,
                          `vendor_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("vendor_options")
                          ?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.account_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: 10,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          SchemeReportList &&
          SchemeReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <PurchaseRegisterGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};
const PurchaseRegisterGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const SchemeReportList = useAppSelector(
    (state) => state.reports.SchemeReportList
  );
  const SchemeReportDetails = useAppSelector(
    (state) => state.reports.SchemeReportDetails
  );

  //   const printTable = (data: any[], formRenderProps: any) => {
  //     let printbuttons = document.querySelector(
  //       ".printbuttons"
  //     ) as HTMLElement | null;
  //     let report_container = document.querySelector(
  //       ".report-container"
  //     ) as HTMLElement | null;

  //     if (printbuttons) printbuttons.style.display = "none";
  //     if (report_container) report_container.style.padding = "20px 0";

  //     let tableRows = data.length;
  //     let maxRows = tableRows;
  //     let pageCount = Math.ceil(tableRows / maxRows);
  //     let content = "";

  //     for (let i = 0; i < pageCount; i++) {
  //       content += '<div class="print-page">';
  //       content +=
  //         '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
  //       content += "<tr>";
  //       content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
  //       content +=
  //         '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
  //       content += "</tr>";
  //       content += "<tr>";
  //       content +=
  //         '<td colSpan="2"><h3 style="margin:0;">Purchase Register Report</h3></td>';
  //       content += "</tr>";
  //       content += "<tr>";
  //       content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
  //       content += "</tr>";

  //       content += "</table>";

  //       content += '<table class="table table-bordered" style="font-size:10px;">';
  //       content += '<thead style="background:lightgray;">';
  //       content += "<tr>";
  //       //   {
  //       //     formRenderProps.valueGetter("filter_columns")?.includes("invoice_no") &&
  //       content += '<th style="width:30px;">Invoice No.</th>';
  //       //   }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("invoice_date") && (content += "<th>Invoice Date</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("inward_no") &&
  //           (content += "<th>Inward No.</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("inward_date") && (content += "<th>Inward Date</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("po_no") &&
  //           (content += "<th>PO No.</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("po_date") &&
  //           (content += "<th>PO Date</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("account_name") && (content += "<th>Vendor Name</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
  //           (content += "<th>PAN No.</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
  //           (content += "<th>GST No.</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("product_name") && (content += "<th>Item Name</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
  //           (content += "<th>Qty</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("free_quantity") && (content += "<th>Free Qty</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
  //           (content += "<th>Rate</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("unit_name") &&
  //           (content += "<th>Unit</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
  //           (content += "<th>Discount</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("taxable_amount") &&
  //           (content += "<th>Taxable Amount</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
  //           (content += "<th>CGST(%)</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("cgst_amount") && (content += "<th>CGST Amount</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
  //           (content += "<th>SGST(%)</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("sgst_amount") && (content += "<th>SGST Amount</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
  //           (content += "<th>IGST(%)</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("igst_amount") && (content += "<th>IGST Amount</th>");
  //       }
  //       {
  //         formRenderProps
  //           .valueGetter("filter_columns")
  //           ?.includes("total_amount") && (content += "<th>Total Amount</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("terms_days") &&
  //           (content += "<th>Terms Day</th>");
  //       }
  //       {
  //         formRenderProps.valueGetter("filter_columns")?.includes("due_date") &&
  //           (content += "<th>Due Date</th>");
  //       }
  //       content += "</tr>";
  //       content += "</thead>";
  //       content += "<tbody>";

  //       let startRow = i * maxRows;
  //       let endRow = Math.min((i + 1) * maxRows, tableRows);

  //       for (let j = startRow; j < endRow; j++) {
  //         content += "<tr>";
  //         // {
  //         //   formRenderProps
  //         //     .valueGetter("filter_columns")
  //         //     ?.includes("invoice_no") &&
  //         rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //           (content += `<td rowspan="${
  //             rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //           }" class="text-start">${data[j].invoice_no}</td>`)
  //         ) : (
  //           <></>
  //         );
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("invoice_date") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${
  //               data[j]?.invoice_date
  //                 ? moment(data[j]?.invoice_date, "DD/MM/YYYY").format(
  //                     "DD MMM YYYY"
  //                   )
  //                 : ""
  //             }</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("inward_no") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${
  //               data[j].inward_no ? data[j].inward_no : ""
  //             }</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("inward_date") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${
  //               data[j]?.inward_date
  //                 ? moment(data[j]?.inward_date, "DD/MM/YYYY").format(
  //                     "DD MMM YYYY"
  //                   )
  //                 : ""
  //             }</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("po_no") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${data[j].po_no}</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("po_date") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${
  //               data[j]?.po_date
  //                 ? moment(data[j]?.po_date, "DD/MM/YYYY").format("DD MMM YYYY")
  //                 : ""
  //             }</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("account_name") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }">${data[j].account_name}</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }">${data[j].pan_no}</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }">${data[j].gst_no}</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("product_name") &&
  //             (content += `<td>${data[j].product_name}</td>`);
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("quantity") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].quantity ? formatIndianNumber(data[j].quantity) : 0
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("free_quantity") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].free_quantity
  //                 ? formatIndianNumber(data[j].free_quantity)
  //                 : 0
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].rate ? formatIndianNumber(data[j].rate) : 0
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("unit_name") &&
  //             (content += `<td>${data[j].unit_code}</td>`);
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("discount") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].discount ? data[j].discount : ""
  //             } ${
  //               data[j].discount && data[j].discount_type
  //                 ? data[j].discount_type === 1
  //                   ? "₹"
  //                   : data[j].discount_type === 2
  //                   ? "%"
  //                   : ""
  //                 : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("taxable_amount") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].taxable_amount
  //                 ? formatIndianNumber(data[j].taxable_amount)
  //                 : 0
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("cgst") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].cgst ? `${data[j].cgst} %` : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("cgst_amount") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].cgst_amount ? formatIndianNumber(data[j].cgst_amount) : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("sgst") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].sgst ? `${data[j].sgst} %` : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("sgst_amount") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].sgst_amount ? formatIndianNumber(data[j].sgst_amount) : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("igst") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].igst ? `${data[j].igst} %` : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("igst_amount") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].igst_amount ? formatIndianNumber(data[j].igst_amount) : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("total_amount") &&
  //             (content += `<td style="text-align:end;">${
  //               data[j].total_amount
  //                 ? formatIndianNumber(data[j].total_amount)
  //                 : ""
  //             }</td>`);
  //         }
  //         {
  //           formRenderProps
  //             .valueGetter("filter_columns")
  //             ?.includes("terms_days") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${
  //               data[j].terms_days ? data[j].terms_days : ""
  //             }</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         {
  //           formRenderProps.valueGetter("filter_columns")?.includes("due_date") &&
  //           rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].index === j ? (
  //             (content += `<td rowspan="${
  //               rowSpans[data[j]?.invoice_no][data[j]?.vendor_id].count
  //             }" style="text-align:center;">${
  //               data[j]?.due_date
  //                 ? moment(data[j]?.due_date, "DD/MM/YYYY").format("DD MMM YYYY")
  //                 : ""
  //             }</td>`)
  //           ) : (
  //             <></>
  //           );
  //         }
  //         content += "</tr>";
  //       }

  //       content += "</tbody>";
  //       content += "</table>";

  //       content += "</div>";
  //     }

  //     let printWindow = window.open("", "_blank");
  //     if (!printWindow) {
  //       console.error("Failed to open print preview window.");
  //       return;
  //     }
  //     printWindow.document.write(`
  //         <html>
  //         <head>
  //           <title>Print Preview</title>
  //           <style>
  //             @page { margin: 1cm; }
  //             .print-page { page-break-after: always; }
  //             .table-bordered { width: 100%; border-collapse: collapse; }
  //             .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
  //             body { font-family: Arial, sans-serif; }
  //           </style>
  //         </head>
  //         <body>
  //           ${content}
  //           <script>
  //             window.onload = function() {
  //               window.print();
  //             };
  //           </script>
  //         </body>
  //         </html>
  //       `);
  //     printWindow.document.close();

  //     if (printbuttons) printbuttons.style.display = "block";
  //     if (report_container) report_container.style.padding = "20px 10px";
  //   };

  return (
    <>
      {SchemeReportList && SchemeReportList.length ? (
        // <Form
        //   initialValues={initialValues}
        //   render={(formRenderProps: FormRenderProps) => {
        //     return (
        //       <FormElement
        //         style={{ width: "100%", minHeight: "calc(100vh - 290px)" }}
        //       >
        <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Scheme Report List{" "}
              {(SchemeReportDetails?.from_date ||
                SchemeReportDetails?.to_Date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {SchemeReportDetails?.from_date
                    ? moment(
                        SchemeReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {SchemeReportDetails?.from_date &&
                    SchemeReportDetails?.to_Date &&
                    " - "}
                  {SchemeReportDetails?.to_Date
                    ? moment(SchemeReportDetails?.to_Date, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div className="position-relative d-flex">
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  //   onClick={() => printTable(SchemeReportList, formRenderProps)}
                >
                  Print
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem
            style={{
              maxHeight: "calc(100vh - 334px)",
              overflow: "scroll",
              scrollbarWidth: "thin",
            }}
          >
            <table
              cellSpacing={1}
              cellPadding={2}
              className="table table-bordered w-100  currentstock-print-table"
              style={{ marginTop: 1 }}
            >
              <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                <th style={{ width: "40%" }}>Description Of Goods</th>
                <th style={{ width: "10%" }}>Quantity</th>
                <th style={{ width: "10%" }}>Total Amount Before GST</th>
              </tr>
              {SchemeReportList &&
                SchemeReportList?.length > 0 &&
                SchemeReportList?.map((item: any) => {
                  let totalAmount = 0;
                  return (
                    <>
                      <tr style={{ background: "whitesmoke" }}>
                        <td
                          className="fw-600"
                          colSpan={7}
                          style={{ fontSize: 12 }}
                        >
                          {item?.item_group}
                        </td>
                      </tr>
                      {item?.item_data &&
                        item?.item_data?.length > 0 &&
                        item?.item_data?.map((subitem: any) => {
                          totalAmount += +subitem?.amount;
                          return (
                            <tr>
                              <td>{subitem?.product_name}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(
                                  subitem?.total_quantity || 0
                                )}
                              </td>
                              <td className="text-end">
                                {formatIndianNumber(subitem?.amount || 0)}
                              </td>
                            </tr>
                          );
                        })}
                      <tr className="text-end fw-600">
                        <td colSpan={2}>Total</td>
                        <td>{formatIndianNumber(totalAmount)}</td>
                      </tr>
                    </>
                  );
                })}
              {/* <tr className="text-end fw-600" style={{ background: "#ffe1e1" }}>
                <td colSpan={6}>Grand Total</td>
                <td>{formatIndianNumber(grandTotal)}</td>
              </tr> */}
            </table>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        //       </FormElement>
        //     );
        //   }}
        // />
        <AlertBox />
      )}
    </>
  );
};
export default SchemeReportForm;
