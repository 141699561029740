import React, { useEffect, useRef } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { setPODeleteIndex } from "./purchaseOrderSlice";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { IGst } from "../gst/gstModel";
import {
  getItemById,
  // getItemById,
} from "../Item/services/item.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { IWarehouse } from "../warehouse/warehouseModel";
import { IItemCategory } from "../Item Category/ItemCategoryModel";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";

const POItemDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const purchase_order_guid = location.state?.purchase_order_guid;

  const PODeleteIndex = useAppSelector(
    (state) => state.purchaseOrder.PODeleteIndex
  );
  const PurchaseOrderDetail = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderDetail
  );

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const ItemCategoryList = useAppSelector(
    (state) => state.itemCategory.ItemCategoryList
  );
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const GstList = useAppSelector((state) => state.gst.GstList);
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("poItemDeleteDialog"));
    dispatch(setPODeleteIndex(index));
  };

  useEffect(() => {
    if (!purchase_order_guid) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/PurchaseOrder/DeletePurchaseOrderItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setPODeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setPODeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        mrp: null,
        qty: null,
        rate: null,
        dagina: null,
        discount: null,
        discount_type: "₹",
        amount: null,
        unit_id: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const purchase_order_items =
      fieldArrayRenderProps.formRenderProps.valueGetter("purchase_order_items");
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const response: any = await dispatch(getItemById(payload));
      purchase_order_items &&
        purchase_order_items.length > 0 &&
        purchase_order_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (response.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_order_items.${index}.gst_id`,
                {
                  value: `${response.payload?.gst_id}--${
                    response.payload?.gst || "0"
                  }`,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_order_items.${index}.unit_id`,
                {
                  value: response.payload?.unit_id,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_order_items.${index}.item_Category_id`,
                {
                  value: response.payload?.item_category_id,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_order_items.${index}.gst_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_order_items.${index}.unit_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `purchase_order_items.${index}.item_Category_id`,
                {
                  value: null,
                }
              );
            }
          }
        });
    } else {
      purchase_order_items &&
        purchase_order_items.length > 0 &&
        purchase_order_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_order_items.${index}.gst_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_order_items.${index}.unit_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `purchase_order_items.${index}.item_Category_id`,
              {
                value: null,
              }
            );
          }
        });
    }
  };
  const debouncedHandleProductChange = useRef(
    _.debounce((e: any, index: number, fieldArrayRenderProps: any) => {
      handleProductChange(e, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (e: any, index: number, fieldArrayRenderProps: any) => {
    debouncedHandleProductChange(e, index, fieldArrayRenderProps);
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 2100 }}
        >
          <GridLayout
            style={{
              marginRight: 55,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "3%" },
              { width: "15%" },
              { width: "10%" },
              { width: "8%" },
              { width: "7%" },
              { width: "6%" },
              { width: "8%" },
              // { width: "8%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "3%" },
            ]}
          >
            <GridLayoutItem>No</GridLayoutItem>
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>Item Category</GridLayoutItem>
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem>Rate</GridLayoutItem>
            <GridLayoutItem>Dagina</GridLayoutItem>
            {/* <GridLayoutItem>MRP</GridLayoutItem> */}
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Discount</GridLayoutItem>
            <GridLayoutItem>GST</GridLayoutItem>
            <GridLayoutItem>Warehouse</GridLayoutItem>
            <GridLayoutItem>Amount</GridLayoutItem>
            <GridLayoutItem
              style={{
                position: "sticky",
                top: 0,
                right: -25,
                zIndex: 9,
                background: "#212529",
              }}
            ></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "poItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(PODeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (purchase_order_items: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: 2100 }}>
                {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
                <GridLayout
                  style={{ marginRight: 55, padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "3%" },
                    { width: "15%" },
                    { width: "10%" },
                    { width: "8%" },
                    { width: "7%" },
                    { width: "6%" },
                    { width: "8%" },
                    // { width: "8%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "3%" },
                  ]}
                >
                  <GridLayoutItem style={{ position: "relative" }}>
                    <span
                      style={{
                        position: "absolute",
                        top: 5,
                        left: 0,
                        fontWeight: 700,
                      }}
                    >
                      {index + 1}
                    </span>
                  </GridLayoutItem>
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={(e) =>
                        handleChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `purchase_order_items.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: any) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.item_Category_id`}
                      placeholder="Product Category"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={ItemCategoryList?.map((item: IItemCategory) => {
                        return {
                          value: item?.id,
                          label: item?.item_category,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.qty`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.rate`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.dagina`}
                      placeholder="0"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.mrp`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      component={FormTextField}
                    />
                  </GridLayoutItem> */}
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.unit_id`}
                      placeholder="Unit"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={UnitList?.map((unit: IUnit) => {
                        return {
                          value: unit?.id,
                          label: unit?.unit_code,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ position: "relative" }}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.discount`}
                      type="number"
                      placeholder="0"
                      component={FormTextField}
                    />
                    <DropDownList
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: "43%",
                        zIndex: 1,
                      }}
                      onChange={(e) => {
                        fieldArrayRenderProps?.formRenderProps?.onChange(
                          `purchase_order_items.${index}.discount_type`,
                          {
                            value: e.value,
                          }
                        );
                      }}
                      name={`purchase_order_items.${index}.discount_type`}
                      data={["₹", "%"]}
                      defaultValue={
                        purchase_order_guid
                          ? PurchaseOrderDetail &&
                            PurchaseOrderDetail?.purchase_order_items.length >
                              0 &&
                            PurchaseOrderDetail?.purchase_order_items[index]
                              ?.discount_type === "₹"
                            ? "₹"
                            : PurchaseOrderDetail?.purchase_order_items[index]
                                ?.discount_type === "%"
                            ? "%"
                            : "₹"
                          : "₹"
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.gst_id`}
                      placeholder="GST (%)"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={GstList?.map((gst: IGst) => {
                        return {
                          value: `${gst?.id}--${gst?.gst}`,
                          label: gst?.gst_title,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.cold_storage_id`}
                      placeholder="Warehouse"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={warehouseList?.map((warehouse: IWarehouse) => {
                        return {
                          value: warehouse?.id,
                          label: warehouse?.warehouse_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`purchase_order_items.${index}.amount`}
                      placeholder="0"
                      format="n2"
                      disabled={true}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      zIndex: 9,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
                {/* </Card> */}
              </GridLayoutItem>
            )
          )}

        {/* <GridLayoutItem
        style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
      >
        <GridLayout
          style={{ marginRight: 45, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={[
            { width: "2%" },
            { width: "21%" },
            { width: "10%" },
            { width: "10%" },
            { width: "10%" },
            { width: "10%" },
            { width: "12%" },
            { width: "12%" },
            { width: "10%" },
            { width: "3%" },
          ]}
        >
          <GridLayoutItem colSpan={2} className="d-flex align-items-end">
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={pushElementInarray}
              type="button"
            >
              Add Row
            </Button>
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={6}
            className="d-flex justify-content-end align-items-center fw-600"
          >
            Total
          </GridLayoutItem>
          <GridLayoutItem colSpan={2}>
            <Field
              wrapperStyle={{ margin: 0 }}
              name="taxable_amount"
              format="n2"
              component={FormNumericTextField}
              disabled="true"
            />
          </GridLayoutItem>
         
        </GridLayout>
      </GridLayoutItem> */}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "2%" },
              { width: "21%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "12%" },
              { width: "12%" },
              { width: "10%" },
              { width: "3%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem>
            <Field
              wrapperStyle={{ margin: 0 }}
              name="totalQty"
              type="number"
              component={FormTextField}
              disabled="true"
            />
          </GridLayoutItem> */}
            <GridLayoutItem
              colSpan={6}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Total
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="taxable_amount"
                format="n2"
                component={FormNumericTextField}
                disabled="true"
              />
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default POItemDetailsArray;
