import React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Typography } from "@progress/kendo-react-common";
import { CgDanger } from "react-icons/cg";

const FormCheckboxGroup = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    title,
    wrapperClassName,
    inputClassName,
    marginRight = 25,
    marginBeetween = 8,
    options = [],
    value = [],
    onChange,
    isSelectAll = false,
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;

  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const handleCheckboxChange = (selectedValue: any) => {
    const updatedValues = value?.includes(selectedValue)
      ? value?.filter((val: any) => val !== selectedValue)
      : [...value, selectedValue];

    onChange({ value: updatedValues });
  };

  return (
    <FieldWrapper>
      {title && <Typography.h5>{title}</Typography.h5>}
      <div className={`k-form-field-wrap ${wrapperClassName}`}>
        {isSelectAll && options?.length ? (
          <div
            style={{
              display: "inline-block",
              marginRight: marginRight,
            }}
          >
            <Checkbox
              className={inputClassName}
              ariaDescribedBy={`${hindId} ${errorId}`}
              label="Select All"
              valid={valid}
              id={`${id}_selectAll`}
              disabled={disabled}
              checked={value.length === options.length}
              style={{ marginRight: marginBeetween }}
              onChange={() =>
                onChange({
                  value:
                    value.length === options.length
                      ? []
                      : options.map((option: any) => option.value),
                })
              }
            />
          </div>
        ) : null}
        {options?.map((option: any, index: number, array: any[]) => (
          <div
            key={option.value}
            style={{
              display: "inline-block",
              marginRight: array?.length - 1 === index ? "" : marginRight,
            }}
          >
            <Checkbox
              className={inputClassName}
              ariaDescribedBy={`${hindId} ${errorId}`}
              label={option.label}
              valid={valid}
              id={`${id}_${option.value}`}
              disabled={disabled}
              checked={value?.includes(option.value)}
              style={{ marginRight: marginBeetween }}
              onChange={() => handleCheckboxChange(option.value)}
            />
          </div>
        ))}
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger /> {validationMessage}
            </span>
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormCheckboxGroup;
