import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISetting } from "../settingModel";

export const getAllSettings = createAsyncThunk(
  "TimeSettings/FindAllTimeSettings",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TimeSettings/FindAllTimeSettings`,
        {
          setting_key: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching settings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSetting = createAsyncThunk(
  "TimeSettings/InsertTimeSettings",
  async (SettingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TimeSettings/InsertTimeSettings`,
        SettingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating setting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSetting = createAsyncThunk(
  "TimeSettings/UpdateTimeSettings",
  async (SettingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TimeSettings/UpdateTimeSettings`,
        SettingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating setting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSetting = createAsyncThunk(
  "TimeSettings/DeleteTimeSettings",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TimeSettings/DeleteTimeSettings`,
        { setting_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting setting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSettingByID = createAsyncThunk(
  "TimeSettings/FindByIDTimeSettings",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TimeSettings/FindByIDTimeSettings`,
        { setting_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        setting_guid: result.setting_guid,
        setting_key: result.setting_key,
        setting_value: result.setting_value,
        isactive: result.isactive === true ? 1 : 2,
      } as ISetting;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching setting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSettings = createAsyncThunk(
  "TimeSettings/FindAllActiveTimeSettings",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/TimeSettings/FindAllActiveTimeSettings`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching settings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const activeInactiveSetting = createAsyncThunk(
  "TimeSettings/ActiveInActiveTimeSettings",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TimeSettings/ActiveInActiveTimeSettings`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive setting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
