import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRelation } from "../relationModel";

export const getAllRelations = createAsyncThunk(
  "User/FindAllRelation",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindAllRelation`,
        {
          relation: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRelations = createAsyncThunk(
  "User/FindAllActiveRelation",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveRelation`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRelation = createAsyncThunk(
  "User/InsertRelation",
  async (RelationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertRelation`,
        RelationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRelation = createAsyncThunk(
  "User/UpdateRelation",
  async (RelationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateRelation`,
        RelationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRelationByID = createAsyncThunk(
  "User/FindByIDRelation",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDRelation`,
        { relation_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        relation_guid: result.relation_guid,
        relation: result.relation,
        relation_code: result.relation_code,
        isactive: result.isactive === true ? 1 : 2,
      } as IRelation;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const relationActiveInactive = createAsyncThunk(
  "User/ActiveInActiveRelation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveRelation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRelation = createAsyncThunk(
  "User/DeleteRelation",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteRelation`,
        { relation_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
