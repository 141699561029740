import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  clearOutwardPackingDetails,
  clearOutwardPackingID,
  setOutwardPackingID,
} from "./outwardPackingMaterialSlice";
import {
  deleteOutwardPackingMaterial,
  getAllOutwardPackingMaterials,
} from "./services/outwardPackingMaterial.services";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearItemList } from "../Item/itemSlice";

const OutwardPackingMaterial: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/outwardpackingmaterial/create");
  };

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(clearOutwardPackingDetails());
      dispatch(setCurrentPage(0));
    };
  }, []);

  return (
    <>
      <DeleteOutwardPackingDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Outward Packing Material List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <OutwardPackingMaterialGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const OutwardPackingMaterialGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const OutwardPackingList = useAppSelector(
    (state) => state.outwardPackingMaterial.OutwardPackingList
  );
  const loading = useAppSelector(
    (state) => state.outwardPackingMaterial.loading
  );
  const { currentPage, pageLimit } = useAppSelector(
    (state) => state.pagination
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  useEffect(() => {
    const payload = {
      outward_type: "",
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllOutwardPackingMaterials(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (outward_packing_material_guid: any) => {
      navigate("/outwardpackingmaterial/edit", {
        state: { outward_packing_material_guid: outward_packing_material_guid },
      });
    };
    const handleOpenDeleteDialog = (outward_packing_material_guid: any) => {
      dispatch(openDialog("deleteOutwardPackingMaterial"));
      dispatch(setOutwardPackingID(outward_packing_material_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEdit(props.dataItem?.outward_packing_material_guid)
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(
                props.dataItem?.outward_packing_material_guid
              )
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {OutwardPackingList && OutwardPackingList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter ? filterBy(OutwardPackingList, filter) : OutwardPackingList
          }
          onFilterChange={handleFilterChange}
          total={OutwardPackingList[0]?.itemcount || OutwardPackingList.length}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="outward_packing_no"
            title="Outward Packing No"
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/outwardpackingmaterial/edit", {
                      state: {
                        outward_packing_material_guid:
                          props.dataItem?.outward_packing_material_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.outward_packing_no}
                </div>
              </td>
            )}
          />
          <Column
            field="outward_packing_material_date"
            title="Outward Packing Date"
          />
          <Column field="outward_type" title="Outward Type" />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteOutwardPackingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const OutwardPackingID = useAppSelector(
    (state) => state.outwardPackingMaterial.OutwardPackingID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (outward_packing_material_guid: any) => {
    if (outward_packing_material_guid) {
      const response = await dispatch(
        deleteOutwardPackingMaterial(outward_packing_material_guid)
      );
      dispatch(setCurrentPage(0));
      const payload = {
        outward_type: "",
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllOutwardPackingMaterials(payload));
        dispatch(closeDialog());
        dispatch(clearOutwardPackingID());
      } else {
        dispatch(getAllOutwardPackingMaterials(payload));
        dispatch(closeDialog());
        dispatch(clearOutwardPackingID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteOutwardPackingMaterial" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Outward Packing Mateial"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Outward Packing?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(OutwardPackingID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default OutwardPackingMaterial;
