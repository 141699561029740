import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOfficeStaffOut,
  IOfficeStaffOutInitialState,
} from "./officeStaffOutModel";
import {
  createOfficeStaffOut,
  deleteOfficeStaffOut,
  getAllOfficeStaffOut,
  getOfficeStaffOutByID,
  updateOfficeStaffOut,
} from "./services/officeStaffOut.services";
import moment from "moment";

const initialState: IOfficeStaffOutInitialState = {
  loading: false,
  error: "",
  OfficeStaffOutID: null,
  OfficeStaffOutList: [],
  OfficeStaffOutDetail: {
    staff_out_date: moment().toDate(),
    user_id: null,
    reason_for_out: "",
    approved_by: null,
    out_time: moment().toDate(),
    in_time: "",
    financial_year: null,
  },
};

const officeStaffOutSlice = createSlice({
  name: "officeStaffOut",
  initialState,
  reducers: {
    clearOfficeStaffOutDetails: (state) => {
      state.OfficeStaffOutDetail = initialState.OfficeStaffOutDetail;
    },
    setOfficeStaffOutID: (state, action) => {
      state.OfficeStaffOutID = action.payload;
    },
    clearOfficeStaffOutID: (state) => {
      state.OfficeStaffOutID = initialState.OfficeStaffOutID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOfficeStaffOut.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOfficeStaffOut.fulfilled,
      (state, action: PayloadAction<IOfficeStaffOut[]>) => {
        state.loading = false;
        state.OfficeStaffOutList = action.payload || [];
      }
    );
    builder.addCase(getAllOfficeStaffOut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OfficeStaffOutList = [];
    });

    builder.addCase(createOfficeStaffOut.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createOfficeStaffOut.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createOfficeStaffOut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateOfficeStaffOut.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateOfficeStaffOut.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOfficeStaffOut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteOfficeStaffOut.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteOfficeStaffOut.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOfficeStaffOut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getOfficeStaffOutByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getOfficeStaffOutByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.OfficeStaffOutDetail = action.payload;
    });
    builder.addCase(getOfficeStaffOutByID.rejected, (state, action) => {
      state.loading = false;
      state.OfficeStaffOutDetail = initialState.OfficeStaffOutDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearOfficeStaffOutDetails,
  clearOfficeStaffOutID,
  setOfficeStaffOutID,
} = officeStaffOutSlice.actions;
export default officeStaffOutSlice.reducer;
