import { useEffect, useState } from "react";
import { DAYS_OF_WEEK } from "../features/account/CreateAccount";

export const checkAcessRights = (route: string, rightID: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  return userResponse
    ?.find((e: any) => e.menu_key === route?.substring(1))
    ?.rights_id?.includes(rightID);
};

export const hasRights = (menu_key: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const menu = userResponse.find((item) => item.menu_key === menu_key);
  return menu && menu.rights_id;
};

export const getLocalStorageItem = (Name: string) => {
  return localStorage.getItem(Name);
};

export function convertToWords(num: number) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const units = ["", "Thousand", "Lakh", "Crore"];

  function oneTwoDigitWords(n: number) {
    if (n < 10) {
      return ones[n];
    } else if (n >= 11 && n < 20) {
      return teens[n - 10];
    } else if (n % 10 === 0) {
      return tens[Math.floor(n / 10)];
    } else {
      return tens[Math.floor(n / 10)] + " " + ones[n % 10];
    }
  }

  function twoDigitWords(n: number) {
    if (n < 20) {
      return oneTwoDigitWords(n);
    } else {
      return (
        tens[Math.floor(n / 10)] + (n % 10 !== 0 ? " " + ones[n % 10] : "")
      );
    }
  }

  function threeDigitWords(n: number) {
    if (n < 100) {
      return twoDigitWords(n);
    } else {
      return (
        ones[Math.floor(n / 100)] +
        " Hundred" +
        (n % 100 !== 0 ? " " + twoDigitWords(n % 100) : "")
      );
    }
  }

  function integerToWords(n: number) {
    if (n === 0) return "Zero";
    const chunks = [];
    while (n > 0) {
      chunks.push(n % 100);
      n = Math.floor(n / 100);
    }
    let result = "";
    const chunkCount = chunks.length;
    for (let i = chunkCount - 1; i >= 0; i--) {
      if (chunks[i] !== 0) {
        if (i > 0) {
          result +=
            threeDigitWords(chunks[i]) + " " + (units[i] ? units[i] + " " : "");
        } else {
          result += threeDigitWords(chunks[i]);
        }
      }
    }
    return result.trim();
  }

  function indianNumberingSystem(n: number) {
    let result = "";
    let crore = Math.floor(n / 10000000);
    n = n % 10000000;
    let lakh = Math.floor(n / 100000);
    n = n % 100000;
    let thousand = Math.floor(n / 1000);
    n = n % 1000;
    let hundred = Math.floor(n / 100);
    let rest = n % 100;

    if (crore > 0) {
      result += integerToWords(crore) + " Crore ";
    }
    if (lakh > 0) {
      result += integerToWords(lakh) + " Lakh ";
    }
    if (thousand > 0) {
      result += integerToWords(thousand) + " Thousand ";
    }
    if (hundred > 0) {
      result += integerToWords(hundred) + " Hundred ";
    }
    if (rest > 0) {
      result += integerToWords(rest);
    }

    return result.trim();
  }

  const parts = num.toString().split(".");
  const integerPart = parseInt(parts[0], 10);
  const decimalPart = parts.length > 1 ? parts[1].padEnd(2, "0") : "00"; // Ensure 2 digits for decimals

  let result = indianNumberingSystem(integerPart);

  if (decimalPart !== "00") {
    result +=
      (result && " Rupees And ") +
      indianNumberingSystem(parseInt(decimalPart, 10)) +
      " Paise";
  }

  return result + " Only";
}

// If amount has decimal then fix 2 numbers after decimal
export function formatNumber(num: any) {
  if (Number.isInteger(num)) {
    return num?.toString();
  } else {
    return num?.toFixed(2);
  }
}

export function formatIndianNumber(number: number, decimal: any = 2): string {
  if (number === null || number === undefined) return "";
  let [integerPart, decimalPart] = number.toFixed(decimal).split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  let formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  return formattedOtherDigits + lastThreeDigits + "." + decimalPart;
}

export function convertNumToIndianCurrency(number: number): string {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);
}

export function formatIndianNumberForQty(number: number, decimal: any = 3) {
  if (number === null || number === undefined) return "";

  const isNegative = number < 0;
  const absoluteNumber = Math.abs(number);
  let [integerPart, decimalPart] = absoluteNumber.toFixed(decimal).split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  let formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedNumber =
    formattedOtherDigits + lastThreeDigits + "." + decimalPart;

  return isNegative ? `-${formattedNumber}` : formattedNumber;
}

export function getYearsArray(
  startYear: number,
  endYear: number
): { label: string; value: string }[] {
  const yearsArray = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push({ label: year.toString(), value: year.toString() });
  }
  return yearsArray || [];
}

export const PercentageValue = (amount: number) => {
  let inputValue = amount.toString();
  if (inputValue.match(/^\d{3,}$/)) {
    inputValue = inputValue.slice(0, 2);
  }
  if (inputValue.match(/^\d{3,}$/)) {
    inputValue = inputValue.slice(0, 2);
  }
  // Allows up to 2 digits before and up to 4 digits after the decimal
  if (inputValue.includes(".")) {
    const [beforeDecimal, afterDecimal] = inputValue.split(".");
    // Limit the beforeDecimal part to 2 digits
    if (beforeDecimal.length > 2) {
      inputValue = beforeDecimal.slice(0, 2) + "." + afterDecimal;
    }
    // Limit the afterDecimal part to 4 digits
    if (afterDecimal.length > 2) {
      inputValue = beforeDecimal + "." + afterDecimal.slice(0, 2);
    }
  }
  return Number(inputValue);
};

export const getTimeDuration = (
  StartDate: string,
  EndDate: string,
  format?: string
) => {
  const moment = require("moment");

  const startDate = format ? moment(StartDate, format) : moment(StartDate);
  const endDate = format ? moment(EndDate, format) : moment(EndDate);

  const diffInHours = endDate.diff(startDate, "hours");
  const diffInMinutes = endDate.diff(startDate, "minutes") % 60;
  return `${diffInHours ? diffInHours + " hour" : ""} ${diffInMinutes} minute`;
};

export const getAccountingYear = (customDate = new Date()) => {
  const date = new Date(customDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  if (month >= 4) {
    return `${year}-${year + 1}`;
  } else {
    return `${year - 1}-${year}`;
  }
};

export const daysCountInRange = (
  startDate: string,
  endDate: string
): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const timeDiff = end.getTime() - start.getTime();
  const dayCount = timeDiff / (1000 * 3600 * 24) + 1;

  return dayCount;
};

export const useResponsiveJSX = (breakpoints: number[]): number => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const updateIndex = () => {
      const width = window.innerWidth;
      const newIndex = breakpoints.findIndex((bp) => width <= bp);
      setIndex(newIndex === -1 ? breakpoints.length : newIndex);
    };

    updateIndex();
    window.addEventListener("resize", updateIndex);
    return () => window.removeEventListener("resize", updateIndex);
  }, [breakpoints]);

  return index;
};

// export const checkOrderEligibility = (
//   orderDay: string,
//   deliveryDay: string
// ): { isEligible: boolean; nextOrderDay: string } => {
//   const today = new Date();
//   const currentIndex = today.getDay();

//   const orderIndex = DAYS_OF_WEEK.indexOf(orderDay);
//   let deliveryIndex = DAYS_OF_WEEK.indexOf(deliveryDay);

//   if (deliveryIndex < orderIndex) {
//     deliveryIndex += 7;
//   }

//   const adjustedCurrentIndex =
//     currentIndex < orderIndex ? currentIndex + 7 : currentIndex;

//   const isEligible =
//     adjustedCurrentIndex <= orderIndex || adjustedCurrentIndex > deliveryIndex;
//   const nextOrderIndex =
//     currentIndex <= deliveryIndex ? deliveryIndex : deliveryIndex + 7;

//   const nextOrderDay = DAYS_OF_WEEK[nextOrderIndex % 7];

//   return { isEligible, nextOrderDay };
// };

export const checkOrderEligibility = (
  orderDay: string,
  deliveryDay: string,
  cutoffTime: string //format:"HH:MM" (e.g., "14:45")
): { isEligible: boolean; nextOrderDay: string } => {
  const today = new Date();
  const currentIndex = today.getDay();
  const currentHour = today.getHours();
  const currentMinute = today.getMinutes();

  const [cutoffHour, cutoffMinute] = cutoffTime.split(":").map(Number);

  const orderIndex = DAYS_OF_WEEK.indexOf(orderDay);
  let deliveryIndex = DAYS_OF_WEEK.indexOf(deliveryDay);
  console.log(orderIndex, deliveryIndex);

  if (deliveryIndex < orderIndex) {
    deliveryIndex += 7;
  }

  const adjustedCurrentIndex =
    currentIndex < orderIndex ? currentIndex + 7 : currentIndex;

  const isBeforeNextDayCutoff =
    currentIndex === (orderIndex + 1) % 7 &&
    (currentHour < cutoffHour ||
      (currentHour === cutoffHour && currentMinute < cutoffMinute));

  const isAfterDeliveryDayEnd =
    currentIndex === deliveryIndex && adjustedCurrentIndex > deliveryIndex;

  const isEligible =
    (adjustedCurrentIndex === orderIndex &&
      (currentHour > cutoffHour ||
        (currentHour === cutoffHour && currentMinute >= cutoffMinute))) ||
    isBeforeNextDayCutoff ||
    isAfterDeliveryDayEnd;
  const nextOrderIndex =
    currentIndex <= deliveryIndex ? deliveryIndex : deliveryIndex + 7;

  const nextOrderDay = DAYS_OF_WEEK[nextOrderIndex % 7];

  return { isEligible, nextOrderDay };
};
