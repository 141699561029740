import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IItemGroup } from "../itemgroupModel";

export const getAllItemGroup = createAsyncThunk(
  "ItemGroup/FindAllItemGroup",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/FindAllItemGroup`,
        {
          itemGroup: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItemGroup = createAsyncThunk(
  "ItemGroup/InsertItemGroup",
  async (itemGroup: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/InsertItemGroup`,
        itemGroup
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItemGroup = createAsyncThunk(
  "ItemGroup/UpdateItemGroup",
  async (itemGroup: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/UpdateItemGroup`,
        itemGroup
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemGroupById = createAsyncThunk(
  "ItemGroup/FindByIDItemGroup",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/FindByIDItemGroup`,
        {
          item_group_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        item_group_guid: result.item_group_guid,
        item_group: result.item_group,
        remarks: result.remarks,
        conversion_unit: 1,
        isactive: result.isactive === true ? 1 : 2,
        item_group_margin_structure: result.item_group_margin_structure
          ? result.item_group_margin_structure?.map((margin: any) => {
              return {
                id: margin?.id ? margin?.id : null,
                item_group_margin_structure_guid:
                  margin?.item_group_margin_structure_guid
                    ? margin?.item_group_margin_structure_guid
                    : "",
                item_group_id: margin?.item_group_id
                  ? margin?.item_group_id
                  : null,
                from_date: margin?.from_date ? margin?.from_date : "",
                to_date: margin?.to_date ? margin?.to_date : "",
                conversion_unit: margin?.conversion_unit
                  ? +margin?.conversion_unit
                  : null,
                agent_margin: margin?.agent_margin
                  ? margin?.agent_margin
                  : null,
                agent_marginvalue1: margin?.agent_marginvalue1
                  ? margin?.agent_marginvalue1
                  : null,
                agent_marginvalue2: margin?.agent_marginvalue2
                  ? margin?.agent_marginvalue2
                  : null,
                retailer_margin: margin?.retailer_margin
                  ? margin?.retailer_margin
                  : null,
                retailer_value: margin?.retailer_value
                  ? margin?.retailer_value
                  : null,
              };
            })
          : [],
      } as IItemGroup;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItemGroup = createAsyncThunk(
  "ItemGroup/DeleteItemGroup",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/DeleteItemGroup`,
        {
          item_group_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveItemGroup = createAsyncThunk(
  "ItemGroup/FindAllActiveItemGroup",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/ItemGroup/FindAllActiveItemGroup`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ItemGroupActiveInactive = createAsyncThunk(
  "ItemGroup/ActiveInActiveItemGroup",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/ActiveInActiveItemGroup`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive ItemGroup:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItemGroupMarginRate = createAsyncThunk(
  "ItemGroup/InsertItemGroupMarignStructure",
  async (marginRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/InsertItemGroupMarignStructure`,
        marginRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting margin rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItemGroupMarginRate = createAsyncThunk(
  "ItemGroup/UpdateItemGroupMarignStructure",
  async (marginRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/UpdateItemGroupMarignStructure`,
        marginRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating margin rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItemGroupMarginRate = createAsyncThunk(
  "ItemGroup/DeleteItemGroupMarginStructure",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/DeleteItemGroupMarginStructure`,
        {
          item_group_margin_structure_id: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting margin rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllItemGroupIncremental = createAsyncThunk(
  "ItemGroup/FindAllItemGroupIncremental",
  async ({ search }: { search: any }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/FindAllItemGroupIncremental`,
        { item_group: search }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Item Group:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
