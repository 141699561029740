import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createTermsAndConditions,
  getAllTermsAndConditions,
  getTermsAndConditionsByID,
  updateTermsAndConditions,
} from "./services/termsAndCondition.services";
import { clearTermsAndConditionDetails } from "./termsAndConditionSlice";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";

const CreateTermsAndCondition: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const TermsAndConditionDetail = useAppSelector(
    (state) => state.termsandcondition.TermsAndConditionDetail
  );
  const terms_and_condition_guid = useAppSelector(
    (state) => state.muidrawer.data
  );
  const formLoading = useAppSelector(
    (state) => state.termsandcondition.formLoading
  );

  useEffect(() => {
    if (terms_and_condition_guid) {
      dispatch(getTermsAndConditionsByID(terms_and_condition_guid));
    }
  }, [terms_and_condition_guid]);

  useEffect(() => {
    return () => {
      dispatch(clearTermsAndConditionDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!terms_and_condition_guid) {
        const insertPayload = {
          terms_and_condition: values?.terms_and_condition
            ? values?.terms_and_condition
            : "",
          description: values?.description ? values?.description : "",
          isactive: values.isactive === 1,
        };
        const response = await dispatch(
          createTermsAndConditions(insertPayload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearTermsAndConditionDetails());
          dispatch(getAllTermsAndConditions());
        }
      } else {
        const payload = {
          terms_and_condition_guid: terms_and_condition_guid,
          terms_and_condition: values?.terms_and_condition
            ? values?.terms_and_condition
            : "",
          description: values?.description ? values?.description : "",
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateTermsAndConditions(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearTermsAndConditionDetails());
          dispatch(getAllTermsAndConditions());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={TermsAndConditionDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {terms_and_condition_guid
                  ? "Update Term And Condition"
                  : "Add Term And Condition"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => {
                  dispatch(closed());
                }}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>

            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="terms_and_condition"
                label="Terms And Conditions"
                astrike={true}
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="description"
                label="Description"
                rows={8}
                component={FormRichTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem colSpan={3} style={{ marginTop: 20 }}>
              <ButtonWithLoading
                label={terms_and_condition_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateTermsAndCondition;
