import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearEmployeeDetailsReport,
  clearEmployeeDetailsReportList,
  setEmployeeDetailsReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS } from "../../../_contstants/common";
import { clearItemList } from "../../Item/itemSlice";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getEmployeeDetailsReport } from "../services/reports.services";
import { findAllActiveDepartment } from "../../department/services/department.services";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getAllActiveEmployee } from "../../employee/services/employee.services";
import { IEmployee } from "../../employee/employeeModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const ChangeWatcher = ({ formRenderProps }: FormChangeWatcherProps) => {
  useEffect(() => {
    formRenderProps.onChange("department_id", {
      value: null,
    });
  }, []);
  return null;
};

const EmployeeDetailsReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const DepartmentList = useAppSelector(
    (state) => state.department.DepartmentList
  );
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);

  const EmployeeDetailsReportList = useAppSelector(
    (state) => state.reports.EmployeeDetailsReportList
  );
  const EmployeeDetailsReport = useAppSelector(
    (state) => state.reports.EmployeeDetailsReport
  );

  //   const currentPage = useAppSelector((state) => state.pagination.currentPage);
  //   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    dispatch(findAllActiveDepartment());
    dispatch(getAllActiveEmployee());
    return () => {
      dispatch(clearEmployeeDetailsReport());
      dispatch(clearEmployeeDetailsReportList());
      //   dispatch(setCurrentPage(0));
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      department_id: values.department_id,
      employee_id: values.employee_id,
      //   limit: +pageLimit,
      //   skip: +currentPage * +pageLimit,
    };
    dispatch(setEmployeeDetailsReport(payload));
    dispatch(getEmployeeDetailsReport(payload));
  };

  const filterEmployeeByDepartment = (department_id: number) => {
    if (department_id) {
      // Filter employees based on department_id
      const filteredEmployees = EmployeeList.filter(
        (employee: IEmployee) => employee.department_id === department_id
      );
      // Return employees in the desired format
      return filteredEmployees.map((emp: IEmployee) => ({
        value: emp.id,
        label: emp.name,
      }));
    } else {
      return EmployeeList.map((emp: IEmployee) => ({
        value: emp.id,
        label: emp.name,
      }));
    }
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={EmployeeDetailsReport}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <ChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Employee Details
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="department_id"
                      label="Department"
                      placeholder="Select Department"
                      component={FormSelectionField}
                      options={DepartmentList.map((dep: any) => ({
                        value: dep.id,
                        label: dep.department_name,
                      }))}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="employee_id"
                      label="Employee"
                      placeholder="Select Employee"
                      component={FormSelectionField}
                      // options={EmployeeList.map((emp: IEmployee) => ({
                      //   value: emp.id,
                      //   label: emp.name,
                      // }))}
                      options={filterEmployeeByDepartment(
                        formRenderProps.valueGetter(`department_id`) || null
                      )}
                      // validator={requiredValidator}
                      // astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div style={{ marginTop: "35px" }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          EmployeeDetailsReportList &&
          EmployeeDetailsReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 , minHeight:"30vh"}}>
              <EmployeeDetailsExportTable />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const EmployeeDetailsExportTable: React.FC = () => {
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const EmployeeDetailsReportList = useAppSelector(
    (state) => state.reports.EmployeeDetailsReportList
  );
  const EmployeeDetailsReport = useAppSelector(
    (state) => state.reports.EmployeeDetailsReport
  );
  const handleExportExcel = () => {
    const table = document.querySelector(
      ".employee-details-report-table"
    ) as HTMLTableElement;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);

    // Adjust column widths dynamically
    const colWidths: number[] = [];
    const rows = table.querySelectorAll("tr");

    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      cells.forEach((cell, colIndex) => {
        const cellContent = cell.textContent || "";
        const cellLength = cellContent.length;

        // Calculate width (max of existing or current cell)
        colWidths[colIndex] = Math.max(
          colWidths[colIndex] || 10,
          cellLength + 2
        );
      });
    });

    // Apply column widths
    ws["!cols"] = colWidths.map((width) => ({ wch: width }));
    const headerRows = table.querySelectorAll("thead tr");
    headerRows.forEach((row) => {
      const cells = row.querySelectorAll("th");
      cells.forEach((cell, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // Assume <thead> is row 0
        if (ws[cellRef]) {
          ws[cellRef].s = {
            font: { bold: true }, // Bold font
            alignment: { horizontal: "center", vertical: "center" }, // Center alignment
            fill: { fgColor: { rgb: "D9E1F2" } }, // Light background color
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      });
    });

    Object.keys(ws).forEach((key) => {
      if (!key.startsWith("!")) {
        const cell = ws[key];
        if (cell.z !== "m/d/yy") {
          if (typeof cell.v === "number") {
            // Check if the cell is a date (custom logic depending on your use case)
            const isDate = cell.t === "d"; // 't' might indicate the cell type in some libraries
            if (!isDate) {
              cell.t = "s"; // Set cell type to 'string'
              cell.v = `${cell.v}`; // Convert number to string
            }
          }
        } else if (cell.z === "m/d/yy") {
          cell.z = "DD/MM/YYYY";
        }
      }
    });

    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${
        EmployeeDetailsReport.department_id
          ? EmployeeDetailsReportList[0].department_name
          : ""
      }${
        EmployeeDetailsReport.department_id && EmployeeDetailsReport.employee_id
          ? "-"
          : ""
      }${
        EmployeeDetailsReport.employee_id
          ? EmployeeDetailsReportList[0].employee_code_string
          : ""
      }`
    );

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Employee Details`);
  };


  const [showfilterColumns, setShowFilterColumns] = React.useState(false);

  const initialValues = {
    filter_columns: [
      "employee_info",
      "personal_info",
      "present_address",
      "permanent_address",
      "emergency_contact",
      "bank_details",
      "pf_account_details",
      "esi_details",
      "other_details",
      "salary_structure",
    ],
  };
  const columns = [
    { label: "Employee Information", value: "employee_info" },
    { label: "Personal Information", value: "personal_info" },
    { label: "Present Address", value: "present_address" },
    { label: "Permanent Address", value: "permanent_address" },
    { label: "Emergency Contact", value: "emergency_contact" },
    { label: "Bank Details", value: "bank_details" },
    { label: "PF Account Details", value: "pf_account_details" },
    { label: "ESI Details", value: "esi_details" },
    { label: "Other Details", value: "other_details" },
    { label: "Salary Structure", value: "salary_structure" },
  ];

  // const calculateRowSpans = (data: any[]) => {
  //   const rowSpans: any = {};

  //   data.forEach((item: any, index: number) => {
  //     const { po_no, vendor_id } = item;
  //     if (!rowSpans[po_no]) {
  //       rowSpans[po_no] = {};
  //     }

  //     if (!rowSpans[po_no][vendor_id]) {
  //       rowSpans[po_no][vendor_id] = { count: 1, index };
  //     } else {
  //       rowSpans[po_no][vendor_id].count++;
  //     }
  //   });

  //   return rowSpans;
  // };

  // const rowSpans = calculateRowSpans(SalaryRegisterList);

  return (
    <>
      {EmployeeDetailsReportList && EmployeeDetailsReportList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  //   style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Employee Details{" "}
                      {EmployeeDetailsReport.department_id ||
                      EmployeeDetailsReport.employee_id ? (
                        <span style={{ fontSize: 16, marginLeft: "20px" }}>
                          {`( ${
                            EmployeeDetailsReport.department_id
                              ? EmployeeDetailsReportList[0].department_name
                              : ""
                          }${
                            EmployeeDetailsReport.department_id &&
                            EmployeeDetailsReport.employee_id
                              ? " - "
                              : ""
                          }${
                            EmployeeDetailsReport.employee_id
                              ? `${EmployeeDetailsReportList[0].first_name} ${EmployeeDetailsReportList[0].middle_name} ${EmployeeDetailsReportList[0].last_name}`
                              : ""
                          } )`}
                        </span>
                      ) : (
                        ""
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isExcelAccess && (
                      <Button
                        type="button"
                        fillMode={"solid"}
                        themeColor={"primary"}
                        onClick={handleExportExcel}
                        style={{ marginRight: 10 }}
                      >
                        Excel Export
                      </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 1,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 340px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  employee-details-report-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <thead>
                        <tr
                          className="text-center fw-600"
                          style={{
                            fontSize: 12,
                            position: "sticky",
                            top: 0,
                            outline: "1px solid #3b3b3b",
                          }}
                        >
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("employee_info") && (
                            <>
                              <th colSpan={17}>Employee Information</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("personal_info") && (
                            <>
                              <th colSpan={7}>Personal Information</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("present_address") && (
                            <>
                              <th colSpan={6}>Present Address</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("permanent_address") && (
                            <>
                              <th colSpan={6}>Permanent Address</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("emergency_contact") && (
                            <>
                              <th colSpan={3}>Emergency Contact</th>
                            </>
                          )}

                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("bank_details") && (
                            <>
                              <th colSpan={4}>Bank Details</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("pf_account_details") && (
                            <>
                              <th colSpan={3}>PF Account Details</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("esi_details") && (
                            <>
                              <th colSpan={2}>ESI Details</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("other_details") && (
                            <>
                              <th colSpan={3}>Other Details</th>
                            </>
                          )}

                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("salary_structure") && (
                            <>
                              <th colSpan={26}>Salary Structure</th>
                            </>
                          )}
                        </tr>

                        <tr
                          className="text-center fw-600"
                          style={{
                            fontSize: 12,
                            position: "sticky",
                            top: 39,
                            outline: "1px solid #3b3b3b",
                          }}
                        >
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("employee_info") && (
                            <>
                              <th style={{ minWidth: 200 }}>
                                Employee Code String
                              </th>
                              <th style={{ minWidth: 200 }}>First Name</th>
                              <th style={{ minWidth: 200 }}>Middle Name</th>
                              <th style={{ minWidth: 200 }}>Last Name</th>
                              <th style={{ minWidth: 200 }}>Gender</th>
                              <th style={{ minWidth: 200 }}>Mobile No</th>
                              <th style={{ minWidth: 200 }}>Email</th>
                              <th style={{ minWidth: 200 }}>Department Name</th>
                              <th style={{ minWidth: 200 }}>
                                Designation Name
                              </th>
                              <th style={{ minWidth: 200 }}>Official Email</th>
                              <th style={{ minWidth: 200 }}>Date Of Joining</th>
                              <th style={{ minWidth: 200 }}>Left Date</th>
                              <th style={{ minWidth: 200 }}>Work Shift Time</th>
                              <th style={{ minWidth: 200 }}>Weekly Off</th>
                              <th style={{ minWidth: 200 }}>Location</th>
                              <th style={{ minWidth: 200 }}>
                                Reporting Person Name
                              </th>
                              {/* <th style={{ minWidth: 200 }}>User Image</th> */}
                              <th style={{ minWidth: 200 }}>Reason Of Exit</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("personal_info") && (
                            <>
                              <th style={{ minWidth: 200 }}>DOB</th>
                              <th style={{ minWidth: 200 }}>Blood Group</th>
                              <th style={{ minWidth: 200 }}>Personal Email</th>
                              <th style={{ minWidth: 200 }}>Marital Status</th>
                              <th style={{ minWidth: 200 }}>
                                Date Of Marriage
                              </th>
                              <th style={{ minWidth: 200 }}>Spouse Name</th>
                              <th style={{ minWidth: 200 }}>Nationality</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("present_address") && (
                            <>
                              <th style={{ minWidth: 200 }}>Address</th>
                              <th style={{ minWidth: 200 }}>Street</th>
                              <th style={{ minWidth: 200 }}>Area</th>
                              <th style={{ minWidth: 200 }}>City</th>
                              <th style={{ minWidth: 200 }}>Pincode</th>
                              <th style={{ minWidth: 200 }}>State Name</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("permanent_address") && (
                            <>
                              <th style={{ minWidth: 200 }}>
                                Permanent Address
                              </th>
                              <th style={{ minWidth: 200 }}>
                                Permanent Street
                              </th>
                              <th style={{ minWidth: 200 }}>Permanent Area</th>
                              <th style={{ minWidth: 200 }}>Permanent City</th>
                              <th style={{ minWidth: 200 }}>
                                Permanent Pincode
                              </th>
                              <th style={{ minWidth: 200 }}>
                                Permanent State Name
                              </th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("emergency_contact") && (
                            <>
                              <th style={{ minWidth: 200 }}>Emergency Name</th>
                              <th style={{ minWidth: 200 }}>
                                Emergency Mobile
                              </th>
                              <th style={{ minWidth: 200 }}>
                                Emergency Relationship Name
                              </th>
                            </>
                          )}

                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("bank_details") && (
                            <>
                              <th style={{ minWidth: 200 }}>Bank Name</th>
                              <th style={{ minWidth: 200 }}>Account No</th>
                              <th style={{ minWidth: 200 }}>IFSC Code</th>
                              <th style={{ minWidth: 200 }}>Bank Branch</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("pf_account_details") && (
                            <>
                              <th style={{ minWidth: 200 }}>PF No</th>
                              <th style={{ minWidth: 200 }}>PF Join Date</th>
                              <th style={{ minWidth: 200 }}>UAN No</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("esi_details") && (
                            <>
                              <th style={{ minWidth: 200 }}>ESI No</th>
                              <th style={{ minWidth: 200 }}>ESI Join Date</th>
                            </>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("other_details") && (
                            <>
                              <th style={{ minWidth: 200 }}>Pancard</th>
                              <th style={{ minWidth: 200 }}>Aadharcard</th>
                              <th style={{ minWidth: 200 }}>Passport No</th>
                            </>
                          )}

                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("salary_structure") && (
                            <>
                              <th style={{ minWidth: 200 }}>Apply From Date</th>
                              <th style={{ minWidth: 200 }}>Basic</th>
                              <th style={{ minWidth: 200 }}>Ceiling Amount</th>
                              <th style={{ minWidth: 200 }}>HRA</th>
                              <th style={{ minWidth: 200 }}>Wash</th>
                              <th style={{ minWidth: 200 }}>
                                Production Insurance All
                              </th>
                              <th style={{ minWidth: 200 }}>Advance Bonus</th>
                              <th style={{ minWidth: 200 }}>Advance Leave</th>
                              <th style={{ minWidth: 200 }}>
                                Advance Standard Benefits
                              </th>
                              <th style={{ minWidth: 200 }}>Food</th>
                              <th style={{ minWidth: 200 }}>DA</th>
                              <th style={{ minWidth: 200 }}>Other Earning</th>
                              <th style={{ minWidth: 200 }}>PF Percentage</th>
                              <th style={{ minWidth: 200 }}>PF</th>
                              <th style={{ minWidth: 200 }}>ESI Percentage</th>
                              <th style={{ minWidth: 200 }}>ESI</th>
                              <th style={{ minWidth: 200 }}>IT</th>
                              <th style={{ minWidth: 200 }}>PT</th>
                              <th style={{ minWidth: 200 }}>Loan</th>
                              <th style={{ minWidth: 200 }}>Advance</th>
                              <th style={{ minWidth: 200 }}>LWF</th>
                              <th style={{ minWidth: 200 }}>TDS</th>
                              <th style={{ minWidth: 200 }}>Other</th>
                              <th style={{ minWidth: 200 }}>Total Income</th>
                              <th style={{ minWidth: 200 }}>Total Deduction</th>
                              <th style={{ minWidth: 200 }}>Payable Amount</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {EmployeeDetailsReportList &&
                          EmployeeDetailsReportList?.length > 0 &&
                          EmployeeDetailsReportList?.map(
                            (item: any, index: number) => {
                              return (
                                <tr className="text-center" key={index}>
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("employee_info") && (
                                    <>
                                      <td>{item?.employee_code_string}</td>
                                      <td>{item?.first_name}</td>
                                      <td>{item?.middle_name}</td>
                                      <td>{item?.last_name}</td>
                                      <td>{item?.gender}</td>
                                      <td>{item?.mobile_no}</td>
                                      <td>{item?.email}</td>
                                      <td>{item?.department_name}</td>
                                      <td>{item?.designation_name}</td>
                                      <td>{item?.official_email}</td>
                                      <td>
                                        {item?.date_of_joining
                                          ? moment(
                                              item?.date_of_joining,
                                              "DD/MM/YYYY"
                                            ).format("DD-MMM-YYYY")
                                          : ""}
                                      </td>
                                      <td>
                                        {item?.left_date
                                          ? moment(
                                              item?.left_date,
                                              "DD/MM/YYYY"
                                            ).format("DD-MMM-YYYY")
                                          : ""}
                                      </td>
                                      <td>{item?.work_shift_time}</td>
                                      <td>{item?.weekly_off}</td>
                                      <td>{item?.location}</td>

                                      <td>{item?.reporting_person_name}</td>
                                      {/* <td>
                                        <Avatar
                                          className="employee-image"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            backgroundColor: item?.user_image
                                              ? "white"
                                              : "#303F9F",
                                          }}
                                        >
                                          {item?.user_image ? (
                                            <img
                                              src={item?.user_image}
                                              alt={`${
                                                item?.first_name +
                                                item?.last_name
                                              }`}
                                              style={{
                                                height: "100%",
                                              }}
                                            />
                                          ) : (
                                            ` ${
                                              item?.first_name &&
                                              item?.last_name
                                                ? `${
                                                    item?.first_name[0]?.toUpperCase() +
                                                    item?.last_name[0]?.toUpperCase()
                                                  }`
                                                : ""
                                            } `
                                          )}
                                        </Avatar>
                                      </td> */}
                                      <td>{item?.reason_of_exit}</td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("personal_info") && (
                                    <>
                                      <td>{item?.dob}</td>
                                      <td>{item?.blood_group}</td>
                                      <td>{item?.personal_email}</td>
                                      <td>{item?.marital_status}</td>
                                      <td>
                                        {item?.date_of_marrige
                                          ? moment(
                                              item?.date_of_marrige,
                                              "DD/MM/YYYY"
                                            ).format("DD-MMM-YYYY")
                                          : ""}
                                      </td>
                                      <td>{item?.spouse_name}</td>
                                      <td>{item?.nationality}</td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("present_address") && (
                                    <>
                                      <td>{item?.address}</td>
                                      <td>{item?.street}</td>
                                      <td>{item?.area}</td>
                                      <td>{item?.city}</td>
                                      <td>{item?.pincode}</td>
                                      <td>{item?.state_name}</td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("permanent_address") && (
                                    <>
                                      <td>{item?.permenent_address}</td>
                                      <td>{item?.permenent_street}</td>
                                      <td>{item?.permenent_area}</td>
                                      <td>{item?.permenent_city}</td>
                                      <td>{item?.permenent_pincode}</td>
                                      <td>{item?.permenent_state_name}</td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("emergency_contact") && (
                                    <>
                                      <td>{item?.emergency_name}</td>
                                      <td>{item?.emergency_mobile}</td>
                                      <td>
                                        {item?.emergency_relationship_name}
                                      </td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("bank_details") && (
                                    <>
                                      <td>{item?.bank_name}</td>
                                      <td>{item?.account_no}</td>
                                      <td>{item?.ifsc_code}</td>
                                      <td>{item?.bank_branch}</td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("pf_account_details") && (
                                    <>
                                      <td>{item?.pf_no}</td>
                                      <td>
                                        {item?.pf_join_date
                                          ? moment(
                                              item?.pf_join_date,
                                              "DD/MM/YYYY"
                                            ).format("DD-MMM-YYYY")
                                          : ""}
                                      </td>
                                      <td>{item?.uan_no}</td>
                                    </>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("esi_details") && (
                                    <>
                                      <td>{item?.est_no}</td>
                                      <td>
                                        {item?.esi_join_date
                                          ? moment(
                                              item?.esi_join_date,
                                              "DD/MM/YYYY"
                                            ).format("DD-MMM-YYYY")
                                          : ""}
                                      </td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("other_details") && (
                                    <>
                                      <td>{item?.pancard}</td>
                                      <td>{item?.aadharcard}</td>
                                      <td>{item?.passport_no}</td>
                                    </>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("salary_structure") && (
                                    <>
                                      <td>
                                        {item?.apply_from_date
                                          ? moment(
                                              item?.apply_from_date,
                                              "DD/MM/YYYY"
                                            ).format("DD-MMM-YYYY")
                                          : ""}
                                      </td>
                                      <td>{item?.basic}</td>
                                      <td>{item?.celling_amount}</td>
                                      <td>{item?.hra}</td>
                                      <td>{item?.wash}</td>
                                      <td>{item?.prod_ins_all}</td>
                                      <td>{item?.adv_bonus}</td>
                                      <td>{item?.adv_leave}</td>
                                      <td>{item?.adv_std_be}</td>
                                      <td>{item?.food}</td>
                                      <td>{item?.da}</td>
                                      <td>{item?.other_earning}</td>
                                      <td>{item?.pf_per}</td>
                                      <td>{item?.pf}</td>
                                      <td>{item?.esi_per}</td>
                                      <td>{item?.esi}</td>
                                      <td>{item?.it}</td>
                                      <td>{item?.pt}</td>
                                      <td>{item?.loan}</td>
                                      <td>{item?.advance}</td>
                                      <td>{item?.lwf}</td>
                                      <td>{item?.tds}</td>
                                      <td>{item?.other}</td>
                                      <td>{item?.total_income}</td>
                                      <td>{item?.total_deduction}</td>
                                      <td>{item?.payable_amount}</td>
                                    </>
                                  )}
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default EmployeeDetailsReportForm;
