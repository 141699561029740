import { createSlice } from "@reduxjs/toolkit";
import {
  getEmployeeSalaryByIDDaysMonthYear,
  getEmployeeSalaryByIDYear,
  getEmployeeSalaryDaysByIDMonthYear,
  updateEmployeeSalaryGeneration,
} from "./services/salaryGeneration.services";
import { ISalaryGenerationInitial } from "./salaryGenerationModel";

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
const initialState: ISalaryGenerationInitial = {
  loading: false,
  error: "",
  SalaryGenerationList: [],
  SalaryGenerationDetail: {
    employee_id: null,
    payout_month: previousMonth,
    payout_year: `${previousYear}`,
    total_days: "",
    working_days: "",
    present_days: "",
    basic: null,
    celling_amount: null,
    da_per: null,
    hra_per: null,
    wash: null,
    adv_bonus: null,
    adv_leave: null,
    prod_ins_all: null,
    adv_std_be: null,
    food: null,
    other_earning: null,
    casual_leave: null,
    pf_per: null,
    esi_per: null,
    pt: null,
    it: null,
    lwf: null,
    tds: null,
    advance: null,
    loan: null,
    other: null,
    remarks: "",
    total_income: null,
    total_deduction: null,
    payable_amount: null,
    apply_from_date: "",
  },
  UpdateSalaryDetails: {
    isstatus: true,
    employee_id: null,
    payout_month: "",
    payout_year: "",
    total_days: "",
    working_days: "",
    present_days: "",
    basic: null,
    celling_amount: null,
    da_per: null,
    da: null,
    hra_per: null,
    hra: null,
    wash: null,
    adv_bonus: null,
    adv_leave: null,
    prod_ins_all: null,
    adv_std_be: null,
    food: null,
    other_earning: null,
    casual_leave: null,
    pf_per: null,
    pf: null,
    esi_per: null,
    esi: null,
    pt: null,
    it: null,
    lwf: null,
    tds: null,
    advance: null,
    loan: null,
    other: null,
    total_income: null,
    total_deduction: null,
    payable_amount: null,
    remarks: "",
  },
  SalaryGenerationViewFilter: {
    employee_id: null,
    year: `${previousYear}`,
  },
  IsGeneratedSalary: false,
};

const salaryGenerationSlice = createSlice({
  name: "salarygeneration",
  initialState,
  reducers: {
    clearSalaryGenerationDetail: (state) => {
      state.SalaryGenerationDetail = initialState.SalaryGenerationDetail;
    },
    setSalaryGenerationDetails: (state, action) => {
      state.SalaryGenerationDetail = action.payload;
    },
    clearSalaryGenerationList: (state) => {
      state.SalaryGenerationList = initialState.SalaryGenerationList;
    },
    clearUpdateSalaryDetails: (state) => {
      state.UpdateSalaryDetails = initialState.UpdateSalaryDetails;
    },
    setUpdateSalaryDetails: (state, action) => {
      state.UpdateSalaryDetails = action.payload;
    },
    setIsGeneratedSalary: (state, action) => {
      state.IsGeneratedSalary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEmployeeSalaryGeneration.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateEmployeeSalaryGeneration.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      updateEmployeeSalaryGeneration.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );

    builder.addCase(getEmployeeSalaryDaysByIDMonthYear.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEmployeeSalaryDaysByIDMonthYear.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getEmployeeSalaryDaysByIDMonthYear.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );
    builder.addCase(getEmployeeSalaryByIDDaysMonthYear.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEmployeeSalaryByIDDaysMonthYear.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getEmployeeSalaryByIDDaysMonthYear.rejected,
      (state, action) => {
        state.loading = false;

        state.error = action.error.message || "Something went wrong";
      }
    );

    builder.addCase(getEmployeeSalaryByIDYear.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getEmployeeSalaryByIDYear.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.SalaryGenerationList = action.payload;
      }
    );
    builder.addCase(getEmployeeSalaryByIDYear.rejected, (state, action) => {
      state.loading = false;
      state.SalaryGenerationList = initialState.SalaryGenerationList;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSalaryGenerationDetail,
  clearSalaryGenerationList,
  clearUpdateSalaryDetails,
  setUpdateSalaryDetails,
  setSalaryGenerationDetails,
  setIsGeneratedSalary,
} = salaryGenerationSlice.actions;
export default salaryGenerationSlice.reducer;
