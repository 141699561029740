import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IWarehouse } from "../warehouseModel";

export const getAllWarehouse = createAsyncThunk(
  "WareHouse/FindAllWareHouse",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/WareHouse/FindAllWareHouse`,
        {
          wareHouse: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createWarehouse = createAsyncThunk(
  "WareHouse/InsertWareHouse",
  async (wareHouse: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/WareHouse/InsertWareHouse`,
        wareHouse
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateWarehouse = createAsyncThunk(
  "WareHouse/UpdateWareHouse",
  async (wareHouse: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/WareHouse/UpdateWareHouse`,
        wareHouse
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getWarehouseById = createAsyncThunk(
  "WareHouse/FindByIDWareHouse",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/WareHouse/FindByIDWareHouse`,
        {
          warehouse_guid: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        warehouse_guid: result.warehouse_guid,
        warehouse_name: result.warehouse_name,
        remarks: result.remarks,
        address: result.address,
        isactive: result.isactive === true ? 1 : 2,
      } as IWarehouse;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteWarehouse = createAsyncThunk(
  "WareHouse/DeleteWareHouse",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/WareHouse/DeleteWareHouse`,
        {
            warehouse_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveWarehouse = createAsyncThunk(
  "WareHouse/FindAllActiveWareHouse",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/WareHouse/FindAllActiveWareHouse`
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const WarehouseActiveInactive = createAsyncThunk(
  "WareHouse/ActiveInActiveWareHouse",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/WareHouse/ActiveInActiveWareHouse`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive wareHouse:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
