import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICountry } from "../countryModel";

export const getAllCountries = createAsyncThunk(
  "country/findallitems",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/FindAllCountry`,
        {
          country_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCountries = createAsyncThunk(
  "country/findallactiveitems",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Country/FindAllActiveCountry`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCountry = createAsyncThunk(
  "country/insert",
  async (CountryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/InsertCountry`,
        CountryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCountry = createAsyncThunk(
  "country/update",
  async (CountryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/UpdateCountry`,
        CountryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCountryByID = createAsyncThunk(
  "country/findbyid",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/FindByIDCountry`,
        { country_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        country_guid: result.country_guid,
        country_name: result.country_name,
        country_code: result.country_code,
        isactive: result.isactive === true ? 1 : 2,
      } as ICountry;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const countryActiveInactive = createAsyncThunk(
  "country/ActiveInActiveCountry",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/ActiveInActiveCountry`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "country/delete",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/DeleteCountry`,
        { country_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
