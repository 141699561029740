import React, { CSSProperties } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Ripple } from "@progress/kendo-react-ripple";

type fillMode = "solid" | "outline" | "flat" | "link";
type Type = "submit" | "button" | "reset";
type themeColor =
  | "primary"
  | "base"
  | "secondary"
  | "tertiary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "light"
  | "inverse";

interface IButtonProps {
  label: string;
  disabled?: boolean;
  color?: string;
  fillMode?: fillMode;
  type?: Type;
  width?: string;
  themeColor?: themeColor;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const ButtonForAll: React.FC<IButtonProps> = ({
  label,
  disabled = false,
  fillMode = "solid",
  themeColor = "primary",
  type = "button",
  width = "fit-content",
  className = "",
  onClick,
  style,
}) => {
  return (
    <Ripple>
      <Button
        type={type}
        fillMode={fillMode}
        style={{ width: width, ...style }}
        themeColor={themeColor}
        disabled={disabled}
        className={className}
        onClick={onClick}
      >
        {label}
      </Button>
    </Ripple>
  );
};

export default ButtonForAll;
