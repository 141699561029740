import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccountGroup, IAccountGroupState } from "./accountgroupModel";
import {
  accountGroupActiveInactive,
  createAccountGroup,
  deleteAccountGroup,
  getAccountGroupByID,
  getAllAccountGroup,
  getAllActiveAccountGroup,
  updateAccountGroup,
} from "./services/accountGroup.services";

const initialState: IAccountGroupState = {
  loading: false,
  formLoading: false,
  error: "",
  AccountGroupList: [],
  AccountGroupID: null,
  AccountGroupDetail: {
    group_name: "",
    under_group_id: null,
    nature_of_group_id: null,
    isactive: 1,
  },
};

const accountgroupSlice = createSlice({
  name: "accountGroup",
  initialState,
  reducers: {
    clearAccountGroupDetails: (state) => {
      state.AccountGroupDetail = initialState.AccountGroupDetail;
    },
    setAccountGroupID: (state, action) => {
      state.AccountGroupID = action.payload;
    },
    clearAccountGroupID: (state) => {
      state.AccountGroupID = initialState.AccountGroupID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAccountGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAccountGroup.fulfilled,
      (state, action: PayloadAction<IAccountGroup[]>) => {
        state.loading = false;
        state.AccountGroupList = action.payload || [];
      }
    );
    builder.addCase(getAllAccountGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AccountGroupList = [];
    });
    builder.addCase(getAllActiveAccountGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAccountGroup.fulfilled,
      (state, action: PayloadAction<IAccountGroup[]>) => {
        state.loading = false;
        state.AccountGroupList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveAccountGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AccountGroupList = [];
    });
    builder.addCase(createAccountGroup.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createAccountGroup.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createAccountGroup.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAccountGroup.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateAccountGroup.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateAccountGroup.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAccountGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAccountGroup.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAccountGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(accountGroupActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(accountGroupActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(accountGroupActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAccountGroupByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getAccountGroupByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.AccountGroupDetail = action.payload;
    });
    builder.addCase(getAccountGroupByID.rejected, (state, action) => {
      state.formLoading = false;
      state.AccountGroupDetail = initialState.AccountGroupDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearAccountGroupDetails,
  setAccountGroupID,
  clearAccountGroupID,
} = accountgroupSlice.actions;
export default accountgroupSlice.reducer;
