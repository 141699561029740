import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDistrict, IDistrictInitialState } from "./districtModel";
import { deleteDistrict, districtActiveInactive, getAllActiveDistrict, getAllDistrict, getDistrictByID, InsertDistrict, updateDistrict } from "./services/district.services";

const initialState: IDistrictInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  DistrictList: [],
  DistrictID: null,
  DistrictDetail: {
    state_id: null,
    district_name: "",
    isactive: 1,
  },
};

const districtSlice = createSlice({
    name: "district",
    initialState,
    reducers: {
      clearDistrictDetails: (state) => {
        state.DistrictDetail = initialState.DistrictDetail;
      },
      setDistrictID: (state, action) => {
        state.DistrictID = action.payload;
      },
      clearDistrictID: (state) => {
        state.DistrictID = initialState.DistrictID;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllDistrict.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(
        getAllDistrict.fulfilled,
        (state, action: PayloadAction<IDistrict[]>) => {
          state.loading = false;
          state.DistrictList = action.payload || [];
        }
      );
      builder.addCase(getAllDistrict.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.DistrictList = [];
      });
      builder.addCase(InsertDistrict.pending, (state) => {
        state.formLoading = true;
        state.error = "";
      });
      builder.addCase(InsertDistrict.fulfilled, (state) => {
        state.formLoading = false;
      });
      builder.addCase(InsertDistrict.rejected, (state, action) => {
        state.formLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(updateDistrict.pending, (state) => {
        state.formLoading = true;
        state.error = "";
      });
      builder.addCase(updateDistrict.fulfilled, (state) => {
        state.formLoading = false;
      });
      builder.addCase(updateDistrict.rejected, (state, action) => {
        state.formLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(deleteDistrict.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(deleteDistrict.fulfilled, (state) => {
        state.loading = false;
      });
      builder.addCase(deleteDistrict.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(getDistrictByID.pending, (state) => {
        state.formLoading = true;
        state.error = "";
      });
      builder.addCase(getDistrictByID.fulfilled, (state, action: any) => {
        state.formLoading = false;
        state.DistrictDetail = action.payload;
      });
      builder.addCase(getDistrictByID.rejected, (state, action) => {
        state.formLoading = false;
        state.DistrictDetail = initialState.DistrictDetail;
        state.error = action.error.message || "Something went wrong";
      });
      builder.addCase(getAllActiveDistrict.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(
        getAllActiveDistrict.fulfilled,
        (state, action: PayloadAction<IDistrict[]>) => {
          state.loading = false;
          state.DistrictList = action.payload || [];
        }
      );
      builder.addCase(getAllActiveDistrict.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.DistrictList = [];
      });
      builder.addCase(districtActiveInactive.pending, (state) => {
        state.loading = true;
        state.error = "";
      });
      builder.addCase(districtActiveInactive.fulfilled, (state) => {
        state.loading = false;
      });
      builder.addCase(districtActiveInactive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
    },
  });


  export const { clearDistrictDetails, setDistrictID, clearDistrictID } = districtSlice.actions;
export default districtSlice.reducer;

