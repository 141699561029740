import { createSlice } from "@reduxjs/toolkit";

export interface IPaginationState {
  loading: boolean;
  error: string;
  currentPage: number;
  pageLimit: number;
}

const initialState: IPaginationState = {
  loading: false,
  error: "",
  currentPage: 0,
  pageLimit: 50,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPageLimit: (state, action) => {
      state.pageLimit = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearCurrentPage: (state) => {
      state.currentPage = 0;
    },
  },
  extraReducers: () => {},
});

export const { setPageLimit, setCurrentPage, clearCurrentPage } =
  paginationSlice.actions;
export default paginationSlice.reducer;
