import React, { CSSProperties } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Ripple } from "@progress/kendo-react-ripple";
import { Tooltip } from "@progress/kendo-react-tooltip";

type fillMode = "solid" | "outline" | "flat" | "link";
type Type = "submit" | "button" | "reset";
type Size = "small" | "medium" | "large";
type themeColor =
  | "primary"
  | "base"
  | "secondary"
  | "tertiary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "light"
  | "inverse";

interface IconButtonProps {
  disabled?: boolean;
  fillMode?: fillMode;
  type?: Type;
  size?: Size;
  style?: CSSProperties;
  themeColor?: themeColor;
  className?: string;
  title?: string;
  onClick?: () => void;
  children: React.ReactNode;
  ref?: any;
}

const IconButton: React.FC<IconButtonProps> = ({
  disabled = false,
  fillMode = "solid",
  size = "medium",
  themeColor = "primary",
  type = "button",
  style,
  className = "",
  title,
  onClick,
  children,
  ref,
}) => {
  return (
    <Tooltip anchorElement="target" position="top" parentTitle={true}>
      <Ripple>
        <Button
          ref={ref}
          type={type}
          size={size}
          title={title}
          fillMode={fillMode}
          style={{ width: 35, height: 35, position: "relative", ...style }}
          themeColor={themeColor}
          disabled={disabled}
          className={className}
          onClick={onClick}
        >
          {children}
        </Button>
      </Ripple>
    </Tooltip>
  );
};

export default IconButton;
