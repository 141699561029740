import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISetting, ISettingState } from "./settingModel";
import {
  activeInactiveSetting,
  createSetting,
  deleteSetting,
  getAllActiveSettings,
  getAllSettings,
  getSettingByID,
  updateSetting,
} from "./services/setting.services";

const initialState: ISettingState = {
  loading: false,
  formLoading: false,
  error: "",
  SettingList: [],
  SettingID: null,
  SettingDetail: {
    setting_key: "",
    setting_value: "",
    isactive: 1,
  },
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    clearSettingDetails: (state) => {
      state.SettingDetail = initialState.SettingDetail;
    },
    setSettingID: (state, action) => {
      state.SettingID = action.payload;
    },
    clearSettingID: (state) => {
      state.SettingID = initialState.SettingID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSettings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSettings.fulfilled,
      (state, action: PayloadAction<ISetting[]>) => {
        state.loading = false;
        state.SettingList = action.payload || [];
      }
    );
    builder.addCase(getAllSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SettingList = [];
    });
    builder.addCase(getAllActiveSettings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSettings.fulfilled,
      (state, action: PayloadAction<ISetting[]>) => {
        state.loading = false;
        state.SettingList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SettingList = [];
    });
    builder.addCase(createSetting.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createSetting.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createSetting.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSetting.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateSetting.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateSetting.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSetting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSetting.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSetting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(activeInactiveSetting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(activeInactiveSetting.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(activeInactiveSetting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSettingByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getSettingByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.SettingDetail = action.payload;
    });
    builder.addCase(getSettingByID.rejected, (state, action) => {
      state.formLoading = false;
      state.SettingDetail = initialState.SettingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearSettingDetails, setSettingID, clearSettingID } =
  settingSlice.actions;
export default settingSlice.reducer;
