import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { LoadingPanel } from "../../components/layout/Loading";
import { IoMdSettings } from "react-icons/io";
import useWindowSize from "../../_hooks/useWindowSize";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
// import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { updateExpense } from "./services/dashboard.services";
import { FaEye } from "react-icons/fa";
import { getAllDriverVisits } from "../drivervisit/services/driverVisit.services";
import { setDriverVisitID } from "../drivervisit/driverVisitSlice";
import { formatIndianNumber } from "../../_helper/helper";

const MAX_WIDTH = 768;

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const ExpChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const diesel = formRenderProps.valueGetter("diesel") || 0;
  const tea_coffee = formRenderProps.valueGetter("tea_coffee") || 0;
  const food_snacks = formRenderProps.valueGetter("food_snacks") || 0;
  const toll_tax_fasttag = formRenderProps.valueGetter("toll_tax_fasttag") || 0;
  const transport_receipt =
    formRenderProps.valueGetter("transport_receipt") || 0;
  const misc_exp = formRenderProps.valueGetter("misc_exp") || 0;
  const other_exp = formRenderProps.valueGetter("other_exp") || 0;

  useEffect(() => {
    formRenderProps.onChange("total_exp", {
      value:
        +diesel +
          +tea_coffee +
          +food_snacks +
          +toll_tax_fasttag +
          +transport_receipt +
          +misc_exp +
          +other_exp || 0,
    });
  }, [
    diesel,
    tea_coffee,
    food_snacks,
    toll_tax_fasttag,
    transport_receipt,
    misc_exp,
    other_exp,
  ]);

  return null;
};

const DriverVisitHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllDriverVisits());
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleGotoDashboard = async () => {
    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <DriverExpenseDialog />
          <div
            className={`col-12 d-flex justify-content-between align-items-center flex-nowrap pb-3 pt-3`}
          >
            <Typography.h4 className="m-0">Your History</Typography.h4>
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              style={{ width: "fit-content" }}
              onClick={handleGotoDashboard}
            >
              View Dashboard
            </Button>
          </div>
          <div className="col-12">
            <DriverGridComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const DriverGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const DriverVisitList = useAppSelector(
    (state) => state.drivervisit.DriverVisitList
  );
  const loading = useAppSelector((state) => state.drivervisit.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? DriverVisitList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenExpenseDialog = (ID: number) => {
      dispatch(openDialog("expenseDialog"));
      dispatch(setDriverVisitID(ID));
    };

    const handleViewDriverVisit = (ID: number) => {
      navigate("/drivervisit/view", {
        state: { id: ID },
      });
    };

    return !props.dataItem?.isupdatetrue ? (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Add Expense"
          onClick={() => handleOpenExpenseDialog(props.dataItem.id)}
        >
          <IoMdSettings
            className="absolute-position"
            style={{ fontSize: "20px" }}
          />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleViewDriverVisit(props.dataItem.id)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "24px" }} />
        </IconButton>
      </td>
    ) : (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleViewDriverVisit(props.dataItem.id)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "24px" }} />
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <KendoGrid
       style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
        filterable={true}
        filter={filter}
        data={
          filter
            ? filterBy(DriverVisitList, filter).slice(
                page.skip,
                page.take + page.skip
              )
            : DriverVisitList.slice(page.skip, page.take + page.skip)
        }
        onFilterChange={handleFilterChange}
        skip={page.skip}
        take={page.take}
        total={DriverVisitList.length}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 25, 50, "All"],
          pageSizeValue: pageSizeValue,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="id"
          filterable={false}
          title="Sr. No."
          cell={(props: any) => (
            <td className="text-center">{props?.dataIndex + 1}</td>
          )}
          width={"80px"}
        />
        <Column
          field="route_name"
          title="Route"
          width={width > MAX_WIDTH ? "unset" : "250px"}
        />
        <Column
          field="session_start"
          title="Session Start"
          width={width > MAX_WIDTH ? "unset" : "150px"}
        />
        <Column
          field="session_end"
          title="Session End"
          width={width > MAX_WIDTH ? "unset" : "150px"}
        />
        <Column
          field="helper_name"
          title="Helper"
          width={width > MAX_WIDTH ? "unset" : "200px"}
        />
        <Column
          field="total_amount"
          title="Total Amount"
          cell={(props: any) => (
            <td className="text-end">
              {formatIndianNumber(props?.dataItem?.total_amount)}
            </td>
          )}
          width={width > MAX_WIDTH ? "unset" : "120px"}
        />
        <Column
          field="approved_amount"
          title="Approved Amount"
          cell={(props: any) => (
            <td className="text-end">
              {formatIndianNumber(props?.dataItem?.approved_amount)}
            </td>
          )}
          width={width > MAX_WIDTH ? "unset" : "120px"}
        />
        <Column
          field="claim_status"
          title="Status"
          width={width > MAX_WIDTH ? "unset" : "100px"}
        />
        <Column
          field="id"
          title="Actions"
          cell={MyEditCommandCell}
          width={"100px"}
          filterable={false}
          locked={width > MAX_WIDTH}
        />
      </KendoGrid>
    </>
  );
};

const DriverExpenseDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const DriverVisitID = useAppSelector(
    (state) => state.drivervisit.DriverVisitID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.dashboard.loading);
  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        id: DriverVisitID,
        ...values,
      };
      const response = await dispatch(updateExpense(payload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(closeDialog());
        dispatch(getAllDriverVisits());
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <>
      {dialogName === "expenseDialog" && (
        <AppDialog className="driver-expense-dialog">
          <>
            <Typography.h4 className="m-0">{"Add Expense"}</Typography.h4>
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ maxWidth: 340 }}>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"diesel"}
                        label="Diesel"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"tea_coffee"}
                        label="Tea / Coffee"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"food_snacks"}
                        label="Food / Snacks"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"toll_tax_fasttag"}
                        label="Toll Tax / Fast Tag"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"transport_receipt"}
                        label="Transport"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"misc_exp"}
                        label="Misc."
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"other_exp"}
                        label="Others"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        wrapperClassName="right-alighned-field"
                        name={"total_exp"}
                        label="Total"
                        placeholder="0"
                        type="number"
                        minNumber="0"
                        disabled={true}
                        component={FormNumericTextField}
                      />
                    </div>
                    <div className="col-12 text-end" style={{ marginTop: 36 }}>
                      <ButtonWithLoading
                        label={"Save"}
                        buttonStyle={{ marginRight: 10 }}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                      <DialogCloseButton themeColor="error" />
                    </div>
                  </div>
                  <ExpChangeWatcher formRenderProps={formRenderProps} />
                </FormElement>
              )}
            />
          </>
        </AppDialog>
      )}
    </>
  );
};

export default DriverVisitHistory;
