import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearSettingDetails } from "./settingSlice";
import {
  createSetting,
  getAllActiveSettings,
  getAllSettings,
  getSettingByID,
  updateSetting,
} from "./services/setting.services";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";

const CreateSetting: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SettingDetail = useAppSelector((state) => state.setting.SettingDetail);
  const setting_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.setting.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    return () => {
      dispatch(clearSettingDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (setting_guid) {
      dispatch(getSettingByID(setting_guid));
    }
  }, [setting_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!setting_guid) {
        const insertPayload = {
          setting_key: values?.setting_key ? `${values?.setting_key}` : "",
          setting_value: values?.setting_value
            ? `${values?.setting_value}`
            : "",
          isactive: values?.isactive === 1,
        };
        const response = await dispatch(createSetting(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSettingDetails());
          isIndirect
            ? dispatch(getAllActiveSettings())
            : dispatch(getAllSettings());
        }
      } else {
        const editPayload = {
          setting_guid: setting_guid,
          setting_key: values?.setting_key ? `${values?.setting_key}` : "",
          setting_value: values?.setting_value
            ? `${values?.setting_value}`
            : "",
          isactive: values?.isactive === 1,
        };
        const response = await dispatch(updateSetting(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSettingDetails());
          isIndirect
            ? dispatch(getAllActiveSettings())
            : dispatch(getAllSettings());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={SettingDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {setting_guid ? "Update Setting" : "Add Setting"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="setting_key"
                label="Setting Key"
                placeholder="Setting Key"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="setting_value"
                label="Setting Value"
                placeholder="Setting Value"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={setting_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateSetting;
