import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

// export const getAllItemMarginRates = createAsyncThunk(
//   "ItemGroup/FindAllItemMarginRates",
//   async (UserType: number) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/ItemGroup/FindAllItemMarginRates`,
//         {
//           user_type_id: UserType,
//         }
//       );
//       const result = response.data?.Data;
//       return result.map((item: any) => {
//         return {
//           item_group_id: item?.item_group_id,
//           item_group: item?.item_group,
//           item_margin_rate: item?.item_margin_rate
//             ? item?.item_margin_rate?.map((subitem: any) => {
//                 return {
//                   item_id: subitem?.item_id ? +subitem?.item_id : null,
//                   unit_id: subitem?.unit_id ? +subitem?.unit_id : null,
//                   unit_name: subitem?.unit_name ? subitem?.unit_name : "",
//                   item_group_id: subitem?.item_group_id
//                     ? +subitem?.item_group_id
//                     : null,
//                   product_name: subitem?.product_name
//                     ? subitem?.product_name
//                     : "",
//                   mrp: subitem?.mrp ? +subitem?.mrp : null,
//                   rate: subitem?.rate ? +subitem?.rate : null,
//                   retail_margin: subitem?.retail_margin
//                     ? +subitem?.retail_margin
//                     : null,
//                   retail_rate_include_gst: subitem?.retail_rate_include_gst
//                     ? +subitem?.retail_rate_include_gst
//                     : null,
//                   retail_sgst: subitem?.retail_sgst
//                     ? +subitem?.retail_sgst
//                     : null,
//                   retail_cgst: subitem?.retail_cgst
//                     ? +subitem?.retail_cgst
//                     : null,
//                   retail_rate_exclude_gst: subitem?.retail_rate_exclude_gst
//                     ? +subitem?.retail_rate_exclude_gst
//                     : null,
//                   distributor_rate_include_gst:
//                     subitem?.distributor_rate_include_gst
//                       ? +subitem?.distributor_rate_include_gst
//                       : null,
//                   distributor_sgst: subitem?.distributor_sgst
//                     ? +subitem?.distributor_sgst
//                     : null,
//                   distributor_cgst: subitem?.distributor_cgst
//                     ? +subitem?.distributor_cgst
//                     : null,
//                   distributor_rate: subitem?.distributor_rate
//                     ? +subitem?.distributor_rate
//                     : null,
//                   gst_id: subitem?.gst_id ? +subitem?.gst_id : null,
//                   gst: subitem?.gst ? +subitem?.gst : null,
//                 };
//               })
//             : [],
//         };
//       });
//     } catch (error: any) {
//       // ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching Item Margin Rates:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

// export const createOldDistributorData = createAsyncThunk(
//   "SaleOrder/InsertPlaceOrder",
//   async (OrderData: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/SaleOrder/InsertPlaceOrder`,
//         OrderData
//       );
//       SuccessToast(response.data?.Details || "Success");
//       return response.data;
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error inserting distrubutors data:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

export const updateOldDistributorData = createAsyncThunk(
  "SaleOrder/DistributerPreviousYearQtyAdd",
  async (OrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DistributerPreviousYearQtyAdd`,
        OrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating distributors data:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOldDistributorDataByID = createAsyncThunk(
  "SaleOrder/DistributerQtyDetailFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DistributerQtyDetailFindByID`,
        { accountid: ID }
      );
      // return response.data?.Data as any;
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        accountid: result?.accountid,
        date: result?.date ? moment(result?.date, "YYYY-MM-DD").toDate() : "",
        distributer_qty: result?.distributer_qty
          ? result?.distributer_qty?.map((distributorQty: any) => {
              return {
                item_group_id: distributorQty?.item_group_id
                  ? distributorQty?.item_group_id
                  : null,
                item_group: distributorQty?.item_group
                  ? distributorQty?.item_group
                  : "",
                distributer_Previous_qty_list:
                  distributorQty?.distributer_Previous_qty_list
                    ? distributorQty?.distributer_Previous_qty_list?.map(
                        (subitem: any) => {
                          return {
                            id: subitem?.id ? +subitem?.id : null,
                            item_id: subitem?.item_id
                              ? +subitem?.item_id
                              : null,
                            item_group_id: subitem?.item_group_id
                              ? +subitem?.item_group_id
                              : null,
                            product_name: subitem?.product_name
                              ? subitem?.product_name
                              : "",
                            previous_year_qty: subitem?.previous_year_qty
                              ? +subitem?.previous_year_qty
                              : null,
                            current_year_qty: subitem?.current_year_qty
                              ? +subitem?.current_year_qty
                              : null,
                            unit_id: subitem?.unit_id
                              ? +subitem?.unit_id
                              : null,
                            unit_name: subitem?.unit_name
                              ? subitem?.unit_name
                              : "",
                          };
                        }
                      )
                    : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching order details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
