import React, { useEffect, useRef } from "react";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  clearFamilyDetails,
  decreaseActiveStep,
  increaseActiveStep,
} from "../employeeSlice";
import moment from "moment";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import FamilyDetailsArray from "./FamilyDetailsArray";
import { Button } from "@progress/kendo-react-buttons";
import { getAllActiveRelations } from "../../relation/services/relation.services";
import {
  getFamilyMemberByUserID,
  updateFamilyDetails,
} from "../services/employee.services";

const FamilyDetails: React.FC = () => {
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const formloading = useAppSelector((state) => state.employee.formloading);
  const FamilyDetails = useAppSelector(
    (state) => state.employee?.FamilyDetails
  );

  useEffect(() => {
    dispatch(getAllActiveRelations());
    return () => {
      dispatch(closeDialog());
      dispatch(clearFamilyDetails());
    };
  }, []);

  useEffect(() => {
    if (EmployeeID) {
      dispatch(getFamilyMemberByUserID(EmployeeID));
    }
  }, [EmployeeID]);

  const handleSubmit = async (values: any) => {
    if (EmployeeID) {
      const insertPayload = {
        employee_id: EmployeeID,
        employee_details: values?.FamilyMemberDetails
          ? values?.FamilyMemberDetails?.map((member: any) => {
              return {
                name: member?.name,
                relationship_id: member?.relationship_id,
                date: member?.date
                  ? moment(member?.date).format("YYYY-MM-DD")
                  : null,
                gender: member?.gender,
                blood_group: member?.blood_group,
              };
            })
          : [],
      };
      const response = await dispatch(updateFamilyDetails(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(increaseActiveStep());
      }
    }
  };

  if (formloading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={FamilyDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={FamilyDetailsArray}
                    name="FamilyMemberDetails"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => dispatch(decreaseActiveStep())}
                  >
                    Previous
                  </Button>
                  <ButtonWithLoading
                    label={"Save & Next"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formloading}
                    loading={formloading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default FamilyDetails;
