import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import moment from "moment";
import ButtonForAll from "../../components/common/Button";
import {
  convertToWords,
  formatIndianNumber,
  // getLocalStorageItem,
} from "../../_helper/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { getAllDistributorRetailerMarginRates } from "../distributoropeningstock/services/distributorOpeningStock.services";
import { clearRetailerSalesOrderDetails } from "./retailerSalesOrderSlice";
import {
  createRetailerSalesOrder,
  getRetailerSalesOrderByID,
  updateRetailerSalesOrder,
} from "./services/retailerSalesOrder.services";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getAllActiveRetailers } from "../retailer/services/retailer.services";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

// interface FormChangeWatcherProps {
//   formRenderProps: FormRenderProps;
// }
// const SchemeChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const FreeScheme = formRenderProps.valueGetter("free_scheme");
//   const CardScheme = formRenderProps.valueGetter("card_scheme");

//   useEffect(() => {
//     if (FreeScheme) {
//       formRenderProps.onChange("card_scheme", {
//         value: false,
//       });
//     }
//   }, [FreeScheme]);

//   useEffect(() => {
//     if (CardScheme) {
//       formRenderProps.onChange("free_scheme", {
//         value: false,
//       });
//     }
//   }, [CardScheme]);

//   return null;
// };
const CreateRetailerSalesOrder: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const AccountID = parseInt(AccountIDString);
  // const UserID = getLocalStorageItem("UserID");
  // const AccountID = useAppSelector(
  //   (state) => state.login.loginDetails.AccountID
  // );
  const StateIDString = useAppSelector(
    (state) => state.login.loginDetails.StateID
  );
  const StateID = parseInt(StateIDString);

  const loading = useAppSelector((state) => state.retailerSalesOrder.loading);
  const RetailerList = useAppSelector((state) => state.retailer.RetailerList);
  const MarginRateList = useAppSelector(
    (state) => state.distributorOpeningStock.MarginRateList
  );
  const RetailerSalesOrderDetail = useAppSelector(
    (state) => state.retailerSalesOrder.RetailerSalesOrderDetail
  );

  const [initialValues, setInitialValues] = useState({});
  const [formKey, setFormKey] = useState(1);
  const [showPrint, setShowPrint] = useState(false);
  const [submitAction, setSubmitAction] = useState("value");
  const [printData, setPrintData] = useState([]);
  const [RateData, setRateData] = useState<any[]>(
    JSON.parse(JSON.stringify(MarginRateList))
  );
  const [expanded, setExpanded] = React.useState<any>(0);

  useEffect(() => {
    dispatch(getAllActiveRetailers());
    dispatch(getAllDistributorRetailerMarginRates(false));
  }, []);

  useEffect(() => {
    dispatch(clearRetailerSalesOrderDetails());
    setInitialValues({});
  }, [location?.pathname]);

  useEffect(() => {
    if (id) {
      dispatch(getRetailerSalesOrderByID(+id));
    }
  }, [id]);

  useEffect(() => {
    const updatedMarginRatelist = JSON.parse(JSON.stringify(MarginRateList));
    const updatedData = updatedMarginRatelist.map((itemGroup: any) => {
      const updatedItems = itemGroup.item_margin_rate.map((item: any) => {
        RetailerSalesOrderDetail?.retailer_sales?.forEach((printGroup: any) => {
          if (item.item_group_id === printGroup.item_group_id) {
            printGroup.retailer_sales_order_list.forEach((printItem: any) => {
              if (item.item_id === printItem.item_id) {
                item.quantity = +printItem.quantity;
                item.amount = +printItem.amount;
                item.id = +printItem.id;
              }
            });
          }
        });
        return item;
      });
      return {
        ...itemGroup,
        item_margin_rate: updatedItems,
      };
    });

    setRateData(updatedData);
  }, [MarginRateList, RetailerSalesOrderDetail]);

  useEffect(() => {
    const newInitialValues =
      RetailerSalesOrderDetail &&
      RetailerSalesOrderDetail?.retailer_sales &&
      RetailerSalesOrderDetail?.retailer_sales
        ?.map((item: any) => {
          return item?.retailer_sales_order_list?.map((subitem: any) => {
            return {
              [`qty_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.quantity,
              [`amount_${item?.item_group_id}_${subitem?.item_id}`]:
                subitem?.amount,
            };
          });
        })
        ?.reduce((acc: any, curr: any) => {
          return {
            ...RetailerSalesOrderDetail,
            ...acc,
            ...curr.reduce((subAcc: any, subCurr: any) => {
              return { ...subAcc, ...subCurr };
            }, {}),
          };
        }, {});

    if (JSON.stringify(newInitialValues) !== JSON.stringify(initialValues)) {
      setInitialValues(newInitialValues);
      setFormKey((prevKey) => prevKey + 1);
    }
  }, [RetailerSalesOrderDetail]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, []);

  const handleQtyChange = (
    formRenderProps: FormRenderProps,
    mainIndex: number,
    subIndex: number,
    itemGroupId: number,
    itemId: number
  ) => {
    const qty = formRenderProps.valueGetter(`qty_${itemGroupId}_${itemId}`);

    const rate = RateData[mainIndex]?.item_margin_rate[subIndex]?.rate;
    if (qty === 0) {
      formRenderProps.onChange(`qty_${itemGroupId}_${itemId}`, {
        value: null,
      });
    }

    formRenderProps.onChange(`amount_${itemGroupId}_${itemId}`, {
      value: +rate * +qty || null,
    });

    RateData[mainIndex].item_margin_rate[subIndex].amount = +rate * +qty || 0;
    RateData[mainIndex].item_margin_rate[subIndex].quantity = +qty || 0;
  };

  const handlePrevious = () => {
    setShowPrint(!showPrint);
    RateData.forEach((itemGroup) => {
      itemGroup.item_margin_rate.forEach((item: any) => {
        printData.forEach((printGroup: any) => {
          if (item.item_group_id === printGroup.item_group_id) {
            printGroup.item_margin_rate.forEach((printItem: any) => {
              if (item.item_id === printItem.item_id) {
                item.quantity = printItem.quantity;
                item.amount = printItem.amount;
              }
            });
          }
        });
      });
    });
  };

  const handleSubmit = async (values: any) => {
    if (submitAction === "value") {
      const updatedValue: any = RateData?.map((item: any) => ({
        ...item,
        item_margin_rate: item?.item_margin_rate?.filter(
          (subItem: any) => subItem.quantity > 0
        ),
      }))?.filter((item: any) => item?.item_margin_rate?.length > 0);

      setPrintData(updatedValue);
      setShowPrint(!showPrint);
    } else {
      const place_order_items =
        printData &&
        printData.length > 0 &&
        printData?.flatMap(
          (salesorderitem: any) =>
            salesorderitem?.item_margin_rate &&
            salesorderitem?.item_margin_rate.length > 0 &&
            salesorderitem?.item_margin_rate?.map((item: any) => {
              return {
                id: item?.id ? +item?.id : 0,
                item_id: item?.item_id ? +item?.item_id : null,
                // item_group_id: item?.item_group_id
                //   ? +item?.item_group_id
                //   : null,
                mrp: item?.mrp ? +item?.mrp : null,
                quantity: item?.quantity ? +item?.quantity : null,
                unit_id: item?.unit_id ? +item?.unit_id : null,
                rate: item?.rate ? +item?.rate : null,
                // gst_id: item?.gst_id ? +item?.gst_id : null,
                total_amount: item?.amount ? +item?.amount : null,
              };
            })
        );

      if (id) {
        const editPayload: any = {
          id: id ? +id : null,
          retailerid: values?.retailerid ? +values?.retailerid : null,
          // createdby: 0,
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : "",
          retailer_sales_order_list: place_order_items || [],
        };

        const response = await dispatch(updateRetailerSalesOrder(editPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/retailersalesorder");
        }
      } else {
        const insertPayload: any = {
          retailerid: values?.retailerid ? +values?.retailerid : null,
          // createdby: 0,
          date: values?.date ? moment(values?.date).format("YYYY-MM-DD") : "",
          retailer_sales_order_list: place_order_items || [],
        };

        const response = await dispatch(
          createRetailerSalesOrder(insertPayload)
        );
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/retailersalesorder");
        }
      }
    }
  };

  let totalAmount = 0;
  let totalAmountGST5 = 0;
  let totalAmountGST12 = 0;
  let totalAmountGST18 = 0;
  let totalAmountGST28 = 0;
  let totalGST5 = 0;
  let totalGST12 = 0;
  let totalGST18 = 0;
  let totalGST28 = 0;

  printData &&
    printData.length > 0 &&
    printData?.map(
      (salesorderitem: any) =>
        salesorderitem?.item_margin_rate &&
        salesorderitem?.item_margin_rate.length > 0 &&
        salesorderitem?.item_margin_rate?.map((item: any) => {
          totalAmount += item?.amount;
          if (item?.gst === 5) {
            totalGST5 += (item?.amount * item?.gst) / 100;
            totalAmountGST5 += item?.amount;
          }
          if (item?.gst === 12) {
            totalGST12 += (item?.amount * item?.gst) / 100;
            totalAmountGST12 += item?.amount;
          }
          if (item?.gst === 18) {
            totalGST18 += (item?.amount * item?.gst) / 100;
            totalAmountGST18 += item?.amount;
          }
          if (item?.gst === 28) {
            totalGST28 += (item?.amount * item?.gst) / 100;
            totalAmountGST28 += item?.amount;
          }
        })
    );

  return (
    <div className={`${AccountID ? "container p-2" : ""}`}>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement
            style={{
              overflow: "scroll",
              scrollbarWidth: "none",
              boxShadow: "lightgray 0px 0px 10px",
              borderRadius: 10,
              background: "white",
            }}
          >
            {!showPrint ? (
              <ShadowCard style={{ padding: 12, marginBottom: 10 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {id
                        ? "Update Retailer Sales Order"
                        : "Add Retailer Sales Order"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={3} className="row">
                    <div className="col-md-4 pe-md-0">
                      <Field
                        name="retailerid"
                        label="Retailer Name"
                        placeholder="Retailer Name"
                        component={FormSelectionField}
                        validator={requiredValidator}
                        astrike={true}
                        options={RetailerList?.map((retailer: any) => {
                          return {
                            value: retailer?.id,
                            label: retailer?.retailer_name,
                          };
                        })}
                      />
                    </div>
                    <div className="col-md-4 pe-md-0">
                      <Field
                        name="date"
                        label="Date"
                        component={FormDatePicker}
                        validator={requiredValidator}
                        astrike={true}
                      />
                    </div>
                  </GridLayoutItem>
                  {/* <GridLayoutItem>
                  </GridLayoutItem> */}
                  <GridLayoutItem
                    colSpan={3}
                    style={{ overflow: "scroll", scrollbarWidth: "none" }}
                  >
                    <div
                      className="container-fluid orderform"
                      style={{ padding: "20px 0 0 0", minWidth: 800 }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <table
                            cellSpacing={1}
                            cellPadding={2}
                            className="table table-bordered w-100  salesorder-print-table"
                            style={{ marginTop: 1 }}
                          >
                            <tr
                              className="text-center fw-600 text-white bg-dark"
                              style={{ fontSize: 12 }}
                            >
                              <th className="pb-3 pt-3" style={{ width: "5%" }}>
                                No.
                              </th>
                              <th style={{ width: "45%" }}>
                                Description Of Goods
                              </th>
                              <th style={{ width: "10%" }}>MRP</th>
                              <th colSpan={2} style={{ width: "20%" }}>
                                Quantity / Per
                              </th>
                              {/* <th style={{ width: "10%" }}>Per</th> */}
                              <th style={{ width: "10%" }}>Rate</th>
                              <th style={{ width: "10%" }}>Amount</th>
                            </tr>
                            {RateData && RateData.length > 0 ? (
                              RateData?.map((item: any, mainIndex: number) => (
                                <tr key={mainIndex}>
                                  <td colSpan={7} className="p-0">
                                    <ExpansionPanel
                                      title={item?.item_group}
                                      expanded={expanded === mainIndex}
                                      tabIndex={0}
                                      key={item.id}
                                      onAction={(
                                        event: ExpansionPanelActionEvent
                                      ) => {
                                        setExpanded(
                                          event.expanded ? null : mainIndex
                                        );
                                      }}
                                    >
                                      <Reveal>
                                        {expanded === mainIndex && (
                                          <ExpansionPanelContent>
                                            {item?.item_margin_rate &&
                                              item?.item_margin_rate?.length >
                                                0 &&
                                              item?.item_margin_rate?.map(
                                                (
                                                  subitem: any,
                                                  subIndex: number
                                                ) => (
                                                  <div
                                                    key={subIndex}
                                                    id={`collapse${mainIndex}`}
                                                    // className={`accordion-collapse collapse ${
                                                    //   mainIndex === 0
                                                    //     ? "show"
                                                    //     : ""
                                                    // }`}
                                                    // data-bs-parent="#accordionExample"
                                                  >
                                                    <div
                                                      // className="accordion-body"
                                                      style={{
                                                        padding: "5px 0",
                                                      }}
                                                    >
                                                      <table
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <tr>
                                                          <td
                                                            style={{
                                                              width: "5%",
                                                            }}
                                                            className="text-center"
                                                          >
                                                            {subIndex + 1}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "45%",
                                                            }}
                                                          >
                                                            {
                                                              subitem?.product_name
                                                            }
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "10%",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            Rs.{" "}
                                                            {subitem?.mrp || 0}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "10%",
                                                            }}
                                                          >
                                                            <Field
                                                              wrapperClassName="right-alighned-field"
                                                              wrapperStyle={{
                                                                margin: 0,
                                                              }}
                                                              name={`qty_${item?.item_group_id}_${subitem?.item_id}`}
                                                              placeholder="0"
                                                              onChange={() =>
                                                                handleQtyChange(
                                                                  formRenderProps,
                                                                  mainIndex,
                                                                  subIndex,
                                                                  item?.item_group_id,
                                                                  subitem?.item_id
                                                                )
                                                              }
                                                              format="n2"
                                                              component={
                                                                FormNumericTextField
                                                              }
                                                            />
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "10%",
                                                            }}
                                                          >
                                                            {subitem?.unit_name}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "10%",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            Rs.{" "}
                                                            {subitem?.rate || 0}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "10%",
                                                              padding: "0 5px",
                                                            }}
                                                          >
                                                            <Field
                                                              wrapperClassName="right-alighned-field orderform-amount"
                                                              wrapperStyle={{
                                                                margin: 0,
                                                              }}
                                                              name={`amount_${item?.item_group_id}_${subitem?.item_id}`}
                                                              placeholder="0"
                                                              format="n2"
                                                              disabled={true}
                                                              component={
                                                                FormNumericTextField
                                                              }
                                                            />
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </ExpansionPanelContent>
                                        )}
                                      </Reveal>
                                    </ExpansionPanel>
                                    {/* <div
                                      className="accordion p-0 "
                                      id="accordionExample"
                                    >
                                      <div
                                        className="accordion-item"
                                        style={{ borderTop: 0 }}
                                      >
                                        <h2 className="accordion-header">
                                          <button
                                            className={`accordion-button ${
                                              mainIndex === 0 ? "" : "collapsed"
                                            }`}
                                            style={{ borderRadius: 0 }}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${mainIndex}`}
                                            aria-expanded={
                                              mainIndex === 0 ? "true" : "false"
                                            }
                                            aria-controls={`collapse${mainIndex}`}
                                          >
                                            <div
                                              className="fw-600"
                                              style={{ fontSize: 12 }}
                                            >
                                              {item?.item_group}
                                            </div>
                                          </button>
                                        </h2>
                                        {item?.item_margin_rate &&
                                          item?.item_margin_rate?.length > 0 &&
                                          item?.item_margin_rate?.map(
                                            (
                                              subitem: any,
                                              subIndex: number
                                            ) => (
                                              <div
                                                key={subIndex}
                                                id={`collapse${mainIndex}`}
                                                className={`accordion-collapse collapse ${
                                                  mainIndex === 0 ? "show" : ""
                                                }`}
                                                data-bs-parent="#accordionExample"
                                              >
                                                <div
                                                  className="accordion-body"
                                                  style={{ padding: "10px 0" }}
                                                >
                                                  <table
                                                    style={{ width: "100%" }}
                                                  >
                                                    <tr>
                                                      <td
                                                        style={{ width: "5%" }}
                                                        className="text-center"
                                                      >
                                                        {subIndex + 1}
                                                      </td>
                                                      <td
                                                        style={{ width: "45%" }}
                                                      >
                                                        {subitem?.product_name}
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "10%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Rs. {subitem?.mrp || 0}
                                                      </td>
                                                      <td
                                                        style={{ width: "10%" }}
                                                      >
                                                        <Field
                                                          wrapperClassName="right-alighned-field"
                                                          wrapperStyle={{
                                                            margin: 0,
                                                          }}
                                                          name={`qty_${item?.item_group_id}_${subitem?.item_id}`}
                                                          placeholder="0"
                                                          onChange={() =>
                                                            handleQtyChange(
                                                              formRenderProps,
                                                              mainIndex,
                                                              subIndex,
                                                              item?.item_group_id,
                                                              subitem?.item_id
                                                            )
                                                          }
                                                          format="n2"
                                                          component={
                                                            FormNumericTextField
                                                          }
                                                        />
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "10%",
                                                        }}
                                                      >
                                                        {subitem?.unit_name}
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "10%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Rs. {subitem?.rate || 0}
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "10%",
                                                          padding: "0 5px",
                                                        }}
                                                      >
                                                        <Field
                                                          wrapperClassName="right-alighned-field orderform-amount"
                                                          wrapperStyle={{
                                                            margin: 0,
                                                          }}
                                                          name={`amount_${item?.item_group_id}_${subitem?.item_id}`}
                                                          placeholder="0"
                                                          format="n2"
                                                          disabled={true}
                                                          component={
                                                            FormNumericTextField
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div> */}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={7} className="text-center p-2">
                                  No Records Found
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                        <div
                          className="col-12"
                          style={{
                            textAlign: "end",
                            marginTop: "20px",
                            width: "100%",
                          }}
                        >
                          <ButtonForAll
                            label="Verify"
                            type="submit"
                            onClick={() => setSubmitAction("value")}
                            disabled={
                              !formRenderProps.allowSubmit
                              // || loading
                            }
                            // loading={loading}
                          />
                          <Button
                            type="button"
                            fillMode={"outline"}
                            themeColor={"primary"}
                            style={{ marginLeft: 4 }}
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            ) : (
              <div
                className="order-container"
                style={{ padding: 12, minWidth: 800 }}
              >
                <div className="row">
                  <div className="col-12">
                    <Typography.h4 className="text-center">
                      Retailer Sales Order
                    </Typography.h4>
                  </div>
                  <div className="col-12 order-print-page">
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  order-print-table mb-0"
                      style={{ marginTop: 1 }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{ fontSize: 12 }}
                      >
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Sr No.
                        </th>
                        <th className="order-print-th" style={{ width: "40%" }}>
                          Description Of Goods
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          MRP
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Quantity
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Per
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Rate
                        </th>
                        <th className="order-print-th" style={{ width: "10%" }}>
                          Amount
                        </th>
                      </tr>
                      {printData &&
                        printData?.length > 0 &&
                        printData?.map((item: any) => (
                          <>
                            <tr>
                              <td
                                className="fw-600"
                                colSpan={7}
                                style={{ fontSize: 12 }}
                              >
                                {item?.item_group}
                              </td>
                            </tr>
                            {item?.item_margin_rate &&
                              item?.item_margin_rate?.length > 0 &&
                              item?.item_margin_rate?.map(
                                (subitem: any, index: number) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{subitem?.product_name}</td>
                                    <td>Rs. {subitem?.mrp || 0}</td>
                                    <td className="text-end">
                                      {subitem?.quantity}
                                    </td>
                                    <td>{subitem?.unit_name}</td>
                                    <td>
                                      Rs.{" "}
                                      {formatIndianNumber(subitem?.rate) || 0}
                                    </td>
                                    <td className=" text-end fw-500">
                                      {formatIndianNumber(subitem?.amount)}
                                    </td>
                                  </tr>
                                )
                              )}
                          </>
                        ))}
                      <tr>
                        <td colSpan={6}></td>
                        <td
                          className="text-end fw-600"
                          style={{ fontSize: 14 }}
                        >
                          {formatIndianNumber(totalAmount)}
                        </td>
                      </tr>
                      {StateID === 1 ? (
                        <>
                          {totalGST5 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 2.5%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">2.5</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST5 / 2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 2.5%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">2.5</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST5 / 2)}
                                </td>
                              </tr>
                            </>
                          )}
                          {totalGST12 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 6%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">6</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST12 / 2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 6%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">6</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST12 / 2)}
                                </td>
                              </tr>
                            </>
                          )}
                          {totalGST18 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 9%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">9</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST18 / 2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 9%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">9</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST18 / 2)}
                                </td>
                              </tr>
                            </>
                          )}
                          {totalGST28 > 0 && (
                            <>
                              <tr>
                                <td></td>
                                <td className=" text-end">CGST 14%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">14</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST28 / 2)}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className=" text-end">SGST 14%</td>
                                <td></td>
                                <td></td>
                                <td className=" text-end">14</td>
                                <td>%</td>
                                <td className=" text-end fw-500">
                                  {formatIndianNumber(+totalGST28 / 2)}
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {totalGST5 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 5%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">5</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {formatIndianNumber(+totalGST5)}
                              </td>
                            </tr>
                          )}
                          {totalGST12 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 12%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">12</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {formatIndianNumber(+totalGST12)}
                              </td>
                            </tr>
                          )}
                          {totalGST18 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 18%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">18</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {formatIndianNumber(+totalGST18)}
                              </td>
                            </tr>
                          )}
                          {totalGST28 > 0 && (
                            <tr>
                              <td></td>
                              <td className=" text-end">IGST 28%</td>
                              <td></td>
                              <td></td>
                              <td className=" text-end">28</td>
                              <td>%</td>
                              <td className=" text-end fw-500">
                                {formatIndianNumber(+totalGST28)}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      <tr style={{ fontSize: 12 }}>
                        <td
                          colSpan={6}
                          className=" text-end fw-600"
                          style={{ fontSize: 14 }}
                        >
                          {" "}
                          Total Invoice Amount:
                        </td>
                        <td
                          className="text-end fw-600"
                          style={{ fontSize: 14 }}
                        >
                          {formatIndianNumber(
                            Math.round(
                              +totalAmount +
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <span className="d-block p-0" style={{ fontSize: 8 }}>
                            Amount Chargeable (in words)
                          </span>
                          <span
                            className="d-block fw-600 p-0"
                            style={{ fontSize: 12, textTransform: "uppercase" }}
                          >
                            {convertToWords(
                              Math.round(
                                +totalAmount +
                                  +totalGST5 +
                                  +totalGST12 +
                                  +totalGST18 +
                                  +totalGST28
                              )
                            )}
                          </span>
                        </td>
                        <td className="text-end">E & O.E</td>
                      </tr>
                      {StateID === 1 ? (
                        <>
                          <tr
                            className="text-center fw-600"
                            style={{ fontSize: 13 }}
                          >
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={2}>Central Tax</th>
                            <th colSpan={2}>State Tax</th>
                            <th rowSpan={2} colSpan={2}>
                              Total Tax Amount
                            </th>
                          </tr>
                          <tr className="text-center" style={{ fontSize: 13 }}>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Amount</th>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr className="text-center" style={{ fontSize: 13 }}>
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={4}>Integrated Tax</th>
                            <th rowSpan={2} colSpan={2}>
                              Total Tax Amount
                            </th>
                          </tr>
                          <tr className="text-center" style={{ fontSize: 13 }}>
                            <th>Rate</th>
                            <th colSpan={3}>Amount</th>
                          </tr>
                        </>
                      )}
                      {StateID === 1 ? (
                        <>
                          {totalGST5 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST5)}</td>
                              <td>2.5 %</td>
                              <td className=" ">
                                {(+totalGST5 / 2).toFixed(2)}
                              </td>
                              <td>2.5 %</td>
                              <td>{formatIndianNumber(+totalGST5 / 2)}</td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST5)}
                              </td>
                            </tr>
                          )}
                          {totalGST12 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST12)}</td>
                              <td>6 %</td>
                              <td className=" ">
                                {(+totalGST12 / 2).toFixed(2)}
                              </td>
                              <td>6 %</td>
                              <td>{formatIndianNumber(+totalGST12 / 2)}</td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST12)}
                              </td>
                            </tr>
                          )}
                          {totalGST18 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST18)}</td>
                              <td>9 %</td>
                              <td className=" ">
                                {(+totalGST18 / 2).toFixed(2)}
                              </td>
                              <td>9 %</td>
                              <td>{formatIndianNumber(+totalGST18 / 2)}</td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST18)}
                              </td>
                            </tr>
                          )}
                          {totalGST28 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST28)}</td>
                              <td>14 %</td>
                              <td className=" ">
                                {(+totalGST28 / 2).toFixed(2)}
                              </td>
                              <td>14 %</td>
                              <td>{formatIndianNumber(+totalGST28 / 2)}</td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST28)}
                              </td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <>
                          {totalGST5 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST5)}</td>
                              <td>5 %</td>
                              <td colSpan={3}>
                                {formatIndianNumber(+totalGST5)}
                              </td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST5)}
                              </td>
                            </tr>
                          )}
                          {totalGST12 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST12)}</td>
                              <td>12 %</td>
                              <td colSpan={3}>
                                {formatIndianNumber(+totalGST12)}
                              </td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST12)}
                              </td>
                            </tr>
                          )}
                          {totalGST18 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST18)}</td>
                              <td>18 %</td>
                              <td colSpan={3}>
                                {formatIndianNumber(+totalGST18)}
                              </td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST18)}
                              </td>
                            </tr>
                          )}
                          {totalGST28 > 0 && (
                            <tr className="text-end">
                              <td>{formatIndianNumber(totalAmountGST28)}</td>
                              <td>28 %</td>
                              <td colSpan={3}>
                                {formatIndianNumber(+totalGST28)}
                              </td>
                              <td colSpan={2}>
                                {formatIndianNumber(+totalGST28)}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      {StateID === 1 ? (
                        <tr
                          className="text-end fw-600"
                          style={{ fontSize: 13 }}
                        >
                          <td>{formatIndianNumber(totalAmount)}</td>
                          <td></td>
                          <td>
                            {formatIndianNumber(
                              +(
                                (+totalGST5 +
                                  +totalGST12 +
                                  +totalGST18 +
                                  +totalGST28) /
                                2
                              )
                            )}
                          </td>
                          <td></td>
                          <td>
                            {formatIndianNumber(
                              +(
                                (+totalGST5 +
                                  +totalGST12 +
                                  +totalGST18 +
                                  +totalGST28) /
                                2
                              )
                            )}
                          </td>
                          <td colSpan={2}>
                            {formatIndianNumber(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className="text-end fw-600"
                          style={{ fontSize: 13 }}
                        >
                          <td>{formatIndianNumber(totalAmount)}</td>
                          <td></td>
                          <td colSpan={3}>
                            {formatIndianNumber(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )
                            )}
                          </td>
                          <td colSpan={2}>
                            {formatIndianNumber(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={7} style={{ textAlign: "end" }}>
                          <span className="d-block p-0" style={{ fontSize: 8 }}>
                            Tax Amount (in words)
                          </span>
                          <span
                            className="d-block fw-600 p-0"
                            style={{ fontSize: 12, textTransform: "uppercase" }}
                          >
                            {convertToWords(
                              +(
                                +totalGST5 +
                                +totalGST12 +
                                +totalGST18 +
                                +totalGST28
                              )?.toFixed(2)
                            )}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      // textAlign: "end",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="button"
                      fillMode={"solid"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() =>
                        // setShowPrint(!showPrint)
                        handlePrevious()
                      }
                    >
                      Previous
                    </Button>
                    <ButtonWithLoading
                      label="Place Order"
                      type="submit"
                      onClick={() => setSubmitAction("submit")}
                      loading={submitAction === "submit" && loading}
                      disabled={submitAction === "submit" && loading}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <SchemeChangeWatcher formRenderProps={formRenderProps} /> */}
          </FormElement>
        )}
      />
    </div>
  );
};

export default CreateRetailerSalesOrder;
