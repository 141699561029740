import { Avatar, MenuProps, Dropdown } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import UserLogo from "../../assets/Images/useravatar.jpg";

const UserProfile = () => {
  const navigate = useNavigate();
  const UserName = localStorage.getItem("UserName");
  const UserType = localStorage.getItem("UserType");
  const UserImage = localStorage.getItem("UserImage");
  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "logout") {
      navigate("/login");
      localStorage.clear();
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} trigger={["click"]}>
      <div className="userprofile">
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
              color: "#fff",
            }}
          >
            <strong>{UserName || ""}</strong>
            <strong style={{ fontSize: 14, fontWeight: 600 }}>
              {UserType || ""}
            </strong>
          </div>
          <Avatar
            className="userprofile-avatar"
            size={40}
            gap={0}
            icon={
              <img
                src={UserImage && UserImage !== "null" ? UserImage : UserLogo}
                alt={`user image`}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                }}
              />
            }
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default UserProfile;
