import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { getItemById } from "../Item/services/item.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { setInwardPackingDeleteIndex } from "./inwardPackingMaterialSlice";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";

const InwardPackingMaterialArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const inward_packing_material_guid =
    location.state?.inward_packing_material_guid;

  const InwardPackingDeleteIndex = useAppSelector(
    (state) => state.inwardPackingMaterial.InwardPackingDeleteIndex
  );

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);

  useEffect(() => {
    if (
      !inward_packing_material_guid &&
      fieldArrayRenderProps?.value?.length < 1
    ) {
      pushElementInarray();
    }
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("inwardPackingItemDeleteDialog"));
    dispatch(setInwardPackingDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/InwardPackingMaterial/DeleteInWardPackingItemDetails`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setInwardPackingDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setInwardPackingDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        inward_item_qty: null,
        unit_id: null,
        roll: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const InwardPackingMaterialDetails =
      fieldArrayRenderProps.formRenderProps.valueGetter(
        "InwardPackingMaterialDetails"
      );
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const response: any = await dispatch(getItemById(payload));
      InwardPackingMaterialDetails &&
        InwardPackingMaterialDetails.length > 0 &&
        InwardPackingMaterialDetails.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (response.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `InwardPackingMaterialDetails.${index}.unit_id`,
                {
                  value: response.payload?.unit_id,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `InwardPackingMaterialDetails.${index}.unit_id`,
                {
                  value: null,
                }
              );
            }
          }
        });
    } else {
      InwardPackingMaterialDetails &&
        InwardPackingMaterialDetails.length > 0 &&
        InwardPackingMaterialDetails.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `InwardPackingMaterialDetails.${index}.unit_id`,
              {
                value: null,
              }
            );
          }
        });
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((e: any, index: number, fieldArrayRenderProps: any) => {
      handleProductChange(e, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (e: any, index: number, fieldArrayRenderProps: any) => {
    debouncedHandleProductChange(e, index, fieldArrayRenderProps);
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              marginRight: 40,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 10 }}
            cols={[
              { width: "35%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem>Item Name</GridLayoutItem>
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Roll</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "inwardPackingItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(InwardPackingDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (InwardPackingMaterialDetails: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 40, padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "35%" },
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                    { width: "5%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`InwardPackingMaterialDetails.${index}.item_id`}
                      placeholder="Item Name"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={
                        (e: any) =>
                          handleChange(e, index, fieldArrayRenderProps)
                        // handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `InwardPackingMaterialDetails.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: any) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`InwardPackingMaterialDetails.${index}.inward_item_qty`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`InwardPackingMaterialDetails.${index}.unit_id`}
                      placeholder="Unit"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={UnitList?.map((unit: IUnit) => {
                        return {
                          value: unit?.id,
                          label: unit?.unit_code,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`InwardPackingMaterialDetails.${index}.roll`}
                      placeholder="0"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "20%" },
              { width: "20%" },
              { width: "20%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem colSpan={5} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default InwardPackingMaterialArray;
