import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../../components/formFields/FormTextField";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";

import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearExperienceDetails,
  clearExperienceDetailsGUID,
  clearExperienceDetailsList,
  decreaseActiveStep,
  increaseActiveStep,
  setExperienceDetails,
  setExperienceDetailsGUID,
} from "../employeeSlice";
import {
  createEmployeeExperience,
  deleteEmployeeExperience,
  getExperienceByUserID,
  updateEmployeeExperience,
} from "../services/employee.services";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { requiredValidator } from "../../../components/formFields/CommonValidator";

const ExperienceDetails = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;

  useEffect(() => {
    return () => {
      dispatch(clearExperienceDetails());
      dispatch(clearExperienceDetailsList());
    };
  }, []);

  useEffect(() => {
    if (EmployeeID) {
      dispatch(getExperienceByUserID(+EmployeeID));
    }
  }, [EmployeeID]);

  return (
    <>
      <DeleteExperienceDetailsDialog />
      <ExperienceDetailsForm />
      <ExperienceDetailsGridComponent />
    </>
  );
};

const ExperienceDetailsForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const EmployeeID = location.state?.EmployeeID;
  const loading = useAppSelector((state) => state.employee.loading);

  const ExperienceDetails = useAppSelector(
    (state) => state.employee.ExperienceDetails
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ExperienceDetails]);

  const handleSubmit = async (values: any) => {
    const formdata = {
      company: values?.company,
      designation: values?.designation,
      duration_from: values?.duration_from
        ? moment(values?.duration_from).format("YYYY-MM-DD")
        : null,
      duration_to: values?.duration_to
        ? moment(values?.duration_to).format("YYYY-MM-DD")
        : null,
      employee_id: EmployeeID,
    };
    try {
      if (!ExperienceDetails?.experience_guid) {
        const response = await dispatch(createEmployeeExperience(formdata));
        if (response?.payload?.Status === 201) {
          dispatch(getExperienceByUserID(EmployeeID));
          setFormKey(formKey + 1);
          dispatch(clearExperienceDetails());
        }
      } else {
        const editPayload = {
          experience_guid: ExperienceDetails?.experience_guid,
          ...formdata,
        };
        const response = await dispatch(updateEmployeeExperience(editPayload));

        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getExperienceByUserID(EmployeeID));
          setFormKey(formKey + 1);
          dispatch(clearExperienceDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={ExperienceDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "calc(33.33% - 50px)" },
                { width: "calc(33.33% - 50px)" },
                { width: "calc(33.33% - 40px)" },
                { width: "140px" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>{"Experience Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="company"
                  label="Company"
                  component={FormTextField}
                  placeholder="i.e. Company Name"
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="designation"
                  label="Designation"
                  placeholder="Enter Designation"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{ width: "100%", display: "flex", gap: 10 }}
              >
                <Field
                  wrapperClassName="w-100"
                  name="duration_from"
                  label="Duration From"
                  placeholder="From Date"
                  format="dd/MM/yyyy"
                  //minDate={moment().toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                  astrike={true}
                />
                <Field
                  wrapperClassName="w-100"
                  name="duration_to"
                  label="Duration To"
                  placeholder="To Date"
                  format="dd/MM/yyyy"
                  minDate={moment(
                    formRenderProps.valueGetter("duration_from")
                  ).toDate()}
                  maxDate={moment().toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearExperienceDetails());
                  }}
                >
                  Clear
                </Button>
                <ButtonWithLoading
                  label={ExperienceDetails?.experience_guid ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <Button
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </Button>
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => dispatch(increaseActiveStep())}
                >
                  Next
                </Button>
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => navigate("/employee")}
                >
                  Go to Employee List
                </Button>
              </GridLayoutItem>
            </GridLayout>
            {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const ExperienceDetailsGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const ExperienceDetailsList = useAppSelector(
    (state) => state.employee.ExperienceDetailsList
  );

  const loading = useAppSelector((state) => state.employee.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? ExperienceDetailsList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        experience_guid: item?.experience_guid,
        employee_id: item?.employee_id,
        company: item?.company,
        designation: item?.designation,
        duration_from: item?.duration_from
          ? moment(item?.duration_from, "DD/MM/YYYY").toDate()
          : "",
        duration_to: item?.duration_to
          ? moment(item?.duration_to, "DD/MM/YYYY").toDate()
          : "",
      };

      dispatch(setExperienceDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteExperienceDetails"));
      dispatch(setExperienceDetailsGUID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() =>
            handleOpenDeleteDialog(props.dataItem?.experience_guid)
          }
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 14 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {ExperienceDetailsList && ExperienceDetailsList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={ExperienceDetailsList}
          skip={page.skip}
          take={page.take}
          total={ExperienceDetailsList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="sr_no"
            title="Sr No."
            cell={(props: any) => {
              return <td>{props.dataIndex + 1}</td>;
            }}
          />
          <Column field="company" title="Company" width={300} />
          <Column field="duration_from" title="From Date" />
          <Column field="duration_to" title="To Date" />
          <Column field="total_duration" title="Duration" />
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteExperienceDetailsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;
  const ExperienceDetailsGUID = useAppSelector(
    (state) => state.employee.ExperienceDetailsGUID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: string) => {
    if (ID) {
      const response = await dispatch(deleteEmployeeExperience(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getExperienceByUserID(EmployeeID));
        dispatch(closeDialog());
        dispatch(clearExperienceDetailsGUID());
      } else {
        dispatch(getExperienceByUserID(EmployeeID));
        dispatch(closeDialog());
        dispatch(clearExperienceDetailsGUID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteExperienceDetails" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Experience Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Experience Details?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ExperienceDetailsGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default ExperienceDetails;
