import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDesignation, IDesignationState } from "./designationModel";
import {
  designationActiveInactive,
  createDesignation,
  deleteDesignation,
  getAllActiveDesignations,
  getAllDesignations,
  getDesignationByID,
  updateDesignation,
} from "./services/designation.services";

const initialState: IDesignationState = {
  loading: false,
  formLoading: false,
  error: "",
  DesignationList: [],
  DesignationID: null,
  DesignationGUID: "",
  DesignationDetail: {
    designation_name: "",
    isactive: 1,
  },
};

const designationSlice = createSlice({
  name: "designation",
  initialState,
  reducers: {
    clearDesignationDetails: (state) => {
      state.DesignationDetail = initialState.DesignationDetail;
    },
    setDesignationID: (state, action) => {
      state.DesignationID = action.payload;
    },
    clearDesignationID: (state) => {
      state.DesignationID = initialState.DesignationID;
    },
    setDesignationGUID: (state, action) => {
      state.DesignationGUID = action.payload;
    },
    clearDesignationGUID: (state) => {
      state.DesignationGUID = initialState.DesignationGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDesignations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDesignations.fulfilled,
      (state, action: PayloadAction<IDesignation[]>) => {
        state.loading = false;
        state.DesignationList = action.payload || [];
      }
    );
    builder.addCase(getAllDesignations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DesignationList = [];
    });
    builder.addCase(getAllActiveDesignations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveDesignations.fulfilled,
      (state, action: PayloadAction<IDesignation[]>) => {
        state.loading = false;
        state.DesignationList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveDesignations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DesignationList = [];
    });
    builder.addCase(createDesignation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createDesignation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createDesignation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateDesignation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateDesignation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateDesignation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteDesignation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDesignation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDesignation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(designationActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(designationActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(designationActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getDesignationByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getDesignationByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.DesignationDetail = action.payload;
    });
    builder.addCase(getDesignationByID.rejected, (state, action) => {
      state.formLoading = false;
      state.DesignationDetail = initialState.DesignationDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearDesignationDetails,
  setDesignationID,
  clearDesignationID,
  setDesignationGUID,
  clearDesignationGUID,
} = designationSlice.actions;
export default designationSlice.reducer;
