import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOutwardPacking,
  IOutwardPackingState,
} from "./outwardPackingMaterialModel";
import {
  createOutwardPackingMaterial,
  deleteOutwardPackingMaterial,
  getAllOutwardPackingMaterials,
  getOutwardPackingMaterialByID,
  updateOutwardPackingMaterial,
} from "./services/outwardPackingMaterial.services";
import moment from "moment";

const initialState: IOutwardPackingState = {
  loading: false,
  error: "",
  OutwardPackingDeleteIndex: -1,
  OutwardPackingID: null,
  OutwardPackingList: [],
  OutwardPackingDetail: {
    outward_type: "",
    outward_packing_material_date: moment().toDate(),
    remarks: "",
    financial_year: null,
    OutwardPackingMaterialDetails: [],
  },
};

const outwardPackingMaterialSlice = createSlice({
  name: "outwardPackingMaterial",
  initialState,
  reducers: {
    clearOutwardPackingDetails: (state) => {
      state.OutwardPackingDetail = initialState.OutwardPackingDetail;
    },
    setOutwardPackingID: (state, action) => {
      state.OutwardPackingID = action.payload;
    },
    clearOutwardPackingID: (state) => {
      state.OutwardPackingID = initialState.OutwardPackingID;
    },
    setOutwardPackingDeleteIndex: (state, action) => {
      state.OutwardPackingDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOutwardPackingMaterials.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOutwardPackingMaterials.fulfilled,
      (state, action: PayloadAction<IOutwardPacking[]>) => {
        state.loading = false;
        state.OutwardPackingList = action.payload || [];
      }
    );
    builder.addCase(getAllOutwardPackingMaterials.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OutwardPackingList = [];
    });
    builder.addCase(createOutwardPackingMaterial.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createOutwardPackingMaterial.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createOutwardPackingMaterial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateOutwardPackingMaterial.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateOutwardPackingMaterial.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOutwardPackingMaterial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteOutwardPackingMaterial.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteOutwardPackingMaterial.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOutwardPackingMaterial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getOutwardPackingMaterialByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getOutwardPackingMaterialByID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OutwardPackingDetail = action.payload;
      }
    );
    builder.addCase(getOutwardPackingMaterialByID.rejected, (state, action) => {
      state.loading = false;
      state.OutwardPackingDetail = initialState.OutwardPackingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearOutwardPackingDetails,
  clearOutwardPackingID,
  setOutwardPackingID,
  setOutwardPackingDeleteIndex,
} = outwardPackingMaterialSlice.actions;
export default outwardPackingMaterialSlice.reducer;
