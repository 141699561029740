import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMachine, IMachineInitialState } from "./machineModel";
import {
  createMachine,
  deleteMachine,
  findAllActiveMachine,
  getAllMachine,
  getItemMachineById,
  MachineActiveInactive,
  updateMachine,
} from "./services/machine.services";

const initialState: IMachineInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  MachineList: [],
  MachineID: null,
  MachineDetail: {
    machine_name: "",
    description: "",
    isactive: 1,
  },
};

export const machineSlice = createSlice({
  name: "machine",
  initialState,
  reducers: {
    clearMachineDetails: (state) => {
      state.MachineDetail = initialState.MachineDetail;
    },
    setMachineID: (state, action) => {
      state.MachineID = action.payload;
    },
    clearMachineID: (state) => {
      state.MachineID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMachine.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMachine.fulfilled,
      (state, action: PayloadAction<IMachine[]>) => {
        state.loading = false;
        state.MachineList = action.payload;
      }
    );
    builder.addCase(getAllMachine.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MachineList = [];
    });

    builder.addCase(findAllActiveMachine.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveMachine.fulfilled,
      (state, action: PayloadAction<IMachine[]>) => {
        state.loading = false;
        state.MachineList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveMachine.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MachineList = [];
    });

    builder.addCase(createMachine.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createMachine.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createMachine.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateMachine.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateMachine.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateMachine.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteMachine.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMachine.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMachine.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(MachineActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(MachineActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(MachineActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getItemMachineById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getItemMachineById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.MachineDetail = action.payload;
    });
    builder.addCase(getItemMachineById.rejected, (state, action) => {
      state.formLoading = false;
      state.MachineDetail = initialState.MachineDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearMachineDetails, setMachineID, clearMachineID } =
  machineSlice.actions;
export default machineSlice.reducer;
