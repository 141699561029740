import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBom, IBomInitial } from "./bomModel";
import {
  createBom,
  deleteBom,
  findAllBom,
  getAllActiveBom,
  getBomById,
  updateBom,
} from "./services/bom.services";

const initialState: IBomInitial = {
  loading: false,
  error: "",
  BomList: [],
  BomID: null,
  BomDetail: {
    item_id: null,
    item_qty: null,
    unit_id: null,
    total_amount: 0,
    BomDetails: [
      {
        bom_id: null,
        item_id: null,
        item_qty: null,
        rate: null,
        total: 0,
      },
    ],
    isactive: 1,
  },
  DeleteIndex: -1,
};

const bomSlice = createSlice({
  name: "bom",
  initialState,
  reducers: {
    clearBomDetail: (state) => {
      state.BomDetail = initialState.BomDetail;
    },
    setBomID: (state, action) => {
      state.BomID = action.payload;
    },
    clearBomID: (state) => {
      state.BomID = null;
    },
    setBomDetailsDeleteIndex: (state, action) => {
      state.DeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAllBom.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllBom.fulfilled,
      (state, action: PayloadAction<IBom[]>) => {
        state.loading = false;
        state.BomList = action.payload;
      }
    );
    builder.addCase(findAllBom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BomList = [];
    });

    builder.addCase(getAllActiveBom.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveBom.fulfilled,
      (state, action: PayloadAction<IBom[]>) => {
        state.loading = false;
        state.BomList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveBom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BomList = [];
    });

    builder.addCase(createBom.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createBom.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createBom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateBom.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateBom.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteBom.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBom.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getBomById.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getBomById.fulfilled, (state, action: any) => {
      state.loading = false;
      state.BomDetail = action.payload;
    });
    builder.addCase(getBomById.rejected, (state, action) => {
      state.loading = false;
      state.BomDetail = initialState.BomDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearBomDetail,
  clearBomID,
  setBomID,
  setBomDetailsDeleteIndex,
} = bomSlice.actions;
export default bomSlice.reducer;
