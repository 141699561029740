import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  emailWOValueValidator,
  MobileNoValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  clearVisitorDetails,
  clearVisitorID,
  setVisitorID,
} from "./visitorSlice";
import {
  createVisitor,
  getVisitorByID,
  updateVisitor,
  uploadVisitorImage,
} from "./services/visitor.services";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import moment from "moment";
// import FormImageUpload from "../../components/formFields/FormImageUpload";
import AppDialog from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import CameraModal from "./CameraModal";
import { BsCameraFill } from "react-icons/bs";
import UserLogo from "../../assets/Images/useravatar.jpg";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const InTimeChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const visitor_guid = location.state?.visitor_guid;

  useEffect(() => {
    if (!visitor_guid) {
      formRenderProps.onChange("in_time", {
        value: moment().toDate() || "",
      });
    }
  }, []);

  return null;
};

const CreateVisitor: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const visitor_guid = location.state?.visitor_guid;
  const loading = useAppSelector((state) => state.visitor.loading);
  const imageLoading = useAppSelector((state) => state.visitor.imageLoading);
  const VisitorDetail = useAppSelector((state) => state.visitor.VisitorDetail);
  const UserList = useAppSelector((state) => state.user.UserList);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState<File | null>(null);

  const handleCapture = (file: File, formRenderProps: FormRenderProps) => {
    setCapturedImage(file);
    setCameraOpen(false);
    if (visitor_guid) {
      formRenderProps.onChange("visitor_no", {
        value: VisitorDetail?.visitor_no || 0,
      });
    }
  };

  useEffect(() => {
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearVisitorDetails());
    };
  }, []);

  useEffect(() => {
    if (visitor_guid) {
      dispatch(getVisitorByID(visitor_guid));
    }
  }, [visitor_guid]);

  const handleSubmit = async (values: any) => {
    if (visitor_guid) {
      try {
        const updatePayload = {
          visitor_guid: VisitorDetail?.visitor_guid,
          visitor_date: values?.visitor_date
            ? moment(values?.visitor_date).format("YYYY-MM-DD")
            : "",
          user_id: values?.user_id ? +values?.user_id : null,
          name: values?.name ? values?.name : "",
          mobile_no: values?.mobile_no ? values?.mobile_no : "",
          email_id: values?.email_id ? values?.email_id : "",
          id_proof_no: values?.id_proof_no ? values?.id_proof_no : "",
          from_company: values?.from_company ? values?.from_company : "",
          reason: values?.reason ? values?.reason : "",
          in_time: values?.in_time
            ? moment(values?.in_time).format("HH:mm:ss")
            : "",
          out_time: values?.out_time
            ? moment(values?.out_time).format("HH:mm:ss")
            : "",
          financial_year: FINANCIAL_YEAR,
        };
        const response = await dispatch(updateVisitor(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/visitor");
          // const file =
          //   values?.visitor_image && typeof values?.visitor_image !== "string"
          //     ? values.visitor_image[0].getRawFile()
          //     : "";
          if (capturedImage && typeof capturedImage !== "string") {
            const ImageData = new FormData();
            ImageData.append("visitor_image", capturedImage || "");
            ImageData.append("visitor_guid", visitor_guid);
            dispatch(uploadVisitorImage(ImageData));
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          visitor_date: values?.visitor_date
            ? moment(values?.visitor_date).format("YYYY-MM-DD")
            : "",
          user_id: values?.user_id ? +values?.user_id : null,
          name: values?.name ? values?.name : "",
          mobile_no: values?.mobile_no ? values?.mobile_no : "",
          email_id: values?.email_id ? values?.email_id : "",
          id_proof_no: values?.id_proof_no ? values?.id_proof_no : "",
          from_company: values?.from_company ? values?.from_company : "",
          reason: values?.reason ? values?.reason : "",
          in_time: values?.in_time
            ? moment(values?.in_time).format("HH:mm:ss")
            : "",
          out_time: values?.out_time
            ? moment(values?.out_time).format("HH:mm:ss")
            : "",
          financial_year: FINANCIAL_YEAR,
        };
        const response = await dispatch(createVisitor(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          // navigate(-1);
          // const ImageData = new FormData();
          // const file =
          //   values?.visitor_image && typeof values?.visitor_image !== "string"
          //     ? values.visitor_image[0].getRawFile()
          //     : "";
          // ImageData.append("visitor_image", file);

          if (capturedImage && typeof capturedImage !== "string") {
            const ImageData = new FormData();
            ImageData.append("visitor_image", capturedImage || "");
            ImageData.append(
              "visitor_guid",
              response.payload?.Data?.visitor_guid
            );
            const res = await dispatch(uploadVisitorImage(ImageData));
            if (res.meta.requestStatus === "fulfilled") {
              dispatch(openDialog("printVisitor"));
              dispatch(setVisitorID(response.payload?.Data?.visitor_guid));
            }
          } else {
            dispatch(openDialog("printVisitor"));
            dispatch(setVisitorID(response.payload?.Data?.visitor_guid));
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading || imageLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={VisitorDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {visitor_guid ? "Update Visitor" : "Add Visitor"}
                  </Typography.h4>
                </GridLayoutItem>
                {visitor_guid && (
                  <GridLayoutItem>
                    <Field
                      // wrapperClassName="w-50"
                      name="visitor_no"
                      //   onKeyDown={handleVisitorNoChange}
                      label="Visitor No"
                      disabled={true}
                      placeholder="Visitor No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    // tabIndex={1}
                    name="visitor_date"
                    label="Visitor Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {visitor_guid ? (
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                ) : (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    // wrapperClassName="right-alighned-field"
                    // tabIndex={2}
                    name="name"
                    label="Name"
                    placeholder="i.e. John Smith"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={3}
                    name="mobile_no"
                    label="Mobile No"
                    placeholder="i.e. 9999999999"
                    type="number"
                    max="10"
                    component={FormTextField}
                    validator={MobileNoValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={4}
                    name="email_id"
                    label="Email"
                    placeholder="i.e. example@gmail.com"
                    component={FormTextField}
                    validator={emailWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={5}
                    name="id_proof_no"
                    label="ID Proof No"
                    placeholder="ID Proof No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={6}
                    name="from_company"
                    label="From Company"
                    placeholder="From Company"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={7}
                    name="user_id"
                    label="To Meet Person"
                    placeholder="Meeting Person Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={8}
                    wrapperStyle={{ width: "100%" }}
                    name="in_time"
                    label="In Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    // tabIndex={9}
                    wrapperStyle={{ width: "100%" }}
                    name="out_time"
                    label="Out Time"
                    component={FormTimePicker}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    // tabIndex={10}
                    name="reason"
                    label="Reason"
                    placeholder="Reason"
                    component={FormTextArea}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    marginRight: "14px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        margin: "10px 0 5px 0",
                      }}
                      className="k-label"
                    >
                      Visitor Image
                    </p>
                    <div className="visitor-image-container">
                      <img
                        src={
                          capturedImage
                            ? URL.createObjectURL(capturedImage)
                            : VisitorDetail?.visitor_image || UserLogo
                        }
                        alt="Captured Image"
                        className="visitor-image"
                      />
                      <div
                        className="captured-image-overlay"
                        onClick={() => setCameraOpen(true)}
                      >
                        <BsCameraFill />
                      </div>
                    </div>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 25,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={visitor_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 10 }}
                      onClick={() => navigate("/visitor")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
              <InTimeChangeWatcher formRenderProps={formRenderProps} />
            </ShadowCard>
            {cameraOpen && (
              <CameraModal
                onCapture={(file) => handleCapture(file, formRenderProps)}
                onClose={() => setCameraOpen(false)}
              />
            )}
          </FormElement>
        )}
      />
      <PrintVisitorDialog />
    </>
  );
};

const PrintVisitorDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const VisitorID = useAppSelector((state) => state.visitor.VisitorID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handlePrintAction = async (ID: any) => {
    if (ID) {
      window.open(`/visitorprint/${ID}`);
      navigate("/visitor");
      dispatch(closeDialog());
      dispatch(clearVisitorID());
    }
  };
  const handleNoButtonClick = () => {
    dispatch(closeDialog());
    navigate("/visitor");
  };

  return (
    <>
      {dialogName === "printVisitor" && (
        <AppDialog>
          <>
            <Typography.h5>{"Print Visitor Pass"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>Are you want to print Visitor Pass?</Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{ marginRight: 4 }}
                  fillMode={"outline"}
                  type="button"
                  themeColor={"error"}
                  onClick={() => handleNoButtonClick()}
                >
                  No
                </Button>
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handlePrintAction(VisitorID)}
                  type="button"
                >
                  Yes
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreateVisitor;
