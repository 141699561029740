import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import { checkAcessRights, formatIndianNumber } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getAccountReport } from "../services/reports.services";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getAllActiveAccountGroup } from "../../accountgroup/services/accountGroup.services";
import { IAccountGroup } from "../../accountgroup/accountgroupModel";
import { clearAccountReportList } from "../reportsSlice";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const AccountReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const AccountGroupList = useAppSelector(
    (state) => state.accountGroup.AccountGroupList
  );
  const AccountReportList = useAppSelector(
    (state) => state.reports.AccountReportList
  );

  useEffect(() => {
    dispatch(getAllActiveAccountGroup());

    return () => {
      dispatch(clearAccountReportList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    dispatch(
      getAccountReport(values?.uncder_group_id ? values?.uncder_group_id : 0)
    );
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            // initialValues={PurchaseRegisterDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Account Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="uncder_group_id"
                      label="Under Group"
                      placeholder="i.e. Capital Account"
                      validator={requiredValidator}
                      component={FormSelectionField}
                      options={
                        AccountGroupList?.map((accountGroup: IAccountGroup) => {
                          return {
                            value: accountGroup?.id,
                            label: accountGroup?.group_name,
                          };
                        }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      marginTop: 35,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          AccountReportList &&
          AccountReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <PurchaseRegisterGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  //   { label: "Account Name", value: "account_name" },
  { label: "Contact Person Name", value: "contact_person_name" },
  { label: "Mobile No.", value: "mobile_number" },
  { label: "Email ID", value: "email" },
  { label: "Billing Address", value: "address1" },
  { label: "State", value: "state_name" },
  { label: "Shipping Address", value: "address2" },
  { label: "PAN No.", value: "pan_no" },
  { label: "GST No.", value: "gst_no" },
  { label: "Route", value: "route_name" },
  { label: "Order Day", value: "order_day" },
  { label: "Delivery Day", value: "delivery_day" },
  { label: "Opening Balance", value: "opening_balance" },
  { label: "Payment Due Days", value: "payment_due_days" },
];

const PurchaseRegisterGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );
  const AccountReportList = useAppSelector(
    (state) => state.reports.AccountReportList
  );
  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  const handleRowClick = (id: string) => {
    const groupKey = `${id}`;
    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  };

  const initialValues = {
    filter_columns: [
      //   "account_name",
      "contact_person_name",
      "mobile_number",
      "email",
      "address1",
      "state_name",
      "address2",
      "pan_no",
      "gst_no",
      "route_name",
      "order_day",
      "delivery_day",
      "opening_balance",
      "payment_due_days",
    ],
  };

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".account-report-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "AccountReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Account Report`);
  };

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Account Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += "<th>Account Name</th>";
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("contact_person_name") &&
          (content += "<th>Contact Person Name</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("mobile_number") && (content += "<th>Mobile No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("email") &&
          (content += "<th>Email ID</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("address1") &&
          (content += "<th>Billing Address</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("state_name") &&
          (content += "<th>State</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("address2") &&
          (content += "<th>Shipping Address</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
          (content += "<th>PAN No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
          (content += "<th>GST No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("route_name") &&
          (content += "<th>Route</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("order_day") &&
          (content += "<th>Order Day</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("delivery_day") && (content += "<th>Delivery Day</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("opening_balance") &&
          (content += "<th>Opening Balance</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("payment_due_days") &&
          (content += "<th>Payment Due Days</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td>${data[j].account_name}</td>`;
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("contact_person_name") &&
            (content += `<td>${
              data[j].contact_person_name ? data[j].contact_person_name : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("mobile_number") &&
            (content += `<td style="text-align:center;">${data[j].mobile_number}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("email") &&
            (content += `<td>${data[j].email}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("address1") &&
            (content += `<td>${data[j].address1}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("state_name") &&
            (content += `<td>${data[j].state_name}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("address2") &&
            (content += `<td>${data[j].address2}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("pan_no") &&
            (content += `<td>${data[j].pan_no}</td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("gst_no") &&
            (content += `<td>${data[j].gst_no}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("route_name") &&
            (content += `<td>${data[j].route_name}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("order_day") &&
            (content += `<td>${
              data[j].order_day ? data[j].order_day : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("delivery_day") &&
            (content += `<td>${
              data[j].delivery_day ? data[j].delivery_day : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("opening_balance") &&
            (content += `<td style="text-align:end;">${
              data[j].opening_balance
                ? formatIndianNumber(data[j].opening_balance)
                : ""
            }</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("payment_due_days") &&
            (content += `<td style="text-align:center;">${
              data[j].payment_due_days ? data[j].payment_due_days : ""
            }</td>`);
        }
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {AccountReportList && AccountReportList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement
                style={{ width: "100%", minHeight: "calc(100vh - 290px)" }}
              >
                <GridLayout
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Account Report List
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(AccountReportList, formRenderProps)
                          }
                        >
                          Print
                        </Button>
                      )}
                      {isExcelAccess && (
                        <Button
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={handleExportExcel}
                          style={{ marginRight: 5 }}
                        >
                          Excel Export
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 1,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 334px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table account-report-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          outline: "1px solid #3b3b3b",
                        }}
                      >
                        <th style={{ minWidth: 250 }}>Account Name</th>
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("contact_person_name") && (
                          <th style={{ minWidth: 200 }}>Contact Person Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("mobile_number") && (
                          <th style={{ minWidth: 150 }}>Mobile No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("email") && (
                          <th style={{ minWidth: 250 }}>Email ID</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("address1") && (
                          <th style={{ minWidth: 350 }}>Billing Address</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("state_name") && (
                          <th style={{ minWidth: 150 }}>State</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("address2") && (
                          <th style={{ minWidth: 350 }}>Shipping Address</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("pan_no") && (
                          <th style={{ minWidth: 150 }}>PAN No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("gst_no") && (
                          <th style={{ minWidth: 170 }}>GST No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("route_name") && (
                          <th style={{ minWidth: 200 }}>Route</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("order_day") && (
                          <th style={{ minWidth: 150 }}>Order Day</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("delivery_day") && (
                          <th style={{ minWidth: 150 }}>Delivery Day</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("opening_balance") && (
                          <th style={{ minWidth: 150 }}>Opening Balance</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("payment_due_days") && (
                          <th style={{ minWidth: 120 }}>Payment Due Days</th>
                        )}
                      </tr>
                      {AccountReportList &&
                        AccountReportList?.length > 0 &&
                        AccountReportList?.map((item: any) => {
                          const groupKey = `${item.id}`;
                          const isSelected = selectedGroup === groupKey;
                          return (
                            <tr
                              className="text-center"
                              style={{
                                background: isSelected
                                  ? "rgb(255 198 198 / 53%)"
                                  : "transparent",
                              }}
                              onClick={() => handleRowClick(item.id)}
                            >
                              <td className="text-start">
                                {item?.account_name}
                              </td>
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("contact_person_name") && (
                                <td className="text-start">
                                  {item?.contact_person_name}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("mobile_number") && (
                                <td>{item?.mobile_number}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("email") && (
                                <td className="text-start">{item?.email}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("address1") && (
                                <td className="text-start">{item?.address1}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("state_name") && (
                                <td>{item?.state_name}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("address2") && (
                                <td className="text-start">{item?.address2}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("pan_no") && (
                                <td className="text-start">{item?.pan_no}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("gst_no") && (
                                <td className="text-start">{item?.gst_no}</td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("route_name") && (
                                <td className="text-start">
                                  {item?.route_name}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("order_day") && (
                                <td className="text-start">
                                  {item?.order_day}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("delivery_day") && (
                                <td className="text-start">
                                  {item?.delivery_day}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("opening_balance") && (
                                <td className="text-end">
                                  {formatIndianNumber(item?.opening_balance) ||
                                    0}
                                </td>
                              )}
                              {formRenderProps
                                .valueGetter("filter_columns")
                                ?.includes("payment_due_days") && (
                                <td>{item?.payment_due_days}</td>
                              )}
                            </tr>
                          );
                        })}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default AccountReportForm;
