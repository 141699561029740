import React, { useEffect, useRef } from "react";
// import { getEmployeeRegisterReport } from "../services/reports.services";
import {
  // useAppDispatch,
  useAppSelector,
} from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
// import { MONTHS_MAP } from "../../../_contstants/common";
// import { convertToWords } from "../../../_helper/helper";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  COMPANY_ADDRESS,
  COMPANY_NAME,
  MONTHS_MAP,
} from "../../../_contstants/common";

const WageRegisterReport = () => {
  const state = JSON.parse(sessionStorage.getItem("wageRegisterState") || "{}");
  // console.log("State", state);

  // const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);

  const WageRegisterReportList = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportList
  );
  console.log(WageRegisterReportList);

  const fetchReport = async () => {
    const payload = {
      department_id: state?.department_id,
    };
    console.log(payload);

    // await dispatch(getWageRegisterReport(payload));
    // window.print();
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("wageRegisterState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const style: Record<string, React.CSSProperties> = {
    headingText: {
      fontSize: "14px",
      fontWeight: "bold",
      margin: 0,
    },
    headingTextSub: {
      fontSize: "12px",
      margin: 0,
    },
    hr: { margin: "1rem 0", borderTop: "1px dashed #000" },
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {/* {WageRegisterReportList && WageRegisterReportList.length > 0 && ( */}
      <div>
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <div>
          <div
            className="d-flex align-items-end justify-content-between"
            style={{ borderBottom: "1px solid #000" }}
          >
            <div style={{ width: "25%" }}></div>
            <div style={{ textAlign: "center", width: "50%" }}>
              <p style={style.headingText}>
                FORM B <br /> WAGE REGISTER
              </p>
            </div>
            <div style={{ width: "25%" }}>
              <p style={style.headingTextSub}>
                <b>LIN No:</b>
              </p>
            </div>
          </div>
          <div
            className="d-flex justify-content-between py-2"
            style={{ borderBottom: "1px solid #000" }}
          >
            <div className="text-start">
              <p style={style.headingText}> {COMPANY_NAME}</p>
              <p style={style.headingTextSub}>{COMPANY_ADDRESS}</p>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between py-1">
            <div>
              <p style={style.headingTextSub}>
                <b>Deptartment :-</b>
              </p>
            </div>
            <div style={{ textAlign: "start" }}>
              <p style={style.headingTextSub}>
                <b>
                  For The Month Of {MONTHS_MAP[state.payout_month]} -{" "}
                  {state.payout_year}
                </b>
              </p>
            </div>
          </div>
          <table className="wage-register-print-table">
            <thead>
              <tr>
                <th rowSpan={2}>Sr. No</th>
                <th rowSpan={2}>Employee Code</th>
                <th
                  rowSpan={2}
                  style={{ textAlign: "start", lineHeight: "14px" }}
                >
                  <p style={{ whiteSpace: "nowrap" }}>Employee Name</p>
                  <p>Designation</p>
                  <p>P.F. No</p>
                  <p>UAN No.</p>
                </th>
                <th rowSpan={2}>Rate of Wages</th>
                <th rowSpan={2}>No of Days Worked</th>
                <th rowSpan={2}>OT Hours Worked</th>
                <th rowSpan={2}>
                  <p>Basic</p>
                  <p>Sp.Basic</p>
                </th>
                <th rowSpan={2} style={{ padding: "5px 15px" }}>
                  <p>DA</p>
                  <p>HRA</p>
                </th>
                <th rowSpan={2}>
                  <p>Overtime</p>
                  <p>Others</p>
                  <p>ADV</p>
                  <p>ADV</p>
                </th>
                <th rowSpan={2} style={{ padding: "5px 15px" }}>
                  Gross Salary
                </th>
                <th colSpan={3}>DEDUCTION</th>
                <th rowSpan={2}>Total Deduction</th>
                <th rowSpan={2}>Net Payment</th>
                <th rowSpan={2}>Employer Share of Welfare fund</th>
                <th rowSpan={2}>Rec. By the employee / Bank Transaction ID</th>
                <th rowSpan={2}>Date Of Payment / Remarks</th>
              </tr>
              <tr>
                <th style={{ padding: "5px 15px" }}>
                  <p>P.F.</p>
                  <p>ESI</p>
                  <p>P.T.</p>
                </th>
                <th>
                  <p>I.T.</p>
                  <p>LWF</p>
                  <p>Insuran.</p>
                  <p>TDS</p>
                </th>
                <th style={{ padding: "5px 10px" }}>
                  <p>Adv</p>
                  <p>Loan</p>
                  <p>Oth.Ded</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {WageRegisterReportList.map((item: any, index: number) => ( */}
              <tr className="wages-register-print-table-row">
                <td>1</td>
                <td>107</td>
                <td
                  style={{
                    textTransform: "uppercase",
                    textAlign: "start",
                    lineHeight: "16px",
                    fontSize: "12px",
                    minWidth: "200px",
                  }}
                >
                  <p>
                    <b>manoj ramsing solanki</b>
                  </p>
                  <p>Unkilled Woker</p>
                  <p>149</p>
                  <p>100597109791</p>
                </td>
                <td className="wage-register-table-cell">
                  <p>
                    <span>BASIC</span>
                    <span>11800.00</span>
                  </p>
                  <p>
                    <span>DA</span>
                    <span>11800.00</span>
                  </p>
                  <p>
                    <span>HRA</span>
                    <span>11800.00</span>
                  </p>
                  <p>
                    <span>Other All</span>
                    <span>11800.00</span>
                  </p>
                  <br />
                  <p style={{ padding: "2px 0" }}>
                    <span>Total</span>
                    <span>11800.00</span>
                  </p>
                </td>
                <td className="wage-register-table-cell">
                  <p>
                    <span>WD</span>
                    <span>27.00</span>
                  </p>
                  <p>
                    <span>PD</span>
                    <span>24.50</span>
                  </p>
                  <p>
                    <span>WO</span>
                    <span>4.00</span>
                  </p>
                  <p>
                    <span>PH</span>
                    <span>0.00</span>
                  </p>
                  <br />
                  <p style={{ padding: "2px 0" }}>
                    <span>TOT</span>
                    <span>28.50</span>
                  </p>
                </td>
                <td>0.00</td>
                <td>10707</td>
                <td>
                  <p>0</p>
                  <p>817</p>
                </td>
                <td>
                  <p>0</p>
                  <p>0</p>
                  <p>0</p>
                  <p>0</p>
                </td>
                <td>
                  <b>11524</b>
                </td>
                <td>
                  <p>1285</p>
                  <p>87</p>
                  <p>0</p>
                </td>
                <td>
                  <p>0</p>
                  <p>0</p>
                  <p>0</p>
                  <p>0</p>
                </td>
                <td>
                  <p>0</p>
                  <p>0</p>
                  <p>0</p>
                </td>
                <td>1372</td>
                <td>
                  <b>10152.00</b>
                </td>
                <td>1285</td>
                <td style={{ position: "relative" }}>
                  <p
                    style={{
                      whiteSpace: "pre",
                      right: 5,
                      bottom: 35,
                      position: "absolute",
                    }}
                  >
                    <b>Bank : KOTAK MAHINDRA BANK</b>
                  </p>

                  <p
                    style={{
                      whiteSpace: "pre",
                      right: 5,
                      bottom: 20,
                      position: "absolute",
                      textTransform: "uppercase",
                    }}
                  >
                    <b>A/c No:5048777969</b>
                  </p>
                </td>
                <td></td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default WageRegisterReport;
