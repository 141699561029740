import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IMachineCleaning } from "../machinecleaningModel";
import moment from "moment";

export const getAllMachineCleaning = createAsyncThunk(
  "MachineCleaning/FindAllMachineCleaning",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MachineCleaning/FindAllMachineCleaning`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Machine Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMachineCleaning = createAsyncThunk(
  "MachineCleaning/InsertMachineCleaning",
  async (machineCleaning: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MachineCleaning/InsertMachineCleaning`,
        machineCleaning
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Machine Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMachineCleaning = createAsyncThunk(
  "MachineCleaning/UpdateMachineCleaning",
  async (machineCleaning: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MachineCleaning/UpdateMachineCleaning`,
        machineCleaning
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Machine Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMachineCleaningById = createAsyncThunk(
  "MachineCleaning/FindByIDMachineCleaning",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MachineCleaning/FindByIDMachineCleaning`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        cleaning_no: result.cleaning_no,
        machine_cleaning_guid: result.machine_cleaning_guid,
        machine_no: result.machine_no,
        item_id: result.item_id,
        item_options: result.item_options,
        grinding_lot_no: result.grinding_lot_no,
        production_qty: result.production_qty,
        maintenance_time: result.maintenance_time,
        machine_cleaning_date: result.machine_cleaning_date
          ? moment(result.machine_cleaning_date, "YYYY-MM-DD").toDate()
          : "",
        qc_by: result.qc_by,
        production_by: result.production_by,
        remarks: result.remarks,
      } as IMachineCleaning;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Machine Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMachineCleaning = createAsyncThunk(
  "MachineCleaning/DeleteMachineCleaning",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MachineCleaning/DeleteMachineCleaning`,
        {
          machine_cleaning_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Machine Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveMachineCleaning = createAsyncThunk(
  "MachineCleaning/FindAllActiveMachineCleaning",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MachineCleaning/FindAllActiveMachineCleaning`
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Machine Cleaning:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
