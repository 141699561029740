import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICity } from "../cityModel";

export const getAllCities = createAsyncThunk("City/FindAllCity", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/City/FindAllCity`,
      {
        city_name: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Cities:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createCity = createAsyncThunk(
  "City/InsertCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/InsertCity`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCity = createAsyncThunk(
  "City/UpdateCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/UpdateCity`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCity = createAsyncThunk(
  "City/DeleteCity",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/DeleteCity`,
        { city_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCityByID = createAsyncThunk(
  "City/FindByIDCity",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/FindByIDCity`,
        { city_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        city_guid: result.city_guid,
        country_id: result.country_id,
        state_id: result.state_id,
        city_name: result.city_name,
        isactive: result.isactive === true ? 1 : 2,
      } as ICity;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCities = createAsyncThunk(
  "City/FindAllActiveCity",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/City/FindAllActiveCity`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const cityActiveInactive = createAsyncThunk(
  "City/ActiveInActiveCity",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/ActiveInActiveCity`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
