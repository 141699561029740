import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
// import { FiEdit } from "react-icons/fi";
import { getYearsArray, useResponsiveJSX } from "../../_helper/helper";
import { MONTHS_MAP } from "../../_contstants/common";
import { clearSalaryGenerationList } from "./salryGenerationSlice";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveEmployee } from "../employee/services/employee.services";
import { IEmployee } from "../employee/employeeModel";
import { getEmployeeSalaryByIDYear } from "./services/salaryGeneration.services";

const SalaryGenerationViewUpdated: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllActiveEmployee());
    return () => {
      dispatch(closeDialog());
      dispatch(clearSalaryGenerationList());
    };
  }, []);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Salary View
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <GeneratedSalaryViewFilter />
        </GridLayoutItem>
        <GridLayoutItem style={{ marginTop: 20 }}>
          <SalaryGenerationViewGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const SalaryGenerationViewGridComponent: React.FC = () => {
  const SalaryGenerationList = useAppSelector(
    (state) => state.salarygeneration.SalaryGenerationList
  );

  const loading = useAppSelector((state) => state.retailer.loading);
  const breakPoint = useResponsiveJSX([2000, 2170, 2514]);
  const dynamicWidth = [350, 525, 850][breakPoint] ?? 800;
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? SalaryGenerationList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      // className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
      className={`table-header `}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {SalaryGenerationList && SalaryGenerationList.length ? (
        <KendoGrid
         style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(SalaryGenerationList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : SalaryGenerationList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={SalaryGenerationList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="Date"
            title="Sr. No."
            width={60}
            cell={(props: GridCellProps) => {
              return <td>{props.dataIndex + 1}</td>;
            }}
            headerCell={(props) => (
              <th
                {...props}
                style={{
                  whiteSpace: "normal",
                  textAlign: "center",
                  padding: 0,
                }}
                className="salaryview-column-header"
              >
                <p>Sr. No</p>
              </th>
            )}
          />
          <Column
            field="Date"
            title="Date"
            width={100}
            headerCell={(props) => (
              <th
                {...props}
                style={{ whiteSpace: "normal", textAlign: "start", padding: 0 }}
                className="salaryview-column-header"
              >
                <p style={{ margin: 0 }}>
                  Month <br />
                  Year <br />
                </p>
              </th>
            )}
            cell={(props: GridCellProps) => {
              return (
                <td>
                  <p style={{ textTransform: "capitalize" }}>
                    {MONTHS_MAP[+props?.dataItem?.payout_month] || ""} <br />{" "}
                    {props?.dataItem?.payout_year}
                  </p>
                </td>
              );
            }}
          />
          <Column field="employee_name" title="Employee Name" width={200} />
          <Column field="employee_id" title="Employee ID" width={120} />
          <Column
            field="employee_id"
            title="Employee Information"
            width={150}
            headerCell={(props) => (
              <div
                {...props}
                style={{ whiteSpace: "normal", textAlign: "start", padding: 0 }}
                className="salaryview-column-header"
              >
                <p style={{ margin: 0 }}>
                  Total Days
                  <br />
                  Working Days <br />
                  Present Day
                  <br />
                  Absent <br />
                </p>
              </div>
            )}
            cell={(props) => (
              <td
                style={{
                  whiteSpace: "normal",
                  textAlign: "center",
                  padding: 0,
                  display: "flex",
                  justifyContent: "end",
                }}
                className="salaryview-column-header"
              >
                <p style={{ margin: 0 }}>
                  {Number(props.dataItem?.total_days)?.toFixed(2)}
                  <br />
                  {Number(props.dataItem?.working_days)?.toFixed(2)}
                  <br />
                  {Number(props.dataItem?.present_days)?.toFixed(2)}
                  <br />
                  {Number(
                    +props.dataItem?.working_days -
                      +props.dataItem?.present_days
                  ).toFixed(2)}{" "}
                  <br />
                </p>
              </td>
            )}
          />
          {/* <Column
            field="employee_id"
            title="Employee Information"
            width={200}
            headerCell={(props) => (
              <div
                {...props}
                style={{ whiteSpace: "normal", textAlign: "start", padding: 0 }}
                className="salaryview-column-header"
              >
                <p style={{ margin: 0 }}>
                  Department <br />
                  Designation <br />
                  PF No <br />
                  UAN No <br />
                  ESI. No
                </p>
              </div>
            )}
          /> */}
          <Column
            field="employee_id"
            title="Rate Earnings"
            width={250}
            headerCell={(props) => (
              <div
                {...props}
                style={{ whiteSpace: "normal", textAlign: "start", padding: 0 }}
                className="salaryview-column-header"
              >
                <table>
                  <tr>
                    <td>Rate</td>
                    <td>Earnings</td>
                  </tr>
                  <tr>
                    <td>
                      <p>Basic</p>
                      <p>HRA</p>
                      <p>WASH</p>
                      <p>PROD.INS.ALL</p>
                      <p>ADV. BONUS</p>
                      <p>ADV. LEAVE</p>
                      <p>ADV. STD. BE</p>
                      <p>DA.</p>
                    </td>
                    <td>
                      <p>Basic</p>
                      <p>HRA</p>
                      <p>WASH</p>
                      <p>PROD.INS.ALL</p>
                      <p>ADV. BONUS</p>
                      <p>ADV. LEAVE</p>
                      <p>ADV. STD. BE</p>
                      <p>DA.</p>
                    </td>
                  </tr>
                </table>
              </div>
            )}
            cell={(props: GridCellProps) => (
              <td
                style={{ whiteSpace: "normal", textAlign: "right", padding: 0 }}
                className="salaryview-column-cell"
              >
                <table style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <p>{props?.dataItem?.basic}</p>
                      <p>{props?.dataItem?.hra}</p>
                      <p>{props?.dataItem?.wash}</p>
                      <p>{props?.dataItem?.prod_ins_all}</p>
                      <p>{props?.dataItem?.adv_bonus}</p>
                      <p>{props?.dataItem?.adv_leave}</p>
                      <p>{props?.dataItem?.adv_std_be}</p>
                      <p>{props?.dataItem?.da}</p>
                    </td>
                    <td>
                      <p>{props?.dataItem?.basic}</p>
                      <p>{props?.dataItem?.hra}</p>
                      <p>{props?.dataItem?.wash}</p>
                      <p>{props?.dataItem?.prod_ins_all}</p>
                      <p>{props?.dataItem?.adv_bonus}</p>
                      <p>{props?.dataItem?.adv_leave}</p>
                      <p>{props?.dataItem?.adv_std_be}</p>
                      <p>{props?.dataItem?.da}</p>
                    </td>
                  </tr>
                </table>
              </td>
            )}
          />
          <Column
            field="total_days"
            title="Gross Salary"
            width={120}
            headerCell={(props) => (
              <div
                {...props}
                style={{ whiteSpace: "normal", textAlign: "start", padding: 0 }}
                className="salaryview-column-header"
              >
                <p>Gross Salary</p>
                <p>PF Wages</p>
                <p>ESI Wages</p>
              </div>
            )}
            cell={(props: GridCellProps) => {
              return (
                <td
                  style={{
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <b>{props.dataItem?.total_income}</b>{" "}
                  </p>
                  <p>{props.dataItem?.basic}</p>
                  <p>{props.dataItem?.total_income}</p>
                </td>
              );
            }}
          />
          <Column
            field="total_days"
            title="Deduction"
            width={200}
            headerCell={(props) => (
              <th
                {...props}
                style={{ whiteSpace: "normal", textAlign: "start", padding: 0 }}
                className="salaryview-column-header"
              >
                <table>
                  <tr>
                    <td
                      style={{ border: "none", textAlign: "center" }}
                      colSpan={2}
                    >
                      Deduction
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <p>P.F</p>
                      <p>ESI</p>
                      <p>IT</p>
                      <p>P.T.</p>
                      <p>Loan</p>
                      <p>Advn.</p>
                      <p>Oth.Ded</p>
                    </td>
                    <td>
                      <p style={{ textAlign: "center" }}>
                        Gross <br /> Deduction
                      </p>
                    </td>
                  </tr>
                </table>
              </th>
            )}
            cell={(props: GridCellProps) => {
              return (
                <td style={{ textAlign: "right" }}>
                  <table className="salary-deduction-cell">
                    <tr>
                      <td>
                        <p>{props.dataItem?.pf}</p>
                        <p>{props.dataItem?.esi}</p>
                        <p>{props.dataItem?.it}</p>
                        <p>{props.dataItem?.pt}</p>
                        <p>{props.dataItem?.loan}</p>
                        <p>{props.dataItem?.advance}</p>
                        <p>{props.dataItem?.other}</p>
                      </td>
                      <td>
                        <p style={{ textAlign: "right" }}>
                          <b>{props.dataItem?.total_deduction}</b>
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              );
            }}
          />
          <Column
            field="payable_amount"
            title="Total Payable Amount"
            width={150}
            headerCell={(props) => (
              <th
                {...props}
                style={{
                  whiteSpace: "normal",
                  textAlign: "center",
                  padding: 0,
                }}
                className="salaryview-column-header"
              >
                <p>Net Salary Payable in Rs.</p>
              </th>
            )}
            cell={(props: GridCellProps) => {
              return (
                <td
                  style={{
                    whiteSpace: "normal",
                    textAlign: "center",
                    padding: 0,
                  }}
                  className="salaryview-column-header"
                >
                  <p>
                    <b>{props.dataItem.payable_amount}</b>
                  </p>
                </td>
              );
            }}
          />

          <Column field="remarks" title="Remarks" width={dynamicWidth} />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const GeneratedSalaryViewFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const SalaryGenerationViewFilter = useAppSelector(
    (state) => state.salarygeneration.SalaryGenerationViewFilter
  );
  const loading = useAppSelector((state) => state.salarygeneration.loading);
  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        employee_id: values?.employee_id,
        year: values?.year,
      };
      await dispatch(getEmployeeSalaryByIDYear(payload));
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={SalaryGenerationViewFilter}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="d-flex gap-3" style={{ width: "50%" }}>
              <Field
                name="year"
                label="Year"
                wrapperStyle={{ width: "30%" }}
                placeholder="Select Year"
                component={FormSelectionField}
                options={getYearsArray(2000, new Date().getFullYear() + 1)
                  ?.reverse()
                  ?.map((year: any) => {
                    return {
                      value: year?.value,
                      label: year?.label,
                    };
                  })}
                validator={requiredValidator}
                astrike={true}
              />
              <Field
                name="employee_id"
                label="Employee"
                wrapperStyle={{ width: "50%" }}
                placeholder="Select Employee"
                component={FormSelectionField}
                options={EmployeeList.map((emp: IEmployee) => ({
                  value: emp.id,
                  label: emp.name,
                }))}
                validator={requiredValidator}
                astrike={true}
              />
              <ButtonWithLoading
                buttonStyle={{ marginTop: 35, width: "20%" }}
                label={"View"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || loading}
                loading={loading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default SalaryGenerationViewUpdated;
