import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createProfessionTaxSlab,
  deleteProfessionTaxSlab,
  getAllActiveProfessionTaxDate,
  getProfessionTaxSlabByDate,
  updateProfessionTaxSlab,
} from "./services/professionTaxSlab.services";
import { IProfessionTaxSlabInitialState } from "./professionTaxSlabModel";

const initialState: IProfessionTaxSlabInitialState = {
  formLoading: false,
  loading: false,
  error: "",
  ProfessionTaxSlabDateList: [],
  ProfessionTaxSlabID: null,
  ProfessionTaxSlabGUID: "",
  ProfessionTaxSlabDetail: {
    effective_from_date: "",
    proffestional_tax: [],
  },
  ProfessionTaxSlabDeleteIndex: -1,
};

const professionTaxSlabSlice = createSlice({
  name: "professionTaxSlab",
  initialState,
  reducers: {
    clearProfessionTaxSlabDetails: (state) => {
      state.ProfessionTaxSlabDetail = initialState.ProfessionTaxSlabDetail;
    },
    setProfessionTaxSlabID: (state, action) => {
      state.ProfessionTaxSlabID = action.payload;
    },
    clearProfessionTaxSlabID: (state) => {
      state.ProfessionTaxSlabID = initialState.ProfessionTaxSlabID;
    },
    setProfessionTaxSlabGUID: (state, action) => {
      state.ProfessionTaxSlabGUID = action.payload;
    },
    clearProfessionTaxSlabGUID: (state) => {
      state.ProfessionTaxSlabGUID = initialState.ProfessionTaxSlabGUID;
    },
    setProfessionTaxSlabDeleteIndex: (state, action) => {
      state.ProfessionTaxSlabDeleteIndex = action.payload;
    },
    clearProfessionTaxSlabDateList: (state) => {
      state.ProfessionTaxSlabDateList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProfessionTaxSlab.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createProfessionTaxSlab.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createProfessionTaxSlab.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateProfessionTaxSlab.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateProfessionTaxSlab.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateProfessionTaxSlab.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteProfessionTaxSlab.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteProfessionTaxSlab.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProfessionTaxSlab.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getProfessionTaxSlabByDate.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getProfessionTaxSlabByDate.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.ProfessionTaxSlabDetail = action.payload;
      }
    );
    builder.addCase(getProfessionTaxSlabByDate.rejected, (state, action) => {
      state.formLoading = false;
      state.ProfessionTaxSlabDetail = initialState.ProfessionTaxSlabDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllActiveProfessionTaxDate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveProfessionTaxDate.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.ProfessionTaxSlabDateList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveProfessionTaxDate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProfessionTaxSlabDateList = [];
    });
  },
});

export const {
  clearProfessionTaxSlabDetails,
  setProfessionTaxSlabID,
  clearProfessionTaxSlabID,
  clearProfessionTaxSlabDateList,
  setProfessionTaxSlabGUID,
  clearProfessionTaxSlabGUID,
  setProfessionTaxSlabDeleteIndex,
} = professionTaxSlabSlice.actions;
export default professionTaxSlabSlice.reducer;
