import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllItemMarginRates = createAsyncThunk(
  "ItemGroup/FindAllItemMarginRates",
  async (UserType: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ItemGroup/FindAllItemMarginRates`,
        {
          user_type_id: UserType,
        }
      );
      const result = response.data?.Data;
      return result.map((item: any) => {
        return {
          item_group_id: item?.item_group_id,
          item_group: item?.item_group,
          item_margin_rate: item?.item_margin_rate
            ? item?.item_margin_rate?.map((subitem: any) => {
                return {
                  item_id: subitem?.item_id ? +subitem?.item_id : null,
                  unit_id: subitem?.unit_id ? +subitem?.unit_id : null,
                  unit_name: subitem?.unit_name ? subitem?.unit_name : "",
                  unit_code: subitem?.unit_code ? subitem?.unit_code : "",
                  item_group_id: subitem?.item_group_id
                    ? +subitem?.item_group_id
                    : null,
                  product_name: subitem?.product_name
                    ? subitem?.product_name
                    : "",
                  mrp: subitem?.mrp ? +subitem?.mrp : null,
                  rate: subitem?.rate ? +subitem?.rate : null,
                  retail_margin: subitem?.retail_margin
                    ? +subitem?.retail_margin
                    : null,
                  retail_rate_include_gst: subitem?.retail_rate_include_gst
                    ? +subitem?.retail_rate_include_gst
                    : null,
                  retail_sgst: subitem?.retail_sgst
                    ? +subitem?.retail_sgst
                    : null,
                  retail_cgst: subitem?.retail_cgst
                    ? +subitem?.retail_cgst
                    : null,
                  retail_rate_exclude_gst: subitem?.retail_rate_exclude_gst
                    ? +subitem?.retail_rate_exclude_gst
                    : null,
                  distributor_rate_include_gst:
                    subitem?.distributor_rate_include_gst
                      ? +subitem?.distributor_rate_include_gst
                      : null,
                  distributor_sgst: subitem?.distributor_sgst
                    ? +subitem?.distributor_sgst
                    : null,
                  distributor_cgst: subitem?.distributor_cgst
                    ? +subitem?.distributor_cgst
                    : null,
                  distributor_rate: subitem?.distributor_rate
                    ? +subitem?.distributor_rate
                    : null,
                  gst_id: subitem?.gst_id ? +subitem?.gst_id : null,
                  gst: subitem?.gst ? +subitem?.gst : null,
                };
              })
            : [],
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Item Margin Rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createOrder = createAsyncThunk(
  "SaleOrder/InsertPlaceOrder",
  async (OrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/InsertPlaceOrder`,
        OrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOrder = createAsyncThunk(
  "SaleOrder/UpdatePlaceOrder",
  async (OrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/UpdatePlaceOrder`,
        OrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOrderByID = createAsyncThunk(
  "SaleOrder/FindByIDPlaceOrder",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/FindByIDPlaceOrder`,
        { id: ID }
      );
      // return response.data?.Data as any;
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        sale_order_guid: result?.sale_order_guid,
        sales_order_no: result?.sales_order_no,
        sales_date: result?.sales_date
          ? moment(result?.sales_date, "YYYY-MM-DD").toDate()
          : "",
        dealer_id: result?.dealer_id,
        financial_year: result?.financial_year,
        total_amount: result?.total_amount,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        free_scheme: result?.free_scheme ? result?.free_scheme : false,
        card_scheme: result?.card_scheme ? result?.card_scheme : false,
        place_order_items: result?.place_order_items
          ? result?.place_order_items?.map((orderitem: any) => {
              return {
                item_group_id: orderitem?.item_group_id
                  ? orderitem?.item_group_id
                  : null,
                item_group: orderitem?.item_group ? orderitem?.item_group : "",
                item_margin_rate: orderitem?.item_margin_rate
                  ? orderitem?.item_margin_rate?.map((subitem: any) => {
                      return {
                        id: subitem?.id ? +subitem?.id : null,
                        item_group_id: subitem?.item_group_id
                          ? +subitem?.item_group_id
                          : null,
                        sale_order_id: subitem?.sale_order_id
                          ? +subitem?.sale_order_id
                          : null,
                        item_id: subitem?.item_id ? +subitem?.item_id : null,
                        product_name: subitem?.product_name
                          ? subitem?.product_name
                          : "",
                        mrp: subitem?.mrp ? +subitem?.mrp : null,
                        quantity: subitem?.quantity ? +subitem?.quantity : null,
                        // [`qty_${mainIndex}_${subIndex}`]: subitem?.quantity
                        //   ? +subitem?.quantity
                        //   : null,
                        unit_id: subitem?.unit_id ? +subitem?.unit_id : null,
                        rate: subitem?.rate ? +subitem?.rate : null,
                        gst_id: subitem?.gst_id ? +subitem?.gst_id : null,
                        amount: subitem?.amount ? +subitem?.amount : null,
                        unit_name: subitem?.unit_name ? subitem?.unit_name : "",
                        gst: subitem?.gst ? +subitem?.gst : null,
                      };
                    })
                  : [],
              };
            })
          : [],
        scheme: result?.free_scheme_offers
          ? result?.free_scheme_offers?.map((item: any) => {
              return {
                free_good: item?.free_good ? item?.free_good : "",
                article: item?.article ? item?.article : "",
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching order details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOrdersByUserID = createAsyncThunk(
  "SaleOrder/PlaceOrderByUserID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/PlaceOrderByUserID`,
        { id: ID }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching all orders:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
