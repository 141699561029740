import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllVisitors = createAsyncThunk(
  "Visitor/FindAllVisitor",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/FindAllVisitor`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Visitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createVisitor = createAsyncThunk(
  "Visitor/InsertVisitor",
  async (VisitorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/InsertVisitor`,
        VisitorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating visitor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVisitor = createAsyncThunk(
  "Visitor/UpdateVisitor",
  async (VisitorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/UpdateVisitor`,
        VisitorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating visitor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteVisitor = createAsyncThunk(
  "Visitor/DeleteVisitor",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/DeleteVisitor`,
        { visitor_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting visitor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVisitorByID = createAsyncThunk(
  "Visitor/FindByIDVisitor",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/FindByIDVisitor`,
        { visitor_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        visitor_guid: result?.visitor_guid,
        visitor_date: result?.visitor_date
          ? moment(result?.visitor_date, "YYYY-MM-DD").toDate()
          : "",
        visitor_no: result?.visitor_no,
        visitor_image: result?.visitor_image,
        user_id: result?.user_id,
        user_name: result?.user_name,
        name: result?.name,
        mobile_no: result?.mobile_no,
        email_id: result?.email_id,
        id_proof_no: result?.id_proof_no,
        from_company: result?.from_company,
        reason: result?.reason,
        in_time: result?.in_time
          ? moment(result?.in_time, "HH:mm:ss").toDate()
          : "",
        out_time: result?.out_time
          ? moment(result?.out_time, "HH:mm:ss").toDate()
          : "",
        financial_year: result?.financial_year,
        createdby: result?.createdby,
        createdby_name: result?.createdby_name,
        isactive: result?.isactive,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching visitor details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveVisitors = createAsyncThunk(
  "Visitor/FindAllActiveVisitor",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Visitor/FindAllActiveVisitor`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Visitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadVisitorImage = createAsyncThunk(
  "Visitor/VisitorImageUpdate",
  async (imageData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/VisitorImageUpdate`,
        imageData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating visitor image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVisitorOutTime = createAsyncThunk(
  "Visitor/VisitorOutTimeUpdate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Visitor/VisitorOutTimeUpdate`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating visitor out time:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
