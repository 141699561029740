import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  updateEmployeeLeave,
  createEmployeeLeave,
  deleteEmployeeLeave,
  getAllEmployeeLeave,
  getEmployeeLeaveByID,
  updateEmployeeLeaveStatus,
  uploadEmployeeLeaveAttachment,
} from "./services/employeeLeave.services";
import { IEmployeeLeaveInitialState } from "./employeeLeaveModel";

const initialState: IEmployeeLeaveInitialState = {
  formLoading: false,
  loading: false,
  error: "",
  EmployeeLeaveList: [],
  EmployeeLeaveID: null,
  EmployeeLeaveGUID: "",
  CCEmployeeList: [],
  EmployeeLeaveDetail: {
    employee_id: null,
    leave_id: null,
    from_date: "",
    from_session: "",
    to_date: "",
    to_session: "",
    days: "",
    ccto: [],
    apply_to_reporting_id: null,
    reason: "",
    attachment: "",
  },
  EmployeeLeaveStatusDialog: {
    leavestatus: "",
    remark: "",
  },
  SelectedTab: 0,
};

const employeeLeaveSlice = createSlice({
  name: "employeeLeave",
  initialState,
  reducers: {
    clearEmployeeLeaveDetails: (state) => {
      state.EmployeeLeaveDetail = initialState.EmployeeLeaveDetail;
    },
    setEmployeeLeaveID: (state, action) => {
      state.EmployeeLeaveID = action.payload;
    },
    clearEmployeeLeaveID: (state) => {
      state.EmployeeLeaveID = initialState.EmployeeLeaveID;
    },
    setEmployeeLeaveGUID: (state, action) => {
      state.EmployeeLeaveGUID = action.payload;
    },
    clearEmployeeLeaveGUID: (state) => {
      state.EmployeeLeaveGUID = initialState.EmployeeLeaveGUID;
    },
    clearEmployeeLeaveList: (state) => {
      state.EmployeeLeaveList = [];
    },
    setCCEmployeeList: (state, action) => {
      state.CCEmployeeList = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.SelectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEmployeeLeave.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllEmployeeLeave.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.EmployeeLeaveList = action.payload || [];
      }
    );
    builder.addCase(getAllEmployeeLeave.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.EmployeeLeaveList = [];
    });
    builder.addCase(createEmployeeLeave.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createEmployeeLeave.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createEmployeeLeave.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateEmployeeLeave.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateEmployeeLeave.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateEmployeeLeave.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteEmployeeLeave.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(deleteEmployeeLeave.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(deleteEmployeeLeave.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getEmployeeLeaveByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getEmployeeLeaveByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.EmployeeLeaveDetail = action.payload;
    });
    builder.addCase(getEmployeeLeaveByID.rejected, (state, action) => {
      state.formLoading = false;
      state.EmployeeLeaveDetail = initialState.EmployeeLeaveDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateEmployeeLeaveStatus.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateEmployeeLeaveStatus.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateEmployeeLeaveStatus.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(uploadEmployeeLeaveAttachment.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(uploadEmployeeLeaveAttachment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(uploadEmployeeLeaveAttachment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearEmployeeLeaveDetails,
  setEmployeeLeaveID,
  clearEmployeeLeaveID,
  clearEmployeeLeaveGUID,
  setEmployeeLeaveGUID,
  clearEmployeeLeaveList,
  setCCEmployeeList,
  setSelectedTab,
} = employeeLeaveSlice.actions;
export default employeeLeaveSlice.reducer;
