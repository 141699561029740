import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setProfessionTaxSlabDeleteIndex } from "./professionTaxSlabSlice";

const ProfessionTaxSlabArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const ProfessionTaxSlabDeleteIndex = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setProfessionTaxSlabDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        salary_from: "",
        salary_till: "",
        tax_amount: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("professionTaxDeleteDialog"));
    dispatch(setProfessionTaxSlabDeleteIndex(index));
  };

  const handlePushElement = (index: number) => {
    if (
      fieldArrayRenderProps.value.length === index + 1 &&
      (!fieldArrayRenderProps.value[index]?.salary_from ||
        !fieldArrayRenderProps.value[index]?.salary_till ||
        !fieldArrayRenderProps.value[index]?.tax_amount)
    ) {
      pushElementInarray();
    }
  };
  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value.length ? pushElementInarray() : null}
      <GridLayoutItem className="bg-dark text-white fw-600">
        <GridLayout
          style={{ marginRight: 30, padding: 10 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[
            { width: "4%" },
            { width: "31%" },
            { width: "31%" },
            { width: "31%" },
            { width: "3%" },
          ]}
        >
          <GridLayoutItem>No</GridLayoutItem>
          <GridLayoutItem>Salary From</GridLayoutItem>
          <GridLayoutItem>Salary Till</GridLayoutItem>
          <GridLayoutItem>Tax Amount</GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
      {dialogName === "professionTaxDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Proffestional Tax"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() =>
                    handleDeleteAction(ProfessionTaxSlabDeleteIndex)
                  }
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index} style={{ padding: 15 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "4%" },
                { width: "31%" },
                { width: "31%" },
                { width: "31%" },
                { width: "3%" },
              ]}
            >
              <GridLayoutItem style={{ position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    top: 5,
                    left: 0,
                    fontWeight: 700,
                  }}
                >
                  {index + 1}
                </span>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`proffestional_tax.${index}.salary_from`}
                  placeholder="0"
                  type="number"
                  minNumber="0"
                  onChange={() => handlePushElement(index)}
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`proffestional_tax.${index}.salary_till`}
                  placeholder="0"
                  type="number"
                  minNumber="0"
                  onChange={() => handlePushElement(index)}
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`proffestional_tax.${index}.tax_amount`}
                  placeholder="0"
                  type="number"
                  minNumber="0"
                  onChange={() => handlePushElement(index)}
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ position: "relative" }}>
                <IconButton
                  onClick={() => handleOpenDeleteDialog(index)}
                  disabled={fieldArrayRenderProps.value.length === index + 1}
                  themeColor={"error"}
                  size={"small"}
                  fillMode={"solid"}
                  type="button"
                  style={{
                    position: "absolute",
                    height: 32,
                    width: 32,
                    top: 1,
                    right: 0,
                  }}
                >
                  <MdDelete
                    className="absolute-position"
                    style={{ fontSize: "16px" }}
                  />
                </IconButton>
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default ProfessionTaxSlabArray;
