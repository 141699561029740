import { createSlice } from "@reduxjs/toolkit";
import { IOldDistributorDataInitialState } from "./oldDistributorDataModel";
import {
  updateOldDistributorData,
  getOldDistributorDataByID,
} from "./services/oldDistributorData.services";

const initialState: IOldDistributorDataInitialState = {
  loading: false,
  error: "",
  OldDistributorDataID: null,
  OldDistributorDataList: [],
  OldDistributorDataDetails: {
    accountid: null,
    date: "",
    distributer_Previous_qty_list: [],
  },
};

const oldDistributorDataSlice = createSlice({
  name: "oldDistributorData",
  initialState,
  reducers: {
    clearOldDistributorDataDetails: (state) => {
      state.OldDistributorDataDetails = initialState.OldDistributorDataDetails;
    },
    setOldDistributorDataID: (state, action) => {
      state.OldDistributorDataID = action.payload;
    },
    clearOldDistributorDataID: (state) => {
      state.OldDistributorDataID = initialState.OldDistributorDataID;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(createOldDistributorData.pending, (state) => {
    //   state.loading = true;
    //   state.error = "";
    // });
    // builder.addCase(createOldDistributorData.fulfilled, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(createOldDistributorData.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message || "Something went wrong";
    // });

    builder.addCase(updateOldDistributorData.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateOldDistributorData.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOldDistributorData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getOldDistributorDataByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getOldDistributorDataByID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OldDistributorDataDetails = action.payload;
      }
    );
    builder.addCase(getOldDistributorDataByID.rejected, (state, action) => {
      state.loading = false;
      state.OldDistributorDataDetails = initialState.OldDistributorDataDetails;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  setOldDistributorDataID,
  clearOldDistributorDataID,
  clearOldDistributorDataDetails,
} = oldDistributorDataSlice.actions;
export default oldDistributorDataSlice.reducer;
