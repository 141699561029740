import { createSlice } from "@reduxjs/toolkit";
import {
  checkinCheckoutUpdate,
  getSessionDetails,
  insertSession,
  sessionEnd,
  tokenCheck,
  updateExpense,
} from "./services/dashboard.services";

const initialState = {
  loading: false,
  error: "",
  sessionDetails: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearSessionDetails: (state) => {
      state.sessionDetails = initialState.sessionDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tokenCheck.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(tokenCheck.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(tokenCheck.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertSession.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertSession.fulfilled, (state, action) => {
      state.loading = false;
      state.sessionDetails = action.payload;
      state.error = "";
    });
    builder.addCase(insertSession.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSessionDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.sessionDetails = action.payload;
      state.error = "";
    });
    builder.addCase(getSessionDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(checkinCheckoutUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkinCheckoutUpdate.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(checkinCheckoutUpdate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateExpense.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateExpense.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(sessionEnd.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sessionEnd.fulfilled, (state) => {
      state.loading = false;
      state.sessionDetails = initialState.sessionDetails;
      state.error = "";
    });
    builder.addCase(sessionEnd.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearSessionDetails } = dashboardSlice.actions;

export default dashboardSlice.reducer;
