import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearHolidayDetails } from "./holidaySlice";
import {
  createHoliday,
  getAllActiveHolidays,
  getAllHolidays,
  getHolidayByID,
  updateHoliday,
} from "./services/holiday.services";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import moment from "moment";
import FormDatePicker from "../../components/formFields/FormDateField";
// import { closed } from "../../components/drawer/drawerSlice";

const CreateHoliday: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const HolidayDetail = useAppSelector((state) => state.holiday.HolidayDetail);
  const holiday_guid = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.holiday.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    return () => {
      dispatch(clearHolidayDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (holiday_guid) {
      dispatch(getHolidayByID(holiday_guid));
    }
  }, [holiday_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!holiday_guid) {
        const insertPayload = {
          title: values?.title,
          holiday_date: values?.holiday_date
            ? moment(values?.holiday_date).format("YYYY-MM-DD")
            : "",
          holiday_day: values?.holiday_day,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createHoliday(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearHolidayDetails());
          isIndirect
            ? dispatch(getAllActiveHolidays())
            : dispatch(getAllHolidays());
        }
      } else {
        const editPayload = {
          id: values?.id,
          holiday_guid: holiday_guid,
          title: values?.title,
          holiday_date: values?.holiday_date
            ? moment(values?.holiday_date).format("YYYY-MM-DD")
            : "",
          holiday_day: values?.holiday_day,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateHoliday(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearHolidayDetails());
          isIndirect
            ? dispatch(getAllActiveHolidays())
            : dispatch(getAllHolidays());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={HolidayDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {holiday_guid ? "Update Holiday" : "Add Holiday"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="title"
                label="Title"
                placeholder="Title"
                component={FormTextField}
                validator={requiredValidator} astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="holiday_date"
                label="Holiday Date"
                format="dd/MM/yyyy"
                component={FormDatePicker}
                validator={requiredValidator} astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="holiday_day"
                label="Holiday Day"
                placeholder="i.e. Diwali"
                component={FormTextField}
                validator={requiredValidator} astrike={true}
              />
            </GridLayoutItem>

            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"isactive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator} astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={holiday_guid ? "Update" : "Save"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateHoliday;
