import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import MainRouter from "./_routers/Router";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "./components/dialog/Dialog";
import { closeDialog } from "./components/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { ToastContainer } from "react-toastify";

function App() {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  return (
    <div>
      <ToastContainer />
      <MainRouter />

      {dialogName === "testDialog" && (
        <AppDialog>
          <>
            <div>Hello How are you</div>
            <div>
              <DialogCloseButton text="Cancel" />
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={() => dispatch(closeDialog())}
              >
                Yes
              </Button>
            </div>
          </>
        </AppDialog>
      )}
    </div>
  );
}

export default App;
