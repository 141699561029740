import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ILeave } from "../leaveModel";

export const getAllLeaves = createAsyncThunk("Leave/FindAllLeave", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Leave/FindAllLeave`,
      {
        leave_type: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching countries:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const getAllActiveLeaves = createAsyncThunk(
  "Leave/FindAllActiveLeave",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Leave/FindAllActiveLeave`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeave = createAsyncThunk(
  "Leave/InsertLeave",
  async (LeaveData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/InsertLeave`,
        LeaveData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeave = createAsyncThunk(
  "Leave/UpdateLeave",
  async (LeaveData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/UpdateLeave`,
        LeaveData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeaveByID = createAsyncThunk(
  "Leave/FindByIDLeave",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/FindByIDLeave`,
        { leave_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        leave_type: result?.leave_type,
        leave_count: result?.leave_count,
        isactive: result.isactive === true ? 1 : 2,
      } as ILeave;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const leaveActiveInactive = createAsyncThunk(
  "Leave/ActiveInActiveLeave",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/ActiveInActiveLeave`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeave = createAsyncThunk(
  "Leave/DeleteLeave",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leave/DeleteLeave`,
        { leave_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting leave:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
