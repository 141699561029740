import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }: { children: any }) => {
  const isAuthenticated = localStorage.getItem("Token");

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const PrivateLoginRoute = ({ children }: { children: any }) => {
  const isAuthenticated = localStorage.getItem("Token");

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};
