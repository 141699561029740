import React, { useEffect } from "react";
import Logo from "../../assets/Images/logo.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useParams } from "react-router-dom";
import { getSalesPrint } from "./services/sales.services";
import { Button } from "@progress/kendo-react-buttons";
import FSSAI from "../../assets/Images/fssai.png";

const GatePassPrint: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const sale_guid = params?.sale_guid;
  const SalesPrintDetail = useAppSelector(
    (state) => state.sales.SalesPrintDetail
  );

  useEffect(() => {
    if (sale_guid) {
      const handleOrderPrint = () => {
        dispatch(getSalesPrint(sale_guid));
      };
      handleOrderPrint();
    }
  }, [sale_guid]);

  useEffect(() => {
    const beforePrintHandler = () => {
      let nonPrintArea = document.querySelector(".non-print-area") as any;
      nonPrintArea.style.display = "none";
    };
    const afterPrintHandler = () => {
      let nonPrintArea = document.querySelector(".non-print-area") as any;
      nonPrintArea.style.display = "block";
    };
    window.addEventListener("beforeprint", beforePrintHandler);
    window.addEventListener("afterprint", afterPrintHandler);

    return () => {
      window.removeEventListener("beforeprint", beforePrintHandler);
      window.removeEventListener("afterprint", afterPrintHandler);
    };
  }, []);

  const handlePrint = () => {
    let nonPrintArea = document.querySelector(".non-print-area") as any;
    nonPrintArea.style.display = "none";
    window.print();
    nonPrintArea.style.display = "block";
  };

  return (
    <div className="container gatepass-print p-3">
      <div className="row">
        <div
          className="col-12 ps-3 pe-3 pb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "white",
              background: "red",
              padding: "10px 15px",
              borderRadius: 5,
              outline: " 1px solid red",
              outlineOffset: 2,
            }}
          >
            Gate Pass
          </span>
          <img src={Logo} height="60px" />
          <div>
            <h2 className="m-0">Harihar Foods Pvt. Ltd.</h2>
          </div>
        </div>
        <div className="col-12">
          <table className="w-100">
            <tr className="border-gray">
              <td className="border-right-gray" width={"50%"}>
                <span className="d-inline-block" style={{ width: 150 }}>
                  Number
                </span>
                :{" "}
                {SalesPrintDetail?.invoice_no_string ||
                  SalesPrintDetail?.invoice_no ||
                  ""}
              </td>
              <td rowSpan={3} width={"50%"}>
                Plot no 519/1, Phase 4, Ramol Cross Road, <br /> GIDC Vatva,
                Ahmedabad-382445, <br /> Phone No. 25840855, 25841773
              </td>
            </tr>
            <tr className="border-gray">
              <td className="border-right-gray">
                <span className="d-inline-block" style={{ width: 150 }}>
                  Date
                </span>
                : {SalesPrintDetail?.invoice_date}
              </td>
            </tr>
            <tr className="border-gray">
              <td className="border-right-gray">
                <span className="d-inline-block" style={{ width: 150 }}>
                  Party Name
                </span>
                : {SalesPrintDetail?.account_name}
              </td>
            </tr>
            <tr className="border-gray">
              <td className="border-right-gray">
                <span className="d-inline-block" style={{ width: 150 }}>
                  Place
                </span>
                : {SalesPrintDetail?.city_name}
              </td>
              <td>
                <span className="d-inline-block" style={{ width: 150 }}>
                  Transport Name
                </span>
                : {SalesPrintDetail?.transport_name}
              </td>
            </tr>
            <tr className="border-gray">
              <td className="border-right-gray">
                <span className="d-inline-block" style={{ width: 150 }}>
                  Delivery Slip No.
                </span>
                : {SalesPrintDetail?.invoice_no_string}
              </td>
              <td>
                <span className="d-inline-block" style={{ width: 150 }}>
                  Vehicle No.
                </span>
                : {SalesPrintDetail?.vehicle_no}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-12 mt-2">
          <table className="w-100">
            <tr className="border-gray text-center">
              <th>Particular</th>
              <th className="border-left-gray">Details</th>
              <th className="border-left-gray">Qty</th>
            </tr>
            <tr className="border-gray">
              <td width={"70%"} rowSpan={3}></td>
              <td width={"15%"} className="border-left-gray">
                Bag
              </td>
              <td width={"15%"} className="border-left-gray text-center">
                {SalesPrintDetail?.bag || 0}
              </td>
            </tr>
            <tr className="border-gray">
              <td className="border-left-gray">Cartoon</td>
              <td className="border-left-gray text-center">
                {SalesPrintDetail?.cartoon || 0}
              </td>
            </tr>
            <tr className="border-gray">
              <td className="border-left-gray">Total</td>
              <td className="border-left-gray text-center">
                {+SalesPrintDetail?.bag + +SalesPrintDetail?.cartoon || 0}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-12 mt-2 d-flex justify-content-between">
          <span>Delivery Receiver Sign : _______________________</span>
          <span>For, Harihar Foods Pvt. Ltd. 2021-2022</span>
        </div>
        <div className="col-12 mt-3 d-flex">
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={FSSAI}
              style={{
                height: "15px",
                marginRight: "5px",
                verticalAlign: "top",
              }}
            />
            LIC No. 10012021000359
          </span>
        </div>
      </div>
      <div className="row non-print-area text-center" style={{ marginTop: 70 }}>
        <div className="col-12 fw-bold text-danger">
          Note : For optimal print quality, Please Select layout to "Landscape"
          & page size to "Statement" with default margin.
        </div>
        <div className="col-12">
          <Button
            fillMode={"solid"}
            themeColor={"primary"}
            onClick={handlePrint}
          >
            Print
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GatePassPrint;
