import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getSalaryRegisterReport } from "../services/reports.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  COMPANY_ADDRESS,
  COMPANY_ESI_NO,
  COMPANY_NAME,
  COMPANY_PF_NO,
  MONTHS_MAP,
} from "../../../_contstants/common";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";

const SalaryRegisterReport = () => {
  const state = JSON.parse(sessionStorage.getItem("salaryreportState") || "{}");
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );

  const fetchReport = async () => {
    const payload = {
      location: state.location,
      payout_month_year: `${String(state.payout_month).padStart(2, "0")}-${
        state.payout_year
      }`,
    };
    await dispatch(getSalaryRegisterReport(payload));
    window.print();
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("salaryreportState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const style: Record<string, React.CSSProperties> = {
    headingText: {
      fontSize: "16px",
      fontWeight: "bold",
      margin: 0,
    },
    headingTextSub: {
      margin: 0,
      fontSize: "11px",
      fontWeight: 500,
    },
    formrules: {
      fontSize: "9px",
      margin: 0,
      whiteSpace: "pre",
      fontWeight: 500,
    },
    hr: { margin: "1rem 0", borderTop: "1px dashed #000" },
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {SalaryRegisterList && SalaryRegisterList.length > 0 && (
        <div>
          <ButtonWithLoading
            buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
            label={"Print"}
            className="no-visible-print-btn"
            type="button"
            onClick={() => window.print()}
          />
          <div>
            {/* TABLE START*/}
            <table
              className="salary-register-report-table"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th colSpan={10}>
                    <p style={style.headingText}>{COMPANY_NAME}</p>
                    <p style={style.headingTextSub}>{COMPANY_ADDRESS}</p>
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={2}
                    style={{ verticalAlign: "bottom", paddingBottom: "10px" }}
                  >
                    <p style={style.headingTextSub}>
                      P.F.No :-{" "}
                      <b style={{ fontSize: "13px" }}> {COMPANY_PF_NO}</b>
                    </p>
                    <p style={style.headingTextSub}>
                      ESI No :-{" "}
                      <span style={{ fontSize: "12px" }}>{COMPANY_ESI_NO}</span>
                    </p>
                  </th>
                  <th
                    colSpan={4}
                    className="table-text-center"
                    style={{
                      verticalAlign: "bottom",
                      lineHeight: "18px",
                      paddingBottom: "10px",
                    }}
                  >
                    <p
                      style={{
                        ...style.headingText,
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight:"20px"
                      }}
                    >
                      <span style={{ ...style.headingTextSub }}>
                        Payment Date:-{" "}
                      </span>{" "}
                      <b>Salary Register</b>
                    </p>
                    <p style={{ ...style.headingTextSub, textAlign: "end" , marginRight:"20px"}}>
                      <b style={style.headingText}>
                        Month :- &nbsp;{MONTHS_MAP[state.payout_month]} -{" "}
                        {state.payout_year}
                      </b>
                    </p>
                  </th>
                  <th
                    colSpan={4}
                    className="table-text-end"
                    style={{ position: "relative" }}
                  >
                    <p style={style.formrules}>
                      (1)Form under Rule - 6 of Equal Remuneration Rules 1976.
                    </p>
                    <p style={style.formrules}>
                      (2) Form under Rule - 21(4),25(2), 26(1) and 26(2) of
                      Gujarat Minimum Wages Rules 1961
                    </p>
                    <p style={style.formrules}>
                      (1) Form under Rule - 6 of Payment of Wages Gujarat Rules.
                      1963.
                    </p>
                    <p style={style.formrules}>
                      (2) Form 17 under Rule - 78 of Contract Labour (Regulation
                      & Abolition) Gujarat, Rules. 1972
                    </p>
                    <p style={style.formrules}>
                      (3) Form under Rule - 52(2)of Inter State Migrant Workers
                      (Gujarat) Rules 1981
                    </p>
                  </th>
                </tr>
                <tr>
                  <th rowSpan={2} className="table-text-center">
                    Sr. No.
                  </th>
                  <th
                    rowSpan={2}
                    className="table-text-start"
                    style={{ lineHeight: "20px" }}
                  >
                    <p>Employee Name</p>
                    <p className="table-text-right">Employee Id</p>
                    <p>Department</p>
                    <p>Designation</p>
                    <p>PF No</p>
                    <p>UAN No</p>
                    <p>ESI.No</p>
                  </th>
                  <th rowSpan={2} className="table-text-center">
                    Working Details
                  </th>
                  <th className="table-text-center">Rate</th>
                  <th className="table-text-center">Earnings</th>
                  <th rowSpan={2} className="table-text-center">
                    <p>Gross Salary</p>
                    <br />
                    <br />
                    <p style={{ whiteSpace: "nowrap" }}>PF Wages</p>
                    <br />
                    <p style={{ whiteSpace: "nowrap" }}>ESI Wages</p>
                  </th>
                  <th colSpan={2} className="table-text-center">
                    Deduction
                  </th>
                  <th rowSpan={2} className="table-text-center">
                    Net Salary Payable in Rs.
                  </th>
                  <th rowSpan={2} className="table-text-center">
                    Signature of{" "}
                    <p style={{ whiteSpace: "nowrap" }}>employee / Thumb</p>{" "}
                    Impression
                  </th>
                </tr>
                <tr>
                  <th className="table-text-start table-line-height">
                    <p>BASIC</p>
                    <p>HRA</p>
                    <p>WASH</p>
                    <p>PROD.INS.ALL</p>
                    <p>ADV BONUS</p>
                    <p>ADV LEAVE</p>
                    <p>ADV STD BE</p>
                    <p>FOOD</p>
                    <p>DA/Arrears</p>
                    <p>OTHER EARNING</p>
                  </th>
                  <th className="table-text-start table-line-height">
                    <p>BASIC</p>
                    <p>HRA</p>
                    <p>WASH</p>
                    <p>PROD.INS.ALL</p>
                    <p>ADV BONUS</p>
                    <p>ADV LEAVE</p>
                    <p>ADV STD BE</p>
                    <p>FOOD</p>
                    <p>DA/Arrears</p>
                    <p>OTHER EARNING</p>
                  </th>
                  <th className="table-text-center">
                    <p>P.F</p>
                    <p>ESI</p>
                    <p>IT</p>
                    <p>P.T.</p>
                    <p>Loan</p>
                    <p>Advn.</p>
                    <p>LWF</p>
                    <p>TDS</p>
                    <p>Oth.Ded</p>
                  </th>
                  <th className="table-text-center">Gross Deduction</th>
                </tr>
              </thead>
              <tbody>
                {SalaryRegisterList.map((emp: any, index: number) => (
                  <tr key={emp.id}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td
                      className="table-text-start"
                      style={{ lineHeight: "20px" }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {emp?.employee_name}
                      </p>
                      <p
                        style={{ textTransform: "uppercase" }}
                        className="table-text-right"
                      >
                        {emp?.employee_code_string || "-"}
                      </p>
                      <p style={{ textTransform: "uppercase" }}>
                        {emp?.department_name || "-"}
                      </p>
                      <p style={{ textTransform: "uppercase" }}>
                        {emp?.designation_name || "-"}
                      </p>
                      <p>{emp?.pf_no || "-"}</p>
                      <p>{emp?.uan_no || "-"}</p>
                      <p>{emp?.est_no || "-"}</p>
                    </td>
                    <td className="word-nowarp-td working-details-cell">
                      <p>
                        <span> WD</span>
                        <span>
                          {" "}
                          {Number(emp?.working_days || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span> WO</span>{" "}
                        <span> {Number(emp?.weekly_off || 0)?.toFixed(2)}</span>
                      </p>
                      <p>
                        <span> PH</span>{" "}
                        <span> {Number(emp?.ph || 0)?.toFixed(2)}</span>
                      </p>
                      <p>
                        <span> PD</span>
                        <span>
                          {" "}
                          {Number(emp?.present_days || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span> PL</span>{" "}
                        <span> {Number(emp?.pl || 0)?.toFixed(2)}</span>
                      </p>
                      <p>
                        <span> CL</span>{" "}
                        <span>
                          {" "}
                          {Number(emp?.casual_leave || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span> SL</span>{" "}
                        <span> {Number(emp?.sl || 0)?.toFixed(2)}</span>
                      </p>
                      <p>
                        <span> AB</span>{" "}
                        <span>
                          {(
                            Number(emp?.working_days || 0) -
                            Number(emp?.present_days || 0)
                          )?.toFixed(2)}
                        </span>
                      </p>
                      <p
                        style={{
                          borderTop: "0.5px solid #757575",
                          position: "relative",
                          bottom: "0",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        <span> TOT</span>{" "}
                        <span>
                          {(
                            Number(emp?.present_days || 0) +
                              Number(emp?.weekly_off || 0) +
                              Number(emp?.casual_leave || 0) || 0
                          )?.toFixed(2)}
                        </span>
                      </p>
                    </td>
                    <td
                      className="table-text-end"
                      style={{ position: "relative" }}
                    >
                      <p>{Number(emp?.actual_basic || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_hra || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_wash || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_prod_ins_all || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_adv_bonus || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_adv_leave || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_adv_std_be || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_food || 0)?.toFixed(2)}</p>
                      <p>{Number(emp?.actual_da || 0)?.toFixed(2)}</p>
                      <p>
                        {Number(emp?.actual_other_earning || 0)?.toFixed(2)}
                      </p>

                      <p
                        style={{
                          borderTop: "0.5px solid #757575",
                          position: "relative",
                          bottom: "0",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        {Number(
                          (emp?.actual_basic || 0) +
                            (emp?.actual_hra || 0) +
                            (emp?.actual_wash || 0) +
                            (emp?.actual_prod_ins_all || 0) +
                            (emp?.actual_adv_bonus || 0) +
                            (emp?.actual_adv_leave || 0) +
                            (emp?.actual_adv_std_be || 0) +
                            (emp?.actual_food || 0) +
                            (emp?.actual_da || 0) +
                            (emp?.actual_other_earning || 0)
                        )?.toFixed(2)}
                      </p>
                    </td>
                    <td
                      className="table-text-end"
                      style={{ position: "relative" }}
                    >
                      <p>{Number(emp?.basic || 0)}</p>
                      <p>{Number(emp?.hra || 0)}</p>
                      <p>{Number(emp?.wash || 0)}</p>
                      <p>{Number(emp?.prod_ins_all || 0)}</p>
                      <p>{Number(emp?.adv_bonus || 0)}</p>
                      <p>{Number(emp?.adv_leave || 0)}</p>
                      <p>{Number(emp?.adv_std_be || 0)}</p>
                      <p>{Number(emp?.food || 0)}</p>
                      <p>{Number(emp?.da || 0)}</p>
                      <p>{Number(emp?.other_earning || 0)}</p>
                      <p
                        style={{
                          borderTop: "0.5px solid #757575",
                          position: "relative",
                          bottom: "0",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        {Number(emp?.total_income || 0)?.toFixed(2)}
                      </p>
                    </td>
                    <td className="table-text-end">
                      <p style={{ fontWeight: "bold" }}>
                        {Number(emp?.total_income || 0)}
                      </p>
                      <br />
                      <br />
                      <p style={{ whiteSpace: "nowrap" }}>
                        {Number(emp?.pf || 0)
                          ? Number(emp?.celling_amount || 0)
                          : 0}
                      </p>
                      <br />
                      <p style={{ whiteSpace: "nowrap" }}>
                        {Number(emp?.total_income || 0) < 21000
                          ? Number(emp?.total_income || 0)
                          : 0}
                      </p>
                    </td>
                    <td className="table-text-end">
                      <p>{Number(emp?.pf)}</p>
                      <p>{Number(emp?.esi)}</p>
                      <p>{Number(emp?.it)}</p>
                      <p>{Number(emp?.pt)}</p>
                      <p>{Number(emp?.loan)}</p>
                      <p>{Number(emp?.advance)}</p>
                      <p>{Number(emp?.lwf)}</p>
                      <p>{Number(emp?.tds)}</p>
                      <p>{Number(emp?.other)}</p>
                    </td>
                    <td
                      className="table-text-end"
                      style={{ fontWeight: "bold" }}
                    >
                      <p>{Number(emp?.total_deduction)}</p>
                    </td>
                    <td
                      className="table-text-end"
                      style={{ fontWeight: "bold" }}
                    >
                      <p>{Number(emp?.payable_amount)}</p>
                    </td>
                    <td style={{ position: "relative", textAlign: "right" }}>
                      <p
                        style={{
                          position: "absolute",
                          bottom: "45px",
                          right: "5px",
                          textTransform: "uppercase",
                        }}
                      >
                        {emp?.bank_name}
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          bottom: "25px",
                          right: "5px",
                          textTransform: "uppercase",
                        }}
                      >
                        {emp?.ifsc_code}
                      </p>
                      <p
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                        }}
                      >
                        {emp?.account_no}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SalaryRegisterReport;
