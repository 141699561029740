import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocument, IDocumentState } from "./documentModel";
import {
  documentActiveInactive,
  createDocument,
  deleteDocument,
  getAllActiveDocuments,
  getAllDocuments,
  getDocumentByID,
  updateDocument,
} from "./services/document.services";

const initialState: IDocumentState = {
  loading: false,
  formLoading: false,
  error: "",
  DocumentList: [],
  DocumentID: null,
  DocumentGUID: "",
  DocumentDetail: {
    document_name: "",
    isactive: 1,
  },
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    clearDocumentDetails: (state) => {
      state.DocumentDetail = initialState.DocumentDetail;
    },
    setDocumentID: (state, action) => {
      state.DocumentID = action.payload;
    },
    clearDocumentID: (state) => {
      state.DocumentID = initialState.DocumentID;
    },
    setDocumentGUID: (state, action) => {
      state.DocumentGUID = action.payload;
    },
    clearDocumentGUID: (state) => {
      state.DocumentGUID = initialState.DocumentGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDocuments.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDocuments.fulfilled,
      (state, action: PayloadAction<IDocument[]>) => {
        state.loading = false;
        state.DocumentList = action.payload || [];
      }
    );
    builder.addCase(getAllDocuments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DocumentList = [];
    });
    builder.addCase(getAllActiveDocuments.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveDocuments.fulfilled,
      (state, action: PayloadAction<IDocument[]>) => {
        state.loading = false;
        state.DocumentList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveDocuments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DocumentList = [];
    });
    builder.addCase(createDocument.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createDocument.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createDocument.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateDocument.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateDocument.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateDocument.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteDocument.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(documentActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(documentActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(documentActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getDocumentByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getDocumentByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.DocumentDetail = action.payload;
    });
    builder.addCase(getDocumentByID.rejected, (state, action) => {
      state.formLoading = false;
      state.DocumentDetail = initialState.DocumentDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearDocumentDetails,
  setDocumentID,
  clearDocumentID,
  setDocumentGUID,
  clearDocumentGUID,
} = documentSlice.actions;
export default documentSlice.reducer;
