import React from "react";
import Drawer from "@mui/material/Drawer";
import { closed } from "./muiDrawerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Box } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";

export interface Element extends React.ReactElement<any, any> {}

interface IDrawerProps {
  children?: Element | Element[];
}

const AppDrawer: React.FC<IDrawerProps> = (props) => {
  const drawerState = useAppSelector((state) => state.muidrawer);
  const dispatch = useAppDispatch();
  const toggleDrawer = () => {
    dispatch(closed());
  };
  return (
    <Box
      sx={{
        width: { sm: drawerState?.drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        className="mt-50"
        anchor={drawerState?.anchor}
        open={drawerState.open}
        onClose={toggleDrawer}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerState?.drawerWidth,
            padding: "0 15px",
            zIndex: 1,
            margin:0
          },
        }}
      >
        <Toolbar />
        {props.children}
      </Drawer>
    </Box>
  );
};

export default AppDrawer;

// import * as React from "react";

// import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { RootState } from "../../app/store";
// import { closed } from "./drawerSlice";

// export interface Element extends React.ReactElement<any, any> {}

// interface IDrawerProps {
//     children?: Element | Element[];
// }

// const AppDrawer: React.FC<IDrawerProps> = (props) => {
//     const drawerState = useAppSelector((state: RootState) => state.drawer);
//     const dispatch = useAppDispatch();
//     const toggleDrawer = () => {
//         dispatch(closed());
//     };

//     return (
//         <div>
//             <Drawer
//                 expanded={drawerState.open}
//                 position={"end"}
//                 mode={"overlay"}
//                 animation={{ duration: 400 }}
//                 items={[]}
//                 width={drawerState.drawerWidth}
//                 onOverlayClick={toggleDrawer}
//                 onSelect={toggleDrawer}
//             >
//                 <DrawerContent
//                     style={{ zIndex: 10, position: "fixed", top: 60, right: 8 }}
//                 >
//                     {props.children}
//                 </DrawerContent>
//             </Drawer>
//         </div>
//     );
// };

// export default AppDrawer;
