import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
// import FormSelectionField from "../../../components/formFields/FormSelectionField";
// import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";

import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  //   closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearEducationDetails,
  clearEducationDetailsGUID,
  //   clearEducationDetailsGUID,
  clearEducationDetailsList,
  decreaseActiveStep,
  increaseActiveStep,
  setEducationDetails,
  setEducationDetailsGUID,
} from "../employeeSlice";
import { getYearsArray } from "../../../_helper/helper";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import FormAttachmentUpload from "../../../components/formFields/FormAttachmentUpload";
import {
  createEmployeeEducation,
  deleteEmployeeEducation,
  getEducationByUserID,
  updateEmployeeEducation,
  updateEmployeeEducationAttachment,
} from "../services/employee.services";
import { FaPaperclip } from "react-icons/fa";
import { requiredValidator } from "../../../components/formFields/CommonValidator";

const EducationDetails = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;

  useEffect(() => {
    return () => {
      dispatch(clearEducationDetails());
      dispatch(clearEducationDetailsList());
    };
  }, []);

  useEffect(() => {
    if (EmployeeID) {
      dispatch(getEducationByUserID(+EmployeeID));
    }
  }, [EmployeeID]);

  return (
    <>
      <DeleteEducationDetailsDialog />
      <EducationDetailsForm />
      <EducationDetailsGridComponent />
    </>
  );
};

const EducationDetailsForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const EmployeeID = location.state?.EmployeeID;
  const loading = useAppSelector((state) => state.employee.loading);

  const EducationDetails = useAppSelector(
    (state) => state.employee.EducationDetails
  );
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [EducationDetails]);

  const handleSubmit = async (values: any) => {
    const formdata = {
      qualification: values?.qualification,
      university_board: values?.university_board,
      employee_id: EmployeeID,
      year: values?.year,
    };
    const attachment = values?.document_attchement;
    try {
      if (!EducationDetails?.education_guid) {
        const response = await dispatch(createEmployeeEducation(formdata));
        if (response?.payload?.Status === 201) {
          if (response?.payload?.education_guid) {
            if (typeof attachment !== "string" && attachment.length > 0) {
              const files = new FormData();
              const binaryDoc = attachment[0]?.getRawFile();
              files.append("education_guid", response?.payload?.education_guid);
              files.append("document_attchement", binaryDoc);
              await dispatch(updateEmployeeEducationAttachment(files));
            }
          }
          dispatch(getEducationByUserID(EmployeeID));
          setFormKey(formKey + 1);
          dispatch(clearEducationDetails());
        }
      } else {
        const editPayload = {
          education_guid: EducationDetails?.education_guid,
          ...formdata,
        };
        const response = await dispatch(updateEmployeeEducation(editPayload));

        if (response?.meta?.requestStatus === "fulfilled") {
          if (values?.education_guid) {
            if (typeof attachment !== "string" && attachment.length > 0) {
              const files = new FormData();
              const binaryDoc = attachment[0]?.getRawFile();
              files.append("education_guid", values?.education_guid);
              files.append("document_attchement", binaryDoc);
              await dispatch(updateEmployeeEducationAttachment(files));
            }
          }
          dispatch(getEducationByUserID(EmployeeID));
          setFormKey(formKey + 1);
          dispatch(clearEducationDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={EducationDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 60 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "15%" },
                { width: "25%" },
                { width: "10%" },
                { width: "30%" },
                { width: "20%" },
              ]}
            >
              <GridLayoutItem colSpan={5}>
                <Typography.h4>{"Education Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="qualification"
                  label="Qualification"
                  component={FormTextField}
                  placeholder="i.e. BCA"
                  validator={requiredValidator} astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="university_board"
                  label="University / Board"
                  component={FormTextField}
                  placeholder="i.e. `Gujarat University`"
                  validator={requiredValidator} astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="year"
                  label="Year"
                  format={"yyyy"}
                  placeholder="Passing Year"
                  component={FormSelectionField}
                  options={getYearsArray(1975, new Date().getFullYear() + 1)?.map(
                    (year: any) => {
                      return {
                        value: year?.value,
                        label: year?.label,
                      };
                    }
                  )}
                  validator={requiredValidator} astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ display: "flex" }}>
                <Field
                  imageWidth={60}
                  imageHeight={60}
                  name={`document_attchement`}
                  label="Attachments"
                  type="file"
                  // accept="image/*"
                  component={FormAttachmentUpload}
                  validator={requiredValidator} astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearEducationDetails());
                  }}
                >
                  Clear
                </Button>
                <ButtonWithLoading
                  label={EducationDetails?.education_guid ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={5}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <Button
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </Button>
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => dispatch(increaseActiveStep())}
                >
                  Next
                </Button>
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => navigate("/employee")}
                >
                  Go to Employee List
                </Button>
              </GridLayoutItem>
            </GridLayout>
            {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const EducationDetailsGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const EducationDetailsList = useAppSelector(
    (state) => state.employee.EducationDetailsList
  );
  const loading = useAppSelector((state) => state.employee.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? EducationDetailsList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        education_guid: item?.education_guid,
        qualification: item?.qualification,
        university_board: item?.university_board,
        document_attchement: item?.document_attchement,
        employee_id: item.employee_id,
        year: item?.year,
      };
      dispatch(setEducationDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteEducationDetails"));
      dispatch(setEducationDetailsGUID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem?.education_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 14 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {EducationDetailsList && EducationDetailsList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={EducationDetailsList}
          skip={page.skip}
          take={page.take}
          total={EducationDetailsList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="sr_no"
            title="Sr No."
            cell={(props: any) => {
              return <td>{props.dataIndex + 1}</td>;
            }}
          />
          <Column field="qualification" title="Qualification" width={300} />
          <Column field="university_board" title="University" />
          <Column field="year" title="Year" />
          <Column
            field="document_attchement"
            title="Attachment"
            width={"120px"}
            cell={(props: any) => {
              return (
                <td>
                  <Link
                    to={props.dataItem?.document_attchement}
                    target="_blank"
                  >
                    <IconButton
                      type="button"
                      fillMode="flat"
                      size="small"
                      title="Attachment"
                      themeColor="info"
                    >
                      <FaPaperclip
                        className="absolute-position"
                        style={{ fontSize: "20px" }}
                      />
                    </IconButton>
                  </Link>
                </td>
              );
            }}
          />

          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteEducationDetailsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;
  const EducationDetailsGUID = useAppSelector(
    (state) => state.employee.EducationDetailsGUID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: string) => {
    if (ID) {
      const response = await dispatch(deleteEmployeeEducation(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getEducationByUserID(EmployeeID));
        dispatch(closeDialog());
        dispatch(clearEducationDetailsGUID());
      } else {
        dispatch(getEducationByUserID(EmployeeID));
        dispatch(closeDialog());
        dispatch(clearEducationDetailsGUID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteEducationDetails" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Education Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Education Details?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(EducationDetailsGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default EducationDetails;
