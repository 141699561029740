import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IBom } from "../bomModel";

export const createBom = createAsyncThunk("BOM/InsertBom", async (bom: any) => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/BOM/InsertBom`,
      bom
    );
    SuccessToast(response.data?.Details || "Success");
    return response.data;
  } catch (error: any) {
    ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Bom:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const updateBom = createAsyncThunk("Bom/UpdateBom", async (bom: any) => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Bom/UpdateBom`,
      bom
    );
    SuccessToast(response.data?.Details || "Success");
    return response.data;
  } catch (error: any) {
    ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Bom:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const deleteBom = createAsyncThunk(
  "Bom/DeleteBom",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(`${API_URL}/Bom/DeleteBom`, {
        bom_guid: ID,
      });
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Bom:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllBom = createAsyncThunk("Bom/FindAllBom", async () => {
  try {
    const response = await axiosApiInstance.post(`${API_URL}/Bom/FindAllBom`, {
      bom: "",
    });
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching BOM:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const getAllActiveBom = createAsyncThunk(
  "Bom/FindAllActiveBom",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Bom/FindAllActiveBom`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Bom:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBomById = createAsyncThunk(
  "Bom/FindByIDBom",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Bom/FindByIDBom`,
        { bom_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        bom_guid: result.bom_guid,
        item_id: result.item_id ? +result.item_id : null,
        item_qty: result.item_qty ? +result.item_qty : null,
        unit_id: result.unit_id ? +result.unit_id : null,
        total_amount: result.total_amount ? +result.total_amount : null,
        isactive: result.isactive === true ? 1 : 2,
        item_options: result?.item_options ? result?.item_options : [],
        BomDetails: result.BomDetails
          ? result.BomDetails.map((item: any) => {
              return {
                id: item.id,
                bom_details_guid: item.bom_details_guid
                  ? +item.bom_details_guid
                  : null,
                bom_id: item.bom_id ? +item.bom_id : null,
                item_id: item.item_id ? +item.item_id : null,
                item_options: item?.item_options ? item?.item_options : [],
                item_qty: item.item_qty ? +item.item_qty : null,
                rate: item.rate ? +item.rate : null,
                product_name: item.product_name,
                total: item.total ? +item.total : null,
              };
            })
          : [],
      } as IBom;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BOM:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
