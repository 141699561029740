import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/drawer/drawerSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { createBom, getBomById, updateBom } from "./services/bom.services";
import { clearBomDetail } from "./bomSlice";
import { findAllActiveUnit } from "../unit/services/unit.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import BomDetailsArray from "./BomDetailsArray";
import { Typography } from "@progress/kendo-react-common";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";

const CreateBom: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [formKey, setFormKey] = useState(1);
  const BomDetail = useAppSelector((state: RootState) => state.bom.BomDetail);
  const loading = useAppSelector((state) => state.bom.loading);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const bom_guid = location.state?.ID;
  const gridRef = useRef<any>(null);
  useEffect(() => {
    if (bom_guid) {
      dispatch(getBomById(bom_guid));
      dispatch(closed());
    }
  }, [bom_guid]);

  useEffect(() => {
    dispatch(findAllActiveUnit());
  }, []);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (bom_guid) {
      const editPayload = {
        id: values.id,
        bom_guid: bom_guid,
        item_id: values.item_id ? +values.item_id : null,
        item_qty: values.item_qty ? +values.item_qty : null,
        unit_id: values.unit_id ? +values.unit_id : null,
        total_amount: values.total_amount ? +values.total_amount : null,
        isactive: values.isactive === 1,
        BomDetails: values.BomDetails
          ? values.BomDetails.map((bom_info: any) => {
              return {
                id: bom_info.id ? bom_info.id : 0,
                bom_id: bom_info.bom_id ? bom_info.bom_id : null,
                item_id: bom_info.item_id ? +bom_info.item_id : null,
                item_qty: bom_info.item_qty ? +bom_info.item_qty : null,
                rate: bom_info.rate ? +bom_info.rate : null,
                total: bom_info.total ? +bom_info.total : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateBom(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearBomDetail());
          navigate("/bom");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        item_id: values.item_id ? +values.item_id : null,
        item_qty: values.item_qty ? values.item_qty : null,
        unit_id: values.unit_id ? values.unit_id : null,
        total_amount: values.total_amount ? +values.total_amount : null,
        isactive: values.isactive === 1,
        BomDetails: values.BomDetails
          ? values.BomDetails.map((bom_info: any) => {
              return {
                bom_id: bom_info.bom_id ? +bom_info.bom_id : 0,
                item_id: bom_info.item_id ? +bom_info.item_id : null,
                item_qty: bom_info.item_qty ? +bom_info.item_qty : null,
                rate: bom_info.rate ? +bom_info.rate : null,
                total: bom_info.total ? +bom_info.total : null,
              };
            })
          : [],
      };
      const response = await dispatch(createBom(insertPayload));
      try {
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearBomDetail());
          navigate("/bom");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    dispatch(clearBomDetail());
    navigate("/bom");
  };

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [BomDetail]);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        key={formKey}
        initialValues={BomDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                {/* {bom_guid ? ( */}
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {bom_guid ? "Update BOM" : "Add BOM"}
                  </Typography.h4>
                </GridLayoutItem>
                {/* ) : (
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4>
                      {bom_guid ? "Update BOM" : "Add BOM"}
                    </Typography.h4>
                  </GridLayoutItem>
                )} */}
                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Item Name"
                    placeholder="Item Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="item_qty"
                    label="Item Quantity"
                    placeholder="0"
                    minNumber="0"
                    format="n3"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="unit_id"
                    label="Unit"
                    placeholder="Unit"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={UnitList?.map((unit: any) => {
                      return {
                        value: unit.id,
                        label: unit.unit_name,
                      };
                    })}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={4}>
                <Typography.h5>Item Details</Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{
                  borderBottom: "1px solid lightgray",
                  paddingBottom: 10,
                }}
              >
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={BomDetailsArray}
                  handleItemSearchChange={handleItemSearchChange}
                  name="BomDetails"
                />
              </GridLayoutItem>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!bom_guid ? "Save" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateBom;
